<template>
  <div class="h-full">
    <header class="bg-slate-100">
      <nav
        class="mx-auto flex w-full items-center justify-between p-2.5 lg:px-8"
        aria-label="Global"
      >
        <div class="flex-1">
          <BaseIcon @click="closeViewer" name="XIcon" class="w-5 h-5" />
        </div>
        <div
          class="w-full flex items-center justify-center text-lg mt-1.5 font-bold"
        >
          {{ fileName }}
        </div>
      </nav>
    </header>

    <div class="h-full w-full overflow-y-auto relative">
      <!-- Spinner visible while loading the image -->
      <div v-show="loading" class="loading-overlay">
        <p>Loading Image...</p>
        <div class="loader"></div>
      </div>

      <!-- Actual image tag -->
      <img
        v-show="imageLoaded"
        :src="`http://46.101.194.80:3000/${folder}/${filePath}`"
        alt="image"
        class="object-cover object-center"
        @load="onImageLoad"
        @error="onImageError"
      />

      <!-- Placeholder if image isn't loaded -->
      <p v-if="imageError">Failed to load the image. Please try again.</p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue"; // Import necessary features from Vue
import BaseIcon from "../components/BaseIcon.vue"; // Import your icon component
import { modalController, loadingController } from "@ionic/vue"; // Import Ionic components

const props = defineProps(["filePath", "fileName", "folder"]);
const loading = ref(true); // Track loading state
const imageLoaded = ref(false); // Track image loading state
const imageError = ref(false); // Track image error state

// Function to show loading spinner
const showLoading = async () => {
  const loading = await loadingController.create({
    message: "Loading Image...",
    spinner: "crescent", // Choose your spinner style
  });
  await loading.present();
  return loading; // Return the loading controller for dismissal later
};

// Handle successful image load
const onImageLoad = () => {
  loading.value = false; // Dismiss loading
  imageLoaded.value = true; // Mark image as loaded
  console.log("Image loaded successfully"); // Debugging log
};

// Handle image load error
const onImageError = () => {
  loading.value = false; // Dismiss loading
  imageError.value = true; // Set image error state
  console.error("Error loading image"); // Log error
};

// Show loading spinner when the component mounts
onMounted(async () => {
  //const loading = await showLoading(); // Show loading spinner
  // Automatically dismiss the loading spinner if image fails
  // Note: Here we rely on event handlers for load and error events
});

// Close viewer method
const closeViewer = async () => {
  await modalController.dismiss(); // Close the viewer
};
</script>

<style scoped>
.loading-overlay {
  position: absolute; /* to overlay only this component */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  z-index: 1000; /* Ensure it's on top */
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px; /* Size of the loader */
  height: 50px;
  animation: spin 2s linear infinite; /* Animation for spinning */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>