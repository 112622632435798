<template>
  <div
    dir="rtl"
    class="w-full h-screen overflow-y-auto overflow-x-hidden bg-gray-100 bg-grid-gray-100 bg-opacity-50"
  >
    <div class="max-w-3xl mx-auto py-4 px-4 mb-24">
      <div class="flex items-center gap-x-3 justify-start">
        <BaseIcon
          name="Bookmarks-D"
          className="w-8 h-8 text-gray-900"
          strokeWidth="0.1"
        />
        <div>
          <h1 class="text-3xl font-extrabold text-gray-900">الجهات</h1>
          <p class="mt-1 max-w-2xl text-md leading-6 text-gray-500">
            قائمة جميع الجهات التي ينتمي إليها الأشخاص
          </p>
        </div>
      </div>
      <div class="flex flex-col justify-center mx-6 md:mx-36 my-6">
        <button
          @click="openNewOfficeModal()"
          class="w-fit mt-10 mb-4 bg-indigo-600 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none shadow-md"
        >
          إضافة جهة
        </button>

        <div class="w-full flex justify-center">
          <div
            dir="rtl"
            class="relative bg-white w-full rounded-lg h-80 overflow-auto ring-1 ring-gray-900/5 -my-px"
          >
            <div
              class="sticky top-0 w-full z-20 bg-gray-200 py-2 text-gray-600 rounded-t border-b border-gray-300 text-center"
            >
              قائمة الجهات
            </div>
            <div class="divide-y">
              <div v-for="office in getOfficesList" :key="office.id">
                <div
                  class="flex flex-row justify-between items-center gap-3 p-3"
                >
                  <div class="flex flex-row items-center">
                    <div class="flex flex-col space-x-2">
                      <strong class="text-gray-900 text-sm font-semibold">{{
                        office.office
                      }}</strong>
                    </div>
                  </div>
                  <div class="flex flex-row">
                    <BaseIcon
                      @click="removeOffice(office)"
                      name="Trash"
                      className="w-5 h-5 text-rose-700"
                      strokeWidth="0.2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from "../components/BaseIcon.vue";
import { actionSheetController } from "@ionic/vue";
export default {
  data() {
    return {};
  },
  components: {
    BaseIcon,
  },
  methods: {
    async openNewOfficeModal() {
      await this.$store.commit("toggleNewOfficeModal", true);
    },
    async removeOffice(office) {
      const actionSheet = await actionSheetController.create({
        header: `حذف ${office.office}`,
        cssClass: "my-custom-class",
        buttons: [
          {
            text: "تأكيد",
            role: "destructive",
            handler: () => {
              this.$store.dispatch("removeOffice", office);
            },
          },
          {
            text: "إلغاء",
            role: "cancel",
            handler: () => {
              console.log("Cancel clicked");
            },
          },
        ],
      });
      await actionSheet.present();
    },
  },
  computed: {
    getOfficesList() {
      return this.$store.getters.getOfficesList;
    },
  },
  async created() {
    await this.$store.dispatch("getOffices");
  },
};
</script>
