<template>
  <ion-content class="ion-padding">
    <ion-list>
      <template v-for="(text, idx) in texts" :key="idx">
        <ion-item @click="selectText(text)">
          <ion-label>{{ text }}</ion-label>
        </ion-item>
      </template>
    </ion-list>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  popoverController,
} from "@ionic/vue";

export default {
  name: "TextPopover",
  props: {
    texts: Array,
    update: { type: Function },
  },
  components: { IonContent, IonItem, IonLabel, IonList },
  methods: {
    async selectText(text) {
      console.log(text);
      this.update(null);
    },
  },
};
</script>
