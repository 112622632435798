import { createApp } from "vue";
import App from "./App.vue";
import "./theme/core.css";
import router from "./router";
import store from "./store";
import "./axios.js";
import socket from "./socketio";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/core.css";
import "./registerServiceWorker";

//import './registerServiceWorker'

const app = createApp(App).use(IonicVue).use(router).use(store).use(socket, {
  connection: 'http://46.101.194.80:3000'//'http://172.20.10.4:3000',//'http://46.101.194.80:3000',//'http://46.101.194.80:3000', //"http://64.23.186.36:3000", //'https://sami2.com:3000' // https://sami2.com:3000
});

router.isReady().then(() => {
  app.mount("#app");
});
