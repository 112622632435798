<template>
  <ion-content
    aria-labelledby="primary-heading"
    class="relative min-w-0 flex-1 h-full overflow-hidden flex flex-col lg:order-last px-4"
  >
    <div
      v-if="dayGuests.length > 0"
      class="w-full h-full overflow-y-auto flex flex-col gap-y-4 px-4 pt-4 bg-slate-50 mb-32 pb-36"
    >
      <ul role="list" class="-mb-8">
        <li v-for="(guest, idx) in dayGuests" :key="idx">
          <div class="relative pb-8">
            <span
              v-if="idx !== dayGuests.length - 1"
              class="absolute right-5 ml-1 top-4 h-full w-[1.3px] bg-gray-200"
              aria-hidden="true"
            />
            <div class="relative flex space-x-3">
              <div class="ml-3 -mr-2">
                <span
                  class="h-12 w-14 text-gray-200 rounded-full flex flex-col items-center bg-gray-50 justify-center ring-8 ring-transparent"
                >
                  <BaseIcon
                    name="UserCircle-D"
                    class="shrink-0 w-5 h-5 text-slate-500"
                    strokeWidth="0.1"
                  />
                  <span class="shrink-0 h-[1px] w-1/2 bg-slate-500 my-1"></span>
                  <span
                    dir="ltr"
                    class="shrink-0 text-xs text-gray-500 font-bold"
                    >{{ idx + 1 }}</span
                  >
                </span>
              </div>
              <div
                @click="selectGuest(guest.id)"
                class="w-full flex rounded-3xl pl-4 custom-shadow"
                :class="[
                  guest.status == 'notapproved'
                    ? 'canceldBG'
                    : getSelected == guest.id
                    ? 'bg-yellow-50/40'
                    : 'bg-white',
                  guest.status == 'notapproved' ? 'opacity-70' : '',
                ]"
              >
                <div
                  class="mt-4 pr-px h-[2.8rem] w-[0.2rem] border-opacity-80"
                  :class="
                    guest.status == 'notapproved'
                      ? 'bg-rose-800 bg-opacity-30'
                      : 'bg-white'
                  "
                ></div>
                <div class="flex-none w-full pr-1 pt-2 -ml-4">
                  <div
                    class="mt-2 w-full flex gap-x-2 justify-between items-start"
                  >
                    <div
                      class="text-lg font-bold transition-all delay-150 duration-300 ease-in-out"
                      :class="
                        guest.status == 'notapproved'
                          ? 'line-through text-red-900'
                          : 'text-indigo-700'
                      "
                    >
                      {{ guest.title }}
                    </div>
                    <div
                      v-if="guest.status == 'notapproved'"
                      class="rounded-full flex items-center gap-x-1 bg-rose-50/80 p-1 text-rose-500 shadow-sm"
                    >
                      <BaseIcon
                        name="UserCross"
                        class="h-5 w-5"
                        strokeWidth="0.4"
                        aria-hidden="true"
                      />
                      <span class="text-xs mt-1">مرفوض</span>
                    </div>
                    <transition name="slide-updown-fade">
                      <div
                        v-if="getSelected !== guest.id"
                        class="flex opacity-100 items-center gap-x-2 transition-all"
                      >
                        <button
                          @click.stop="getNames(guest)"
                          type="button"
                          class="rounded-full bg-stone-50 p-1 text-stone-500 shadow-sm hover:bg-stone-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          <BaseIcon
                            name="Chat"
                            class="h-5 w-5"
                            strokeWidth="0.4"
                            aria-hidden="true"
                          />
                        </button>
                        <button
                          v-if="guest.status == 'waiting'"
                          @click.stop="acceptGuest(guest)"
                          type="button"
                          class="rounded-full bg-blue-50/70 p-1 text-blue-500 shadow-sm"
                        >
                          <BaseIcon
                            name="UserCheck"
                            class="h-5 w-5"
                            strokeWidth="0.4"
                            aria-hidden="true"
                          />
                        </button>
                        <button
                          v-if="guest.status == 'waiting'"
                          @click.stop="rejectGuest(guest)"
                          type="button"
                          class="rounded-full bg-rose-50/80 p-1 text-rose-500 shadow-sm"
                        >
                          <BaseIcon
                            name="UserCross"
                            class="h-5 w-5"
                            strokeWidth="0.4"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </transition>
                  </div>
                  <div class="w-full flex gap-x-2 py-3 pr-2">
                    <div class="w-full flex flex-col gap-y-2">
                      <div
                        class="flex flex-col gap-y-1 items-start justify-center"
                      >
                        <p class="text-xs text-gray-500">الأشخاص</p>
                      </div>
                      <div
                        class="flex flex-col gap-y-1 items-start justify-center"
                      >
                        <span
                          v-for="(guest, idx) in JSON.parse(guest.person)"
                          :key="idx"
                          class="text-sm font-bold text-gray-900"
                        >
                          {{ guest.name }}
                        </span>
                      </div>
                    </div>
                    <div class="w-full flex flex-col items-start gap-y-1">
                      <dt
                        class="-mr-2 flex items-center gap-x-px text-xs text-gray-500"
                      >
                        <span>الوقت</span>
                      </dt>
                      <dd
                        class="flex items-center gap-x-3 text-sm font-medium text-gray-900"
                      >
                        <span
                          class="inline-flex items-center rounded-full bg-gray-100/100 px-2 py-1 text-sm font-bold text-gray-900"
                          >{{
                            `${JSON.parse(guest.time).hour}:${
                              JSON.parse(guest.time).minute
                            } ${JSON.parse(guest.time).apm}`
                          }}</span
                        >
                      </dd>
                    </div>
                  </div>
                  <transition
                    v-on:before-enter="beforeEnter"
                    v-on:enter="enter"
                    v-on:after-enter="afterEnter"
                    v-on:enter-cancelled="enterCancelled"
                    v-on:before-leave="beforeLeave"
                    v-on:leave="leave"
                    v-on:after-leave="afterLeave"
                    v-on:level-cancelled="leaveCancelled"
                    v-bind:css="false"
                  >
                    <div
                      v-show="getSelected == guest.id"
                      class="grid grid-cols-2 gap-2 justify-items-stretch font-mono text-white text-sm font-bold leading-6"
                    >
                      <div
                        v-if="JSON.parse(guest.attachment).length > 0"
                        class="col-span-2"
                      >
                        <div
                          class="border-t border-gray-100 pb-6 sm:col-span-2 sm:px-0 pt-3"
                        >
                          <dt
                            class="text-xs font-medium leading-6 text-gray-900"
                          >
                            المرفقات
                          </dt>
                          <dd
                            class="mt-1 py-2 px-3 text-sm leading-6 text-indigo-700 bg-transparent"
                          >
                            <ul
                              role="list"
                              class="divide-y divide-gray-100 rounded-md border border-gray-200 bg-white"
                            >
                              <template
                                v-for="(file, idx) in JSON.parse(
                                  guest.attachment
                                )"
                                :key="idx"
                              >
                                <li
                                  @click="openPDF(file.name, file.description)"
                                  v-if="file.ext == 'pdf'"
                                  class="flex items-center justify-between py-2 px-2 text-sm leading-6"
                                >
                                  <div
                                    class="flex items-center w-0 flex-1 items-center"
                                  >
                                    <BaseIcon
                                      name="Attachment"
                                      class="h-4 w-4 flex-shrink-0 text-gray-400"
                                      aria-hidden="true"
                                      strokeWidth="0.2"
                                    />
                                    <div
                                      class="mr-2 pt-1.5 flex min-w-0 flex-1 gap-2"
                                    >
                                      <span class="truncate font-bold">
                                        {{
                                          file.description == ""
                                            ? `ملف رقم ${idx + 1}`
                                            : file.description
                                        }}</span
                                      >
                                      <span
                                        class="flex-shrink-0 text-gray-400"
                                        >{{ file.ext }}</span
                                      >
                                    </div>
                                  </div>
                                  <div class="ml-4 flex-shrink-0">
                                    <div
                                      class="font-medium text-indigo-600 hover:text-indigo-500"
                                    >
                                      <BaseIcon
                                        name="Eye"
                                        class="w-5 h-5"
                                        stokeWidth="0.1"
                                      />
                                    </div>
                                  </div>
                                </li>
                                <li
                                  @click="
                                    openImage(file.name, file.description)
                                  "
                                  v-else
                                  class="flex items-center justify-between py-2 px-2 text-sm leading-6"
                                >
                                  <div
                                    class="flex items-center w-0 flex-1 items-center"
                                  >
                                    <BaseIcon
                                      name="Attachment"
                                      class="h-4 w-4 flex-shrink-0 text-gray-400"
                                      aria-hidden="true"
                                      strokeWidth="0.2"
                                    />
                                    <div
                                      class="mr-2 pt-1.5 flex min-w-0 flex-1 gap-2"
                                    >
                                      <span class="truncate font-bold">
                                        {{
                                          file.description == ""
                                            ? `ملف رقم ${idx + 1}`
                                            : file.description
                                        }}</span
                                      >
                                      <span
                                        class="flex-shrink-0 text-gray-400"
                                        >{{ file.ext }}</span
                                      >
                                    </div>
                                  </div>
                                  <div class="ml-4 flex-shrink-0">
                                    <div
                                      class="font-medium text-indigo-600 hover:text-indigo-500"
                                    >
                                      <BaseIcon
                                        name="Eye"
                                        class="w-5 h-5"
                                        stokeWidth="0.1"
                                      />
                                    </div>
                                  </div>
                                </li>
                              </template>
                            </ul>
                          </dd>
                        </div>
                      </div>
                      <div class="col-span-2">
                        <div class="pb-6 sm:col-span-2 sm:px-0">
                          <dt
                            class="text-xs font-medium leading-6 text-gray-900"
                          >
                            الملاحظات
                          </dt>
                          <dd
                            class="mt-1 py-2 px-3 text-sm leading-6 text-yellow-700 bg-yellow-100/50 sm:mt-2"
                          >
                            {{ guest.note }}
                          </dd>
                        </div>
                      </div>
                      <transition-group name="slide-downup-fade">
                        <div
                          v-if="guest.status == 'waiting'"
                          @click="rejectGuest(guest)"
                          class="col-span-1 text-blue-600 mb-3 inline-flex items-center justify-center gap-x-1.5 rounded-md px-2.5 py-2 text-md font-semibold text-blue-700 bg-blue-100 active:bg-blue-50"
                        >
                          <BaseIcon
                            name="UserCheck"
                            class="-pr-1.5 h-5 w-5"
                            strokeWidth="0.1"
                            aria-hidden="true"
                          />
                          <span class="mt-1">قبول</span>
                        </div>
                        <div
                          v-if="guest.status == 'waiting'"
                          @click="rejectGuest(guest)"
                          class="col-span-1 mb-3 inline-flex items-center justify-center gap-x-1.5 rounded-md px-2.5 py-2 text-md font-semibold text-rose-700 bg-rose-50 active:bg-rose-50"
                        >
                          <BaseIcon
                            name="UserCross"
                            class="-pr-1.5 h-5 w-5"
                            strokeWidth="0.1"
                            aria-hidden="true"
                          />
                          <span class="mt-1">رفض</span>
                        </div>
                        <div
                          @click="getNames(guest)"
                          class="col-span-2 mb-3 inline-flex items-center justify-center gap-x-1.5 rounded-md px-2.5 py-2 text-md font-semibold bg-stone-400/50 text-stone-700 active:bg-stone-300"
                        >
                          <BaseIcon
                            name="Chat"
                            class="-mr-2 h-5 w-5"
                            strokeWidth="0.1"
                            aria-hidden="true"
                          />
                          <span class="mt-1">محادثة</span>
                        </div>
                      </transition-group>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      v-else
      class="w-full h-full flex items-center justify-center bg-slate-50 overflow-hidden mx-auto"
    >
      <div class="-mt-[50%] flex flex-col justify-center items-center gap-y-3">
        <BaseIcon name="NoGuests" className="w-24 h-24 text-slate-200" />
        <h2 class="font-bold text-slate-300">لا يوجد ضيوف لهذا اليوم</h2>
      </div>
    </div>
  </ion-content>
</template>

<script>
import moment from "moment-hijri";
moment.updateLocale("ar-sa", {
  postformat: (string) => string,
});
import BaseIcon from "../components/BaseIcon.vue";
import QuickEventInfo from "./QuickEventInfo.vue";
import UsersList from "../components/UsersChatListMobile.vue";
import UserChatMobile from "../components/UserChatMobile.vue";
import PDFViwer from "../components/PDFViewer.vue";
import ImgViewer from "../components/ImgViewer.vue";
import GuestTimeOptions from "../components/GuestTimeOptions.vue";

import { IonContent, modalController, actionSheetController } from "@ionic/vue";
export default {
  data() {
    return {
      interval: null,
      selectedGuest: null,
    };
  },
  components: {
    BaseIcon,
    IonContent,
    QuickEventInfo,
    UsersList,
    UserChatMobile,
    PDFViwer,
    ImgViewer,
    GuestTimeOptions,
  },
  props: ["currentDay"],
  computed: {
    dayGuests() {
      return this.$store.getters.getGuestsOfDay;
    },
    getSelected() {
      return this.selectedGuest;
    },
  },
  methods: {
    checkDayGuests(v) {
      return this.dayGuests.filter((guest) => {
        return (
          JSON.parse(guest.time).hour == v.name &&
          JSON.parse(guest.time).apm == v.apm &&
          guest.status != "waiting"
        );
      });
    },

    async openPDF(path, name) {
      const modal = await modalController.create({
        component: PDFViwer,
        componentProps: { filePath: path, fileName: name },
      });
      await modal.present();
    },

    async openImage(path, name) {
      const modal = await modalController.create({
        component: ImgViewer,
        componentProps: { filePath: path, fileName: name },
      });
      await modal.present();
    },

    selectGuest(guestID) {
      if (guestID == this.getSelected) {
        return (this.selectedGuest = null);
      }
      this.selectedGuest = guestID;
    },
    timeFromTo(time, duration) {
      let compare;
      if (duration == 0) {
        return (compare = {
          from: time.hour + ":" + time.minute + " " + time.apm,
          durationTo: "غير محدد",
        });
      } else {
        return (compare = {
          from: time.hour + ":" + time.minute + " " + time.apm,
          durationTo: moment(time.hour + ":" + time.minute + " " + time.apm, [
            "hh:mm A",
          ])
            .add(parseInt(duration), "minutes")
            .format("hh:mm A"),
        });
      }
    },
    canDoAction(currentEvent) {
      const currentDate = moment().format("YYYY/M/D HH:mm A");
      const eventTime =
        JSON.parse(currentEvent.time).hour +
        ":" +
        JSON.parse(currentEvent.time).minute +
        " " +
        JSON.parse(currentEvent.time).apm;
      const eventDate = moment(currentEvent.en_date + " " + eventTime, [
        "YYYY/M/D HH:mm A",
      ]).format("YYYY/M/D HH:mm A");
      //const clickedDate = moment(this.getCurrentEventData.en_date).format("YYYY/M/D");
      if (currentDate <= eventDate) {
        return true;
      } else {
        return false;
      }
    },
    async rejectGuest(currentGuest) {
      const actionSheet = await actionSheetController.create({
        header: `رفض الضيف`,
        cssClass: "my-custom-class",
        buttons: [
          {
            text: "تأكيد رفض الضيف",
            role: "destructive",
            handler: () => {
              currentGuest.type = "cancel";
              currentGuest.status = "notapproved";
              this.$store.dispatch("updateGuest", currentGuest);
              /*this.$store.dispatch("getEventsForCommingUp", {
                group: parseInt(currentEvent.group_num),
              });*/
            },
          },
          {
            text: "إغلاق",
            role: "cancel",
            handler: () => {},
          },
        ],
      });
      await actionSheet.present();
    },
    async acceptGuest(currentGuest) {
      const modal = await modalController.create({
        component: GuestTimeOptions,
        componentProps: { guest: currentGuest },
        initialBreakpoint: 0.5,
        breakpoints: [0.5, 0.6],
      });
      await modal.present();
    },
    async getNames(eventData) {
      let payload = {
        group: parseInt(localStorage.getItem("awqatiUserGroup")),
        me: parseInt(localStorage.getItem("awqatiUserId")),
      };
      const modal = await modalController.create({
        component: UsersList,
        componentProps: { payload, eventData, linkType: "guest" },
        initialBreakpoint: 0.7,
        breakpoints: [0, 0.8],
      });
      await modal.present();

      return modal.onDidDismiss().then((data) => {
        if (data) {
          if (data.data) {
            if (data.data.command == "openChat") {
              setTimeout(async () => {
                const modal = await modalController.create({
                  component: UserChatMobile,
                  cssClass: "my-custom-class",
                  componentProps: {
                    fromUser: data.data.userData,
                  },
                });
                return modal.present();
              }, 200);
            } else {
              return;
            }
          } else {
            return;
          }
        }
      });
    },
    updateCurrentTime() {
      const currentDate = moment().format("YYYY/M/D");
      const clickedDate = moment(this.currentDay).format("YYYY/M/D");
      if (currentDate == clickedDate) {
        this.currentTime = moment().format("h:mm A");

        var minute = moment().format("mm");
        var hour = parseInt(moment().format("H")) * 60;
        var total = (hour + parseInt(minute)) / 10;
      }
    },
    async showEventInfo(event) {
      const modal = await modalController.create({
        component: QuickEventInfo,
        componentProps: { event },
        initialBreakpoint: 0.7,
        breakpoints: [0.7, 0.8],
      });
      await modal.present();
    },

    beforeEnter(el) {
      el.style.height = 0;
      el.style.overflow = "hidden";
    },
    enter(el, done) {
      const increaseHeight = () => {
        if (el.clientHeight < el.scrollHeight) {
          const height = `${parseInt(el.style.height) + 5}px`;
          el.style.height = height;
        } else {
          clearInterval(this.enterInterval);
          done();
        }
      };
      this.enterInterval = setInterval(increaseHeight, 4);
    },
    afterEnter(el) {},
    enterCancelled(el) {
      clearInterval(this.enterInterval);
    },
    beforeLeave(el) {},
    leave(el, done) {
      const decreaseHeight = () => {
        if (el.clientHeight > 0) {
          const height = `${parseInt(el.style.height) - 5}px`;
          el.style.height = height;
        } else {
          clearInterval(this.leaveInterval);
          done();
        }
      };
      this.leaveInterval = setInterval(decreaseHeight, 4);
    },
    afterLeave(el) {},
    leaveCancelled(el) {
      clearInterval(this.leaveInterval);
    },
    getCategory(val) {
      let cat = this.categories.filter((cat) => cat.id == val);
      return cat[0];
    },
  },
  created() {
    this.interval = setInterval(() => this.updateCurrentTime(), 1000);

    this.$socket.on("syncEventsOfDay", (data) => {
      this.$store.dispatch("getEventsForCommingUp", {
        group: parseInt(localStorage.getItem("awqatiUserGroup")),
      });
      if (
        moment(this.currentDay).format("iYYYY/iM/iD") == data.dayy &&
        parseInt(localStorage.getItem("awqatiUserGroup")) == data.group
      ) {
        this.$store.dispatch(data.command, {
          date: data.dayy,
          group: data.group,
        });
      } else {
        console.log("IT IS NOT IN SAME DAY!");
      }
    });

    this.$socket.on("syncGuestsOfDay", (data) => {
      if (this.currentView == "day") {
        if (
          moment(this.currentDay).format("iYYYY/iM/iD") == data.dayy &&
          parseInt(localStorage.getItem("awqatiUserGroup")) == data.group
        ) {
          /*this.$store.dispatch("getEventsForCommingUp", {
            group: parseInt(localStorage.getItem("awqatiUserGroup")),
          });*/
          this.$store.dispatch(data.command, {
            date: data.dayy,
            group: data.group,
          });
        } else {
          console.log("IT IS NOT IN SAME DAY!");
        }
      }
    });
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
.custom-shadow {
  box-shadow: rgba(43, 41, 0, 0.16) 0px 8px 31px;
  border: 1px solid hsl(64, 6%, 93%);
}

.custom-bg {
  background-color: hsl(264, 6%, 98.5%);
}

/* Slide Up Down */
.slide-updown-fade-enter-active {
  transition: all 0.4s ease-out;
}

.slide-updown-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-updown-fade-enter-from,
.slide-updown-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

/* Slide Down Up */
.slide-downup-fade-enter-active {
  transition: all 0.4s ease-out;
}

.slide-downup-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-downup-fade-enter-from,
.slide-downup-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.slidedown-enter-active,
.slidedown-leave-active {
  transition: max-height 0.5s ease-in-out;
}

.slidedown-enter-to,
.slidedown-leave-from {
  overflow: hidden;
  max-height: 1000px;
}

.slidedown-enter-from,
.slidedown-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
