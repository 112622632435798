<template>
  <!-- Sheet Modal -->

  <div
    dir="rtl"
    class="w-full h-screen overflow-y-auto overflow-x-hidden bg-gray-100 bg-grid-gray-100 bg-opacity-50"
  >
    <div
      class="mt-8 mx-6 md:mx-44 flex flex-row items-center space-x-5 bg-red-50 border border-red-700 rounded-lg p-3 text-red-700"
    >
      <BaseIcon name="Alert" class="w-5 h-5 ml-2" strokeWidth="0.2" />
      <b>تنبيه</b>
      <p>هذه الصفحة تحتوي على بيانات حساسة، يرجى التأكد عند إجراء أي عملية.</p>
    </div>
    <div class="max-w-3xl mx-auto py-4 px-4 mb-2">
      <div class="flex items-center gap-x-3 justify-start">
        <BaseIcon
          name="Users-D"
          className="w-8 h-8 text-gray-900"
          strokeWidth="0.1"
        />
        <div>
          <h1 class="text-3xl font-extrabold text-gray-900">المستخدمين</h1>
          <p class="mt-1 max-w-2xl text-md leading-6 text-gray-500">
            إدارة مستخدمي النظام وصلاحياتهم وأيضاً المجموعات
          </p>
        </div>
      </div>
      <div>
        <button
          @click="openNewUserModal()"
          class="w-fit mt-10 mb-4 bg-indigo-600 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none shadow-md"
        >
          إضافة مستخدم
        </button>
        <div class="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
          <div class="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-3">
            <div
              v-for="user in allUsers"
              :key="user.id"
              class="relative rounded-lg border border-gray-300 bg-white px-4 py-3 shadow-sm flex flex-wrap items-center space-x-3 hover:border-indigo-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500"
            >
              <span
                class="h-7 w-7 rounded-full border border-gray-200 overflow-hidden bg-gray-100 ml-3"
              >
                <svg
                  class="h-full w-full text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                  ></path>
                </svg>
              </span>

              <div class="flex-1 min-w-0">
                <span class="absolute inset-0" aria-hidden="true" />
                <p class="text-sm font-semibold text-gray-700">
                  {{ user.name }}
                </p>
                <p class="text-xs text-gray-500 truncate">
                  {{ user.position }}
                </p>
              </div>

              <div
                v-if="!isItMe(user)"
                class="absolute -top-4 -left-2 flex flex-row justify-between z-20 ml-auto"
              >
                <button
                  type="button"
                  class="bg-indigo-50 rounded-full h-8 w-8 mx-1 flex items-center justify-center text-indigo-400 hover:bg-indigo-100 hover:text-indigo-500 focus:outline-none border border-indigo-700"
                >
                  <BaseIcon
                    name="Edit"
                    class="h-5 w-5 text-blue-500"
                    strokeWidth="0.2"
                    aria-hidden="true"
                  />
                </button>
                <button
                  @click="removeUser(user)"
                  type="button"
                  class="bg-red-50 rounded-full h-8 w-8 mr-2 flex items-center justify-center text-red-400 hover:bg-red-100 hover:text-red-500 focus:outline-none border border-red-700"
                >
                  <BaseIcon
                    name="Trash"
                    class="h-5 w-5 text-rose-500"
                    strokeWidth="0.2"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full my-2 mx-52 border-b border-gray-200"></div>

    <div class="max-w-3xl mx-auto py-4 px-4">
      <h1 class="text-3xl font-extrabold text-blue-gray-900">الصلاحيات</h1>
      <div>
        <div class="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
          <div class="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-3">
            <div
              v-for="priv in privileges"
              :key="priv.id"
              class="relative rounded-lg border border-gray-300 bg-white px-4 py-3 shadow-sm flex flex-wrap items-center space-x-3 hover:border-indigo-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500"
            >
              <span
                class="flex items-center justify-center h-7 w-7 rounded-full border border-gray-200 overflow-hidden bg-gray-100 ml-3"
              >
                <BaseIcon
                  name="Key"
                  class="h-5 w-5 text-gray-500"
                  strokeWidth="0.2"
                  aria-hidden="true"
                />
              </span>

              <div class="flex-1 min-w-0">
                <span class="absolute inset-0" aria-hidden="true" />
                <p class="text-sm font-semibold text-gray-700">
                  {{ priv.privilege }}
                </p>
                <p class="text-xs text-gray-500 truncate">
                  {{ priv.id }}
                </p>
              </div>

              <div
                class="absolute -top-5 -left-2 flex flex-row justify-between z-20 ml-auto"
              >
                <button
                  @click="removePrivilege(priv)"
                  type="button"
                  class="bg-red-50 rounded-full h-8 w-8 mr-2 flex items-center justify-center text-red-400 hover:bg-red-100 hover:text-red-500 focus:outline-none border border-red-700"
                >
                  <BaseIcon
                    name="Trash"
                    class="h-5 w-5 text-rose-500"
                    strokeWidth="0.2"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full my-2 mx-52 border-b border-gray-200"></div>

    <div class="max-w-3xl mx-auto py-4 px-4">
      <h1 class="text-3xl font-extrabold text-blue-gray-900">المجموعات</h1>
      <div>
        <button
          @click="openNewGroupModal()"
          class="w-fit mt-10 mb-4 bg-indigo-600 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none shadow-md"
        >
          إضافة مجموعة
        </button>
        <div class="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
          <div class="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-3">
            <div
              v-for="group in groups"
              :key="group.id"
              class="relative rounded-lg border border-gray-300 bg-white px-4 py-3 shadow-sm flex flex-wrap items-center space-x-3 hover:border-indigo-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500"
            >
              <span
                class="flex items-center justify-center h-7 w-7 rounded-full border border-gray-200 overflow-hidden bg-gray-100 ml-3"
              >
                <BaseIcon
                  name="Users"
                  class="h-5 w-5 text-gray-500"
                  strokeWidth="0.2"
                  aria-hidden="true"
                />
              </span>

              <div class="flex-1 min-w-0">
                <span class="absolute inset-0" aria-hidden="true" />
                <p class="text-sm font-semibold text-gray-700">
                  {{ group.group }}
                </p>
                <p class="text-xs text-gray-500 truncate">
                  {{ group.id }}
                </p>
              </div>

              <div
                class="absolute -top-5 -left-2 flex flex-row justify-between z-20 ml-auto"
              >
                <button
                  @click="removeGroup(group)"
                  type="button"
                  class="bg-red-50 rounded-full h-8 w-8 mr-2 flex items-center justify-center text-red-400 hover:bg-red-100 hover:text-red-500 focus:outline-none border border-red-700"
                >
                  <BaseIcon
                    name="Trash"
                    class="h-5 w-5 text-rose-500"
                    strokeWidth="0.2"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full my-2 mx-52 border-b border-gray-200"></div>

    <div class="max-w-3xl mx-auto py-4 px-4 mb-24">
      <h1 class="text-3xl font-extrabold text-blue-gray-900">حالة المستخدم</h1>
      <div>
        <div class="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
          <div class="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-3">
            <div
              v-for="status in userStatus"
              :key="status.id"
              class="relative rounded-lg border border-gray-300 bg-white px-4 py-3 shadow-sm flex flex-wrap items-center space-x-3 hover:border-indigo-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500"
            >
              <span
                class="flex items-center justify-center h-7 w-7 rounded-full border border-gray-200 overflow-hidden bg-gray-100 ml-3"
              >
                <BaseIcon
                  name="UserAlert"
                  class="h-5 w-5 text-gray-500"
                  strokeWidth="0.2"
                  aria-hidden="true"
                />
              </span>

              <div class="flex-1 min-w-0">
                <span class="absolute inset-0" aria-hidden="true" />
                <p class="text-sm font-semibold text-gray-700">
                  {{ status.status }}
                </p>
                <p class="text-xs text-gray-500 truncate">
                  {{ status.id }}
                </p>
              </div>

              <div
                class="absolute -top-5 -left-2 flex flex-row justify-between z-20 ml-auto"
              >
                <button
                  @click="removeUserStatus(status)"
                  type="button"
                  class="bg-red-50 rounded-full h-8 w-8 mr-2 flex items-center justify-center text-red-400 hover:bg-red-100 hover:text-red-500 focus:outline-none border border-red-700"
                >
                  <BaseIcon
                    name="Trash"
                    class="h-5 w-5 text-rose-500"
                    strokeWidth="0.2"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from "../components/BaseIcon.vue";
import { IonModal, actionSheetController } from "@ionic/vue";
export default {
  components: {
    IonModal,
    BaseIcon,
  },
  data() {
    return {};
  },
  methods: {
    isItMe(user) {
      if (
        user.username == localStorage.getItem("awqatiUserUserName") &&
        user.id == localStorage.getItem("awqatiUserId")
      ) {
        return true;
      } else {
        return false;
      }
    },
    async openNewUserModal() {
      await this.$store.commit("toggleNewUserModal", true);
    },
    async removeUser(user) {
      const actionSheet = await actionSheetController.create({
        header: `حذف ${user.name}`,
        cssClass: "my-custom-class",
        buttons: [
          {
            text: "تأكيد",
            role: "destructive",
            handler: () => {
              this.$store.dispatch("removeUser", user);
            },
          },
          {
            text: "إلغاء",
            role: "cancel",
            handler: () => {
              console.log("Cancel clicked");
            },
          },
        ],
      });
      await actionSheet.present();
    },

    async openNewPrivilegeModal() {
      await this.$store.commit("toggleNewPrivilegeModal", true);
    },
    async removePrivilege(privilege) {
      const actionSheet = await actionSheetController.create({
        header: `حذف ${privilege.privilege}`,
        cssClass: "my-custom-class",
        buttons: [
          {
            text: "تأكيد",
            role: "destructive",
            handler: () => {
              this.$store.dispatch("removePrivilege", privilege);
            },
          },
          {
            text: "إلغاء",
            role: "cancel",
            handler: () => {
              console.log("Cancel clicked");
            },
          },
        ],
      });
      await actionSheet.present();
    },

    async openNewGroupModal() {
      await this.$store.commit("toggleNewGroupModal", true);
    },
    async removeGroup(group) {
      const actionSheet = await actionSheetController.create({
        header: `حذف ${group.group}`,
        cssClass: "my-custom-class",
        buttons: [
          {
            text: "تأكيد",
            role: "destructive",
            handler: () => {
              this.$store.dispatch("removeGroup", group);
            },
          },
          {
            text: "إلغاء",
            role: "cancel",
            handler: () => {
              console.log("Cancel clicked");
            },
          },
        ],
      });
      await actionSheet.present();
    },

    async openNewUserStatusModal() {
      await this.$store.commit("toggleNewUserStatusModal", true);
    },
    async removeUserStatus(userStatus) {
      const actionSheet = await actionSheetController.create({
        header: `حذف ${userStatus.status}`,
        cssClass: "my-custom-class",
        buttons: [
          {
            text: "تأكيد",
            role: "destructive",
            handler: () => {
              this.$store.dispatch("removeUserStatus", userStatus);
            },
          },
          {
            text: "إلغاء",
            role: "cancel",
            handler: () => {
              console.log("Cancel clicked");
            },
          },
        ],
      });
      await actionSheet.present();
    },
  },
  computed: {
    allUsers() {
      return this.$store.getters.getAllUsers;
    },
    privileges() {
      return this.$store.getters.getPrivileges;
    },
    groups() {
      return this.$store.getters.getGroups;
    },
    userStatus() {
      return this.$store.getters.getUserStatus;
    },
    getNewGroupModalStatus() {
      return this.$store.getters.getNewGroupModalStatus;
    },
  },
  async created() {
    await this.$store.dispatch("getAllUsers");
    await this.$store.dispatch("getPrivileges");
    await this.$store.dispatch("getGroups");
    this.$store.dispatch("getUserStatus");
  },
};
</script>

<style scoped>
.ion-modal {
  --height: auto !important;
  --width: auto !important;
}
</style>
