<template>
  <ion-menu type="push" content-id="main-content" side="end">
    <div class="w-full pl-3">
      <div class="w-full flex flex-col items-center justify-center">
        <div class="flex items-center">
          <div class="self-center">
            <h2 class="text-2xl font-bold text-indigo-700">أوقات</h2>
          </div>
          <div class="self-center">
            <svg
              viewBox="0 0 24 24"
              class="w-12 h-12"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
            >
              <rect
                width="16"
                height="16"
                x="4"
                y="4"
                stroke="#334155"
                stroke-width="1.5"
                rx="8"
              />
              <path
                stroke="#334155"
                stroke-linecap="round"
                stroke-width="1.5"
                d="M12.021 12l2.325 2.325"
              />
              <path
                stroke="#4f46e5"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M12.021 12V6.84"
                class="clock-once"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <ion-content dir="rtl" color="light">
      <div
        class="group flex items-center py-4 px-6 border-y border-slate-200 mb-2"
      >
        <span
          class="h-8 w-8 rounded-full border border-gray-200 overflow-hidden bg-gray-100"
        >
          <svg
            class="h-full w-full text-gray-300"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
            ></path>
          </svg>
        </span>
        <div class="ml-3 rtl:ml-0 rtl:mr-3">
          <p class="text-md font-bold text-gray-700 group-hover:text-gray-900">
            {{ getUserInfo.name }}
          </p>
          <p
            class="text-sm font-medium text-gray-500 group-hover:text-gray-700"
          >
            {{ getUserInfo.position }}
          </p>
        </div>
      </div>
      <div class="w-full flex items-center justify-center">
        <button
          @click="logout"
          class="bg-rose-600 px-4 py-3 rounded-lg text-white"
        >
          تسجيل الخروج
        </button>
      </div>
    </ion-content>
    <div class="absolute bottom-2 w-[100%] flex items-center justify-center">
      <span class="text-slate-400">Awqat version BETA</span>
    </div>
  </ion-menu>

  <ion-page id="main-content" ref="page">
    <div dir="rtl" class="relative h-full w-full overflow-hidden">
      <div class="z-30 flex flex-col sticky bg-slate-50/50">
        <div class="flex flex-row justify-between items-center custom-shadow">
          <div @click="openDays()" class="flex flex-row items-center">
            <BaseIcon
              name="Calendar-SVG"
              className="h-16 w-16 text-indigo-100"
            />
            <div class="flex flex-col py-4">
              <div class="flex-1 text-md font-semibold leading-6 text-gray-900">
                {{ this.calendarTitleAr }}
              </div>
              <div class="flex-1 text-xs font-light text-gray-500">
                الموافق - {{ this.calendarTitleEn }}
              </div>
            </div>
          </div>
          <div class="relative flex items-center gap-x-1 text-slate-700">
            <span
              v-if="chatNotification != 0"
              class="absolute left-[80px] top-1.5 flex items-center place-content-center justify-center rounded-full text-sm px-1 pt-1 h-5 w-5 bg-green-500 text-center text-emerald-50 font-bold"
              >{{ chatNotification }}</span
            >
            <div
              @click="openUsersChatList()"
              class="p-3 flex items-center justify-center"
            >
              <BaseIcon name="Chat-D" class="w-7 h-7" strokeWidth="0.1" />
            </div>
            <ion-menu-toggle class="p-3 flex items-center justify-center">
              <BaseIcon name="UserCircle-D" class="w-7 h-7" strokeWidth="0.1" />
            </ion-menu-toggle>
          </div>
        </div>
        <transition-group
          v-on:before-enter="beforeEnter"
          v-on:enter="enter"
          v-on:after-enter="afterEnter"
          v-on:enter-cancelled="enterCancelled"
          v-on:before-leave="beforeLeave"
          v-on:leave="leave"
          v-on:after-leave="afterLeave"
          v-on:level-cancelled="leaveCancelled"
          v-bind:css="false"
        >
          <div v-if="getOpenedDays">
            <!-- Start Top Bar For Mobile -->
            <div
              class="sticky top-0 z-50 grid flex-none grid-cols-7 text-xs text-gray-500 md:hidden"
            >
              <button
                v-for="dow in daysOfWeek(this.getCurrentDay)"
                :key="dow"
                :class="dow.dayClass"
                type="button"
                @click="openDay(dow.englishDate)"
                class="flex flex-col items-center pt-3 mb-1 pb-1.5"
              >
                <span>{{ dow.day }}</span>
                <span
                  class="mt-1 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold"
                  >{{ dow.date }}</span
                >
                <div v-if="isSameDay(dow.englishDate)">
                  <svg
                    class="h-1.5 w-1.5 fill-rose-700"
                    viewBox="0 0 6 6"
                    aria-hidden="true"
                  >
                    <circle cx="3" cy="3" r="3" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
          <div
            v-if-else="getOpenedDays || !getOpenedDays"
            class="sticky absolute z-50 w-full flex items-center bg-slate-50 justify-center top-24 px-24 -pt-2 pb-2 !bg-transparent border-b border-slate-200"
          >
            <div class="text-slate-500 font-bold">
              {{ currentMobileView == "guests" ? "الضيوف" : "المواعيد" }}
            </div>
            <Transition name="slide-down">
              <span
                v-if="!isSameDay(getCurrentDay)"
                @click="goToday"
                class="absolute -mr-12 right-[50%] mt-20 flex items-center gap-x-1 rounded-md bg-white/50 px-2 py-2 text-xs font-medium text-rose-700 ring-1 ring-inset ring-slate-700/10 shadow-sm"
              >
                <BaseIcon
                  name="Flag"
                  class="w-3.5 h-3.5 transition-all"
                  strokeWidth="0.1"
                />
                <span class="pt-[2px]"> العودة لليوم</span>
              </span>
            </Transition>
          </div>
        </transition-group>
      </div>
      <MobileWeekView
        v-if="currentMobileView == 'events'"
        :currentDay="this.getCurrentDay"
      />
      <MobileWeekGuest
        v-if="currentMobileView == 'guests'"
        :currentDay="this.getCurrentDay"
      />
      <div
        class="absolute w-full flex items-center justify-center bottom-8 px-24 bg-transparent"
      >
        <div
          class="flex items-center transition-all ease-in-out delay-150 duration-300"
        >
          <div
            style="
              -webkit-backdrop-filter: blur(20px);
              backdrop-filter: blur(20px);
              background: rgba(25, 25, 25, 0.6);
            "
            ref="badge"
            class="relative h-[2.76rem] flex space-x-1 rounded-full bg-slate-100 p-1 top-0 shadow-lg"
            role="tablist"
            :class="isSuper() ? 'w-[9rem]' : 'w-[12rem]'"
            aria-orientation="horizontal"
          >
            <div
              ref="hideAdd"
              class="absolute z-20 mt-[5px] top-0 mr-px w-full flex items-center transition-all ease-in-out delay-150 duration-300"
            >
              <button
                @click="changeMobileView('events')"
                :class="
                  getCurrentMobileView == 'events'
                    ? 'text-slate-900 bg-white shadow'
                    : 'text-slate-300/50'
                "
                class="flex items-center justify-center rounded-full py-[0.4375rem] px-2.5 text-sm font-semibold lg:pr-3"
                id="headlessui-tabs-tab-256"
                role="tab"
                type="button"
              >
                <span class="lg:ml-2">المواعيد</span></button
              ><button
                @click="changeMobileView('guests')"
                :class="
                  getCurrentMobileView == 'guests'
                    ? 'text-slate-900 bg-white shadow'
                    : 'text-slate-300/50'
                "
                class="flex items-center justify-center rounded-full py-[0.4375rem] px-2.5 text-sm font-semibold lg:pr-3"
                id="headlessui-tabs-tab-257"
                role="tab"
                type="button"
              >
                <span class="lg:ml-2">الضيوف</span>
              </button>
            </div>
            <div
              ref="showAdd"
              class="absolute z-0 mt-[5px] top-3 pr-px opacity-0 w-full flex gap-x-1 items-center transition-all ease-in-out delay-150 duration-300"
            >
              <button
                @click="openNewGuestAside()"
                class="flex items-center rounded-full py-[0.4375rem] px-2.5 text-sm font-semibold lg:pr-3 bg-white shadow"
                id="headlessui-tabs-tab-257"
                role="tab"
                type="button"
              >
                <span class="lg:ml-2 text-slate-900">إضافة ضيف</span>
              </button>
            </div>
            <button
              v-if="!isSuper()"
              @click="toggleAddMenu()"
              class="absolute z-20 top-[2.2px] -left-[1.9px] flex items-center rounded-full border border-stone-500 bg-stone-400 ring-inset p-2 text-sm text-white font-semibold lg:pr-3"
              id="headlessui-tabs-tab-257"
              role="tab"
              type="button"
              tabindex="-1"
            >
              <BaseIcon
                name="Plus"
                class="w-6 h-6 transition-all ease-in-out delay-150 duration-300"
                :className="
                  getAddMenu ? '-rotate-[135deg] text-rose-600' : 'rotate-0'
                "
                strokeWidth="0.2"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </ion-page>
</template>

<script>
import moment from "moment-hijri";
moment.updateLocale("ar-sa", {
  postformat: (string) => string,
});
import BaseIcon from "../components/BaseIcon.vue";
import MobileWeekView from "../components/MobileWeekView.vue";
import MobileWeekGuest from "../components/MobileWeekGuests.vue";
import NewGuestModal from "../components/NewGuestModal";
import UsersListForChat from "../components/UsersListForChat.vue";
import UserChatMobile from "../components/UserChatMobile.vue";

import {
  IonPage,
  IonContent,
  IonMenu,
  IonMenuToggle,
  modalController,
  alertController,
  menuController,
} from "@ionic/vue";
export default {
  data() {
    return {
      addMenu: false,
      currentMobileView: "events",
      presentingElement: null,
      openedDays: true,
      propCurrentDay: moment(),
      forCommingDate: moment(new Date()).format("HH:mm"),
    };
  },
  components: {
    BaseIcon,
    IonPage,
    IonContent,
    IonMenu,
    IonMenuToggle,
    MobileWeekView,
    MobileWeekGuest,
    NewGuestModal,
    UsersListForChat,
    UserChatMobile,
  },
  computed: {
    getAddMenu() {
      return this.addMenu;
    },
    getCurrentMobileView() {
      return this.currentMobileView;
    },
    getOpenedDays() {
      return this.openedDays;
    },
    calendarTitleAr() {
      return moment(this.propCurrentDay).format(
        "[شهر] [(]iM[)] iMMMM / iYYYY [هـ]"
      );
    },
    calendarTitleEn() {
      return moment(this.propCurrentDay).format(
        "[شهر] [(]M[)] MMMM / YYYY [م]"
      );
    },
    getCurrentDay() {
      return this.propCurrentDay;
    },
    currentView() {
      return this.$store.getters.getCurrentView;
    },
    getCurrentMobileView() {
      return this.currentMobileView;
    },
    chatNotification() {
      return this.$store.getters.getTotalOfAllUnseenChat;
    },
    getUserInfo() {
      if (localStorage.getItem("awqatiAccess_token")) {
        let info = {
          name: localStorage.getItem("awqatiUser"),
          position: localStorage.getItem("awqatiUserPosition"),
          privilege: localStorage.getItem("awqatiUserPrivilege"),
        };
        return info;
      } else {
        return this.$router.push("/");
      }
    },
  },
  methods: {
    async logout() {
      const alert = await alertController.create({
        header: "تسجيل الخروج",
        message: "هل تريد تسجيل الخروج؟",
        buttons: [
          {
            text: "لا",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              alertController.dismiss("still");
            },
          },
          {
            text: "نعم",
            handler: () => {
              alertController.dismiss("close");
              menuController.close();
              this.$store.dispatch("doLogout");
            },
          },
        ],
      });
      alert.onDidDismiss().then((data) => {
        if (data.data == "close") {
          setTimeout(() => {
            this.$router.push("/");
          }, 500);
        } else {
          return;
        }
      });
      return alert.present();
    },
    isSuper() {
      if (localStorage.getItem("awqatiUserPrivilege") == 6) {
        return true;
      }
      return false;
    },
    isSameDay(val) {
      const currentDay = moment().format("YYYY/M/D");
      if (moment(val).format("YYYY/M/D") == currentDay) {
        return true;
      }
      return false;
    },
    async changeMobileView(val) {
      this.currentMobileView = val;
      await this.openDay(this.getCurrentDay);
    },
    async openUsersChatList() {
      let payload = {
        group: parseInt(localStorage.getItem("awqatiUserGroup")),
        me: parseInt(localStorage.getItem("awqatiUserId")),
      };
      const modal = await modalController.create({
        component: UsersListForChat,
        componentProps: { payload },
        initialBreakpoint: 0.7,
        breakpoints: [0.7, 0.8],
      });
      await modal.present();

      return modal.onDidDismiss().then((data) => {
        if (data) {
          if (data.data) {
            if (data.data.command == "openChat") {
              setTimeout(async () => {
                const modal = await modalController.create({
                  component: UserChatMobile,
                  cssClass: "my-custom-class",
                  componentProps: {
                    fromUser: data.data.userData,
                  },
                });
                return modal.present();
              }, 200);
            } else {
              return;
            }
          } else {
            return;
          }
        }
      });
    },
    async openNewGuestAside() {
      const modal = await modalController.create({
        component: NewGuestModal,
        backdropDismiss: false,
        showBackdrop: true,
        presentingElement: this.presentingElement,
        backdropDismiss: false,
        cssClass: "new-guest-modal",
      });

      modal.present();

      const { data, role } = await modal.onWillDismiss();

      if (role === "confirm") {
        message.value = `Hello, ${data}!`;
      }
    },
    toggleAddMenu() {
      this.addMenu = !this.addMenu;
      const badge = this.$refs["badge"].classList;
      const showAdd = this.$refs["showAdd"].classList;
      const hideAdd = this.$refs["hideAdd"].classList;
      if (this.addMenu) {
        hideAdd.remove("top-0", "opacity-100", "z-20");
        hideAdd.add("-top-3", "opacity-0", "z-0");
        setTimeout(() => {
          showAdd.remove("top-3", "opacity-0", "z-0");
          showAdd.add("top-0", "opacity-100", "z-20");
        }, 100);
        setTimeout(() => {
          badge.remove("w-[12rem]");
          badge.add("w-[10rem]");
        }, 600);
      } else {
        showAdd.remove("top-0", "opacity-100", "z-20");
        showAdd.add("top-3", "opacity-0", "z-0");
        setTimeout(() => {
          badge.remove("w-[10rem]");
          badge.add("w-[12rem]");
          hideAdd.remove("-top-3", "opacity-0", "z-0");
          hideAdd.add("top-0", "opacity-100", "z-20");
        }, 100);
      }
    },
    openDays() {
      this.openedDays = !this.openedDays;
    },
    async changeView(view) {
      await this.$store.commit("setCurrentView", view);
      await this.openDay(this.getCurrentDay);
    },
    daysOfWeek(v) {
      var currentDate = moment(v);
      var dayNo = currentDate.startOf("iweek").day();
      var weekStart = "";
      if (dayNo == 0) {
        weekStart = currentDate.startOf("iweek");
      } else if (dayNo > 0) {
        weekStart = currentDate.startOf("iweek").add(-dayNo, "iDate");
      }
      var days = [];
      var dayClass = "text-gray-700 border border-gray-100";
      var hijFull = "";
      for (var i = 0; i <= 6; i++) {
        hijFull = moment(weekStart).add(i, "iDate").format("iYYYY/iM/iD");
        if (
          moment(v).format("iYYYY/iM/iD") ==
          moment(weekStart).add(i, "iDate").format("iYYYY/iM/iD")
        ) {
          dayClass =
            "bg-rose-200 bg-opacity-50 text-rose-800 rounded-md shadow-border-b";
        } else {
          dayClass = "text-gray-600 border border-gray-100";
        }
        days.push({
          day: moment(weekStart).add(i, "iDate").format("dddd"),
          date:
            moment(weekStart).add(i, "iDate").format("iM") +
            "/" +
            moment(weekStart).add(i, "iDate").format("iDD"),
          dayClass: dayClass,
          englishDate: moment(hijFull, "iYYYY/iM/iD").format("YYYY/M/D"),
        });
      }

      return days;
    },
    async openDay(D) {
      //this.closeMobileCalendar();
      this.propCurrentDay = D;

      if (this.currentView == "day") {
        let getEventsOfDayPayload = {
          date: moment(D).format("iYYYY/iM/iD"),
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        };
        await this.$store.dispatch("getEventsOfDay", getEventsOfDayPayload);
        this.$store.dispatch("getGuestsOfDay", getEventsOfDayPayload);
      } else if (this.currentView == "week") {
        var currentDate = moment(D);
        var dayNo = currentDate.startOf("iweek").day();
        var weekStart = "";
        if (dayNo == 0) {
          weekStart = currentDate.startOf("iweek");
        } else if (dayNo > 0) {
          weekStart = currentDate.startOf("iweek").add(-dayNo, "iDate");
        }

        let lastDay = moment(weekStart).add(6, "iDate").format("YYYY/M/D");
        let days = {
          fromDay: moment(weekStart).format("YYYY/M/D"),
          toDay: lastDay,
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        };
        await this.$store.dispatch("getEventsOfWeek", days);
        await this.$store.dispatch("getGuestsOfWeek", days);
        let getEventsOfDayPayload = {
          date: moment(D).format("iYYYY/iM/iD"),
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        };
        this.$store.dispatch("getGuestsOfDay", getEventsOfDayPayload);
      }
    },
    beforeEnter(el) {
      el.style.height = 0;
      el.style.overflow = "hidden";
    },
    enter(el, done) {
      const increaseHeight = () => {
        if (el.clientHeight < el.scrollHeight) {
          const height = `${parseInt(el.style.height) + 5}px`;
          el.style.height = height;
        } else {
          clearInterval(this.enterInterval);
          done();
        }
      };
      this.enterInterval = setInterval(increaseHeight, 10);
    },
    afterEnter(el) {},
    enterCancelled(el) {
      clearInterval(this.enterInterval);
    },
    beforeLeave(el) {},
    leave(el, done) {
      const decreaseHeight = () => {
        if (el.clientHeight > 0) {
          const height = `${parseInt(el.style.height) - 5}px`;
          el.style.height = height;
        } else {
          clearInterval(this.leaveInterval);
          done();
        }
      };
      this.leaveInterval = setInterval(decreaseHeight, 10);
    },
    afterLeave(el) {},
    leaveCancelled(el) {
      clearInterval(this.leaveInterval);
    },
    async goToday() {
      await this.openDay(moment().format("YYYY/M/D"));
      this.propCurrentDay = moment();
    },
    updateCurrentTime() {
      this.forCommingDate = moment(new Date()).format("HH:mm");
      this.currentTime = moment().format("h:mm:ss A");
      this.dayDateAr = moment().format("iYYYY/iMM/iDD");
      this.dayDateEn = moment().format("YYYY/MM/DD");
    },
  },
  async mounted() {
    this.presentingElement = this.$refs.page.$el;
    let getEventsOfDayPayload = {
      date: moment(new Date()).format("iYYYY/iM/iD"),
      group: parseInt(localStorage.getItem("awqatiUserGroup")),
    };
    await this.$store.dispatch("getEventsOfDay", getEventsOfDayPayload);
    await this.$store.dispatch("getGuestsOfDay", getEventsOfDayPayload);
    const upData = {
      to: parseInt(localStorage.getItem("awqatiUserId")),
      group: parseInt(localStorage.getItem("awqatiUserGroup")),
    };
    this.$store.dispatch("getTotalOfAllUnseenChat", upData);
    await this.$store.dispatch("getUserCallingByID", {
      to: parseInt(localStorage.getItem("awqatiUserId")),
      group: parseInt(localStorage.getItem("awqatiUserGroup")),
    });

    this.$socket.on("checkUserForChat", (data) => {
      console.log("START CHECK USER");
      if (data.to == localStorage.getItem("awqatiUserId")) {
        console.log("IS (to) USER !");
        if (this.$route.name !== "Chat") {
          this.$store.dispatch("showNotification", {
            title: "رسالة",
            text: "لديك رسالة جديدة",
            type: "newchat",
          });
        }
        this.$store.dispatch("getTotalOfAllUnseenChat", upData);
      } else {
        console.log("NOT (to) USER !");
        return;
      }
    });
    this.$socket.on("syncEventsOfWeek", (data) => {
      if (this.currentView == "week") {
        this.$store.dispatch("getEventsForCommingUp", {
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        });

        var currentDate = moment(this.currentDay);
        var dayNo = currentDate.startOf("iweek").day();
        var weekStart = "";

        if (dayNo == 0) {
          weekStart = currentDate.startOf("iweek");
        } else if (dayNo > 0) {
          weekStart = currentDate.startOf("iweek").add(-dayNo, "iDate");
        }

        for (var i = 0; i <= 6; i++) {
          if (
            data.dayy == moment(weekStart).add(i, "iDate").format("iYYYY/iM/iD")
          ) {
            const newDays = {
              fromDay: moment(weekStart).format("YYYY/M/D"),
              toDay: moment(weekStart).add(6, "iDate").format("YYYY/M/D"),
              group: data.group,
            };
            this.$store.dispatch("getEventsOfWeek", newDays);
            return;
          } else {
            console.log("IT IS NOT IN SAME WEEK!");
          }
        }
      }
    });

    this.$socket.on("syncGuestsOfWeek", (data) => {
      if (this.currentView == "week") {
        var currentDate = moment(data.ar_date);
        var dayNo = currentDate.startOf("iweek").day();
        var weekStart = "";
        if (dayNo == 0) {
          weekStart = currentDate.startOf("iweek");
        } else if (dayNo > 0) {
          weekStart = currentDate.startOf("iweek").add(-dayNo, "iDate");
        }

        let lastDay = moment(weekStart).add(6, "iDate").format("YYYY/M/D");
        let days = {
          fromDay: moment(weekStart).format("YYYY/M/D"),
          toDay: lastDay,
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        };
        this.$store.dispatch("getGuestsOfWeek", days);
        let getEventsOfDayPayload = {
          date: moment().format("iYYYY/iM/iD"),
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        };
        this.$store.dispatch("getGuestsOfDay", getEventsOfDayPayload);
        this.$store.dispatch("showNotification", {
          title: "ضيف",
          text: "تم إضافة ضيف جديد",
          type: "newguest",
        });
      }
      /*this.$store.dispatch("getEventsForCommingUp", {
            group: parseInt(localStorage.getItem("awqatiUserGroup")),
          });*/
    });

    this.$socket.on("syncUpdateGuestOfWeek", (data) => {
      if (this.currentView == "week") {
        var currentDate = moment(data.ar_date);
        var dayNo = currentDate.startOf("iweek").day();
        var weekStart = "";
        if (dayNo == 0) {
          weekStart = currentDate.startOf("iweek");
        } else if (dayNo > 0) {
          weekStart = currentDate.startOf("iweek").add(-dayNo, "iDate");
        }

        let lastDay = moment(weekStart).add(6, "iDate").format("YYYY/M/D");
        let days = {
          fromDay: moment(weekStart).format("YYYY/M/D"),
          toDay: lastDay,
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        };
        this.$store.dispatch("getGuestsOfWeek", days);
        let getEventsOfDayPayload = {
          date: moment().format("iYYYY/iM/iD"),
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        };
        this.$store.dispatch("getGuestsOfDay", getEventsOfDayPayload);

        if (data.updateType == "approved") {
          this.$store.dispatch("showNotification", {
            title: "ضيف",
            text: `${data.guestName} تم قبول الضيف`,
            type: "acceptguest",
          });
          /*this.$store.dispatch("getEventsForCommingUp", {
                group: parseInt(localStorage.getItem("awqatiUserGroup")),
              });*/
          this.$store.dispatch(data.command, {
            date: data.dayy,
            group: data.group,
          });
        } else if (data.updateType == "reject") {
          this.$store.dispatch("showNotification", {
            title: "ضيف",
            text: `${data.guestName} تم رفض الضيف`,
            type: "rejectguest",
          });
          /*this.$store.dispatch("getEventsForCommingUp", {
                group: parseInt(localStorage.getItem("awqatiUserGroup")),
              });*/
          this.$store.dispatch(data.command, {
            date: data.dayy,
            group: data.group,
          });
        }
      }
      /*this.$store.dispatch("getEventsForCommingUp", {
            group: parseInt(localStorage.getItem("awqatiUserGroup")),
          });*/
    });

    this.$socket.on("syncUpdateGuestOfDay", (data) => {
      if (
        moment(this.currentDay).format("iYYYY/iM/iD") == data.dayy &&
        parseInt(localStorage.getItem("awqatiUserGroup")) == data.group
      ) {
        if (data.updateType == "approved") {
          this.$store.dispatch("showNotification", {
            title: "ضيف",
            text: `${data.guestName} تم قبول الضيف`,
            type: "acceptguest",
          });
          /*this.$store.dispatch("getEventsForCommingUp", {
                group: parseInt(localStorage.getItem("awqatiUserGroup")),
              });*/
          this.$store.dispatch(data.command, {
            date: data.dayy,
            group: data.group,
          });
        } else if (data.updateType == "reject") {
          this.$store.dispatch("showNotification", {
            title: "ضيف",
            text: `${data.guestName} تم رفض الضيف`,
            type: "rejectguest",
          });
          /*this.$store.dispatch("getEventsForCommingUp", {
                group: parseInt(localStorage.getItem("awqatiUserGroup")),
              });*/
          this.$store.dispatch(data.command, {
            date: data.dayy,
            group: data.group,
          });
        }
      } else {
        console.log("IT IS NOT IN SAME DAY!");
      }
    });

    this.$socket.on("syncGuestsOfDay", (data) => {
      if (
        moment(this.currentDay).format("iYYYY/iM/iD") == data.dayy &&
        parseInt(localStorage.getItem("awqatiUserGroup")) == data.group
      ) {
        this.$store.dispatch("showNotification", {
          title: "ضيف",
          text: "تم إضافة ضيف جديد",
          type: "newguest",
        });
        /*this.$store.dispatch("getEventsForCommingUp", {
            group: parseInt(localStorage.getItem("awqatiUserGroup")),
          });*/
        this.$store.dispatch(data.command, {
          date: data.dayy,
          group: data.group,
        });
      } else {
        console.log("IT IS NOT IN SAME DAY!");
      }
    });
  },
  created() {
    const upData = {
      to: parseInt(localStorage.getItem("awqatiUserId")),
      group: parseInt(localStorage.getItem("awqatiUserGroup")),
    };

    this.$store.dispatch("getTotalOfAllUnseenChat", upData);
    setInterval(() => this.updateCurrentTime(), 1000);
  },
};
</script>
<style scoped>
.up {
  -webkit-animation: top 1s ease-in-out forwards;
  animation: top 1s ease-in-out forwards;
}

.down {
  -webkit-animation: down 1s ease-in-out forwards;
  animation: down 1s ease-in-out forwards;
}

.control.down {
  --rotate: -135deg;
}

.control.up {
  --rotate: 0deg;
}

@keyframes top {
  0%,
  100% {
    transform: rotate(0deg);
  }
  50%,
  60% {
    transform: rotate(-6deg);
  }
  88% {
    transform: rotate(1deg);
  }
}
@-webkit-keyframes down {
  0%,
  100% {
    transform: rotate(0deg);
  }
  50%,
  60% {
    transform: rotate(6deg);
  }
  88% {
    transform: rotate(-1deg);
  }
}
@keyframes down {
  0%,
  100% {
    transform: rotate(0deg);
  }
  50%,
  60% {
    transform: rotate(6deg);
  }
  88% {
    transform: rotate(-1deg);
  }
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.25s ease-out;
}

.slide-down-enter-from {
  opacity: 0;
  transform: translateY(-25px);
}

.slide-down-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
</style>
