<template>
  <ion-header>
    <ion-toolbar dir="rtl" class="custom-shadow bg-white">
      <div class="right-0 min-w-0 flex-1 flex items-center my-3">
        <div class="flex-shrink-0">
          <div
            class="h-8 w-8 rounded-full border border-gray-200 overflow-hidden bg-gray-100"
          >
            <svg
              class="h-full w-full text-gray-400"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
              ></path>
            </svg>
          </div>
        </div>
        <div class="min-w-0 flex flex-col px-4">
          <div>
            <p class="truncate font-bold text-lg text-slate-700 mt-1.5">
              {{ getSelectedUser.name }}
            </p>
          </div>
        </div>
      </div>
      <ion-buttons slot="end">
        <button
          @click="closeMobileChat()"
          type="button"
          class="rounded-full bg-slate-50 p-2 text-slate-600 shadow-sm hover:bg-slate-100 ring-1 ring-slate-200 ring-inset"
        >
          <BaseIcon name="XIcon" class="h-5 w-5" aria-hidden="true" />
        </button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <div class="flex-1 flex items-stretch overflow-hidden bg-gray-50">
    <!-- Secondary column (hidden on smaller screens) -->
    <!-- <aside class="hidden w-96 bg-white border-l border-gray-200 overflow-y-auto xl:block"> -->

    <main dir="rtl" class="flex-1 overflow-y-auto">
      <!-- Primary column -->
      <section
        aria-labelledby="primary-heading"
        class="min-w-0 relative flex-1 h-full overflow-hidden flex flex-col lg:order-last"
      >
        <div
          v-show="getChatDataLoading"
          class="absolute top-1 w-full left-0 right-0 mx-auto flex items-center justify-center"
        >
          <!--<div
            class="fixed z-50 inset-0 bg-gray-500 bg-opacity-40 transition-opacity"
          />-->
          <div class="flex items-center justify-center">
            <div
              class="inline-flex items-center px-2.5 py-px font-semibold leading-6 text-xs shadow rounded-md text-white bg-slate-700 transition ease-in-out duration-150"
              disabled=""
            >
              <span class="mt-1">جلب البيانات</span>
              <svg
                class="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div
          id="mobile-chat"
          class="flex flex-col space-y-4 h-full overflow-y-auto overflow-x-hidden pt-1 pb-12 px-3 border-b border-gray-200"
        >
          <div
            class="w-full message"
            v-for="chat in getChatOfUserById"
            :key="chat.id"
            :ref="`chat#${chat.id}`"
          >
            <div
              v-if="chat.link == 0 && chat.reply_to == null"
              @touchstart="startHold($event, chat.text, chat.id)"
              @touchend="endHold()"
              @mousedown="startHold($event, chat.text, chat.id)"
              @mouseup="endHold($event)"
              style="width: fit-content !important; max-width: 260px"
              class="relative py-2 flex flex-col max-w-sm custom-shadow transition-all"
              :class="
                chat.from_user == getMyID
                  ? 'bg-stone-600 float_right rounded-l-2xl rounded-b-2xl'
                  : 'bg-white float-left rounded-r-2xl rounded-b-2xl'
              "
            >
              <div
                class="absolute -top-0.5"
                :class="
                  chat.from_user == getMyID ? '-right-2.5' : 'flip-tail -left-3'
                "
              >
                <BaseIcon
                  name="TailOut"
                  class="w-3 h-6 shadow-none"
                  :class="
                    chat.from_user == getMyID ? 'text-stone-600' : 'text-white'
                  "
                  strokeWidth="0.2"
                />
              </div>
              <ion-item lines="none">
                <ion-text>
                  <span
                    class="font-bold"
                    :class="
                      chat.from_user == getMyID ? 'text-white' : 'text-gray-800'
                    "
                    >{{ chat.text }}</span
                  >
                  <br />
                  <span
                    class="w-full flex items-center gap-x-1 float-left text-xs text-gray-400"
                  >
                    <BaseIcon
                      v-if="chat.from_user == getMyID"
                      name="DoubleCheck"
                      class="w-4 h-4"
                      :class="chat.seen ? 'check-seen' : 'check-unseen'"
                      strokeWidth="0.2"
                    />
                    <span class="pt-1.5">{{
                      moment(chat.created_at).from(getCurrentTime)
                    }}</span>
                  </span>
                </ion-text>
              </ion-item>
            </div>
            <div
              @click="scrollToChat(chat.reply_to)"
              v-else-if="chat.reply_to != null"
              @touchstart="startHold($event, chat.text, chat.id)"
              @touchend="endHold()"
              @mousedown="startHold($event, chat.text, chat.id)"
              @mouseup="endHold($event)"
              style="width: fit-content !important; max-width: 260px"
              class="relative flex flex-col max-w-sm custom-shadow"
              :class="
                chat.from_user == getMyID
                  ? 'bg-stone-600 float_right rounded-l-2xl rounded-b-2xl'
                  : 'bg-white float-left rounded-r-2xl rounded-b-2xl'
              "
            >
              <div class="mb-2">
                <div
                  class="w-full flex items-center text-center text-md border-x-4 border-gray-400 ml-1 mt-4 py-1 px-2 bg-gray-100/60 text-gray-700"
                >
                  <BaseIcon
                    name="Reply"
                    class="h-5 w-5 text-slate-600 ml-2"
                    strokeWidth="0.5"
                    aria-hidden="true"
                  />
                  <b class="mt-1">{{ chat.reply_text }}</b>
                </div>
              </div>
              <div
                class="absolute -top-0.5"
                :class="
                  chat.from_user == getMyID ? '-right-2.5' : 'flip-tail -left-3'
                "
              >
                <BaseIcon
                  name="TailOut"
                  class="w-3 h-6 shadow-none"
                  :class="
                    chat.from_user == getMyID ? 'text-stone-600' : 'text-white'
                  "
                  strokeWidth="0.2"
                />
              </div>
              <ion-item lines="none">
                <ion-text>
                  <span
                    class="font-bold"
                    :class="
                      chat.from_user == getMyID ? 'text-white' : 'text-gray-800'
                    "
                    >{{ chat.text }}</span
                  >
                  <br />
                  <span
                    class="w-full flex items-center gap-x-1 float-left text-xs text-gray-400"
                  >
                    <BaseIcon
                      v-if="chat.from_user == getMyID"
                      name="DoubleCheck"
                      class="w-4 h-4"
                      :class="chat.seen ? 'check-seen' : 'check-unseen'"
                      strokeWidth="0.2"
                    />
                    <span class="pt-1.5">{{
                      moment(chat.created_at).from(getCurrentTime)
                    }}</span>
                  </span>
                </ion-text>
              </ion-item>
            </div>
            <div
              @click="showMentioned(chat.link, chat.link_type)"
              v-else-if="chat.link != 0"
              @touchstart="startHold($event, chat.text, chat.id)"
              @touchend="endHold()"
              @mousedown="startHold($event, chat.text, chat.id)"
              @mouseup="endHold($event)"
              style="width: fit-content !important; max-width: 260px"
              class="relative flex flex-col max-w-sm custom-shadow"
              :class="
                chat.from_user == getMyID
                  ? 'bg-stone-600 float_right rounded-l-2xl rounded-b-2xl'
                  : 'bg-white float-left rounded-r-2xl rounded-b-2xl'
              "
            >
              <div>
                <div
                  v-if="chat.link_type == 'event'"
                  class="w-full flex items-center text-center text-md border-b-2 border-yellow-800 ml-1 mt-4 px-2 bg-yellow-50 text-yellow-700"
                >
                  <BaseIcon
                    name="CalendarDate"
                    class="h-5 w-5 text-yellow-700 ml-2"
                    strokeWidth="0.5"
                    aria-hidden="true"
                  />
                  <b class="mt-1">{{
                    chat.link_text ? chat.link_text : "الموعد"
                  }}</b>
                </div>
                <div
                  v-if="chat.link_type == 'guest'"
                  class="w-full flex items-center text-center text-md border-b-2 border-yellow-800 ml-1 mt-4 px-2 bg-yellow-50 text-yellow-700"
                >
                  <BaseIcon
                    name="UserSquare"
                    class="h-5 w-5 text-yellow-700 ml-2"
                    strokeWidth="0.5"
                    aria-hidden="true"
                  />
                  <b class="mt-1">{{
                    chat.link_text ? chat.link_text : "الضيف"
                  }}</b>
                </div>
              </div>
              <div
                class="absolute -top-0.5"
                :class="
                  chat.from_user == getMyID ? '-right-2.5' : 'flip-tail -left-3'
                "
              >
                <BaseIcon
                  name="TailOut"
                  class="w-3 h-6 shadow-none"
                  :class="
                    chat.from_user == getMyID ? 'text-stone-600' : 'text-white'
                  "
                  strokeWidth="0.2"
                />
              </div>
              <ion-item lines="none">
                <ion-text>
                  <span
                    class="font-bold pt-2"
                    :class="
                      chat.from_user == getMyID ? 'text-white' : 'text-gray-800'
                    "
                    >{{ chat.text }}</span
                  >
                  <br />
                  <span
                    class="w-full flex items-center gap-x-1 float-left text-xs text-gray-400"
                  >
                    <BaseIcon
                      v-if="chat.from_user == getMyID"
                      name="DoubleCheck"
                      class="w-4 h-4"
                      :class="chat.seen ? 'check-seen' : 'check-unseen'"
                      strokeWidth="0.2"
                    />
                    <span class="pt-1.5">{{
                      moment(chat.created_at).from(getCurrentTime)
                    }}</span>
                  </span>
                </ion-text>
              </ion-item>
            </div>
          </div>
        </div>

        <!-- Your content -->
        <div
          v-if="
            selectedEventForChat != '' &&
            selectedEventForChat.linkType == 'event'
          "
          class="p-2 rounded-lg bg-yellow-100 border border-yellow-500 shadow-lg"
        >
          <div class="flex items-center justify-between flex-wrap">
            <div class="w-0 flex-1 flex items-center">
              <span class="flex p-2 rounded-lg bg-yellow-800">
                <BaseIcon
                  name="CalendarDate"
                  class="h-6 w-6 text-white"
                  strokeWidth="0.3"
                  aria-hidden="true"
                />
              </span>
              <p class="mr-3 font-medium text-yellow-700 truncate">
                <span class="inline font-semibold">
                  {{ selectedEventForChat.title }}
                </span>
                -
                <span>{{ selectedEventForChat.ar_date }} هـ</span>
              </p>
            </div>
            <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
              <button
                @click="removeMention()"
                type="button"
                class="-mr-1 flex p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-white"
              >
                <BaseIcon
                  name="XIcon"
                  class="h-5 w-5 text-yellow-500"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </div>

        <div
          v-if="
            selectedEventForChat != '' &&
            selectedEventForChat.linkType == 'guest'
          "
          class="p-2 rounded-lg bg-yellow-100 border border-yellow-500 shadow-lg"
        >
          <div class="flex items-center justify-between flex-wrap">
            <div class="w-0 flex-1 flex items-center">
              <span class="flex p-2 rounded-lg bg-yellow-800">
                <BaseIcon
                  name="UserSquare"
                  class="h-6 w-6 text-white"
                  strokeWidth="0.3"
                  aria-hidden="true"
                />
              </span>
              <p class="mr-3 font-medium text-yellow-700 truncate">
                <span class="inline">
                  {{ selectedEventForChat.title }}
                </span>
                -
                <span class="font-bold">{{
                  JSON.parse(selectedEventForChat.person)[0].name
                }}</span>
              </p>
            </div>
            <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
              <button
                @click="removeMention()"
                type="button"
                class="-mr-1 flex p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-white"
              >
                <BaseIcon
                  name="XIcon"
                  class="h-5 w-5 text-yellow-500"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
  <div
    dir="rtl"
    v-if="!getSelectedUser == ''"
    class="pb-3"
    style="
      background-color: rgba(255, 255, 255, 0.5);
      filter: blur(10px) !important;
      -webkit-backdrop-filter: blur(10px);
    "
  >
    <!--<div
      class="flex flex-wrap overflow-y-auto overflow-x-hidden gap-1 max-h-24 py-2 px-1"
    >
      <span
        class="w-1/3 inline-flex items-center gap-x-0.5 rounded-md bg-slate-200/60 px-2 py-1 text-xs font-medium text-slate-700"
      >
        <button
          type="button"
          class="group relative -mr-1 h-4 w-4 rounded-sm text-slate-500"
        >
          <BaseIcon name="Attachment" class="h-4 w-4" strokeWidth="0.1" />
        </button>
        <span class="pt-1 pr-1 text-sm font-bold truncate"
          >Attachment Attachment Attachment Attachment Attachment
          Attachment</span
        >
        <button
          type="button"
          class="group flex items-center justify-center mr-1 h-5 w-5 text-slate-400 rounded-full bg-white hover:bg-white/20 ring-1 ring-inset ring-slate-200"
        >
          <BaseIcon name="XIcon" class="h-4 w-5" strokeWidth="0.5" />
        </button>
      </span>
    </div> -->
    <div
      v-if="getReplyText"
      class="w-full flex items-center justify-between text-right float-right border-r-4 border-green-900 bg-green-50 px-1"
    >
      <p class="py-3">{{ getReplyText }}</p>
      <button
        @click="removeTextReply()"
        type="button"
        class="group flex items-center justify-center mr-3 h-6 w-6 text-slate-400 rounded-full bg-white hover:bg-white/20 ring-1 ring-inset ring-slate-200 active:bg-slate-500 active:text-white"
      >
        <BaseIcon name="XIcon" class="h-4 w-4" strokeWidth="0.5" />
      </button>
    </div>
    <div
      class="bg-white mx-2 pl-4 pt-3 pb-10 sticky flex flex-row justify-between gap-x-2 w-full"
    >
      <div class="w-full relative flex items-center">
        <input
          @keyup.enter="handleSend($event)"
          v-model="this.chatText"
          type="text"
          name="chat-text"
          id="chat-text"
          ref="chatText"
          autocomplete="off"
          class="block w-full rounded-full border-0 py-2 pr-12 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
        />
        <div class="absolute inset-y-0 right-0 flex py-px pr-1.5">
          <button
            v-if="this.chatText != '' && !getSendingChatLoading"
            @click="sendChat()"
            type="button"
            class="inline-flex items-center px-1.5 font-sans text-xs text-blue-500 transition-all delay-75"
          >
            <BaseIcon name="Send-D" class="w-7 h-7" strokeWidth="0.2" />
          </button>
          <button
            v-else-if="this.chatText == ''"
            type="button"
            class="inline-flex items-center border border-gray-200 px-1.5 font-sans text-xs text-slate-300 transition-all delay-75"
          >
            <BaseIcon name="Send-D" class="w-7 h-7" strokeWidth="0.2" />
          </button>
          <button
            v-if="this.chatText != '' && getSendingChatLoading"
            type="button"
            class="inline-flex items-center px-1.5 font-sans text-xs text-blue-500 transition-all delay-75"
          >
            <svg
              class="animate-spin h-6 w-6 text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <!--<div class="w-1/12 relative mt-px ml-1.5 flex items-center">
        <div class="absolute inset-y-0 right-0 flex py-px pr-px">
          <button
            type="button"
            class="inline-flex items-center rounded-full text-slate-600 border border-gray-200 font-sans text-xs transition-all delay-75"
          >
            <BaseIcon name="Attachment" class="w-6 h-6" strokeWidth="0.1" />
          </button>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import moment from "moment-hijri";
moment.updateLocale("ar-sa", {
  postformat: (string) => string,
});
import BaseIcon from "../components/BaseIcon.vue";
import ChatOptions from "../components/ChatOptions.vue";
import QuickEventInfo from "../components/QuickEventInfo.vue";
import QuickGuestInfo from "../components/QuickGuestInfo.vue";
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonInput,
  IonItem,
  IonText,
  popoverController,
  modalController,
} from "@ionic/vue";
export default {
  name: "Modal",
  props: {
    title: { type: String, default: "Super Modal" },
  },
  data() {
    return {
      moment: moment,
      chatText: "",
      currentTime: moment(new Date()),
      messages: "",
      interval: null,
      holdTimer: null,
      holdDuration: 500,
      currentEvent: null,
      currentChatText: null,
      currentChatTextID: null,
      replyText: null,
      replyTo: null,
    };
  },
  props: ["fromUser"],
  components: {
    BaseIcon,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonItem,
    IonText,
    IonInput,
    ChatOptions,
    QuickEventInfo,
    QuickGuestInfo,
  },
  methods: {
    removeTextReply() {
      this.replyText = null;
      this.replyTo = null;
    },
    startHold(event, currentChatText, currentChatTextID) {
      this.holdTimer = setTimeout(() => {
        this.currentEvent = event;
        this.currentChatText = currentChatText;
        this.currentChatTextID = currentChatTextID;
        this.openMenu();
      }, this.holdDuration);
    },
    endHold(event) {
      clearTimeout(this.holdTimer);
      this.holdTimer = null;
    },
    async openMenu() {
      const popover = await popoverController.create({
        component: ChatOptions,
        cssClass: "chat-options-popover",
        componentProps: { chatText: this.currentChatText },
        event: this.currentEvent,
        arrow: true,
      });
      await popover.present();

      return popover.onDidDismiss().then((data) => {
        if (data) {
          if (data.data == "startReply") {
            this.replyText = this.currentChatText;
            this.replyTo = this.currentChatTextID;
            //document.getElementById("chat-text").focus();
            this.$refs.chatText.focus();
          } else if (data.data == "textCopied") {
            alert("Copied");
          }
        }
        setTimeout(() => {
          this.currentChatText = null;
          this.currentChatTextID = null;
        }, 100);
      });
    },
    scrollToChat(val) {
      const el = this.$refs[`chat#${val}`][0];
      //const el = document.getElementById(`"${val}"`);
      if (el) {
        console.log(this.$refs[`chat#${val}`][0].childNodes[0].classList);
        el.scrollIntoView({ behavior: "smooth" });
        this.$refs[`chat#${val}`][0].childNodes[0].classList.add(
          "animate-pulse",
          "duration-700",
          "bg-slate-600/40",
          "shadow-gray-200"
        );
        //this.$refs[`chat#${val}`][0].div.$el.classList.value =
        //this.$refs[field].$el.classList.value + "animate-pulse";
      } else {
        console.log(el);
      }

      setTimeout(() => {
        this.$refs[`chat#${val}`][0].childNodes[0].classList.remove(
          "animate-pulse",
          "duration-700",
          "bg-slate-600/40",
          "shadow-gray-200"
        );
      }, 2000);
    },
    scrollToBottom() {
      if (this.messages != "" && this.messages.scrollHeight !== null) {
        this.messages.scrollTop = this.messages.scrollHeight;
      }
    },
    async showMentioned(mentioned, linkType) {
      let eventData = null;
      if (linkType == "event") {
        await this.$store.dispatch("getMentionedEvent", mentioned);
        eventData = await this.$store.getters.currentViewEventAside;
        this.$store.commit("toggleViewEventAside", false);

        const modal = await modalController.create({
          component: QuickEventInfo,
          componentProps: { event: eventData },
          initialBreakpoint: 0.7,
          breakpoints: [0.7, 0.8],
        });
        await modal.present();
      } else if (linkType == "guest") {
        await this.$store.dispatch("getMentionedGuest", mentioned);
        eventData = await this.$store.getters.getMentionedGuest;
        const modal = await modalController.create({
          component: QuickGuestInfo,
          componentProps: { guest: eventData },
          initialBreakpoint: 0.7,
          breakpoints: [0.7, 0.8],
        });
        await modal.present();
      }
    },
    updateCurrentTime() {
      this.currentTime = moment(new Date());
    },
    handleSend(e) {
      if (e.keyCode == 13) {
        this.sendChat();
      }
    },
    async sendChat() {
      let linkValue = 0; // Change value later might caused issue when start new DB
      let linkTitle = null;
      let linkType = null;
      if (this.selectedEventForChat != "") {
        linkValue = this.selectedEventForChat.id;
        linkType = this.selectedEventForChat.linkType;
        linkTitle =
          this.selectedEventForChat.linkType == "event"
            ? this.selectedEventForChat.title
            : this.selectedEventForChat.title +
              " - " +
              JSON.parse(this.selectedEventForChat.person)[0].name;
      }

      if (this.chatText != "") {
        let payload = {
          text: this.chatText,
          from: parseInt(localStorage.getItem("awqatiUserId")),
          to: this.$store.getters.getSelectedUserForChat.id, //this.selectedUser.id,
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
          link: linkValue,
          seen: 0,
          link_text: linkTitle,
          link_type: linkType,
          reply_to: this.replyTo,
          reply_text: this.replyText,
          attachments: null,
        };
        await this.$store.dispatch("sendNewChat", payload);
        await this.$store.commit("setEventForChat", "");
        await this.$store.commit("setMentionedUser", "");
        this.clearChat();
        this.$refs.chatText.focus();
        this.messages = document.getElementById("mobile-chat");
        setTimeout(() => {
          this.scrollToBottom();
        }, 800);
      }
    },
    clearChat() {
      this.chatText = "";
      this.replyText = null;
      this.replyTo = null;
      //this.selectedEventForChat = ''
      this.$store.commit("setEventForChat", "");
    },
    async removeMention() {
      await this.$store.commit("setMentionedUser", "");
      this.$store.commit("setEventForChat", "");
      //this.selectedEventForChat = ''
    },
    async closeMobileChat() {
      await this.$store.commit("setEventForChat", "");
      await this.$store.commit("setMentionedUser", "");
      await this.$store.commit("resetChatOfUserById");
      await this.$store.commit("resetSelectedUserForChat");
      modalController.dismiss();
    },
    async getNewMsgAndUpdate(data) {
      const upData = {
        to: parseInt(localStorage.getItem("awqatiUserId")),
        group: parseInt(localStorage.getItem("awqatiUserGroup")),
      };
      await this.$store.dispatch("getUserChatByID", data);
      let payload = {
        from: this.$store.getters.getSelectedUserForChat.id, //this.selectedUser.id,
        to: parseInt(localStorage.getItem("awqatiUserId")),
        group: parseInt(localStorage.getItem("awqatiUserGroup")),
      };
      await this.$store.dispatch("getTotalOfAllUnseenChat", data);
      await this.$store.dispatch("getTotalOfUnseenChatsFromUsers", data);
      await this.$store.dispatch("updateUnseenChat", payload);
      await this.$store.dispatch("getTotalOfAllUnseenChat", upData);
      this.$socket.emit("updateAfterSeen", payload);
      this.scrollToBottom();
    },
  },
  computed: {
    getReplyText() {
      return this.replyText;
    },
    getChatDataLoading() {
      return this.$store.getters.getChatDataLoading;
    },
    selectedEventForChat() {
      return this.$store.getters.getEventForChat;
    },
    getSendingChatLoading() {
      return this.$store.getters.getSendingChatLoading;
    },
    getTotalOfAllUnseenChat() {
      return this.$store.getters.getTotalOfAllUnseenChat;
    },
    getCurrentTime() {
      return this.currentTime;
    },
    getSelectedUser() {
      return this.$store.getters.getSelectedUserForChat; //this.selectedUser
    },
    getChatOfUserById() {
      return this.$store.getters.getChatOfUserById;
    },
    getMyID() {
      return parseInt(localStorage.getItem("awqatiUserId"));
    },
  },
  async mounted() {
    const upData = {
      to: parseInt(localStorage.getItem("awqatiUserId")),
      group: parseInt(localStorage.getItem("awqatiUserGroup")),
    };
    let payload = {
      from: this.fromUser.id,
      to: parseInt(localStorage.getItem("awqatiUserId")),
      group: parseInt(localStorage.getItem("awqatiUserGroup")),
    };
    await this.$store.dispatch("updateUnseenChat", payload);
    await this.$store.dispatch("getUserChatByID", payload);
    await this.$store.dispatch("getTotalOfUnseenChatsFromUsers", {
      to: parseInt(localStorage.getItem("awqatiUserId")),
      group: parseInt(localStorage.getItem("awqatiUserGroup")),
    });
    await this.$store.dispatch("getTotalOfAllUnseenChat", upData);
    this.scrollToBottom();
    this.messages = document.getElementById("mobile-chat");
    //this.scrollToBottom();
    this.scrollToBottom();
    //this.scrollToBottom();
  },
  created() {
    this.$socket.on("checkUserForChat", (data) => {
      const upData = data;
      if (data.to == localStorage.getItem("awqatiUserId")) {
        if (
          this.$store.getters.getSelectedUserForChat != "" &&
          this.$store.getters.getSelectedUserForChat.id == data.from
        ) {
          this.getNewMsgAndUpdate(data);
        } else {
          this.$store.dispatch("getTotalOfUnseenChatsFromUsers", upData);
          return;
        }
      } else {
        return;
      }
    });

    this.$socket.on("updateAfterSeen", async (data) => {
      if (
        data.to == this.$store.getters.getSelectedUserForChat.id &&
        data.from == localStorage.getItem("awqatiUserId")
      ) {
        await this.$store.dispatch("getUserChatByID", data);
      }
    });
    this.interval = setInterval(() => this.updateCurrentTime(), 30000);
  },
  beforeUnmount() {},
  async beforeDestroy() {
    clearInterval(this.interval);
  },
  beforeUpdate() {
    this.scrollToBottom();
  },
};
</script>

<style scoped>
.ion-toolbar {
  padding-top: 35px !important;
}

input {
  border: none !important;
  outline: none !important;
}

input:focus {
  border: none;
}

.bg-chat {
  background: #176b5b;
}

.text-chat {
  color: #176b5b;
}

.check-seen {
  color: #53bdeb;
}

.check-unseen {
  color: hsla(0, 0%, 100%, 0.5);
}

.flip-tail {
  transform: rotateY(180deg);
}

ion-item::part(native) {
  background: transparent;
}

.custom-shadow {
  box-shadow: rgba(43, 41, 0, 0.16) 0px 8px 31px;
  border: 1px solid hsl(64, 6%, 93%);
}
</style>
