<template>
  <ion-header translucent>
    <ion-toolbar>
      <button
        slot="start"
        @click="closeModal()"
        class="sm:col-span-1 mr-px py-3 px-4 text-lg font-semibold text-rose-700 focus:outline-none"
      >
        إلغاء
      </button>
      <ion-title class="flex items-center gap-x-2 text-slate-800 text-right">
        <h3 class="text-2xl leading-6 font-semibold text-gray-900">إضافة</h3>
      </ion-title>
      <button
        slot="end"
        v-if="requiredData"
        @click="savePerson()"
        class="sm:col-span-2 ml-px bg-indigo-600 py-3 px-4 text-lg font-semibold text-white hover:bg-indigo-700 active:bg-indigo-500 focus:outline-none"
      >
        حفظ
      </button>
      <button
        slot="end"
        v-if="!requiredData"
        class="sm:col-span-2 ml-px py-3 px-4 text-lg font-light text-gray-400"
      >
        حفظ
      </button>
    </ion-toolbar>
  </ion-header>
  <ion-content fullscreen>
    <div class="mt-2 grid px-8 py-6 grid-cols-1 gap-y-4 gap-x-4 bg-gray-50">
      <div class="sm:col-span-3 text-right">
        <label
          for="person-name"
          class="block text-sm font-medium text-gray-700"
        >
          الاسم
        </label>
        <div class="mt-1">
          <ion-input
            type="text"
            name="person-name"
            id="person-name"
            autocomplete="off"
            v-model="this.personName"
            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md text-indigo-700"
            style="
              display: block;
              width: 100%;
              --padding-top: 6px;
              --padding-start: 6px;
              --padding-end: 6px;
              --padding-bottom: 6px;
              border-width: 1px;
              --tw-border-opacity: 1;
              border-color: rgba(209, 213, 219, var(--tw-border-opacity));
              --background: white;
              --color: rgb(99, 102, 241);
              outline: 2px solid transparent;
              outline-offset: 2px;
            "
          ></ion-input>
          <!-- <input
                  type="text"
                  name="person-name"
                  id="person-name"
                  autocomplete="off"
                  v-model="this.personName"
                  class="
                    shadow-sm
                    focus:ring-indigo-500 focus:border-indigo-500
                    block
                    w-full
                    sm:text-sm
                    border-gray-300
                    rounded-md
                    text-indigo-700
                  "
                />-->
        </div>
      </div>

      <div class="sm:col-span-2 text-right">
        <div class="col-span-1">
          <Listbox as="div">
            <ListboxLabel class="block text-sm font-medium text-gray-700">
              الجهة
            </ListboxLabel>
            <div
              class="mt-1 relative border border-gray-300 rounded-lg shadow-sm"
            >
              <ListboxButton
                class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-right cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <span
                  class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                >
                  <CalendarIcon
                    class="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
                <span class="block truncate text-indigo-700"
                  >{{ selectedOffice.office || "اختر الجهة" }}
                </span>
              </ListboxButton>

              <transition
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <ListboxOptions
                  class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                >
                  <ListboxOption
                    as="template"
                    v-for="office in getOfficesList"
                    :key="office.id"
                  >
                    <li
                      @click="selectOffice(office)"
                      :class="[
                        selectedOffice.id == office.id
                          ? 'text-white bg-indigo-700'
                          : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9',
                      ]"
                    >
                      <span
                        :class="[
                          selectedOffice.id == office.id
                            ? 'font-semibold'
                            : 'font-normal',
                          'block truncate',
                        ]"
                      >
                        {{ office.office }}
                      </span>
                    </li>
                  </ListboxOption>
                </ListboxOptions>
              </transition>
            </div>
          </Listbox>
        </div>
      </div>
      <div
        @click="openNewOffice()"
        class="hidden md:flex mt-6 items-center col-span-1 px-2 py-1 rounded-lg bg-indigo-50 text-indigo-600"
      >
        <PlusIcon class="w-4 h-4 ml-2" />
        <div>إضافة جهة</div>
      </div>
      <!-- for mobile -->
      <div
        @click="openNewOfficeMobile()"
        class="flex md:hidden cursor-pointer items-center text-center col-span-1 justify-end w-full px-2 py-1 rounded-lg"
      >
        <div
          class="flex bg-indigo-50 text-indigo-600 px-2 py-1 rounded-lg items-center"
        >
          <span class="pt-1">إضافة جهة</span>
          <PlusIcon class="w-4 h-4 ml-2" />
        </div>
      </div>
      <div class="sm:col-span-3 text-right">
        <label
          for="position-name"
          class="block text-sm font-medium text-gray-700"
        >
          المنصب
        </label>
        <div class="mt-1">
          <ion-input
            type="text"
            name="position-name"
            id="position-name"
            autocomplete="off"
            v-model="this.position"
            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md text-indigo-700"
            style="
              display: block;
              width: 100%;
              --padding-top: 6px;
              --padding-start: 6px;
              --padding-end: 6px;
              --padding-bottom: 6px;
              border-width: 1px;
              --tw-border-opacity: 1;
              border-color: rgba(209, 213, 219, var(--tw-border-opacity));
              --background: white;
              --color: rgb(99, 102, 241);
              outline: 2px solid transparent;
              outline-offset: 2px;
            "
          ></ion-input>
        </div>
      </div>

      <div class="sm:col-span-3 text-right">
        <label for="identity" class="block text-sm font-medium text-gray-700">
          رقم الهوية
        </label>
        <div class="mt-1">
          <ion-input
            type="text"
            name="identity"
            id="identity"
            autocomplete="off"
            v-model="this.idty"
            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md text-indigo-700"
            style="
              display: block;
              width: 100%;
              --padding-top: 6px;
              --padding-start: 6px;
              --padding-end: 6px;
              --padding-bottom: 6px;
              border-width: 1px;
              --tw-border-opacity: 1;
              border-color: rgba(209, 213, 219, var(--tw-border-opacity));
              --background: white;
              --color: rgb(99, 102, 241);
              outline: 2px solid transparent;
              outline-offset: 2px;
            "
          ></ion-input>
        </div>
      </div>

      <div class="sm:col-span-3 text-right">
        <label for="phone" class="block text-sm font-medium text-gray-700">
          رقم الجوال
        </label>
        <div class="mt-1">
          <ion-input
            type="tel"
            name="phone"
            id="phone"
            autocomplete="off"
            v-model="this.phone"
            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md text-indigo-700"
            style="
              display: block;
              width: 100%;
              --padding-top: 6px;
              --padding-start: 6px;
              --padding-end: 6px;
              --padding-bottom: 6px;
              border-width: 1px;
              --tw-border-opacity: 1;
              border-color: rgba(209, 213, 219, var(--tw-border-opacity));
              --background: white;
              --color: rgb(99, 102, 241);
              outline: 2px solid transparent;
              outline-offset: 2px;
            "
          ></ion-input>
        </div>
      </div>

      <div class="sm:col-span-3 text-right">
        <label for="email" class="block text-sm font-medium text-gray-700">
          البريد الالكتروني
        </label>
        <div class="mt-1">
          <ion-input
            type="email"
            name="email"
            id="email"
            autocomplete="off"
            v-model="this.email"
            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md text-indigo-700"
            style="
              display: block;
              width: 100%;
              --padding-top: 6px;
              --padding-start: 6px;
              --padding-end: 6px;
              --padding-bottom: 6px;
              border-width: 1px;
              --tw-border-opacity: 1;
              border-color: rgba(209, 213, 219, var(--tw-border-opacity));
              --background: white;
              --color: rgb(99, 102, 241);
              outline: 2px solid transparent;
              outline-offset: 2px;
            "
          ></ion-input>
        </div>
      </div>
    </div>
  </ion-content>
</template>

<script>
import BaseIcon from "../components/BaseIcon.vue";
import NewOfficeModalMobile from "../components/NewOfficeModalMobile.vue";
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonInput,
  modalController,
} from "@ionic/vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { PlusIcon } from "@heroicons/vue/24/outline";
import { CalendarIcon } from "@heroicons/vue/24/solid";

export default {
  components: {
    NewOfficeModalMobile,
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    IonInput,
    BaseIcon,
  },
  data() {
    return {
      personName: "",
      office: "",
      position: "",
      idty: "",
      phone: "",
      email: "",
    };
  },
  methods: {
    selectOffice(office) {
      this.office = office;
    },
    async openNewOffice() {
      await this.$store.commit("toggleNewOfficeModal", true);
    },
    async openNewOfficeMobile() {
      const modal = await modalController.create({
        component: NewOfficeModalMobile,
        initialBreakpoint: 0.3,
        breakpoints: [0, 0.3, 0.3],
      });
      return modal.present();
    },
    async savePerson() {
      const payload = {
        personName: this.personName,
        office: JSON.stringify(this.office),
        position: this.position,
        idty: this.idty,
        phone: this.phone,
        email: this.email,
      };
      await this.$store.dispatch("saveNewPerson", payload);
      await modalController.dismiss();
    },
    async closeModal() {
      await modalController.dismiss();
    },
  },
  computed: {
    modalStatus() {
      return this.$store.getters.getNewPersonModalStatus;
    },
    requiredData() {
      if (this.personName !== "" && this.office !== "") {
        return true;
      }
      return false;
    },
    selectedOffice() {
      return this.office;
    },
    getOfficesList() {
      return this.$store.getters.getOfficesList;
    },
  },
  created() {
    this.$store.dispatch("getOffices");
  },
};
</script>

<style scoped>
ion-content {
  --background: rgb(249, 250, 251);
}
</style>
