<template>
  <ion-page>
    <ion-content>
      <div class="h-full flex">
        <!-- Content area -->
        <div class="flex-1 hidden md:flex flex-col overflow-hidden">
          <!-- Main content -->
          <Users v-if="currentSettingsPage == 'users'" />

          <Persons v-if="currentSettingsPage == 'persons'" />

          <Offices v-if="currentSettingsPage == 'offices'" />

          <Places v-if="currentSettingsPage == 'places'" />

          <Categories v-if="currentSettingsPage == 'categories'" />
        </div>

        <!-- Narrow sidebar -->
        <transition
          enter-active-class="transform transition ease-in-out duration-500 sm:duration-300"
          enter-from-class="translate-x-full"
          enter-to-class="translate-x-0"
          leave-active-class="transform transition ease-in-out duration-500 sm:duration-300"
          leave-from-class="translate-x-0"
          leave-to-class="translate-x-full"
        >
          <div
            dir="rtl"
            class="overflow-hidden hidden md:block w-80 bg-gray-100"
          >
            <div class="flex flex-col h-full">
              <div
                class="sticky z-40 py-4 px-3 top-0 bg-gray-100 border-b border-gray-200"
              >
                <div class="flex items-center gap-x-3 justify-start">
                  <BaseIcon
                    name="Settings-D"
                    className="w-8 h-8 text-gray-900"
                    strokeWidth="0.1"
                  />
                  <div>
                    <p class="text-2xl font-extrabold text-gray-900">
                      قائمة الإعدادات
                    </p>
                  </div>
                </div>
              </div>

              <div class="flex-1 min-h-0 overflow-y-auto">
                <div
                  v-if="getUserPrivilege == '1' || getUserPrivilege == 1"
                  @click="selectPage('users')"
                  class="hover:bg-white flex p-6 border-b border-blue-gray-200"
                  :class="
                    currentSettingsPage == 'users'
                      ? 'bg-white text-indigo-600'
                      : 'text-gray-600'
                  "
                >
                  <BaseIcon
                    name="Users-D"
                    className="w-6 h-6"
                    strokeWidth="0.1"
                  />
                  <div class="mr-3">
                    <p class="text-lg font-semibold text-blue-gray-900">
                      المستخدمين
                    </p>
                    <p class="mt-1 text-xs text-gray-500">
                      المستخدمين - المجموعات - الصلاحيات
                    </p>
                  </div>
                  <BaseIcon
                    name="ChevronLeft"
                    class="h-5 w-5 self-center mr-auto"
                    aria-hidden="true"
                  />
                </div>

                <div
                  @click="selectPage('persons')"
                  class="hover:bg-white flex p-6 border-b border-blue-gray-200"
                  :class="
                    currentSettingsPage == 'persons'
                      ? 'bg-white text-indigo-600'
                      : 'text-gray-600'
                  "
                >
                  <BaseIcon
                    name="Users-D"
                    className="w-6 h-6"
                    strokeWidth="0.1"
                  />
                  <div class="mr-3">
                    <p class="text-lg font-semibold text-blue-gray-900">
                      الأشخاص
                    </p>
                    <p class="mt-1 text-xs text-gray-500">إدارة الأشخاص</p>
                  </div>
                  <BaseIcon
                    name="ChevronLeft"
                    class="h-5 w-5 self-center mr-auto"
                    aria-hidden="true"
                  />
                </div>

                <div
                  @click="selectPage('offices')"
                  class="hover:bg-white flex p-6 border-b border-blue-gray-200"
                  :class="
                    currentSettingsPage == 'offices'
                      ? 'bg-white text-indigo-600'
                      : 'text-gray-600'
                  "
                >
                  <BaseIcon
                    name="Bookmarks-D"
                    className="w-6 h-6"
                    strokeWidth="0.1"
                  />
                  <div class="mr-3">
                    <p class="text-lg font-semibold text-blue-gray-900">
                      الجهات
                    </p>
                    <p class="mt-1 text-xs text-gray-500">إدارة الجهات</p>
                  </div>
                  <BaseIcon
                    name="ChevronLeft"
                    class="h-5 w-5 self-center mr-auto"
                    aria-hidden="true"
                  />
                </div>

                <div
                  @click="selectPage('places')"
                  class="hover:bg-white flex p-6 border-b border-blue-gray-200"
                  :class="
                    currentSettingsPage == 'places'
                      ? 'bg-white text-indigo-600'
                      : 'text-gray-600'
                  "
                >
                  <BaseIcon
                    name="Pin-D"
                    className="w-6 h-6"
                    strokeWidth="0.1"
                  />
                  <div class="mr-3">
                    <p class="text-lg font-semibold text-blue-gray-900">
                      الأماكن
                    </p>
                    <p class="mt-1 text-xs text-gray-500">إدارة الأماكن</p>
                  </div>
                  <BaseIcon
                    name="ChevronLeft"
                    class="h-5 w-5 self-center mr-auto"
                    aria-hidden="true"
                  />
                </div>

                <div
                  @click="selectPage('categories')"
                  class="hover:bg-white flex p-6 border-b border-blue-gray-200"
                  :class="
                    currentSettingsPage == 'categories'
                      ? 'bg-white text-indigo-600'
                      : 'text-gray-600'
                  "
                >
                  <BaseIcon
                    name="Categories-D"
                    className="w-6 h-6"
                    strokeWidth="0.1"
                  />
                  <div class="mr-3">
                    <p class="text-lg font-semibold text-blue-gray-900">
                      التصنيف
                    </p>
                    <p class="mt-1 text-xs text-gray-500">إدارة التصنيف</p>
                  </div>
                  <BaseIcon
                    name="ChevronLeft"
                    class="h-5 w-5 self-center mr-auto"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </transition>

        <transition
          enter-active-class="transform transition ease-in-out duration-500 sm:duration-300"
          enter-from-class="translate-x-full"
          enter-to-class="translate-x-0"
          leave-active-class="transform transition ease-in-out duration-500 sm:duration-300"
          leave-from-class="translate-x-0"
          leave-to-class="translate-x-full"
        >
          <div
            dir="rtl"
            class="overflow-hidden block md:hidden w-full bg-gray-100 pt-[10px]"
          >
            <div class="flex flex-col h-full">
              <div
                class="sticky z-40 py-4 top-0 bg-gray-100 border-b border-gray-200"
              >
                <div class="flex justify-between pt-4">
                  <div class="px-4 pb-1 text-center w-full">
                    <h2 class="text-2xl font-semibold text-gray-700">
                      قائمة الإعدادات
                    </h2>
                  </div>
                </div>
              </div>

              <div class="flex-1 min-h-0 overflow-y-auto">
                <div
                  v-if="getUserPrivilege == 5"
                  @click="openModal('users')"
                  class="hover:bg-white flex p-6 border-b border-blue-gray-200"
                  :class="
                    currentSettingsPage == 'users'
                      ? 'bg-white text-indigo-600'
                      : 'text-gray-600'
                  "
                >
                  <div class="mr-3">
                    <p class="text-lg font-semibold text-blue-gray-900">
                      المستخدمين
                    </p>
                    <p class="mt-1 text-sm text-gray-500">
                      المستخدمين - المجموعات - الصلاحيات
                    </p>
                  </div>
                </div>

                <div
                  @click="openModal('persons')"
                  class="hover:bg-white flex p-6 border-b border-blue-gray-200"
                  :class="
                    currentSettingsPage == 'persons'
                      ? 'bg-white text-indigo-600'
                      : 'text-gray-600'
                  "
                >
                  <div class="mr-3">
                    <p class="text-lg font-semibold text-blue-gray-900">
                      الأشخاص
                    </p>
                    <p class="mt-1 text-sm text-gray-500">إدارة الأشخاص</p>
                  </div>
                </div>

                <div
                  @click="openModal('offices')"
                  class="hover:bg-white flex p-6 border-b border-blue-gray-200"
                  :class="
                    currentSettingsPage == 'offices'
                      ? 'bg-white text-indigo-600'
                      : 'text-gray-600'
                  "
                >
                  <div class="mr-3">
                    <p class="text-lg font-semibold text-blue-gray-900">
                      الجهات
                    </p>
                    <p class="mt-1 text-sm text-gray-500">إدارة الجهات</p>
                  </div>
                </div>

                <div
                  @click="openModal('places')"
                  class="hover:bg-white flex p-6 border-b border-blue-gray-200"
                  :class="
                    currentSettingsPage == 'places'
                      ? 'bg-white text-indigo-600'
                      : 'text-gray-600'
                  "
                >
                  <div class="mr-3">
                    <p class="text-lg font-semibold text-blue-gray-900">
                      الأماكن
                    </p>
                    <p class="mt-1 text-sm text-gray-500">
                      أماكن انعقاد الاجتماعات واللقاءات
                    </p>
                  </div>
                </div>

                <div
                  @click="openModal('categories')"
                  class="hover:bg-white flex p-6 border-b border-blue-gray-200"
                  :class="
                    currentSettingsPage == 'categories'
                      ? 'bg-white text-indigo-600'
                      : 'text-gray-600'
                  "
                >
                  <div class="mr-3">
                    <p class="text-lg font-semibold text-blue-gray-900">
                      التصنيف
                    </p>
                    <p class="mt-1 text-sm text-gray-500">
                      تصنيف الاجتماعات والأحداث
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import BaseIcon from "../components/BaseIcon.vue";
import Users from "../components/Users.vue";
import Persons from "../components/Persons.vue";
import Offices from "../components/Offices.vue";
import Places from "../components/Places.vue";
import Categories from "../components/Categories.vue";

import moment from "moment-hijri";
moment.updateLocale("ar-sa", {
  postformat: (string) => string,
});

import { IonPage, IonContent, modalController } from "@ionic/vue";
export default {
  components: {
    Users,
    Persons,
    Offices,
    Places,
    Categories,
    IonPage,
    IonContent,
    BaseIcon,
  },
  data() {
    return {};
  },
  methods: {
    selectPage(pageName) {
      this.$store.commit("setCurrentSettingsPage", pageName);
    },
    async openModal(modalName) {
      await this.$store.commit("setCurrentSettingsPage", modalName);
      let currentModal;
      if (modalName == "persons") {
        currentModal = Persons;
      } else if (modalName == "users") {
        currentModal = Users;
      } else if (modalName == "offices") {
        currentModal = Offices;
      } else if (modalName == "places") {
        currentModal = Places;
      } else if (modalName == "categories") {
        currentModal = Categories;
      }
      const modal = await modalController.create({
        component: currentModal,
        initialBreakpoint: 0.5,
        breakpoints: [0, 0.5, 1],
      });
      return modal.present();
    },
  },
  computed: {
    getUserPrivilege() {
      return localStorage.getItem("awqatiUserPrivilege");
    },
    currentSettingsPage() {
      return this.$store.getters.getCurrentSettingsPage;
    },
    usersGroup() {
      return this.$store.getters.getUsersByGroup;
    },
    getSelectedUser() {
      return this.selectedUser;
    },
    getChatOfUserById() {
      return this.$store.getters.getChatOfUserById;
    },
    getMyID() {
      return parseInt(localStorage.getItem("awqatiUserId"));
    },
    newChatfromUsers() {
      return this.newChatfromUsersList;
    },
    getTotalOfUnseenChatOfUserById() {
      return this.$store.getters.getTotalOfUnseenChatOfUserById;
    },
  },
  async created() {
    let payload = {
      group: parseInt(localStorage.getItem("awqatiUserGroup")),
      me: parseInt(localStorage.getItem("awqatiUserId")),
    };
    await this.$store.dispatch("getUsersByGroup", payload);
    //this.interval = setInterval(() => this.updateCurrentTime(), 300000);
  },
  mounted() {
    this.$socket.on("checkUserForChat", (data) => {
      const upData = data;
      if (data.to == localStorage.getItem("awqatiUserId")) {
        console.log("IS (to) USER !");
        if (this.selectedUser != "" && this.selectedUser.id == data.from) {
          console.log("Its current user !!");
          this.$store.dispatch("getUserChatByID", data);
        } else {
          console.log("Its Not current user !!");

          this.$store.dispatch("getTotalOfUnseenChatOfUserByID", upData);
          //this.inUserNotification.push()
          return;
        }
      } else {
        console.log("NOT (to) USER !");
        return;
      }
    });
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
ion-content {
  background-color: rgb(249 250 251);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='%23f1f5f9'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e");
}
</style>
