<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div
    dir="rtl"
    class="
      fixed
      z-50
      inset-0
      overflow-y-auto
      bg-gray-500 bg-opacity-75
      transition-opacity
    "
  >
    <div
      class="
        flex
        items-end
        justify-center
        min-h-screen
        pt-4
        pb-20
        text-center
        sm:block sm:p-0
      "
    >
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="
          inline-block
          align-bottom
          bg-white
          rounded-lg
          pt-2
          text-left
          overflow-hidden
          shadow-xl
          transform
          transition-all
          sm:align-middle sm:max-w-lg sm:w-full
        "
      >
        <div class="flex items-center justify-center">
          <div class="text-center">
            <h3 class="text-2xl leading-6 font-semibold text-gray-900">
              إضافة مكان
            </h3>
          </div>
        </div>
        <div
          class="
            mt-2
            grid
            px-12
            py-6
            grid-cols-1
            gap-y-4 gap-x-4
            bg-gray-50
            border-t border-gray-300
          "
        >
          <div class="sm:col-span-3 text-right">
            <label
              for="place-name"
              class="block text-sm font-medium text-gray-700"
            >
              الاسم
            </label>
            <div class="mt-1">
              <ion-input
                type="text"
                name="place-name"
                id="place-name"
                autocomplete="off"
                v-model="this.placeName"
                class="shadow-sm block w-full sm:text-sm rounded-md"
                :class="
                  !this.checkData
                    ? 'text-red-700 border border-red-700 focus:ring-red-700 focus:border-red-700'
                    : 'text-indigo-700 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                "
                style="
                  display: block;
                  width: 100%;
                  --padding-top: 6px;
                  --padding-start: 6px;
                  --padding-end: 6px;
                  --padding-bottom: 6px;
                  border-width: 1px;
                  --tw-border-opacity: 1;
                  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
                  --background: white;
                  --color: rgb(99, 102, 241);
                  outline: 2px solid transparent;
                  outline-offset: 2px;
                "
              ></ion-input>
              <!-- <input
                  type="text"
                  name="place-name"
                  id="place-name"
                  autocomplete="off"
                  v-model="this.placeName"
                  class="
                    shadow-sm
                    block
                    w-full
                    sm:text-sm
                    rounded-md
                  "
                  :class="!this.checkData ? 'text-red-700 border border-red-700 focus:ring-red-700 focus:border-red-700' : 'text-indigo-700 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'"
                /> -->
            </div>
          </div>
          <span v-if="!this.checkData" class="text-center text-red-700"
            >اسم المكان مضاف مسبقاً</span
          >
        </div>
        <div
          dir="ltr"
          class="
            py-3
            px-4
            sm:grid sm:grid-cols-3 sm:gap-1 sm:grid-flow-row-dense
            border-t border-gray-300
          "
        >
          <button
            @click="closeModal()"
            class="
              sm:col-span-1
              mr-px
              py-3
              px-4
              text-lg
              font-semibold
              text-rose-700
              focus:outline-none
            "
          >
            إلغاء
          </button>
          <button
            v-if="requiredData && this.checkData"
            @click="savePlace()"
            class="
              sm:col-span-2
              ml-px
              bg-indigo-600
              border border-transparent
              rounded-md
              shadow-sm
              py-3
              px-4
              text-lg
              font-semibold
              text-white
              hover:bg-indigo-700
              active:bg-indigo-500
              focus:outline-none
            "
          >
            حفظ
          </button>
          <button
            v-if="!requiredData || !this.checkData"
            class="
              sm:col-span-2
              ml-px
              bg-gray-200
              border border-transparent
              rounded-md
              shadow-sm
              py-3
              px-4
              text-lg
              font-light
              text-gray-400
            "
          >
            حفظ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IonInput } from "@ionic/vue";
export default {
  data() {
    return {
      placeName: "",
    };
  },
  components: {
    IonInput,
  },
  methods: {
    async savePlace() {
      if (this.checkData) {
        const payload = {
          placeName: this.placeName,
        };
        await this.$store.dispatch("saveNewPlace", payload);
        this.$store.commit("toggleNewPlaceModal", false);
      } else {
        return;
      }
    },
    closeModal() {
      this.$store.commit("toggleNewPlaceModal", false);
    },
  },
  computed: {
    modalStatus() {
      return this.$store.getters.getNewPlaceModalStatus;
    },
    requiredData() {
      if (this.placeName !== "") {
        return true;
      }
      return false;
    },
    getPlaces() {
      return this.$store.getters.getPlacesList;
    },
    checkData() {
      let places = this.$store.getters.getPlacesList;
      if (places.some((place) => place.place === this.placeName)) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.$store.dispatch("getPlaces");
  },
};
</script>