<template>
  <div dir="rtl" class="block mt-3 p-3">
    <div
      class="flex pb-4 items-center justify-between border-b border-gray-200"
    >
      <div class="flex items-center gap-x-2">
        <BaseIcon name="Users-D" className="w-7 h-7" strokeWidth="0.1" />
        <p class="text-lg font-bold text-slate-900">
          {{ this.guest.title }}
        </p>
      </div>
      <div class="flex items-center gap-x-3 text-sm font-medium text-gray-900">
        <span
          class="inline-flex items-center rounded-full bg-gray-100/100 px-2 py-1 text-sm font-bold text-gray-900"
          >{{
            `${JSON.parse(guest.time).hour}:${JSON.parse(guest.time).minute} ${
              JSON.parse(guest.time).apm
            }`
          }}</span
        >
      </div>
    </div>

    <dl class="mt-6 space-y-4">
      <div class="flex items-center gap-x-12 justify-around">
        <div class="flex flex-col items-start gap-y-1">
          <dt class="flex items-center gap-x-1 text-xs text-gray-600">
            <BaseIcon name="Users" className="w-4 h-4" strokeWidth="0.2" />
            <span>الشخص/الأشخاص</span>
          </dt>
          <dd class="flex items-center mr-2 gap-x-3">
            <ul role="list" class="divide-y divide-gray-100">
              <li
                v-for="(person, idx) in JSON.parse(guest.person)"
                :key="person.email"
                class="flex items-start gap-x-2 py-2"
              >
                <BaseIcon
                  name="UserSquare"
                  className="mt-px h-5 w-5 flex-none rounded-full text-gray-600"
                  strokeWidth="0.1"
                  :alt="'Person- ' + idx"
                />
                <div class="min-w-0">
                  <p class="text-xs font-semibold leading-6 text-gray-900">
                    {{ person.name }}
                  </p>
                  <p class="truncate text-xs leading-5 text-gray-500">
                    {{ person.position }}
                  </p>
                </div>
              </li>
            </ul>
          </dd>
        </div>
        <div
          v-if="guest.note && guest.note !== ''"
          class="flex flex-col items-start justify-between px-8"
        >
          <dt class="-mr-2 flex items-center gap-x-px text-xs text-gray-600">
            <BaseIcon name="NoteText" className="w-4 h-4" strokeWidth="0.2" />
            <span>الملاحظات</span>
          </dt>
          <dd
            class="mt-2 flex items-center gap-x-3 text-sm font-medium text-rose-900"
          >
            <div
              class="flex-auto bg-yellow-50/50 rounded-md p-3 ring-1 ring-inset ring-yellow-700/50"
            >
              <p class="text-sm leading-6 text-yellow-800">{{ guest.note }}</p>
            </div>
          </dd>
        </div>
      </div>
    </dl>
    <div class="relative pt-8 pb-3">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300" />
      </div>
      <div class="relative flex justify-center">
        <span
          class="bg-white px-3 -mt-3 text-base font-semibold leading-6 text-gray-900"
          >خيارات الوقت</span
        >
      </div>
    </div>
    <ul
      role="list"
      class="w-full grid grid-cols-3 gap-4 justify-items-stretch font-bold leading-6 sm:gap-6 bg-indigo-50 p-2 rounded-lg border border-indigo-600"
    >
      <li
        v-for="(option, idx) in timeOptions"
        :key="idx"
        @click="selectTime(option.value)"
        class="text-sm col-span-1 flex items-center justify-center font-bold text-indigo-600 text-center rounded-md shadow-sm ring-1 ring-inset ring-gray-300 bg-white active:bg-indigo-50 px-2.5 py-4"
      >
        {{ option.name }}
      </li>
    </ul>
  </div>
</template>
<script>
import moment from "moment-hijri";
moment.updateLocale("ar-sa", {
  postformat: (string) => string,
});
import BaseIcon from "./BaseIcon.vue";
import { modalController } from "@ionic/vue";
export default {
  data() {
    return {
      timeOptions: [
        {
          name: "الآن",
          value: null,
        },
        {
          name: "بعد 5 دقائق",
          value: 5,
        },
        {
          name: "بعد 10 دقائق",
          value: 10,
        },
        {
          name: "بعد 15 دقيقة",
          value: 15,
        },
        {
          name: "بعد 30 دقيقة",
          value: 30,
        },
        {
          name: "بعد ساعه",
          value: 60,
        },
      ],
    };
  },
  props: ["guest"],
  components: {
    BaseIcon,
  },
  methods: {
    async selectTime(val) {
      let updatedTime = null;
      if (val !== null) {
        updatedTime = moment().add(val, "M");
      } else {
        updatedTime = moment();
      }
      this.guest.status = "approved";
      this.guest.time = JSON.stringify({
        hour: `${moment(updatedTime).format("hh")}`,
        minute: `${moment(updatedTime).format("mm")}`,
        apm: `${moment(updatedTime).format("A")}`,
      });
      await this.$store.dispatch("updateGuest", this.guest);
      /*await this.$store.dispatch("getGuestForCommingUp", {
        group: parseInt(this.guest.group_num),
      });*/
      modalController.dismiss();
    },
  },
};
</script>
