<template>
  <div>
    <ion-toggle
      :checked="getIsAudioEnabled"
      @click="initAudioPlayback"
      :enable-on-off-labels="true"
      >الصوت</ion-toggle
    >
  </div>
</template>

<script>
import { IonToggle } from "@ionic/vue";
import callingSound from "@/assets/calling-ring.mp3"; // Update the path as needed
import notificationSound from "@/assets/notification.mp3"; // Update the path as needed
export default {
  data() {
    return {
      callAudio: null,
      eventAudio: null,
      notificationAudio: null,
      isAudioEnabled: false,
    };
  },
  components: {
    IonToggle,
  },
  methods: {
    isSuper() {
      if (localStorage.getItem("awqatiUserPrivilege") == 6) {
        return true;
      }
      return false;
    },
    initAudioPlayback() {
      this.callAudio = new Audio(callingSound); // Use the imported audio file
      //this.notificationAudio = new Audio(notificationSound);
      this.isAudioEnabled = true;

      // Optionally: Start listening to data after audio is enabled
      this.startListening();
    },
    startListening() {
      // Example function to simulate receiving data from the server
      setInterval(() => {
        this.receiveData();
      }, 2000); // Simulates receiving data every 5 seconds
    },
    receiveData() {
      console.log("Data received from server.");

      const notificationType = Math.random() > 0.5 ? "call" : "notification";

      if (notificationType === "call") {
        this.playAudio("call"); // Play primary notification sound
      } else {
        //this.playAudio("notification"); // Play secondary notification sound
      }
      //this.playAudio();
    },
    playAudio(type) {
      if (this.isAudioEnabled) {
        if (type === "call" && this.hasCalling && this.callAudio) {
          this.callAudio.play().catch((error) => {
            console.error("Audio playback failed: ", error);
          });
        } else if (
          type === "notification" &&
          this.hasCalling &&
          this.notificationAudio
        ) {
          //this.notificationAudio.play().catch((error) => {
          //  console.error("Audio playback failed: ", error);
          //});
        }
      }
    },
  },
  computed: {
    hasCalling() {
      return this.$store.getters.getHasCalling;
    },
    getNotificationStatus() {
      return this.$store.getters.getNotificationStatus;
    },
    getIsAudioEnabled() {
      return this.isAudioEnabled;
    },
  },
  mounted() {
    if (this.hasCalling && this.callAudio) {
      this.playAudio("call");
    }
    if (this.getNotificationStatus && this.notificationAudio) {
      //this.playAudio("notification");
    }
  },
};
</script>
