<template>
  <aside
    dir="rtl"
    :class="this.modalStatus ? 'absolute z-30 h-full' : 'hidden'"
    class="right-0 w-full sm:w-[20rem] bg-gray-100 border-l border-gray-300 overflow-y-auto select-none"
  >
    <!-- Your content -->
    <div class="flex flex-col h-full">
      <div
        class="sticky z-40 flex items-start justify-between px-3 py-5 bg-white text-slate-800 ring-1 ring-black ring-opacity-5 backdrop-blur backdrop-filter"
      >
        <div class="shrink-0 w-7">
          <BaseIcon
            name="CalendarEvent-D"
            className="w-7 h-7"
            strokeWidth="0.1"
          />
        </div>
        <div class="grow text-xl font-bold">
          {{ getCurrentEventData.title }}
        </div>
        <div @click="closeSide()" class="shrink-0 self-start mt-1">
          <BaseIcon name="XIcon" className="w-5 h-5 text-rose-500" />
        </div>
      </div>

      <div class="h-full px-3 flex flex-col overflow-y-auto overflow-x-hidden">
        <div class="sm:col-span-2 mt-4">
          <label
            for="account-number"
            class="block flex items-center gap-x-1 text-sm font-medium text-gray-700"
          >
            <BaseIcon
              name="CalendarDate"
              className="w-4 h-4"
              strokeWidth="0.1"
            />
            <span>التاريخ</span>
          </label>
          <div class="w-full h-10 mt-1 relative pr-4">
            <p class="font-semibold">{{ getCurrentEventData.ar_date }} هـ</p>
          </div>
        </div>

        <div class="mt-4 sm:col-span-2">
          <p
            class="block flex items-center gap-x-1 text-sm font-medium text-gray-700"
          >
            <BaseIcon name="Time" className="w-4 h-4" strokeWidth="0.1" />
            <span>الوقت</span>
          </p>
        </div>
        <div class="flex space-x-4">
          <div class="flex sm:col-span-1 pr-4 ml-12">
            <label
              for="last-name"
              class="mt-1 block text-sm font-medium text-gray-700"
              >من</label
            >
            <div class="w-full h-10 mt-1 relative pr-4">
              <p class="font-semibold">
                {{
                  JSON.parse(getCurrentEventData.time).hour +
                  ":" +
                  JSON.parse(getCurrentEventData.time).minute +
                  " " +
                  JSON.parse(getCurrentEventData.time).apm
                }}
              </p>
            </div>
          </div>

          <div class="flex justify-between sm:col-span-1 mr-12">
            <label
              for="last-name"
              class="mt-1 block text-sm font-medium text-gray-700"
              >إلى</label
            >
            <div class="w-full h-10 mt-1 relative pr-4">
              <p class="font-semibold">
                {{
                  formatTo(
                    JSON.parse(getCurrentEventData.time),
                    getCurrentEventData.duration
                  )
                }}
              </p>
            </div>
          </div>
        </div>

        <div
          v-if="getCurrentEventData.duration !== 'غير محدد'"
          class="flex items-center justify-end gap-x-1 sm:col-span-2 -mt-2 text-sm font-medium text-rose-700"
        >
          <BaseIcon name="TimeDuration" className="w-4 h-4" strokeWidth="0.1" />
          <span>لمدة</span>
          <span>{{ getCurrentEventData.duration }} د</span>
        </div>

        <div class="sm:col-span-2 mt-4">
          <label
            for="address"
            class="block flex items-center gap-x-1 text-sm font-medium text-gray-700"
          >
            <BaseIcon name="Users" className="w-4 h-4" strokeWidth="0.1" />
            <span>الشخص/الأشخاص</span>
          </label>
          <div class="w-full h-10 mt-1 relative pr-4">
            <p
              class="flex items-center gap-x-1 font-semibold text-sm"
              v-for="person in JSON.parse(getCurrentEventData.person)"
              :key="person"
            >
              <BaseIcon
                name="UserSquare"
                className="w-4 h-4 mb-px"
                strokeWidth="0.1"
              />
              <span>{{ person.name }}</span>
            </p>
          </div>
        </div>
        <div class="mt-4 sm:col-span-2">
          <label
            for="phone"
            class="block flex items-center gap-x-1 text-sm font-medium text-gray-700"
          >
            <BaseIcon name="Pin" className="w-4 h-4" strokeWidth="0.1" />
            <span>المكان</span>
          </label>
          <div class="w-full h-10 mt-1 relative pr-4">
            <p class="font-semibold">{{ getCurrentEventData.place }}</p>
          </div>
        </div>

        <div class="mt-4 sm:col-span-1">
          <label
            for="apartment"
            class="block flex items-center gap-x-1 text-sm font-medium text-gray-700"
          >
            <BaseIcon name="Bookmark" className="w-4 h-4" strokeWidth="0.1" />
            <span>التصنيف</span>
          </label>
          <div class="mt-1 pr-4">
            <div class="relative w-auto bg-gray-100 border rounded-lg p-2 flex">
              <div class="flex-1 flex">
                <span
                  class="mt-1 w-2.5 h-2.5 ml-1 rounded-full"
                  aria-hidden="true"
                />
                <div class="flex flex-col">
                  <span
                    class="block text-sm font-semibold"
                    :class="`${
                      JSON.parse(getCurrentEventData.color).txt_class_color
                    }`"
                  >
                    {{ getCurrentEventData.category }}
                  </span>
                </div>
              </div>
              <CheckCircleIcon
                class="h-5 w-5"
                :class="`${
                  JSON.parse(getCurrentEventData.color).txt_class_color
                }`"
                aria-hidden="true"
              />
              <div
                :class="`absolute -inset-px rounded-lg pointer-events-none`"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>

        <div class="mt-8 sm:col-span-2">
          <label
            for="note"
            class="block flex items-center gap-x-1 text-sm font-medium text-gray-700"
          >
            <BaseIcon name="NoteText" className="w-4 h-4" strokeWidth="0.1" />
            <span>الملاحظات</span>
          </label>
          <div class="w-full h-10 mt-1 relative pr-4">
            <p class="font-semibold">{{ getCurrentEventData.note }}</p>
          </div>
        </div>

        <div
          class="mt-4 sm:col-span-2"
          v-if="isValid(getCurrentEventData.attachment)"
        >
          <label
            for="attachments"
            class="block flex items-center gap-x-1 text-sm font-medium text-gray-700"
          >
            <BaseIcon name="Attachment" className="w-4 h-4" strokeWidth="0.1" />
            <span>الملفات</span>
          </label>
          <div
            class="relative"
            v-if="JSON.parse(getCurrentEventData.attachment).length > 0"
          >
            <div class="block w-full h-10 mt-1 relative pr-4">
              <div class="border-t border-gray-100 pb-6 sm:col-span-2 sm:px-0">
                <ul
                  role="list"
                  class="divide-y divide-gray-100 rounded-md border border-gray-200 bg-white"
                >
                  <template
                    v-for="(file, idx) in JSON.parse(
                      getCurrentEventData.attachment
                    )"
                    :key="idx"
                  >
                    <li
                      @click="openPDF(file.name, file.description, 'events')"
                      v-if="file.ext == 'pdf'"
                      class="flex items-center justify-between py-2 px-2 text-sm leading-6"
                    >
                      <div class="flex items-center w-0 flex-1 items-center">
                        <BaseIcon
                          name="Attachment"
                          class="h-4 w-4 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                          strokeWidth="0.2"
                        />
                        <div class="mr-2 pt-1.5 flex min-w-0 flex-1 gap-2">
                          <span class="truncate font-bold">
                            {{
                              file.description == ""
                                ? `ملف رقم ${idx + 1}`
                                : file.description
                            }}</span
                          >
                          <span class="flex-shrink-0 text-gray-400">{{
                            file.ext
                          }}</span>
                        </div>
                      </div>
                      <div class="ml-4 flex-shrink-0">
                        <div
                          class="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          <BaseIcon
                            name="Eye"
                            class="w-5 h-5"
                            stokeWidth="0.1"
                          />
                        </div>
                      </div>
                    </li>
                    <li
                      @click="openImage(file.name, file.description, 'events')"
                      v-else
                      class="flex items-center justify-between py-2 px-2 text-sm leading-6"
                    >
                      <div class="flex items-center w-0 flex-1 items-center">
                        <BaseIcon
                          name="Attachment"
                          class="h-4 w-4 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                          strokeWidth="0.2"
                        />
                        <div class="mr-2 pt-1.5 flex min-w-0 flex-1 gap-2">
                          <span class="truncate font-bold">
                            {{
                              file.description == ""
                                ? `ملف رقم ${idx + 1}`
                                : file.description
                            }}</span
                          >
                          <span class="flex-shrink-0 text-gray-400">{{
                            file.ext
                          }}</span>
                        </div>
                      </div>
                      <div class="ml-4 flex-shrink-0">
                        <div
                          class="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          <BaseIcon
                            name="Eye"
                            class="w-5 h-5"
                            stokeWidth="0.1"
                          />
                        </div>
                      </div>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ion-popover
        :backdropDismiss="false"
        :is-open="getPopOverStatus"
        :show-backdrop="true"
        style="overflow-y: hidden !important; --height: 320px"
      >
        <div dir="rtl">
          <div
            class="flex items-center justify-between py-3 px-2 text-center bg-gray-100 w-full font-semibold"
          >
            <span>قائمة الموظفين</span>
            <div
              @click="closePopover()"
              class="bg-red-50 text-red-700 rounded-full p-1 float-left"
            >
              <BaseIcon name="XIcon" class="w-4 h-4" />
            </div>
          </div>
          <ion-list>
            <ion-item
              @click="goToChat(user)"
              v-for="user in usersGroup"
              :key="user.id"
              class="flex flex-col text-sm hover:bg-yellow-500 cursor-pointer"
            >
              <ion-label>
                <h3 class="font-semibold">{{ user.name }}</h3>
                <p>{{ user.position }}</p>
              </ion-label>
            </ion-item>
          </ion-list>
        </div>
      </ion-popover>
      <div
        class="sticky bottom-0 z-40 px-2 flex gap-x-1 border-t border-gray-200 bg-white bg-opacity-40 backdrop-filter backdrop-blur py-6"
      >
        <div
          v-if="this.canDoAction && getUserPrivilege != 8"
          @click="editEvent()"
          class="flex-1 flex flex-col gap-y-2 text-center justify-center items-center sm:col-span-1 bg-white shadow shadow-indigo-300/50 rounded-md py-2 px-2 text-sm font-semibold text-indigo-700 focus:outline-none"
        >
          <BaseIcon name="Edit" className="w-5 h-5" strokeWidth="0.1" />
          <span>تعديل</span>
        </div>
        <div
          v-if="getUserPrivilege !== '4'"
          @click="getNames()"
          id="open-chat-with-employee-1"
          class="flex-1 flex flex-col gap-y-2 text-center justify-center items-center bg-white bg-opacity-70 shadow shadow-yellow-200/50 rounded-md py-2 px-2 text-sm font-semibold text-yellow-700 focus:outline-none"
        >
          <BaseIcon name="Chat" className="w-5 h-5" strokeWidth="0.1" />
          <span class="text-[0.94rem] sm:text-sm">محادثة</span>
        </div>
        <!-- <div class="sm:col-span-1" v-else></div> -->
        <div
          v-if="
            this.canDoAction &&
            this.getCurrentEventData.status !== 'unavailable' &&
            getUserPrivilege != 8 &&
            getUserPrivilege !== '4'
          "
          @click="cancelEvent()"
          class="flex-1 flex flex-col gap-y-2 text-center justify-center items-center bg-white shadow shadow-rose-300/50 rounded-md py-2 px-2 text-sm font-semibold text-rose-600 focus:outline-none"
        >
          <BaseIcon name="Trash" className="w-5 h-5" strokeWidth="0.1" />
          <span>إلغاء الموعد</span>
        </div>
        <div
          v-if="
            this.canDoAction &&
            this.getCurrentEventData.status == 'unavailable' &&
            getUserPrivilege != 8
          "
          @click="activeEvent()"
          class="flex-1 flex flex-col gap-y-2 text-center justify-center items-center bg-white shadow shadow-green-300/50 rounded-md py-2 px-2 text-sm font-semibold text-green-600 focus:outline-none"
        >
          <BaseIcon name="Reactivate" className="w-5 h-5" strokeWidth="0.1" />
          <span>إعادة تفعيل</span>
        </div>
        <div
          v-if="
            !this.canDoAction &&
            currentRouteName == 'Main' &&
            (getUserPrivilege == 5 || getUserPrivilege == 6)
          "
          @click="getNames()"
          id="open-chat-with-employee-2"
          class="flex flex-row text-center justify-center items-center sm:col-span-3 bg-white bg-opacity-70 shadow rounded-md py-2 px-2 text-base font-semibold text-yellow-700 focus:outline-none"
        >
          <div>
            <BaseIcon name="Chat" className="w-5 h-5" strokeWidth="0.1" />
          </div>
          <div class="text-md mr-4 mt-1">محادثة الموظف</div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import moment from "moment-hijri";
moment.updateLocale("ar-sa", {
  postformat: (string) => string,
});
import BaseIcon from "../components/BaseIcon.vue";
import PDFViwer from "../components/PDFViewer.vue";
import ImgViewer from "../components/ImgViewer.vue";

import {
  IonLabel,
  IonItem,
  IonList,
  IonPopover,
  popoverController,
  CheckCircleIcon,
  actionSheetController,
  modalController,
} from "@ionic/vue";
export default {
  components: {
    IonLabel,
    IonItem,
    IonList,
    IonPopover,
    BaseIcon,
    CheckCircleIcon,
    PDFViwer,
    ImgViewer,
  },
  data() {
    return {
      dateContext: moment(),
      popOverIsOpen: false,
    };
  },
  methods: {
    // Check attachments value
    isValid(param) {
      const invalidValues = [
        null,
        undefined,
        "",
        "undefined",
        JSON.stringify(""), // This would be the stringified empty string
        JSON.stringify(undefined), // This would handle the string `'undefined'`
      ];

      return !invalidValues.includes(param);
    },

    async openPDF(path, name, folder) {
      const modal = await modalController.create({
        component: PDFViwer,
        componentProps: { filePath: path, fileName: name, folder },
      });
      await modal.present();
    },

    async openImage(path, name, folder) {
      const modal = await modalController.create({
        component: ImgViewer,
        componentProps: { filePath: path, fileName: name, folder },
      });
      await modal.present();
    },

    openPopover() {
      this.popOverIsOpen = true;
    },
    closePopover() {
      //popoverController.dismiss()
      this.popOverIsOpen = false;
    },
    formatTo(time, duration) {
      let fullTime = time.hour + ":" + time.minute + " " + time.apm;
      let convert = moment(fullTime, ["HH:mm A"])
        .add(parseInt(duration), "minutes")
        .format("hh:mm A");
      return convert;
    },
    async getNames() {
      let payload = {
        group: parseInt(localStorage.getItem("awqatiUserGroup")),
        me: parseInt(localStorage.getItem("awqatiUserId")),
      };
      await this.$store.dispatch("getUsersByGroup", payload);
      this.openPopover();
    },
    async editEvent() {
      //this.$store.commit("toggleViewEventAside", false)
      await this.$store.commit("toggleEditEventModal", true);
    },
    async cancelEvent() {
      const actionSheet = await actionSheetController.create({
        header: `إلغاء الموعد`,
        cssClass: "my-custom-class",
        buttons: [
          {
            text: "تأكيد إلغاء الموعد",
            role: "destructive",
            handler: () => {
              this.getCurrentEventData.type = "cancel";
              this.getCurrentEventData.status = "unavailable";
              this.$store.dispatch("updateEvent", this.getCurrentEventData);
              this.$store.dispatch("getEventsForCommingUp", {
                group: parseInt(this.getCurrentEventData.group_num),
              });
            },
          },
          {
            text: "إغلاق",
            role: "cancel",
            handler: () => {},
          },
        ],
      });
      await actionSheet.present();
    },
    async activeEvent() {
      const actionSheet = await actionSheetController.create({
        header: `تفعيل الموعد`,
        cssClass: "my-custom-class",
        buttons: [
          {
            text: "تأكيد تفعيل الموعد",
            role: "destructive",
            handler: () => {
              this.getCurrentEventData.type = "active";
              this.getCurrentEventData.status = "available";
              this.$store.dispatch("activeEvent", this.getCurrentEventData);
              this.$store.dispatch("getEventsForCommingUp", {
                group: parseInt(this.getCurrentEventData.group_num),
              });
            },
          },
          {
            text: "إغلاق",
            role: "cancel",
            handler: () => {},
          },
        ],
      });
      await actionSheet.present();
    },
    async goToChat(user) {
      let payload = {
        from: user.id,
        to: parseInt(localStorage.getItem("awqatiUserId")),
        group: parseInt(localStorage.getItem("awqatiUserGroup")),
      };
      let currentEvent = this.getCurrentEventData;
      currentEvent.linkType = "event";
      await this.$store.commit("setEventForChat", "");
      await this.$store.commit("setMentionedUser", "");
      await this.$store.commit("resetChatOfUserById");
      await this.$store.commit("resetSelectedUserForChat");
      await this.$store.dispatch("updateUnseenChat", payload);
      await this.$store.dispatch("getUserChatByID", payload);
      await this.$store.commit("setSelectedUserForChat", user);
      await this.$store.commit("setEventForChat", currentEvent);
      await this.$store.commit("setMentionedUser", user);
      await this.$store.commit("toggleViewEventAside", false);
      await popoverController.dismiss();

      setTimeout(() => {
        this.$router.push("/chat");
      }, 200);
    },
    closeSide() {
      this.$store.commit("toggleViewEventAside", false);
    },
  },
  computed: {
    getUserPrivilege() {
      return localStorage.getItem("awqatiUserPrivilege");
    },
    currentRouteName() {
      return this.$route.name;
    },
    getPopOverStatus() {
      return this.popOverIsOpen;
    },
    canDoAction() {
      const currentDateTime = moment();

      const { en_date, time, duration } = this.getCurrentEventData;

      // Parse the time object
      const timeObj = JSON.parse(time);
      const hour = parseInt(timeObj.hour);
      const minute = parseInt(timeObj.minute);
      const isPM = timeObj.apm === "م";

      // Convert to 24-hour format if PM
      const hour24 = isPM && hour !== 12 ? hour + 12 : hour;

      // Create moment objects for the event start and end times
      const eventStartDateTime = moment(
        `${en_date} ${hour24}:${minute}`,
        "YYYY/M/D HH:mm"
      );
      const eventEndDateTime = moment(eventStartDateTime).add(
        parseInt(duration),
        "minutes"
      );

      if (currentDateTime.isBefore(eventStartDateTime)) {
        return true;
      } else if (currentDateTime.isAfter(eventEndDateTime)) {
        return false;
      }
    },
    modalStatus() {
      return this.$store.getters.viewEventAsideStatus;
    },
    getCurrentEventData() {
      return this.$store.getters.currentViewEventAside;
    },
    getCurrentDay() {
      return this.propCurrentDay;
    },
    year() {
      return this.dateContext.format("iYYYY");
    },
    month() {
      return this.dateContext.format("iMMMM");
    },
    currentDate() {
      return this.dateContext.get("iDate");
    },
    usersGroup() {
      let valideUsers = this.$store.getters.getUsersByGroup.filter((user) => {
        return user.user_status != 4;
      });
      return valideUsers;
    },
  },
};
</script>

<style scoped>
.size {
  width: 14.286%;
}
</style>
