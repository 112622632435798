<template>
  <div
    dir="rtl"
    class="w-full h-screen overflow-y-auto overflow-x-hidden bg-gray-100 bg-grid-gray-100 bg-opacity-50"
  >
    <div class="max-w-3xl mx-auto py-4 px-4 mb-24">
      <div class="flex items-center gap-x-3 justify-start">
        <BaseIcon
          name="Users-D"
          className="w-8 h-8 text-gray-900"
          strokeWidth="0.1"
        />
        <div>
          <h1 class="text-3xl font-extrabold text-gray-900">الأشخاص</h1>
          <p class="mt-1 max-w-2xl text-md leading-6 text-gray-500">
            قائمة جميع الأشخاص مثل ( الضيوف - أعضاء الاجتماع وغيرهم )
          </p>
        </div>
      </div>
      <div class="flex flex-col justify-center mx-6 md:mx-36 my-6">
        <button
          @click="openNewPersonModal()"
          class="w-fit mt-10 mb-4 bg-indigo-600 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none shadow-md"
        >
          إضافة شخص
        </button>

        <div class="w-full flex justify-center">
          <div
            dir="rtl"
            class="relative bg-white w-full rounded-lg h-80 overflow-auto ring-1 ring-gray-900/5 -my-px"
          >
            <div
              class="sticky top-0 w-full z-20 bg-gray-200 py-2 text-gray-600 rounded-t border-b border-gray-300 text-center"
            >
              القائمة
            </div>
            <div class="relative" v-for="office in offices" :key="office">
              <div
                class="sticky top-0 px-4 py-2 flex items-center font-light text-sm text-gray-900 bg-gray-50/90 backdrop-blur-sm ring-1 ring-gray-900/10"
              >
                {{ office }}
              </div>
              <div class="divide-y">
                <div v-for="person in personsList" :key="person">
                  <div
                    class="flex flex-row justify-between items-center gap-3 p-3"
                    v-if="JSON.parse(person.office).office == office"
                  >
                    <div class="flex flex-row items-center">
                      <div class="flex flex-col space-x-2">
                        <strong class="text-gray-900 text-sm font-semibold">{{
                          person.name
                        }}</strong>
                        <span class="text-gray-600 text-sm">
                          {{ person.position }}
                        </span>
                      </div>
                    </div>
                    <div class="flex flex-row items-center gap-x-3">
                      <BaseIcon
                        @click="editPerson(person)"
                        name="Edit"
                        className="w-5 h-5 text-green-700"
                        strokeWidth="0.2"
                      />
                      <BaseIcon
                        @click="removePerson(person)"
                        name="Trash"
                        className="w-5 h-5 text-rose-700"
                        strokeWidth="0.2"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from "../components/BaseIcon.vue";
import { actionSheetController } from "@ionic/vue";
export default {
  data() {
    return {
      office: "",
    };
  },
  components: {
    BaseIcon,
  },
  methods: {
    groupBy(xs) {
      let officesNames = [];
      xs.forEach((element) => {
        if (
          officesNames.find(
            (office) => office == JSON.parse(element.office).office
          )
        ) {
          return;
        }
        officesNames.push(JSON.parse(element.office).office);
      });
      return officesNames;
    },
    selectOffice(office) {
      this.office = office;
    },
    async openNewPersonModal() {
      await this.$store.commit("toggleNewPersonModal", true);
    },
    async removePerson(person) {
      const actionSheet = await actionSheetController.create({
        header: `حذف ${person.name}`,
        cssClass: "my-custom-class",
        buttons: [
          {
            text: "تأكيد",
            role: "destructive",
            handler: () => {
              this.$store.dispatch("removePerson", person);
            },
          },
          {
            text: "إلغاء",
            role: "cancel",
            handler: () => {
              console.log("Cancel clicked");
            },
          },
        ],
      });
      await actionSheet.present();
    },
    async editPerson(person) {
      await this.$store.commit("setEditPerson", person);
      this.$store.commit("toggleEditPersonModal", true);
    },
  },
  computed: {
    personsList() {
      return this.$store.getters.getPersonsList;
    },
    offices() {
      let list = this.$store.getters.getPersonsList;
      return this.groupBy(list);
    },
    selectedOffice() {
      return this.office;
    },
  },
  async created() {
    await this.$store.dispatch("getPersons");
    this.$store.dispatch("getOffices");
  },
};
</script>
