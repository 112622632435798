<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div dir="rtl" class="fixed z-40 inset-0 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0"
    >
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded-lg pt-2 text-right text-slate-800 overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full"
      >
        <div class="flex items-center gap-x-2 justify-start py-3 px-3">
          <BaseIcon name="Edit-D" className="w-7 h-7" strokeWidth="0.1" />
          <p class="text-xl leading-6 font-semibold">
            {{ this.currentPerson }}
          </p>
        </div>
        <div
          class="mt-2 grid px-12 py-6 grid-cols-1 gap-y-4 gap-x-4 bg-gray-50 border-t border-gray-300"
        >
          <div class="sm:col-span-3 text-right">
            <label
              for="person-name"
              class="block text-sm font-medium text-gray-700"
            >
              الاسم
            </label>
            <div class="mt-1">
              <ion-input
                type="text"
                name="person-name"
                id="person-name"
                autocomplete="off"
                v-model="this.personName"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md text-indigo-700"
                style="
                  display: block;
                  width: 100%;
                  --padding-top: 6px;
                  --padding-start: 6px;
                  --padding-end: 6px;
                  --padding-bottom: 6px;
                  border-width: 1px;
                  --tw-border-opacity: 1;
                  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
                  --background: white;
                  --color: rgb(99, 102, 241);
                  outline: 2px solid transparent;
                  outline-offset: 2px;
                "
              ></ion-input>
            </div>
          </div>

          <div class="sm:col-span-2 text-right">
            <div class="col-span-1">
              <Listbox as="div">
                <ListboxLabel class="block text-sm font-medium text-gray-700">
                  الجهة
                </ListboxLabel>
                <div
                  class="mt-1 relative border border-gray-300 rounded-lg shadow-sm"
                >
                  <ListboxButton
                    class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-right cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <span
                      class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                    >
                      <BaseIcon
                        name="Flag"
                        className="h-5 w-5 text-gray-400"
                        strokeWidth="0.1"
                        aria-hidden="true"
                      />
                    </span>
                    <span class="block truncate text-indigo-700"
                      >{{ selectedOffice.office || "اختر الجهة" }}
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    >
                      <ListboxOption
                        as="template"
                        v-for="office in getOfficesList"
                        :key="office.id"
                      >
                        <li
                          @click="selectOffice(office)"
                          :class="[
                            selectedOffice.id == office.id
                              ? 'text-white bg-indigo-700'
                              : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-3 pr-9',
                          ]"
                        >
                          <span
                            :class="[
                              selectedOffice.id == office.id
                                ? 'font-semibold'
                                : 'font-normal',
                              'block truncate',
                            ]"
                          >
                            {{ office.office }}
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
          </div>
          <div
            @click="openNewOffice()"
            class="col-span-1 flex mt-6 items-center gap-x-1 text-sm font-bold col-span-1 px-2 py-1 rounded-lg bg-indigo-50 text-indigo-600"
          >
            <BaseIcon
              name="PlusCircle"
              class="h-5 w-5 text-indigo-700"
              strokeWidth="0.2"
              aria-hidden="true"
            />
            <div>إضافة جهة</div>
          </div>

          <div class="sm:col-span-3 text-right">
            <label
              for="position-name"
              class="block text-sm font-medium text-gray-700"
            >
              المنصب
            </label>
            <div class="mt-1">
              <ion-input
                type="text"
                name="position-name"
                id="position-name"
                autocomplete="off"
                v-model="this.position"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md text-indigo-700"
                style="
                  display: block;
                  width: 100%;
                  --padding-top: 6px;
                  --padding-start: 6px;
                  --padding-end: 6px;
                  --padding-bottom: 6px;
                  border-width: 1px;
                  --tw-border-opacity: 1;
                  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
                  --background: white;
                  --color: rgb(99, 102, 241);
                  outline: 2px solid transparent;
                  outline-offset: 2px;
                "
              ></ion-input>
            </div>
          </div>

          <div class="sm:col-span-3 text-right">
            <label
              for="identity"
              class="block text-sm font-medium text-gray-700"
            >
              رقم الهوية
            </label>
            <div class="mt-1">
              <ion-input
                type="text"
                name="identity"
                id="identity"
                autocomplete="off"
                v-model="this.idty"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md text-indigo-700"
                style="
                  display: block;
                  width: 100%;
                  --padding-top: 6px;
                  --padding-start: 6px;
                  --padding-end: 6px;
                  --padding-bottom: 6px;
                  border-width: 1px;
                  --tw-border-opacity: 1;
                  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
                  --background: white;
                  --color: rgb(99, 102, 241);
                  outline: 2px solid transparent;
                  outline-offset: 2px;
                "
              ></ion-input>
            </div>
          </div>

          <div class="sm:col-span-3 text-right">
            <label for="phone" class="block text-sm font-medium text-gray-700">
              رقم الجوال
            </label>
            <div class="mt-1">
              <ion-input
                type="tel"
                name="phone"
                id="phone"
                autocomplete="off"
                v-model="this.phone"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md text-indigo-700"
                style="
                  display: block;
                  width: 100%;
                  --padding-top: 6px;
                  --padding-start: 6px;
                  --padding-end: 6px;
                  --padding-bottom: 6px;
                  border-width: 1px;
                  --tw-border-opacity: 1;
                  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
                  --background: white;
                  --color: rgb(99, 102, 241);
                  outline: 2px solid transparent;
                  outline-offset: 2px;
                "
              ></ion-input>
            </div>
          </div>

          <div class="sm:col-span-3 text-right">
            <label for="email" class="block text-sm font-medium text-gray-700">
              البريد الالكتروني
            </label>
            <div class="mt-1">
              <ion-input
                type="email"
                name="email"
                id="email"
                autocomplete="off"
                v-model="this.email"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md text-indigo-700"
                style="
                  display: block;
                  width: 100%;
                  --padding-top: 6px;
                  --padding-start: 6px;
                  --padding-end: 6px;
                  --padding-bottom: 6px;
                  border-width: 1px;
                  --tw-border-opacity: 1;
                  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
                  --background: white;
                  --color: rgb(99, 102, 241);
                  outline: 2px solid transparent;
                  outline-offset: 2px;
                "
              ></ion-input>
            </div>
          </div>
        </div>
        <div
          dir="ltr"
          class="py-3 px-4 sm:grid sm:grid-cols-3 sm:gap-1 sm:grid-flow-row-dense border-t border-gray-300"
        >
          <button
            @click="closeModal()"
            class="sm:col-span-1 mr-px py-3 px-4 text-lg font-semibold text-gray-400 focus:outline-none"
          >
            إلغاء
          </button>
          <button
            v-if="requiredData"
            @click="savePerson()"
            class="sm:col-span-2 ml-px bg-indigo-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-lg font-semibold text-white hover:bg-indigo-700 focus:outline-none"
          >
            حفظ التعديل
          </button>
          <button
            v-if="!requiredData"
            class="sm:col-span-2 ml-px bg-gray-200 border border-transparent rounded-md shadow-sm py-3 px-4 text-lg font-light text-gray-400"
          >
            حفظ التعديل
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from "../components/BaseIcon.vue";
import { IonInput } from "@ionic/vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    IonInput,
    BaseIcon,
  },
  data() {
    return {
      ID: "",
      personName: "",
      office: "",
      position: "",
      idty: "",
      phone: "",
      email: "",
      currentPerson: "",
    };
  },
  methods: {
    selectOffice(office) {
      this.office = office;
    },
    async openNewOffice() {
      await this.$store.commit("toggleNewOfficeModal", true);
    },
    async savePerson() {
      const payload = {
        id: this.ID,
        personName: this.personName,
        office: JSON.stringify(this.office),
        position: this.position,
        idty: this.idty,
        phone: this.phone,
        email: this.email,
      };
      await this.$store.dispatch("editPerson", payload);
      this.$store.commit("toggleEditPersonModal", false);
    },
    closeModal() {
      this.$store.commit("toggleEditPersonModal", false);
    },
  },
  computed: {
    requiredData() {
      if (this.personName !== "" && this.office !== "") {
        return true;
      }
      return false;
    },
    selectedOffice() {
      return this.office;
    },
    getOfficesList() {
      return this.$store.getters.getOfficesList;
    },
  },
  created() {
    this.ID = this.$store.getters.getEditPerson.id;
    this.personName = this.$store.getters.getEditPerson.name;
    this.currentPerson = this.$store.getters.getEditPerson.name;
    this.office = JSON.parse(this.$store.getters.getEditPerson.office);
    this.position = this.$store.getters.getEditPerson.position;
    this.idty = this.$store.getters.getEditPerson.idty;
    this.phone = this.$store.getters.getEditPerson.phone;
    this.email = this.$store.getters.getEditPerson.email;
  },
};
</script>
