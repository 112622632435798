<template>
  <aside
    dir="rtl"
    :class="this.modalStatus ? 'absolute z-30 h-full' : 'hidden'"
    class="right-0 w-full sm:w-[20rem] bg-gray-100 border-l border-gray-300 overflow-y-auto"
  >
    <!-- Your content -->
    <div class="flex flex-col h-full">
      <div
        class="sticky z-40 flex items-center gap-x-2 px-3 py-5 bg-white text-indigo-700 ring-1 ring-black ring-opacity-5 backdrop-blur backdrop-filter"
      >
        <BaseIcon name="UserPlus-D" className="w-7 h-7" strokeWidth="0.1" />
        <p class="text-xl font-bold">إضافة ضيف</p>
      </div>

      <div class="h-full px-3 flex flex-col overflow-y-auto overflow-x-hidden">
        <div class="my-2">
          <label
            for="event-title"
            class="block mb-1 text-sm font-medium text-gray-700"
            >الموضوع</label
          >
          <div class="mt-1 sm:mt-0 sm:col-span-2">
            <input
              v-model="this.guestTitle"
              type="text"
              name="event-title"
              id="event-title"
              autocomplete="off"
              class="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
            <!--<ion-input
              v-model="this.guestTitle"
              type="text"
              name="event-title"
              id="event-title"
              autocomplete="off"
              clear-input
              class="max-w-lg flex w-full text-indigo-700 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
              style="
                display: flex;
                width: 100%;
                --padding-top: 6px;
                --padding-start: 6px;
                --padding-end: 6px;
                --padding-bottom: 6px;
                border-width: 1px;
                --tw-border-opacity: 1;
                border-color: rgba(209, 213, 219, var(--tw-border-opacity));
                --background: white;
                --color: rgb(99, 102, 241);
                outline: 2px solid transparent;
                outline-offset: 2px;
              "
            ></ion-input>-->
          </div>
        </div>

        <div class="my-2">
          <label
            for="account-number"
            class="block text-sm font-medium text-gray-700"
            >التاريخ</label
          >
          <div
            @click="openDatePopover()"
            class="w-full h-10 mt-1 bg-white border border-gray-300 relative rounded-md shadow-sm"
          >
            <div
              class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
            >
              <BaseIcon
                name="Calendar"
                className="h-5 w-5 text-gray-400"
                strokeWidth="0.2"
                aria-hidden="true"
              />
              <span v-if="seletedDay != ''" class="text-indigo-700 text-sm"
                ><b>{{ seletedDay.dateText }}</b> -
                {{ seletedDay.arDate }} الموافق {{ seletedDay.enDate }}</span
              >
            </div>
          </div>
        </div>

        <div class="my-2">
          <p class="block text-sm font-medium text-gray-700">الوقت</p>
          <div
            @click="openPicker"
            class="w-full h-10 mt-1 bg-white border border-gray-300 relative rounded-md shadow-sm"
          >
            <div
              class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
            >
              <BaseIcon
                name="Time"
                className="ml-1 h-5 w-5 text-gray-400"
                strokeWidth="0.1"
                aria-hidden="true"
              />
              <span class="text-indigo-700 text-sm">{{
                selectedFromTime.hour +
                ":" +
                selectedFromTime.minute +
                " " +
                selectedFromTime.apm
              }}</span>
            </div>
          </div>
        </div>

        <div class="hidden md:block my-2">
          <label for="address" class="block text-sm font-medium text-gray-700"
            >الضيف/الضيوف</label
          >
          <div
            id="open-person"
            class="w-full h-10 mt-1 bg-white border border-gray-300 relative rounded-md shadow-sm"
          >
            <div
              class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
            >
              <BaseIcon
                name="UserSquare"
                className="h-5 w-5 text-gray-400"
                strokeWidth="0.1"
                aria-hidden="true"
              />
            </div>
            <ion-popover
              trigger="open-person"
              :show-backdrop="true"
              style="
                --width: 250px;
                overflow-y: hidden !important;
                --height: 320px;
              "
            >
              <div dir="rtl" class="flex flex-row justify-around">
                <div class="relative text-gray-400 mx-4 my-2">
                  <ion-input
                    type="text"
                    name="search"
                    class="w-full py-2 text-sm bg-gray-100 rounded-md border border-gray-300 pr-10 focus:outline-none text-gray-900"
                    placeholder="ابحث ..."
                    autocomplete="off"
                    v-model="this.personSearch"
                    :clear-input="getPersonSearch !== ''"
                    style="
                      display: block;
                      width: 100%;
                      --padding-top: 6px;
                      --padding-start: 6px;
                      --padding-end: 6px;
                      --padding-bottom: 6px;
                      border-width: 1px;
                      --tw-border-opacity: 1;
                      border-color: rgba(
                        209,
                        213,
                        219,
                        var(--tw-border-opacity)
                      );
                      --background: white;
                      --color: rgb(99, 102, 241);
                      outline: 2px solid transparent;
                      outline-offset: 2px;
                    "
                  ></ion-input>
                  <span
                    class="absolute inset-y-0 right-0 flex items-center pl-2"
                  >
                    <button
                      v-if="getPersonSearch == ''"
                      class="p-1 focus:outline-none focus:shadow-outline"
                    >
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        class="w-6 h-6"
                      >
                        <path
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        ></path>
                      </svg>
                    </button>
                  </span>
                </div>
                <button @click="openNewPersonModal()" class="text-center pl-2">
                  <BaseIcon
                    name="PlusCircle"
                    class="h-6 w-6 text-indigo-700"
                    strokeWidth="0.5"
                    aria-hidden="true"
                  />
                </button>
              </div>
              <div
                dir="rtl"
                class="relative bg-white shadow-lg h-80 overflow-auto ring-1 ring-gray-900/5 -my-px"
              >
                <div class="relative" v-for="office in offices" :key="office">
                  <div
                    class="sticky top-0 px-4 py-2 flex items-center font-light text-sm text-gray-900 bg-gray-50/90 backdrop-blur-sm ring-1 ring-gray-900/10"
                  >
                    {{ office }}
                  </div>
                  <div class="divide-y">
                    <div
                      @click="selectPerson(person)"
                      v-for="person in getPersonList"
                      :key="person"
                      class="active:bg-indigo-500 active:text-white"
                    >
                      <div
                        class="flex items-center gap-3 p-3"
                        v-if="JSON.parse(person.office).office == office"
                      >
                        <BaseIcon
                          name="UserSquare"
                          className="w-7 h-7 text-gray-700"
                          strokeWidth="0.1"
                        />
                        <div class="flex flex-col space-x-2">
                          <strong class="text-gray-900 text-sm font-semibold">{{
                            person.name
                          }}</strong>
                          <span class="text-gray-600 text-sm">
                            {{ person.position }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ion-popover>
          </div>
          <span
            v-for="(person, index) in getSelectedPersons"
            :key="index"
            class="inline-flex rounded-lg items-center py-0.5 pl-2.5 pr-1 m-1 text-sm font-medium bg-indigo-100 text-indigo-700"
          >
            {{ person.name }}
            <button
              @click="removePerson(index)"
              type="button"
              class="flex-shrink-0 ml-0.5 mr-2 h-4 w-4 rounded-lg inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white"
            >
              <svg
                class="h-2 w-2"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 8 8"
              >
                <path
                  stroke-linecap="round"
                  stroke-width="1.5"
                  d="M1 1l6 6m0-6L1 7"
                />
              </svg>
            </button>
          </span>
        </div>

        <!-- Start person picker for mobile -->
        <div class="md:hidden block my-2">
          <label for="address" class="block text-sm font-medium text-gray-700"
            >الضيف/الضيوف</label
          >
          <div
            id="person-picker-mobile"
            class="w-full h-10 mt-1 bg-white border border-gray-300 relative rounded-md shadow-sm"
          >
            <div
              class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
            >
              <BaseIcon
                name="UserSquare"
                class="h-5 w-5 text-gray-400"
                strokeWidth="0.1"
                aria-hidden="true"
              />
            </div>
            <!-- :dismiss-on-select="true" -->
            <ion-modal
              trigger="person-picker-mobile"
              :breakpoints="[0.1, 0.5, 1]"
              :initialBreakpoint="0.5"
            >
              <ion-header dir="rtl">
                <ion-toolbar style="padding-top: 20px !important">
                  <ion-searchbar
                    dir="rtl"
                    placeholder="ابحث ..."
                    autocomplete="off"
                    v-model="this.personSearch"
                  ></ion-searchbar>
                  <button
                    slot="end"
                    @click="openNewPersonModalMobile()"
                    class="text-center pl-2 mt-2"
                  >
                    <BaseIcon
                      name="PlusCircle"
                      className="h-6 w-6 text-indigo-700"
                      strokeWidth="0.1"
                      aria-hidden="true"
                    />
                  </button>
                </ion-toolbar>
              </ion-header>
              <ion-content>
                <div
                  dir="rtl"
                  class="relative bg-white h-80 overflow-auto -my-px"
                >
                  <div class="relative" v-for="office in offices" :key="office">
                    <div
                      class="sticky top-0 px-4 pt-1 flex items-center font-light text-sm text-gray-900 bg-gray-50/90 backdrop-blur-sm ring-1 ring-gray-900/10"
                    >
                      {{ office }}
                    </div>
                    <div class="divide-y">
                      <div
                        @click="selectPersonMobile(person)"
                        v-for="person in getPersonList"
                        :key="person"
                      >
                        <div
                          class="flex items-center gap-3 p-3"
                          v-if="JSON.parse(person.office).office == office"
                        >
                          <BaseIcon
                            name="UserSquare"
                            class="h-6 w-6 text-gray-700"
                            strokeWidth="0.1"
                            aria-hidden="true"
                          />
                          <div class="flex flex-col space-x-2">
                            <strong
                              class="text-gray-900 text-sm font-semibold"
                              >{{ person.name }}</strong
                            >
                            <span class="text-gray-600 text-sm">
                              {{ person.position }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ion-content>
            </ion-modal>
          </div>
          <span
            v-for="(person, index) in getSelectedPersons"
            :key="index"
            class="inline-flex rounded-lg items-center py-0.5 pl-2.5 pr-1 m-1 text-sm font-medium bg-indigo-100 text-indigo-700"
          >
            {{ person.name }}
            <button
              @click="removePerson(index)"
              type="button"
              class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-lg inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white"
            >
              <svg
                class="h-2 w-2"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 8 8"
              >
                <path
                  stroke-linecap="round"
                  stroke-width="1.5"
                  d="M1 1l6 6m0-6L1 7"
                />
              </svg>
            </button>
          </span>
        </div>
        <!-- End person picker for mobile -->

        <div class="my-2">
          <label for="apartment" class="block text-sm font-medium text-gray-700"
            >الحالة</label
          >
          <div class="mt-1">
            <RadioGroup>
              <div
                class="mt-2 grid grid-cols-1 gap-y-3 sm:grid-cols-2 sm:gap-x-3"
              >
                <RadioGroupOption
                  as="template"
                  v-for="status in this.getStatusList"
                  :key="status.id"
                  @click="selectStatus(status)"
                >
                  <div
                    class="relative bg-white rounded-lg shadow-sm p-2 flex cursor-pointer focus:outline-none"
                  >
                    <div class="flex-1 flex items-center">
                      <span
                        :class="
                          getSelectedStatus.value == status.value
                            ? `${status.bg_color_class}`
                            : 'bg-gray-200'
                        "
                        class="w-2.5 h-2.5 ml-1 rounded-full"
                        aria-hidden="true"
                      />
                      <div class="flex flex-col">
                        <RadioGroupLabel
                          as="span"
                          class="block text-sm font-medium"
                          :class="
                            getSelectedStatus.value == status.value
                              ? `${status.txt_color_class}`
                              : 'text-gray-400'
                          "
                        >
                          {{ status.name }}
                        </RadioGroupLabel>
                      </div>
                    </div>
                    <BaseIcon
                      v-if="status.value == 'approved'"
                      name="UserCheck"
                      class="h-5 w-5"
                      strokeWidth="0.1"
                      :class="
                        getSelectedStatus.value == 'approved'
                          ? `${status.txt_color_class}`
                          : 'text-gray-400'
                      "
                      aria-hidden="true"
                    />
                    <BaseIcon
                      v-else
                      name="UserAlert"
                      class="h-5 w-5"
                      strokeWidth="0.1"
                      :class="
                        getSelectedStatus.value == 'waiting'
                          ? `${status.txt_color_class}`
                          : 'text-gray-400'
                      "
                      aria-hidden="true"
                    />
                    <div
                      class="absolute -inset-px rounded-lg pointer-events-none"
                      aria-hidden="true"
                    />
                  </div>
                </RadioGroupOption>
              </div>
            </RadioGroup>
          </div>
        </div>

        <div class="my-2">
          <label for="phone" class="block text-sm font-bold text-gray-700"
            >الملفات</label
          >
          <div
            class="w-[70%] flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6"
          >
            <div class="flex w-0 flex-1 items-center">
              <label
                for="meeting-files"
                class="flex justify-center items-center gap-x-3 w-full cursor-pointer rounded-md text-sm text-white font-semibold px-3 py-2 bg-slate-600 focus-within:outline-none shadow-sm hover:bg-slate-500"
              >
                <BaseIcon
                  name="Browse"
                  className="text-white w-4 h-4 rtl:ml-1 ltr:mr-1"
                />
                <span class="mt-1">أضف ملف</span>
                <input
                  id="meeting-files"
                  name="meeting-files"
                  type="file"
                  class="sr-only"
                  @change="
                    onFileChange($event.target.name, $event.target.files)
                  "
                  ref="file"
                  multiple
                  accept="image/*,.pdf"
                />
              </label>
            </div>
          </div>
          <template v-if="getGuestFiles.length > 0">
            <ion-item v-for="(file, idx) in getGuestFiles" :key="file">
              <ion-label>
                <div class="w-[100%] flex items-center gap-x-3">
                  <div
                    class="w-24 h-24 group block overflow-hidden rounded-lg bg-gray-100 shadow"
                  >
                    <a
                      v-if="file.ext !== 'pdf'"
                      :href="file.URL"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        :src="file.URL"
                        alt="image"
                        class="group-hover:opacity-75 cursor-pointer object-cover object-center"
                      />
                    </a>
                    <a
                      v-else
                      :href="file.URL"
                      target="_blank"
                      rel="noopener noreferrer"
                      class="flex-initial ml-2 px-2 py-1 flex items-center justify-center text-xs rounded-md font-semibold text-purple-500"
                    >
                      <BaseIcon name="PDF" className="w-24 h-24 mr-2 -mt-1" />
                    </a>
                  </div>
                  <div class="w-[100%] flex justify-between items-center">
                    <div
                      class="mr-2 flex flex-col items-start self-end min-w-0 flex-1 gap-2"
                    >
                      <input
                        v-model="this.guestFiles[idx].description"
                        type="member-files"
                        name="member-files"
                        id="member-files"
                        autocomplete="off"
                        class="block w-full text-xs rounded-md border-0 px-2 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                        placeholder="اسم الملف"
                      />
                      <span class="text-xs flex-shrink-0 text-gray-400">{{
                        file.name
                      }}</span>
                      <span class="text-xs flex-shrink-0 text-gray-400"
                        >{{ file.size }} MB</span
                      >
                    </div>
                    <div class="mr-4 flex-shrink-0">
                      <BaseIcon
                        @click="removeFile(idx)"
                        :name="'Trash'"
                        :className="'w-9 h-9 p-2 rounded-full text-rose-600 border-full hover:bg-rose-50 cursor-pointer'"
                        :strokeWidth="'0.2'"
                      />
                    </div>
                  </div>
                </div>
              </ion-label>
            </ion-item>
            <div
              v-if="getGuestFiles.length > 1"
              class="w-full flex items-center justify-end mb-3 -mr-3"
            >
              <div
                @click="removeAllFiles()"
                class="w-auto flex items-center gap-x-3 px-3 py-1.5 rounded-md bg-rose-600 text-white"
              >
                <BaseIcon
                  name="Trash"
                  className="w-4 h-4"
                  :strokeWidth="'0.2'"
                />
                <span class="text-sm mt-1">حذف جميع الملفات</span>
              </div>
            </div>
          </template>
        </div>

        <div class="my-2">
          <label
            for="guest-note"
            class="block text-sm font-medium text-gray-700"
          >
            ملاحظات
          </label>
          <div class="mt-1">
            <textarea
              v-model="this.guestNote"
              rows="4"
              id="guest-note"
              name="guest-note"
              class="focus:ring-yellow-300 focus:border-yellow-300 block w-full sm:text-sm bg-yellow-50 bg-opacity-60 border border-yellow-200 text-yellow-900 px-3 py-3 rounded-md"
            />
          </div>
        </div>
      </div>

      <div
        class="sticky bottom-0 z-40 bg-white bg-opacity-40 backdrop-filter backdrop-blur grid grid-cols-1 gap-y-3 sm:grid-cols-3 sm:gap-x-2 border-t border-gray-200 py-3 px-4"
      >
        <button
          v-if="requiredData"
          @click="addGuest()"
          class="sm:col-span-2 ml-px bg-indigo-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-semibold text-white hover:bg-indigo-700 focus:outline-none"
        >
          إضافة
        </button>
        <button
          v-if="!requiredData"
          class="sm:col-span-2 ml-px bg-gray-200 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-semibold text-gray-400 focus:outline-none"
        >
          إضافة
        </button>
        <button
          @click="closeSide()"
          class="sm:col-span-1 mr-px py-3 px-4 text-base font-semibold text-slate-500 focus:outline-none"
        >
          إلغاء
        </button>
      </div>
    </div>
  </aside>
</template>

<script>
import NewPersonModal from "../components/NewPersonModalMobile.vue";
import BaseIcon from "./BaseIcon.vue";
import moment from "moment-hijri";
moment.updateLocale("ar-sa", {
  postformat: (string) => string,
});

import {
  IonToolbar,
  IonContent,
  IonModal,
  IonPopover,
  IonInput,
  IonTextarea,
  IonHeader,
  IonTitle,
  IonSearchbar,
  popoverController,
  modalController,
  alertController,
} from "@ionic/vue";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
export default {
  components: {
    IonToolbar,
    IonContent,
    IonModal,
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    IonPopover,
    IonInput,
    IonTextarea,
    NewPersonModal,
    IonHeader,
    IonTitle,
    IonSearchbar,
    BaseIcon,
  },
  data() {
    return {
      date: "",
      personSearch: "",
      selectedPersons: [],
      guestTitle: "",
      guestNote: "",
      getStatusList: [
        {
          name: "مؤكد",
          value: "approved",
          bg_color_class: "bg-green-700",
          txt_color_class: "text-green-700",
        },
        {
          name: "غير مؤكد",
          value: "waiting",
          bg_color_class: "bg-yellow-700",
          txt_color_class: "text-yellow-700",
        },
      ],
      selectedStatus: {
        name: "غير مؤكد",
        value: "waiting",
        bg_color_class: "bg-yellow-700",
        txt_color_class: "text-yellow-700",
      },
      // Files
      guestFiles: [],
    };
  },
  methods: {
    // Files
    onFileChange(fieldName, file) {
      //const loading = await bla bla;
      this.alerts = [];
      const formData = new FormData();
      if (file.length > 0) {
        for (let index = 0; index < file.length; index++) {
          let upFile = file[index];
          let lastDot = file[index].name.lastIndexOf(".");
          let ext = file[index].name.substring(lastDot + 1);
          let fileName = upFile.name;

          let size = upFile.size / 1000;
          if (
            !ext == "jpg" ||
            !ext == "jpeg" ||
            !ext == "png" ||
            !ext == "gif" ||
            !ext == "pdf"
          ) {
            this.setAlert("خطأ", "صيغة الملف غير مدعومة", ["حسناً"]);
          } else {
            let fileURL = URL.createObjectURL(upFile);
            formData.append(fieldName, file[index]);
            this.$emit("input", { formData, fileURL });
            this.fileURL = { formData, fileURL };

            let fileNameExt = "";
            fileNameExt = `${moment().format("x")}${index}.${ext}`;

            if (this.guestFiles) {
              this.guestFiles.push({
                data: upFile,
                URL: fileURL,
                name: fileNameExt,
                description: "",
                ext,
                size,
              });
            } else {
              this.guestFiles = [
                {
                  data: upFile,
                  URL: fileURL,
                  name: fileNameExt,
                  description: "",
                  ext,
                  size,
                },
              ];
            }
          }
        }
        this.$refs.file.value = "";
      }
    },
    removeFile(index) {
      this.idxHolder = index;
      this.setAlert("تنبيه", "هل تريد حذف الملف", [
        {
          text: "إلغاء",
          role: "cancel",
          handler: () => {
            console.log("Alert canceled");
          },
        },
        {
          text: "تأكيد",
          role: "confirm",
          handler: () => {
            this.guestFiles.splice(this.idxHolder, 1);
          },
        },
      ]);
    },
    removeAllFiles() {
      this.setAlert("تنبيه", "هل تريد حذف جميع الملفات", [
        {
          text: "إلغاء",
          role: "cancel",
          handler: () => {
            console.log("Alert canceled");
          },
        },
        {
          text: "تأكيد",
          role: "confirm",
          handler: () => {
            this.guestFiles = [];
          },
        },
      ]);
    },
    async setAlert(header, message, button) {
      const alert = await alertController.create({
        header,
        message,
        buttons: button,
      });

      await alert.present();
    },

    selectStatus(status) {
      this.selectedStatus = status;
    },
    async openPicker() {
      this.$store.commit("toggleTimePickerGuest", true);
    },
    async openDatePopover() {
      /*const popover = await popoverController.create({
        component: Popover,
        cssClass: "date-popover",
        showBackdrop: true,
      });
      await popover.present();

      const { role } = await popover.onDidDismiss();*/
      //this.$store.commit("toggleDatePopover", true);
      this.$store.commit("toggleDatePopoverGuest", true);
    },
    confirmTime() {
      popoverController.dismiss();
    },
    async closeSide() {
      await this.clearData();
      await this.$store.commit("toggleNewGuestAside", false);
    },
    clearPersonSearch() {
      this.personSearch = "";
    },
    selectPerson(val) {
      if (this.selectedPersons.find((person) => person.name == val.name)) {
        return;
      }
      this.selectedPersons.push(val);
    },
    removePerson(val) {
      this.selectedPersons.splice(val, 1);
    },
    async addGuest() {
      const persons = [];
      if (this.selectedPersons.length != 0) {
        this.selectedPersons.map(function (value) {
          persons.push({
            name: value.name,
            position: value.position,
          });
        });
      }
      const guestData = {
        title: this.guestTitle,
        arDate: this.seletedDay.arDate,
        enDate: this.seletedDay.enDate,
        time: JSON.stringify(this.selectedFromTime),
        person: JSON.stringify(persons),
        note: this.guestNote,
        user: localStorage.getItem("awqatiUser"),
        attachments: this.guestFiles,
        group: parseInt(localStorage.getItem("awqatiUserGroup")),
        status: this.selectedStatus.value,
      };
      //await this.$store.commit('setDayEventsList', guestData)
      await this.$store.dispatch("saveNewGuest", guestData);
      this.clearData();
      this.$store.commit("toggleNewGuestAside", false);
    },
    clearData() {
      this.$store.commit("setNewGuestSelectedDay", "");
      this.$store.commit("resetNewGuestSelectedTime");
      this.$store.commit("restNewGuestSelectedDay");
      this.selectedPersons = [];
      this.guestTitle = "";
      this.guestNote = "";
    },
    async openNewPersonModal() {
      await popoverController.dismiss();
      this.$store.commit("toggleNewPersonModal", true);
    },
    async openNewPersonModalMobile() {
      //await modalController.dismiss();
      const modal = await modalController.create({
        component: NewPersonModal,
        cssClass: "my-custom-class",
        componentProps: {
          title: "New Title",
        },
      });
      return modal.present();
    },
    async openNewPlaceModal() {
      await popoverController.dismiss();
      this.$store.commit("toggleNewPlaceModal", true);
    },
    groupBy(xs) {
      let officesNames = [];
      xs.forEach((element) => {
        if (
          officesNames.find(
            (office) => office == JSON.parse(element.office).office
          )
        ) {
          return;
        }
        officesNames.push(JSON.parse(element.office).office);
      });
      return officesNames;
    },
  },
  computed: {
    // Files
    getGuestFiles() {
      return this.guestFiles;
    },
    requiredData() {
      if (this.guestTitle !== "" && this.selectedPersons.length != 0) {
        return true;
      }
      return false;
    },
    modalStatus() {
      return this.$store.getters.NewGuestAsideStatus;
    },
    seletedDay() {
      return this.$store.getters.newGuestSelectedDay;
    },
    selectedFromTime() {
      return this.$store.getters.newGuestSelectedTime;
    },
    getPersonSearch() {
      return this.personSearch;
    },
    getPersonList() {
      let filterList = "";
      let list = this.$store.getters.getPersonsList;

      if (this.personSearch == "") {
        filterList = list;
        return filterList;
      } else if (this.personSearch != "") {
        filterList = list.filter((person) => {
          return person.name.includes(this.personSearch);
        });
        return filterList;
      }
    },
    offices() {
      let list = this.$store.getters.getPersonsList;
      return this.groupBy(list);
    },
    getSelectedPersons() {
      return this.selectedPersons;
    },
    getSelectedStatus() {
      return this.selectedStatus;
    },
    getDatePopoverStatus() {
      return this.$store.getters.getDatePopoverStatus;
    },
  },
  async created() {
    await this.$store.dispatch("getOffices");
    await this.$store.dispatch("getPersons");
  },
};
</script>
