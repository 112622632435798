<template>
  <div
    class="fixed z-50 inset-0 overflow-hidden select-none"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-center md:items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        @click="closeMe()"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="relative inline-block align-bottom bg-white rounded-lg pt-5 pb-1 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-sm sm:w-full"
      >
        <div dir="rtl" class="z-50 w-full">
          <div
            class="flex px-8 pb-3 justify-between items-center w-full border-b border-200"
          >
            <BaseIcon
              name="ChevronRight"
              class="h-7 w-7 heroicon-stroke-w-1 text-gray-700 active:bg-slate-100 rounded-full active:scale-90"
              @click="nextMonth"
            />
            <div class="flex flex-col justify-center text-center">
              <div class="font-semibold text-gray-800">
                {{ month }} {{ year }}
              </div>
              <div class="text-sm font-light text-gray-800">
                {{ enMonth }} {{ enYear }}
              </div>
            </div>
            <BaseIcon
              name="ChevronLeft"
              class="h-7 w-7 heroicon-stroke-w-1 text-gray-700 active:bg-slate-100 rounded-full active:scale-90"
              @click="lastMonth"
            />
          </div>
          <ul class="flex flex-wrap shadow-sm bg-gray-100 bg-opacity-60 py-4">
            <li
              class="text-sm text-center size p-px text-gray-400 font-semibold mb-4"
              v-for="dow in days"
              :key="dow"
            >
              {{ dow }}
            </li>
            <li
              v-for="blank in firstDayOfMonth"
              class="size p-1 text-center cursor-pointer"
              :key="blank"
            ></li>
            <li
              v-for="date in daysInMonth"
              :key="date.day"
              @click="
                selectDay(date.arabicDate, date.englishDate, date.dayText)
              "
              class="flex flex-col size p-1 text-center cursor-pointer text-lg"
              :class="[
                date.elClass,
                getSelectedDay.enDate == date.englishDate
                  ? 'bg-indigo-100 text-indigo-600 rounded-full font-bold border border-indigo-500'
                  : isDateGrater(date.englishDate)
                  ? 'text-indigo-500'
                  : 'text-gray-300',
              ]"
            >
              <span>{{ date.fullDate }}</span>
              <span class="text-xs opacity-50">{{ date.enDate }}</span>
            </li>
          </ul>
          <div
            class="flex items-center gap-x-3 justify-between text-center px-8 border-t border-gray-200 py-2"
          >
            <div
              v-if="getSelectedDay != ''"
              @click="saveSelectedDay()"
              class="p-3 w-full rounded-lg bg-indigo-600 text-white font-semibold"
            >
              حفظ
            </div>
            <div
              @click="closeMe()"
              class="p-3 w-full rounded-lg text-slate-500 font-semibold cursor-pointer focus:bg-slate-50 active:bg-slate-50"
            >
              إلغاء
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-hijri";
moment.updateLocale("ar-sa", {
  postformat: (string) => string,
});
import BaseIcon from "../components/BaseIcon.vue";
import { popoverController } from "@ionic/vue";

export default {
  components: {
    BaseIcon,
  },
  data() {
    return {
      dateContext: moment(),
      today: moment(),
      days: [
        "الأحد",
        "الإثنين",
        "الثلاثاء",
        "الأربعاء",
        "الخميس",
        "الجمعة",
        "السبت",
      ],
      selectedDay: this.$store.getters.newEventSelectedDay,
    };
  },
  methods: {
    nextMonth: function () {
      var t = this;
      t.dateContext = moment(t.dateContext).add(1, "iMonth");
    },
    lastMonth: function () {
      var t = this;
      t.dateContext = moment(t.dateContext).add(-1, "iMonth");
    },
    selectDay(ar, en, text) {
      const val = {
        arDate: ar,
        enDate: en,
        dateText: text,
      };
      this.selectedDay = val;
      //popoverController.dismiss();
    },
    closeMe() {
      this.selectedDay = "";
      this.$store.commit("toggleDatePopover", false);
    },
    async saveSelectedDay() {
      const dayInfo = {
        arDate: this.getSelectedDay.arDate,
        enDate: this.getSelectedDay.enDate,
        dateText: this.getSelectedDay.dateText,
      };
      await this.$store.commit("setNewEventSelectedDay", dayInfo);
      this.$store.commit("toggleDatePopover", false);
    },
    isDateGrater(cueerntDate) {
      const val = moment(cueerntDate).isAfter(this.today.format("YYYY/M/D"));
      return val;
    },
  },
  computed: {
    getDatePopoverStatus() {
      return this.$store.getters.getDatePopoverStatus;
    },
    getCurrentDay() {
      return this.propCurrentDay;
    },
    getSelectedDay() {
      return this.selectedDay;
    },
    year() {
      return this.dateContext.format("iYYYY");
    },
    month() {
      return this.dateContext.format("iMMMM");
    },
    enYear() {
      return this.dateContext.format("YYYY");
    },
    enMonth() {
      return this.dateContext.format("MMMM");
    },
    daysInMonth() {
      var day = this.dateContext.endOf("iMonth").format("iD");
      var month = this.dateContext.format("iM");
      var year = this.dateContext.format("iYYYY");
      let days = [];
      for (let i = 1; i <= day; i++) {
        var elClass = "";
        if (
          i < this.initialDate &&
          month <= this.initialMonth &&
          year <= this.initialYear
        ) {
          elClass = "text-gray-300";
          console.log("bingo #1");
        } else if (
          i == this.initialDate &&
          month == this.initialMonth &&
          year == this.initialYear
        ) {
          elClass = "bg-gray-200 text-gray-700 font-bold rounded-full";
          console.log("bingo #2");
        } else if (year < this.initialYear) {
          elClass = "text-gray-300";
          console.log("bingo #3");
        } else if (month < this.initialMonth) {
          elClass = "text-gray-300";
          console.log("bingo #4");
        } else if (
          i > this.initialDate &&
          month == this.initialMonth &&
          year == this.initialYear
        ) {
          elClass = "";
        } else {
          elClass = "";
        }

        if (
          moment(this.getCurrentDay).format("iYYYY/iM/iD") ==
          year + "/" + month + "/" + i
        ) {
          elClass = "bg-rose-100 text-rose-700 font-bold rounded-full";
        }
        var hijFull = this.year + "/" + month + "/" + i;

        days.push({
          day: i,
          fullDate: month + "/" + i,
          arabicDate: year + "/" + month + "/" + i,
          englishDate: moment(hijFull, "iYYYY/iM/iD").format("YYYY/M/D"),
          enDate: moment(hijFull, "iYYYY/iM/iD").format("M/D"),
          dayText: moment(hijFull, "iYYYY/iM/iD").format("dddd"),
          month: month,
          year: year,
          elClass: elClass,
        });
      }
      return days; //.daysInMonth(); //.endOf('iMonth').format('iDD');;
    },
    currentDate() {
      return this.dateContext.get("iDate");
    },
    firstDayOfMonth() {
      var firstDay = moment(this.dateContext).startOf("iMonth").day(); //moment(t.dateContext).subtract((t.currentDate - 1), 'iDays');
      return firstDay; //.weekday();
    },
    //Previous Code Above
    initialDate() {
      return this.today.format("iD");
    },
    initialMonth() {
      return this.today.format("iM");
    },
    initialYear() {
      return this.today.format("iYYYY");
    },
  },
};
</script>

<style scoped>
.size {
  width: 14.286%;
}
</style>
