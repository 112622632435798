<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>إضافة جهة</ion-title>
      <button
        slot="start"
        @click="cancelNewOffice()"
        class="text-center font-bold text-rose-700 pl-2 mt-2"
      >
        إلغاء
      </button>
    </ion-toolbar>
  </ion-header>
  <ion-content dir="rtl" color="light">
    <ion-list :inset="true">
      <ion-item>
        <ion-label class="mt-3.5">اسم الجهة</ion-label>
        <input
          v-model="this.officeName"
          type="text"
          name="office-name"
          id="office-name"
          autocomplete="off"
          class="block w-[70%] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 bg-slate-50 ring-inset ring-gray-300 focus:outline-none px-1 focus:border focus:border-indigo-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
        />
      </ion-item>
      <ion-item v-if="!this.checkData">
        <span class="text-center text-rose-700">اسم الجهة مضاف مسبقاً</span>
      </ion-item>
    </ion-list>
    <div class="w-full mx-auto flex items-center justify-center px-4">
      <ion-button
        v-if="requiredData && this.checkData"
        @click="saveOffice()"
        class="w-1/2"
        :disabled="getIsSaving"
        color="primary"
      >
        <svg
          v-if="getIsSaving"
          class="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        حفظ
      </ion-button>
      <ion-button
        :disabled="true"
        v-if="!requiredData || !this.checkData"
        class="w-1/2"
        color="medium"
        >حفظ</ion-button
      >
    </div>
  </ion-content>
</template>

<script>
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonButton,
  modalController,
} from "@ionic/vue";

export default {
  data() {
    return {
      officeName: "",
      isSaving: false,
    };
  },
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonButton,
  },
  methods: {
    async saveOffice() {
      if (this.checkData) {
        this.isSaving = true;
        const payload = {
          officeName: this.officeName,
        };
        await this.$store.dispatch("saveNewOffice", payload);
        this.isSaving = false;
        await modalController.dismiss();
      } else {
        return;
      }
    },
    async cancelNewOffice() {
      await modalController.dismiss();
    },
  },
  computed: {
    getIsSaving() {
      return this.isSaving;
    },
    requiredData() {
      if (this.officeName !== "") {
        return true;
      }
      return false;
    },
    getOffice() {
      return this.$store.getters.getOfficeList;
    },
    checkData() {
      let office = this.$store.getters.getOfficesList;
      if (office.some((office) => office.office === this.officeName)) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.$store.dispatch("getOffices");
  },
};
</script>
