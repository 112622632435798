<template>
  <component
    :is="dynamicComponent"
    :class="this.className"
    :stroke-width="this.strokeWidth"
    role="svg"
    class="currentColor"
    stroke="currentColor"
  />
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  name: "BaseIcon",
  props: {
    name: {
      type: String,
      required: true,
    },
    className: {
      type: String,
    },
    strokeWidth: {
      type: String,
    },
    iconType: {
      type: String,
    },
  },

  computed: {
    dynamicComponent() {
      const name = this.name;
      return defineAsyncComponent(() => import(`./icons/${name}.vue`));
    },
  },
};
</script>
