<template>
  <div dir="rtl" class="flex">
    <div class="sm:block mt-2">
      <nav
        class="flex flex-col h-full items-end divide-y divide-gray-200 border-l border-gray-200"
        aria-label="Tabs"
      >
        <div
          v-for="(tab, tabIdx) in tabs"
          @click="selectTab(tab)"
          :key="tabIdx"
          :class="[
            tab.val == getSelectedTab.val
              ? 'text-gray-900'
              : 'text-gray-500 hover:text-gray-700',
            getSelectedTab.val == tab.val
              ? 'bg-indigo-600 text-white'
              : 'bg-white text-gray-600',
            'group relative w-32 h-[6rem] flex flex-col items-center gap-y-2 overflow-hidden py-4 px-4 text-center text-sm font-medium focus:z-10',
          ]"
          :aria-current="tab.val == getSelectedTab.val ? 'page' : undefined"
        >
          <BaseIcon
            :name="tab.icon"
            class="w-5 h-5"
            :className="
              tab.val == getSelectedTab.val ? 'text-white' : 'text-gray-400'
            "
            strokeWidth="0.1"
          />
          <span>{{ tab.name }}</span>
          <span
            aria-hidden="true"
            :class="[
              tab.val == getSelectedTab.val
                ? 'bg-indigo-500'
                : 'bg-transparent',
              'absolute inset-x-0 bottom-0 h-0.5',
            ]"
          />
        </div>
      </nav>
    </div>
    <div class="w-full flex items-start px-4 py-3">
      <div class="w-full" v-show="getSelectedTab.val == 1">
        <h2 class="text-sm font-medium text-gray-500">المعاملات</h2>
        <ul
          role="list"
          class="mt-3 w-full grid grid-cols-3 gap-4 justify-items-stretch font-bold leading-6 sm:gap-6"
        >
          <li
            v-for="(element, idx) in paperWorks"
            :key="idx"
            @click="selectElement(element)"
            class="text-sm col-span-1 h-24 flex items-center justify-center text-center rounded-md shadow-sm ring-1 ring-inset ring-gray-300 active:bg-indigo-50 px-2.5 py-2"
          >
            {{ element }}
          </li>
        </ul>
      </div>
      <div class="w-full" v-show="getSelectedTab.val == 2">
        <h2 class="text-sm font-medium text-gray-500">الضيافة</h2>
        <ul
          role="list"
          class="mt-3 w-full grid grid-cols-3 gap-4 justify-items-stretch font-bold leading-6 sm:gap-6"
        >
          <li
            v-for="(element, idx) in hosp"
            :key="idx"
            @click="selectElement(element)"
            class="text-sm col-span-1 h-24 flex items-center justify-center text-center rounded-md shadow-sm ring-1 ring-inset ring-gray-300 active:bg-indigo-50 px-2.5 py-2"
          >
            {{ element }}
          </li>
        </ul>
      </div>
      <div class="w-full" v-show="getSelectedTab.val == 3">
        <h2 class="text-sm font-medium text-gray-500">أدوات مكتبية</h2>
        <ul
          role="list"
          class="mt-3 w-full grid grid-cols-3 gap-4 justify-items-stretch font-bold leading-6 sm:gap-6"
        >
          <li
            v-for="(element, idx) in tools"
            :key="idx"
            @click="selectElement(element)"
            class="text-sm col-span-1 h-24 flex items-center justify-center text-center rounded-md shadow-sm ring-1 ring-inset ring-gray-300 active:bg-indigo-50 px-2.5 py-2"
          >
            {{ element }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-hijri";
moment.updateLocale("ar-sa", {
  postformat: (string) => string,
});
import BaseIcon from "../components/BaseIcon.vue";
import { modalController } from "@ionic/vue";
export default {
  data() {
    return {
      tabs: [
        { name: "المعاملات", val: 1, icon: "Folder-D" },
        //{ name: "الضيافة", val: 2, icon: "Coffee-D" },
        //{ name: "الأدوات المكتبية", val: 3, icon: "Briefcase-D" },
      ],
      paperWorks: [
        "عرض المعاملات",
        "الإنتهاء من المعاملات",
        "المعاملات العاجلة فقط",
      ],
      hosp: ["شاي", "قهوة", "ماء", "تمر"],
      tools: ["قلم توقيع", "أقلام", "قلم رصاص", "مناديل"],
      selectedTab: { name: "My Account", val: 1 },
    };
  },
  components: {
    BaseIcon,
  },
  methods: {
    selectTab(tab) {
      this.selectedTab = tab;
    },
    async selectElement(element) {
      this.$store.dispatch("establishCall", {
        from: parseInt(localStorage.getItem("awqatiUserId")),
        to: 6,
        group: parseInt(localStorage.getItem("awqatiUserGroup")),
        date: moment().format("YYYY-MM-DD"),
        time: JSON.stringify({
          hour: `${moment().format("hh")}`,
          minute: `${moment().format("mm")}`,
          apm: `${moment().format("A")}`,
        }),
        type: "calling",
        element: element,
        status: true,
      });
      modalController.dismiss();
    },
  },
  computed: {
    getSelectedTab() {
      return this.selectedTab;
    },
  },
};
</script>
