<template>
  <section
    dir="rtl"
    aria-labelledby="summary-heading"
    class="relative rounded-lg bg-gray-50 px-2 pb-2 pt-5 lg:col-span-5 lg:mt-0 lg:p-8 h-screen overflow-y-auto"
  >
    <div
      class="flex pb-4 items-center justify-between border-b border-gray-200"
    >
      <div class="sticky z-40 flex items-center gap-x-2">
        <BaseIcon
          name="CalendarEvent-D"
          className="w-7 h-7"
          strokeWidth="0.1"
        />
        <p class="pt-2 text-lg font-bold text-slate-900">
          {{ event.title }}
        </p>
      </div>
      <span
        class="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium"
        :class="`${JSON.parse(event.color).txt_class_color} ${
          JSON.parse(event.color).bg_class_color
        } bg-opacity-10`"
      >
        <span
          class="h-1.5 w-1.5 rounded-full"
          :class="`${JSON.parse(event.color).bg_class_color}`"
          aria-hidden="true"
        >
        </span>
        <span class="pt-1">{{ event.category }}</span>
      </span>
    </div>

    <dl
      class="flex flex-col items-start mt-6 pr-2 space-y-4 h-full overflow-y-auto"
    >
      <div class="flex items-center gap-x-12 justify-around">
        <div class="flex flex-col items-start gap-y-1">
          <dt class="-mr-2 flex items-center gap-x-px text-xs text-gray-600">
            <BaseIcon name="Pin" className="w-4 h-4" strokeWidth="0.2" />
            <span>المكان</span>
          </dt>
          <dd class="text-sm font-bold text-gray-900">
            {{ event.place }}
          </dd>
        </div>
        <div class="flex flex-col items-start gap-y-1">
          <dt class="-mr-2 flex items-center gap-x-px text-xs text-gray-600">
            <BaseIcon
              name="CalendarDate"
              className="w-4 h-4"
              strokeWidth="0.2"
            />
            <span>التاريخ</span>
          </dt>
          <dd
            class="flex items-center gap-x-3 text-sm font-medium text-gray-900"
          >
            <span
              class="inline-flex items-center px-2 py-1 text-sm font-bold text-gray-900"
              >{{ event.ar_date }} هـ</span
            >
            <span class="text-xs">الموافق</span>
            <span
              class="inline-flex items-center px-2 py-1 text-sm font-bold text-gray-900"
              >{{ event.en_date }} م</span
            >
          </dd>
        </div>
      </div>
      <div class="w-full border-t border-gray-200"></div>
      <div class="flex items-center gap-x-24 justify-around">
        <div class="flex flex-col items-start gap-y-1">
          <dt class="-mr-2 flex items-center gap-x-px text-xs text-gray-600">
            <BaseIcon name="Time" className="w-4 h-4" strokeWidth="0.2" />
            <span>الوقت</span>
          </dt>
          <dd
            class="flex items-center gap-x-3 text-sm font-medium text-gray-900"
          >
            <span
              class="inline-flex items-center rounded-full bg-gray-100/100 px-2 py-1 text-sm font-bold text-gray-900"
              >{{
                `${JSON.parse(event.time).hour}:${
                  JSON.parse(event.time).minute
                } ${JSON.parse(event.time).apm}`
              }}</span
            >
            <BaseIcon name="ChevronLeft" className="w-4 h-4" />
            <span
              class="inline-flex items-center rounded-full bg-gray-100/100 px-2 py-1 text-sm font-bold text-gray-900"
              >{{
                `${getToTime(JSON.parse(event.time), event.duration)}`
              }}</span
            >
          </dd>
        </div>
        <div class="flex flex-col items-start gap-y-1">
          <dt class="-mr-2 flex items-center gap-x-px text-xs text-gray-600">
            <span>المدة</span>
          </dt>
          <dd class="flex items-center gap-x-3 text-sm font-bold text-rose-900">
            <BaseIcon name="TimeDuration" className="w-4 h-4" />
            <span>{{ event.duration }} د</span>
          </dd>
        </div>
      </div>
      <div class="w-full border-t border-gray-200 mb-8"></div>
      <div class="flex flex-col items-start gap-y-1">
        <dt class="flex items-center gap-x-1 text-xs text-gray-600">
          <BaseIcon name="Users" className="w-4 h-4" strokeWidth="0.2" />
          <span>الشخص/الأشخاص</span>
        </dt>
        <dd class="flex items-center mr-8 gap-x-3">
          <ul role="list" class="divide-y divide-gray-100">
            <li
              v-for="(person, idx) in JSON.parse(event.person)"
              :key="person.email"
              class="flex items-start gap-x-2 py-2"
            >
              <BaseIcon
                name="UserSquare"
                className="mt-px h-5 w-5 flex-none rounded-full text-gray-600"
                strokeWidth="0.1"
                :alt="'Person- ' + idx"
              />
              <div class="min-w-0">
                <p class="text-xs font-semibold leading-6 text-gray-900">
                  {{ person.name }}
                </p>
                <p class="truncate text-xs leading-5 text-gray-500">
                  {{ person.position }}
                </p>
              </div>
            </li>
          </ul>
        </dd>
      </div>

      <div
        v-if="event.note && event.note !== ''"
        class="w-full border-t border-gray-200 mb-8"
      ></div>
      <div
        v-if="event.note && event.note !== ''"
        class="w-full flex flex-col items-start justify-between px-8"
      >
        <dt class="-mr-2 flex items-center gap-x-px text-xs text-gray-600">
          <BaseIcon name="NoteText" className="w-4 h-4" strokeWidth="0.2" />
          <span>الملاحظات</span>
        </dt>
        <dd
          class="w-full mt-2 flex items-center gap-x-3 text-sm font-medium text-rose-900"
        >
          <div
            class="w-full bg-yellow-50/50 rounded-md p-3 ring-1 ring-inset ring-yellow-700/50"
          >
            <p class="text-sm leading-6 text-yellow-800">{{ event.note }}</p>
          </div>
        </dd>
      </div>

      <div class="w-full border-t border-gray-200 mb-8"></div>
      <div
        v-if="isValid(event.attachment)"
        class="w-full flex flex-col items-start justify-between px-8"
      >
        <dt class="-mr-2 flex items-center gap-x-px text-xs text-gray-600">
          <BaseIcon name="Attachment" className="w-4 h-4" strokeWidth="0.2" />
          <span>الملفات</span>
        </dt>
        <dd
          class="w-full mt-2 flex items-center gap-y-3 text-sm font-medium text-rose-900"
        >
          <div
            class="w-full block"
            v-if="JSON.parse(event.attachment).length > 0"
          >
            <div class="w-full h-10 mt-1 relative pr-4">
              <div class="border-t border-gray-100 pb-6 sm:col-span-2 sm:px-0">
                <ul
                  role="list"
                  class="divide-y divide-gray-100 rounded-md border border-gray-200 bg-white"
                >
                  <template
                    v-for="(file, idx) in JSON.parse(event.attachment)"
                    :key="idx"
                  >
                    <li
                      @click="openPDF(file.name, file.description, 'events')"
                      v-if="file.ext == 'pdf'"
                      class="flex items-center justify-between py-2 px-2 text-sm leading-6"
                    >
                      <div class="flex items-center w-0 flex-1 items-center">
                        <BaseIcon
                          name="Attachment"
                          class="h-4 w-4 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                          strokeWidth="0.2"
                        />
                        <div class="mr-2 pt-1.5 flex min-w-0 flex-1 gap-2">
                          <span class="truncate font-bold">
                            {{
                              file.description == ""
                                ? `ملف رقم ${idx + 1}`
                                : file.description
                            }}</span
                          >
                          <span class="flex-shrink-0 text-gray-400">{{
                            file.ext
                          }}</span>
                        </div>
                      </div>
                      <div class="ml-4 flex-shrink-0">
                        <div
                          class="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          <BaseIcon
                            name="Eye"
                            class="w-5 h-5"
                            stokeWidth="0.1"
                          />
                        </div>
                      </div>
                    </li>
                    <li
                      @click="openImage(file.name, file.description, 'events')"
                      v-else
                      class="flex items-center justify-between py-2 px-2 text-sm leading-6"
                    >
                      <div class="flex items-center w-0 flex-1 items-center">
                        <BaseIcon
                          name="Attachment"
                          class="h-4 w-4 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                          strokeWidth="0.2"
                        />
                        <div class="mr-2 pt-1.5 flex min-w-0 flex-1 gap-2">
                          <span class="truncate font-bold">
                            {{
                              file.description == ""
                                ? `ملف رقم ${idx + 1}`
                                : file.description
                            }}</span
                          >
                          <span class="flex-shrink-0 text-gray-400">{{
                            file.ext
                          }}</span>
                        </div>
                      </div>
                      <div class="ml-4 flex-shrink-0">
                        <div
                          class="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          <BaseIcon
                            name="Eye"
                            class="w-5 h-5"
                            stokeWidth="0.1"
                          />
                        </div>
                      </div>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </div>
          <div v-else>لا يوجد</div>
        </dd>
      </div>
    </dl>
  </section>
</template>

<script>
import moment from "moment-hijri";
import BaseIcon from "./BaseIcon.vue";
import PDFViwer from "../components/PDFViewer.vue";
import ImgViewer from "../components/ImgViewer.vue";
import { modalController } from "@ionic/vue";
export default {
  components: {
    BaseIcon,
    PDFViwer,
    ImgViewer,
  },
  props: {
    event: Object,
  },
  methods: {
    // Check attachments value
    isValid(param) {
      const invalidValues = [
        null,
        undefined,
        "",
        "undefined",
        JSON.stringify(""), // This would be the stringified empty string
        JSON.stringify(undefined), // This would handle the string `'undefined'`
      ];

      return !invalidValues.includes(param);
    },
    async openPDF(path, name, folder) {
      const modal = await modalController.create({
        component: PDFViwer,
        componentProps: { filePath: path, fileName: name, folder },
      });
      await modal.present();
    },

    async openImage(path, name, folder) {
      const modal = await modalController.create({
        component: ImgViewer,
        componentProps: { filePath: path, fileName: name, folder },
      });
      await modal.present();
    },
    getToTime(time, duration) {
      const eventDateTime = moment(
        time.hour + ":" + time.minute + " " + time.apm,
        ["hh:mm A"]
      )
        .add(duration, "minutes")
        .format("hh:mm A");
      return eventDateTime;
    },
  },
};
</script>
