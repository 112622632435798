import { createStore } from "vuex";
import router from "../router";
import axios from "axios";
import io from "socket.io-client";
//var socket = io.connect("http://172.20.10.4:3000");
var socket = io.connect("http://46.101.194.80:3000");
//var socket = io.connect(process.env.BASE_URL);
import moment from "moment-hijri";
const platform = await Capacitor.getPlatform();
moment.updateLocale("ar-sa", {
  postformat: (string) => string,
});
const setTime = moment().set("hour", "08").set("minute", "00");

import {
  modalController,
  toastController,
  loadingController,
} from "@ionic/vue";

const store = createStore({
  state() {
    return {
      loggedIn: false,
      tabStatus: true,

      // Loading //
      skeletonLoading: false,
      chatDataLoading: false,
      sendingChatLoading: false,

      // Alert //
      notificationTitle: "",
      notificationText: "",
      notificationType: "",
      notificationStatus: false,

      // Calling //
      isCalling: null,
      hasCalling: null,

      // Popovers //
      datePopver: false,
      datePopverGuest: false,
      timePicker: false,
      timePickerGuest: false,

      newEventAside: false,
      viewEventAside: false,
      editEventAside: false,
      newGuestAside: false,
      currentViewEventAside: "",
      currentView: "week",

      userCheckStatus: false,

      newUserModalStatus: false,
      privileges: [],
      groups: [],
      newGroupModalStatus: false,
      userStatus: [],
      newUserStatusModalStatus: false,
      allUsers: [],

      // Device Key
      deviceKey: "",

      usersByGroup: [],

      newEventSelectedDay: "",
      newEventSelectedTime: {
        hour: moment(setTime).format("HH"),
        minute: moment(setTime).format("mm"),
        apm: moment(setTime).format("A"),
      },
      newGuestSelectedDay: {
        arDate: moment(new Date()).format("iYYYY/iM/iD"),
        enDate: moment(new Date()).format("YYYY/M/D"),
        dateText: moment(new Date()).format("dddd"),
      },
      newGuestSelectedTime: {
        hour: moment(new Date()).format("hh"),
        minute: moment(new Date()).format("mm"),
        apm: moment(new Date()).format("A"),
      },
      dayEventsList: [],

      eventsOfDay: [],
      eventsOfCommingUp: [],
      eventsOfWeek: [],

      guestsOfDay: [],
      guestsOfWeek: [],
      guestMentioned: null,

      selectedUserForChat: "",
      chatOfUserById: [],
      totalOfUnseenChatOfUserById: [],
      totalOfUnseenChatsFromUsers: [],
      totalOfAllUnseenChat: 0,
      chatWithEmployeeModalStatus: false,
      chatWithEmployee: "",
      eventForChat: "",
      mentionedUser: "",

      newPersonModalStatus: false,
      personsList: [],
      editPersonModalStatus: false,
      editPerson: "",
      newOfficeModalStatus: false,
      officesList: [],
      newPlaceModalStatus: false,
      placesList: [],
      newCategoryModalStatus: false,
      categoriesList: [],

      currentSettingsPage: "persons",

      // Files
      uploadProgress: {}
    };
  },
  mutations: {
    // Alert //
    setNotification(state, payload) {
      state.notificationTitle = payload.title;
      state.notificationText = payload.text;
      state.notificationType = payload.type;
      state.notificationStatus = payload.status;
    },

    setLoggedIn(state, payload) {
      state.loggedIn = payload;
    },

    // Loading //
    setSkeletonLoading(state, payload) {
      state.skeletonLoading = payload;
    },
    setChatDataLoading(state, payload) {
      state.chatDataLoading = payload;
    },
    setSendingChatLoading(state, payload) {
      state.sendingChatLoading = payload;
    },

    // Calling //
    setIsCalling(state, payload) {
      state.isCalling = payload;
    },
    setHasCalling(state, payload) {
      state.hasCalling = payload;
    },

    toggleEditEventModal(state, payload) {
      state.editEventAside = payload;
      state.tabStatus = !payload;
    },
    toggleNewUserModal(state, payload) {
      state.newUserModalStatus = payload;
    },
    setPrivileges(state, payload) {
      state.privileges = payload;
    },
    setGroups(state, payload) {
      state.groups = payload;
    },

    // Popovers //
    toggleDatePopover(state, payload) {
      state.datePopver = payload;
    },
    toggleDatePopoverGuest(state, payload) {
      state.datePopverGuest = payload;
    },
    toggleTimePicker(state, payload) {
      state.timePicker = payload;
    },
    toggleTimePickerGuest(state, payload) {
      state.timePickerGuest = payload;
    },

    // Store Device Key temparary //
    setDeviceKey(state, payload) {
      state.deviceKey = payload;
    },

    toggleNewGroupModal(state, payload) {
      state.newGroupModalStatus = payload;
    },
    setUserStatus(state, payload) {
      state.userStatus = payload;
    },
    toggleNewUserStatusModal(state, payload) {
      state.newUserStatusModalStatus = payload;
    },
    setAllUsers(state, payload) {
      state.setAllUsers = payload;
    },
    toggleNewEventAside(state, payload) {
      state.newEventAside = payload;
      state.tabStatus = !payload;
    },
    toggleViewEventAside(state, payload) {
      state.newEventAside = false;
      state.viewEventAside = payload.status;
      state.currentViewEventAside = payload.event;
      state.tabStatus = !payload.status;
    },
    toggleNewGuestAside(state, payload) {
      state.newGuestAside = payload;
      state.tabStatus = !payload;
    },
    setCurrentView(state, payload) {
      state.currentView = payload;
    },
    setUsersByGroup(state, payload) {
      state.usersByGroup = payload;
    },
    setNewEventSelectedDay(state, payload) {
      state.newEventSelectedDay = payload;
    },
    resetNewEventSelectedDay(state) {
      state.newEventSelectedDay = "";
    },
    setNewEventSelectedTime(state, payload) {
      state.newEventSelectedTime = payload;
    },
    resetNewEventSelectedTime(state) {
      state.newEventSelectedTime = {
        hour: moment(setTime).format("HH"),
        minute: moment(setTime).format("mm"),
        apm: moment(setTime).format("A"),
      };
    },
    setNewGuestSelectedTime(state, payload) {
      state.newGuestSelectedTime = payload;
    },
    resetNewGuestSelectedTime(state) {
      state.newGuestSelectedTime = {
        hour: moment(new Date()).format("hh"),
        minute: moment(new Date()).format("mm"),
        apm: moment(new Date()).format("A"),
      };
    },
    setNewGuestSelectedDay(state, payload) {
      state.newGuestSelectedDay = payload;
    },
    restNewGuestSelectedDay(state) {
      state.newGuestSelectedDay = {
        arDate: moment(new Date()).format("iYYYY/iM/iD"),
        enDate: moment(new Date()).format("YYYY/M/D"),
        dateText: moment(new Date()).format("dddd"),
      };
    },
    setDayEventsList(state, payload) {
      state.dayEventsList = [payload];
    },
    setEventsOfDay(state, payload) {
      state.eventsOfDay = payload;
    },
    setEventsOfCommingUp(state, payload) {
      state.eventsOfCommingUp = payload;
    },
    setEventsOfWeek(state, payload) {
      state.eventsOfWeek = payload;
    },
    setGuestsOfDay(state, payload) {
      state.guestsOfDay = payload;
    },
    setGuestsOfWeek(state, payload) {
      state.guestsOfWeek = payload;
    },
    setSelectedUserForChat(state, payload) {
      state.selectedUserForChat = payload;
    },
    resetSelectedUserForChat(state) {
      state.selectedUserForChat = "";
    },
    setChatOfUserById(state, payload) {
      state.chatOfUserById = payload;
    },
    resetChatOfUserById(state) {
      state.chatOfUserById = [];
    },
    setTotalOfUnseenChatOfUserById(state, payload) {
      if (state.totalOfUnseenChatOfUserById.length != 0) {
        const found = state.totalOfUnseenChatOfUserById.some(
          (el) => el.from == payload.from
        );
        if (!found) {
          state.totalOfUnseenChatOfUserById.push(0, payload);
        } else {
          let objIndex = state.totalOfUnseenChatOfUserById.findIndex(
            (obj) => obj.from == payload.from
          );
          state.totalOfUnseenChatOfUserById[objIndex].data = payload.data;
        }
      } else {
        state.totalOfUnseenChatOfUserById.push(0, payload);
      }
    },
    setTotalOfUnseenChatsFromUsers(state, payload) {
      state.totalOfUnseenChatsFromUsers = payload;
    },
    setTotalOfAllUnseenChat(state, payload) {
      state.totalOfAllUnseenChat = payload;
    },
    clearTotalOfAllUnseenChat(state) {
      state.totalOfAllUnseenChat = 0;
    },
    setChatWithEmployeeModalStatus(state, payload) {
      state.chatWithEmployeeModalStatus = payload;
    },
    toggleNewPersonModal(state, payload) {
      state.newPersonModalStatus = payload;
    },
    setPersonsList(state, payload) {
      state.personsList = payload;
    },
    setEditPerson(state, payload) {
      state.editPerson = payload;
    },
    toggleEditPersonModal(state, payload) {
      state.editPersonModalStatus = payload;
    },
    toggleNewOfficeModal(state, payload) {
      state.newOfficeModalStatus = payload;
    },
    setOfficesList(state, payload) {
      state.officesList = payload;
    },
    toggleNewPlaceModal(state, payload) {
      state.newPlaceModalStatus = payload;
    },
    setPlacesList(state, payload) {
      state.placesList = payload;
    },
    toggleNewCategoryModal(state, payload) {
      state.newCategoryModalStatus = payload;
    },
    setCategoriesList(state, payload) {
      state.categoriesList = payload;
    },
    setEventForChat(state, payload) {
      state.eventForChat = payload;
    },
    setMentionedUser(state, payload) {
      state.mentionedUser = payload;
    },
    setCurrentSettingsPage(state, payload) {
      state.currentSettingsPage = payload;
    },
    setUserCheckStatus(state, payload) {
      state.userCheckStatus = payload;
    },
    setGuestMentioned(state, payload) {
      state.guestMentioned = payload;
    },

    // Files
        SET_UPLOAD_PROGRESS(state, { fileId, progress }) {
      state.uploadProgress = { ...state.uploadProgress, [fileId]: progress };
    },
    RESET_UPLOAD_PROGRESS(state) {
      state.uploadProgress = {};
    }
  },
  actions: {
    // Alert //
    showNotification({ commit }, payload) {
      commit("setNotification", {
        title: payload.title,
        text: payload.text,
        type: payload.type,
        status: true,
      });

      setTimeout(() => {
        commit("setNotification", {
          title: "",
          text: "",
          type: "",
          status: false,
        });
      }, 4200);
    },

    // Get Privileges //
    async getPrivileges({ commit }) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .get("api/getPrivileges", {})
        .then((res) => {
          commit("setPrivileges", res.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },

    // Delete Privilege //
    async removePrivilege(NULL, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/removePrivilege", {
          ID: payload.id,
        })
        .then((res) => {
          if (res.data) {
            this.dispatch("getPrivileges");
            toastController
              .create({
                duration: 2000,
                message: `تم حذف ${payload.name}`,
                color: "success",
                position: "top",
                showCloseButton: true,
                closeButtonText: "Ok",
                cssClass: "wk-text-center",
              })
              .then((toast) => toast.present());
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log("there are an error !");
          }
        });
    },

    // Get Groups //
    async getGroups({ commit }) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .get("api/getGroups", {})
        .then((res) => {
          commit("setGroups", res.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },

    // New Group //
    async saveNewGroup(NULL, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/newGroup", {
          NAME: payload.groupName,
        })
        .then((res) => {
          if (res.data) {
            this.dispatch("getGroups");
            toastController
              .create({
                duration: 2000,
                message: `تم إضافة ${payload.groupName}`,
                color: "success",
                position: "top",
                showCloseButton: true,
                closeButtonText: "Ok",
                cssClass: "wk-text-center",
              })
              .then((toast) => toast.present());
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log("there are an error !");
          }
        });
    },

    // Delete Group //
    async removeGroup(NULL, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/removeGroup", {
          ID: payload.id,
        })
        .then((res) => {
          if (res.data) {
            this.dispatch("getGroups");
            toastController
              .create({
                duration: 2000,
                message: `تم حذف ${payload.name}`,
                color: "success",
                position: "top",
                showCloseButton: true,
                closeButtonText: "Ok",
                cssClass: "wk-text-center",
              })
              .then((toast) => toast.present());
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log("there are an error !");
          }
        });
    },

    // Get User Status //
    async getUserStatus({ commit }) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .get("api/getUserStatus", {})
        .then((res) => {
          commit("setUserStatus", res.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },

    // Delete User Status //
    async removeUserStatus(NULL, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/removeUserStatus", {
          ID: payload.id,
        })
        .then((res) => {
          if (res.data) {
            this.dispatch("getUserStatus");
            toastController
              .create({
                duration: 2000,
                message: `تم حذف ${payload.name}`,
                color: "success",
                position: "top",
                showCloseButton: true,
                closeButtonText: "Ok",
                cssClass: "wk-text-center",
              })
              .then((toast) => toast.present());
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log("there are an error !");
          }
        });
    },

    /* START USERS */
    // Do Login //
    async doLogin({ commit, state }, payload) {
      const loading = await loadingController.create({
        spinner: "crescent",
        message: null,
      });
      await loading.present();
      await axios
        .post("api/doLogin", {
          USERDATA: payload,
          DEVICEKEY: state.deviceKey,
        })
        .then((res) => {
          if (res.data.userStatus == 4) {
            // 4 OFF in new DB
            return toastController
              .create({
                duration: 2000,
                message: `عفواً حساب المستخدم غير فعال`,
                color: "danger",
                position: "top",
                showCloseButton: true,
                closeButtonText: "Ok",
                cssClass: "wk-text-center",
              })
              .then((toast) => toast.present());
          }
          localStorage.clear();
          localStorage.setItem("awqatiAccess_token", res.data.accessToken);
          localStorage.setItem("awqatiUser", res.data.name);
          localStorage.setItem("awqatiUserUserName", res.data.userName);
          localStorage.setItem("awqatiUserGroup", res.data.group);
          localStorage.setItem("awqatiUserPrivilege", res.data.privilege);
          localStorage.setItem("awqatiUserStatus", res.data.userStatus);
          localStorage.setItem("awqatiUserId", res.data.id);
          localStorage.setItem("awqatiUserPosition", res.data.position);
          //commit('retrieveUser', res.data)
          //axios.defaults.headers.common['x-access-token'] = localStorage.getItem('access_token');
          commit("setLoggedIn", true);
          loading.dismiss();
          /*if(localStorage.getItem('farmAppUserRoles') == 'Entry') {
              router.push('/entry')
            } else if (localStorage.getItem('farmAppUserRoles') == 'Leader') {
              router.push('/leader')
            } else if(localStorage.getItem('farmAppUserRoles') == 'Admin') {
              router.push('/main')
            }*/
          toastController
            .create({
              duration: 2000,
              message: `مرحباً بك ${res.data.name}`,
              color: "success",
              position: "top",
              showCloseButton: true,
              closeButtonText: "Ok",
              cssClass: "wk-text-center",
            })
            .then((toast) => toast.present());
          setTimeout(() => {
            if (
              window.innerWidth < 640 ||
              platform === "ios" ||
              platform === "android"
            ) {
              // Navigate to the desired page for mobile devices
              router.push("/mobile");
            } else {
              //this.$router.push("/mobile");
              router.push("/main");
              // Render the default app experience for non-mobile devices
            }
          }, 500);
        })
        .catch((error) => {
          loading.dismiss();
          if (error.response) {
            toastController
              .create({
                duration: 2000,
                message: `اسم المستخدم أو كلمة المرور غير صحيحة`,
                color: "danger",
                position: "top",
                showCloseButton: true,
                closeButtonText: "Ok",
                cssClass: "wk-text-center",
              })
              .then((toast) => toast.present());
          } else if (error.request) {
            console.log(error.request.data);
            //this.dispatch("setShowNotification", [{type: 'error', status: true, title: 'يوجد خطأ',  description: 'توجد مشكلة في الاتصال'}])
            //commit('saveNewLetterLoading', false)
          } else {
            console.log("there are an error !" + error);
            //this.dispatch("setShowNotification", [{type: 'error', status: true, title: 'يوجد خطأ', description: 'توجد مشكلة' + error}])
            //commit('saveNewLetterLoading', false)
          }
        });
    },

    // Check User Status //
    async checkUserStatus({ commit }, payload) {
      let name = localStorage.getItem("awqatiUser");
      let username = localStorage.getItem("awqatiUserUserName");
      let group_num = localStorage.getItem("awqatiUserGroup");
      let privilege = localStorage.getItem("awqatiUserPrivilege");
      let userStatus = localStorage.getItem("awqatiUserStatus");
      //let position = localStorage.getItem("awqatiUserPosition");
      await axios
        .post("api/checkUserStatus", {
          ID: payload,
        })
        .then((res) => {
          if (
            //res.data.accessToken == localStorage.getItem('awqatiAccess_token') &&
            res.data.name == name &&
            res.data.username == username &&
            res.data.group_num == group_num &&
            res.data.user_privilege == privilege &&
            res.data.user_status == userStatus //&&
            //res.data.user_position == position
          ) {
            return commit("setUserCheckStatus", true);
          } else {
            localStorage.removeItem("awqatiAccess_token");
            localStorage.removeItem("awqatiUser");
            localStorage.removeItem("awqatiUserUserName");
            localStorage.removeItem("awqatiUserGroup");
            localStorage.removeItem("awqatiUserPrivilege");
            localStorage.removeItem("awqatiUserStatus");
            localStorage.removeItem("awqatiUserId");
            localStorage.removeItem("awqatiUserPosition");
            return commit("setUserCheckStatus", false);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },

    // New User //
    async saveNewUser(NULL, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/newUser", {
          NAME: payload.name,
          USERNAME: payload.userName,
          PASSWORD: payload.password,
          GROUP: payload.group,
          PRIVILEGE: payload.privilege,
          USERSTATUS: payload.userStatus,
          OFFICE: payload.office,
          POSITION: payload.position,
          PHONE: payload.phone,
          EMAIL: payload.email,
        })
        .then((res) => {
          if (res.data) {
            this.dispatch("getAllUsers");
            toastController
              .create({
                duration: 2000,
                message: `تم إضافة ${payload.name}`,
                color: "success",
                position: "top",
                showCloseButton: true,
                closeButtonText: "Ok",
                cssClass: "wk-text-center",
              })
              .then((toast) => toast.present());
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log("there are an error !");
          }
        });
    },

    // Delete User //
    async removeUser(NULL, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/removeUser", {
          ID: payload.id,
        })
        .then((res) => {
          if (res.data) {
            this.dispatch("getAllUsers");
            toastController
              .create({
                duration: 2000,
                message: `تم حذف ${payload.name}`,
                color: "success",
                position: "top",
                showCloseButton: true,
                closeButtonText: "Ok",
                cssClass: "wk-text-center",
              })
              .then((toast) => toast.present());
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log("there are an error !");
          }
        });
    },

    // Get All Users //
    async getAllUsers({ commit }) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .get("api/getAllUsers", {})
        .then((res) => {
          commit("setAllUsers", res.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },

    // Get Users By Group //
    async getUsersByGroup({ commit }, payload) {
      commit("setSkeletonLoading", true);
      //axios.defaults.headers.common["x-access-token"] = localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/getUsersByGroup", {
          GROUP: payload.group,
          ME: payload.me,
        })
        .then((res) => {
          commit("setSkeletonLoading", false);
          commit("setUsersByGroup", res.data);
        })
        .catch((error) => {
          commit("setSkeletonLoading", true);
          if (error.response.status == 401) {
            router.push("/login");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },

    // Do Logout //
    async doLogout({ commit }) {
      /*
      localStorage.removeItem("awqatiAccess_token");
      localStorage.removeItem("awqatiUser");
      localStorage.removeItem("awqatiUserUserName");
      localStorage.removeItem("awqatiUserGroup");
      localStorage.removeItem("awqatiUserPrivilege");
      localStorage.removeItem("awqatiUserStatus");
      localStorage.removeItem("awqatiUserId");
      localStorage.removeItem("awqatiUserPosition");
      */
      localStorage.clear();
      this.commit("setLoggedIn", false);
      router.push("/intro");
    },
    /* END USERS */

    /* START EVENTS */
    // New Event //
    async saveNewEvent({ dispatch }, payload) {
      let loading;
      try {
        // Set up axios headers
        axios.defaults.headers.common["x-access-token"] = localStorage.getItem("awqatiAccess_token");

        // Create loading indicator
        loading = await loadingController.create({
          spinner: "crescent",
          message: "جار حفظ الموعد ...",
        });

        // Present loading indicator, with error handling
        try {
          await loading.present();
        } catch (overlayError) {
          console.warn('Failed to present loading overlay:', overlayError);
          // Proceed without the loading indicator
        }

        // Make the API call to save the new event
        const res = await axios.post("api/newEvent", { EVENT: payload });

        if (res.data) {
          // Update events and emit socket events
          await dispatch("getEventsOfDay", {
            date: payload.arDate,
            group: payload.group,
          });

          let day = payload.arDate;
          socket.emit("syncEventsOfDay", {
            command: "getEventsOfDay",
            dayy: day,
            group: payload.group,
          });
          socket.emit("syncEventsOfWeek", {
            command: "getEventsOfWeek",
            dayy: day,
            group: payload.group,
          });

          // Dismiss the modal
          try {
            await modalController.dismiss();
          } catch (modalError) {
            console.warn('Failed to dismiss modal:', modalError);
          }

          // Show success toast
          const toast = await toastController.create({
            duration: 2000,
            message: `تم إضافة الموعد بنجاح`,
            color: "success",
            position: "top",
            showCloseButton: true,
            closeButtonText: "Ok",
            cssClass: "wk-text-center",
          });
          await toast.present();

          // Upload attachments if any
          if (payload.attachments && payload.attachments.length > 0) {
            try {
              await dispatch("uploadFiles", {
                Files: payload.attachments,
                Path: `events`
              });
            } catch (uploadError) {
              console.error("Error uploading files:", uploadError);
              // Show error toast for file upload
              const uploadErrorToast = await toastController.create({
                duration: 3000,
                message: "Error uploading files. Event saved, but attachments failed to upload.",
                color: "warning",
                position: "top",
                showCloseButton: true,
                closeButtonText: "Ok",
              });
              await uploadErrorToast.present();
            }
          }
        }
      } catch (error) {
        console.error("Error in saveNewEvent:", error);

        let errorMessage = "An error occurred while saving the event.";
        if (error.response) {
          if (error.response.status === 401) {
            router.push("/login");
            errorMessage = "Session expired. Please log in again.";
          } else {
            errorMessage = error.response.data.message || `Server error: ${error.response.status}`;
          }
        } else if (error.request) {
          errorMessage = "No response received from the server. Please check your connection and try again.";
        }

        // Show error toast
        try {
          const errorToast = await toastController.create({
            duration: 3000,
            message: errorMessage,
            color: "danger",
            position: "top",
            showCloseButton: true,
            closeButtonText: "Ok",
            cssClass: "wk-text-center",
          });
          await errorToast.present();
        } catch (toastError) {
          console.error('Failed to show error toast:', toastError);
        }
      } finally {
        // Ensure loading is dismissed whether there was an error or not
        if (loading) {
          try {
            await loading.dismiss();
          } catch (dismissError) {
            console.warn('Failed to dismiss loading overlay:', dismissError);
          }
        }
      }
    },

    // Update Event //
async updateEvent({ dispatch, commit }, payload) {
  let loading;
  try {
    // Set up axios headers
    axios.defaults.headers.common["x-access-token"] = localStorage.getItem("awqatiAccess_token");

    // Create loading indicator
    loading = await loadingController.create({
      spinner: "crescent",
      message: "Updating event...",
    });

    // Present loading indicator, with error handling
    try {
      await loading.present();
    } catch (overlayError) {
      console.warn('Failed to present loading overlay:', overlayError);
      // Proceed without the loading indicator
    }

    // Make the API call to update the event
    const res = await axios.post("api/updateEvent", {
      EVENT: payload,
      updateType: payload.type,
    });

    if (res.data) {
      // Update events and emit socket events
      await dispatch("getEventsOfDay", {
        date: payload.arDate,
        group: payload.group,
      });

      commit("toggleViewEventAside", false);

      let day = payload.ar_date;
      socket.emit("syncEventsOfWeek", {
        command: "getEventsOfWeek",
        dayy: day,
        group: payload.group_num,
      });
      socket.emit("syncEventsOfDay", {
        command: "getEventsOfDay",
        dayy: day,
        group: payload.group_num,
      });

      // Dismiss the modal
      try {
        await modalController.dismiss();
      } catch (modalError) {
        console.warn('Failed to dismiss modal:', modalError);
      }

      // Show success toast
      const toast = await toastController.create({
        duration: 2000,
        message: `تم تعديل الموعد بنجاح`,
        color: "success",
        position: "top",
        showCloseButton: true,
        closeButtonText: "Ok",
        cssClass: "wk-text-center",
      });
      await toast.present();

      // Upload new attachments if any
      if (payload.newAttachments && payload.newAttachments.length > 0) {
        try {
          await dispatch("uploadFiles", {
            Files: payload.newAttachments,
            Path: `events`
          });
        } catch (uploadError) {
          console.error("Error uploading files:", uploadError);
          // Show error toast for file upload
          const uploadErrorToast = await toastController.create({
            duration: 3000,
            message: "Error uploading new files. Event updated, but new attachments failed to upload.",
            color: "warning",
            position: "top",
            showCloseButton: true,
            closeButtonText: "Ok",
          });
          await uploadErrorToast.present();
        }
      }
    }
  } catch (error) {
    console.error("Error in updateEvent:", error);

    let errorMessage = "An error occurred while updating the event.";
    if (error.response) {
      if (error.response.status === 401) {
        router.push("/");
        errorMessage = "Session expired. Please log in again.";
      } else {
        errorMessage = error.response.data.message || `Server error: ${error.response.status}`;
      }
    } else if (error.request) {
      errorMessage = "No response received from the server. Please check your connection and try again.";
    }

    // Show error toast
    try {
      const errorToast = await toastController.create({
        duration: 3000,
        message: errorMessage,
        color: "danger",
        position: "top",
        showCloseButton: true,
        closeButtonText: "Ok",
        cssClass: "wk-text-center",
      });
      await errorToast.present();
    } catch (toastError) {
      console.error('Failed to show error toast:', toastError);
    }
  } finally {
    // Ensure loading is dismissed whether there was an error or not
    if (loading) {
      try {
        await loading.dismiss();
      } catch (dismissError) {
        console.warn('Failed to dismiss loading overlay:', dismissError);
      }
    }
  }
},

    // Active/Deactive Event //
    async activeEvent(NULL, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      const loading = await loadingController.create({
        spinner: "crescent",
        message: null,
      });
      await loading.present();
      //axios.defaults.headers.common['x-access-token'] = localStorage.getItem('access_token');
      await axios
        .post("api/activeEvent", {
          EVENT: payload,
          updateType: payload.type,
        })
        .then(async (res) => {
          loading.dismiss();
          if (res.data) {
            this.dispatch("getEventsOfDay", {
              date: payload.arDate,
              group: payload.group,
            });
            this.commit("toggleViewEventAside", false);
            let day = "";

            day = payload.ar_date;
            socket.emit("syncEventsOfWeek", {
              command: "getEventsOfWeek",
              dayy: day,
              group: payload.group_num,
            });
            socket.emit("syncEventsOfDay", {
              command: "getEventsOfDay",
              dayy: day,
              group: payload.group_num,
            });

            modalController.dismiss();
            toastController
              .create({
                duration: 2000,
                message: `تم تعديل الموعد بنجاح`,
                color: "success",
                position: "top",
                showCloseButton: true,
                closeButtonText: "Ok",
                cssClass: "wk-text-center",
              })
              .then((toast) => toast.present());
          }
        })
        .catch((error) => {
          console.log(error, 'ERROR');
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log("there are an error !");
          }
        });
    },

    // Get Events Of Day //
    async getEventsOfDay({ commit }, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      const loading = await loadingController.create({
        spinner: "crescent",
        message: null,
      });
      await loading.present();
      //axios.defaults.headers.common['x-access-token'] = localStorage.getItem('access_token');
      await axios
        .post("api/getEventsOfDay", {
          DAY: payload.date,
          GROUP: payload.group,
        })
        .then((res) => {
          loading.dismiss();
          commit("setEventsOfDay", res.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },

    // Get Events For Comming up //
    async getEventsForCommingUp({ commit }, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      const thisDay = moment(new Date()).format("iYYYY/iM/iD");
      //axios.defaults.headers.common['x-access-token'] = localStorage.getItem('access_token');
      await axios
        .post("api/getEventsOfDay", {
          DAY: thisDay,
          GROUP: payload.group,
        })
        .then((res) => {
          commit("setEventsOfCommingUp", res.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },

    // Get Events Of Week //
    async getEventsOfWeek({ commit }, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      const loading = await loadingController.create({
        spinner: "crescent",
        message: null,
      });
      await loading.present();
      //axios.defaults.headers.common['x-access-token'] = localStorage.getItem('access_token');
      await axios
        .post("api/getEventsOfWeek", {
          FROMDAY: payload.fromDay,
          TODAY: payload.toDay,
          GROUP: payload.group,
        })
        .then((res) => {
          loading.dismiss();
          commit("setEventsOfWeek", res.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },

    // Get Mentioned Event //
    async getMentionedEvent({ commit }, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/getMentionedEvent", {
          ID: payload,
        })
        .then((res) => {
          commit("toggleViewEventAside", {
            status: true,
            event: res.data[0],
          });
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },
    /* END EVENTS */

    /* START GUEST */
    // New Guest //
async saveNewGuest({ dispatch }, payload) {
  let loading;
  try {
    // Set up axios headers
    axios.defaults.headers.common["x-access-token"] = localStorage.getItem("awqatiAccess_token");

    // Create loading indicator
    loading = await loadingController.create({
      spinner: "crescent",
      message: "جار حفظ الضيف ...",
    });

    // Present loading indicator, with error handling
    try {
      await loading.present();
    } catch (overlayError) {
      console.warn('Failed to present loading overlay:', overlayError);
      // Proceed without the loading indicator
    }

    // Make the API call to save the new guest
    const res = await axios.post("api/newGuest", {
      GUEST: payload,
    });

    if (res.data) {
      // Dismiss the modal
      try {
        await modalController.dismiss();
      } catch (modalError) {
        console.warn('Failed to dismiss modal:', modalError);
      }

      // Show success toast
      const toast = await toastController.create({
        duration: 2000,
        message: `تم إضافة الضيف بنجاح`,
        color: "success",
        position: "top",
        showCloseButton: true,
        closeButtonText: "Ok",
        cssClass: "wk-text-center",
      });
      await toast.present();

      // Update guests and emit socket events
      await dispatch("getGuestsOfDay", {
        date: payload.arDate,
        group: payload.group,
      });

      let day = payload.arDate;
      socket.emit("syncGuestsOfDay", {
        command: "getGuestsOfDay",
        dayy: day,
        group: payload.group,
      });
      socket.emit("syncGuestsOfWeek", {
        command: "getGuestsOfWeek",
        dayy: day,
        group: payload.group,
      });

      // Upload attachments if any
      if (payload.attachments && payload.attachments.length > 0) {
        try {
          await dispatch("uploadFiles", {
            Files: payload.attachments,
            Path: `guests`
          });
        } catch (uploadError) {
          console.error("Error uploading files:", uploadError);
          // Show error toast for file upload
          const uploadErrorToast = await toastController.create({
            duration: 3000,
            message: "Error uploading files. Guest saved, but attachments failed to upload.",
            color: "warning",
            position: "top",
            showCloseButton: true,
            closeButtonText: "Ok",
          });
          await uploadErrorToast.present();
        }
      }
    }
  } catch (error) {
    console.error("Error in saveNewGuest:", error);

    let errorMessage = "An error occurred while saving the new guest.";
    if (error.response) {
      if (error.response.status === 401) {
        router.push("/login");
        errorMessage = "Session expired. Please log in again.";
      } else {
        errorMessage = error.response.data.message || `Server error: ${error.response.status}`;
      }
    } else if (error.request) {
      errorMessage = "No response received from the server. Please check your connection and try again.";
    }

    // Show error toast
    try {
      const errorToast = await toastController.create({
        duration: 3000,
        message: errorMessage,
        color: "danger",
        position: "top",
        showCloseButton: true,
        closeButtonText: "Ok",
        cssClass: "wk-text-center",
      });
      await errorToast.present();
    } catch (toastError) {
      console.error('Failed to show error toast:', toastError);
    }
  } finally {
    // Ensure loading is dismissed whether there was an error or not
    if (loading) {
      try {
        await loading.dismiss();
      } catch (dismissError) {
        console.warn('Failed to dismiss loading overlay:', dismissError);
      }
    }
  }
},

    // Get Guests Of Day //
    async getGuestsOfDay({ commit }, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/getGuestsOfDay", {
          DAY: payload.date,
          GROUP: payload.group,
        })
        .then((res) => {
          commit("setGuestsOfDay", res.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },

    // Get Guests Of Week //
    async getGuestsOfWeek({ commit }, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/getGuestsOfWeek", {
          FROMDAY: payload.fromDay,
          TODAY: payload.toDay,
          GROUP: payload.group,
        })
        .then((res) => {
          commit("setGuestsOfWeek", res.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },

    // Update Guest //
async updateGuest({ dispatch }, payload) {
  let loading;
  try {
    // Set up axios headers
    axios.defaults.headers.common["x-access-token"] = localStorage.getItem("awqatiAccess_token");

    // Create loading indicator
    loading = await loadingController.create({
      spinner: "crescent",
      message: "جار تحديث الضيف ...", // "Updating guest..."
    });

    // Present loading indicator, with error handling
    try {
      await loading.present();
    } catch (overlayError) {
      console.warn('Failed to present loading overlay:', overlayError);
      // Proceed without the loading indicator
    }

    // Make the API call to update the guest
    const res = await axios.post("api/updateGuest", {
      GUEST: payload,
    });

    if (res.data) {
      // Dismiss the modal
      try {
        await modalController.dismiss();
      } catch (modalError) {
        console.warn('Failed to dismiss modal:', modalError);
      }

      // Show success toast
      const toast = await toastController.create({
        duration: 2000,
        message: `تم تحديث الضيف بنجاح`, // "Guest updated successfully"
        color: "success",
        position: "top",
        showCloseButton: true,
        closeButtonText: "Ok",
        cssClass: "wk-text-center",
      });
      await toast.present();

      // Update guests and emit socket events
      await dispatch("getGuestsOfDay", {
        date: payload.ar_date,
        group: payload.group_num,
      });

      let day = payload.ar_date;
      let guestName = JSON.parse(payload.person)[0].name;

      socket.emit("syncGuestsOfDay", {
        command: "getGuestsOfDay",
        dayy: day,
        group: payload.group_num,
        updateType: payload.status,
        guestName: guestName,
      });
      socket.emit("syncGuestsOfWeek", {
        command: "getGuestsOfWeek",
        dayy: day,
        group: payload.group_num,
        updateType: payload.status,
        guestName: guestName,
      });

      // Note: File upload functionality removed as per your previous request
    }
  } catch (error) {
    console.error("Error in updateGuest:", error);

    let errorMessage = "An error occurred while updating the guest.";
    if (error.response) {
      if (error.response.status === 401) {
        router.push("/login"); // Changed from "/" to "/login" to match saveNewGuest
        errorMessage = "Session expired. Please log in again.";
      } else {
        errorMessage = error.response.data.message || `Server error: ${error.response.status}`;
      }
    } else if (error.request) {
      errorMessage = "No response received from the server. Please check your connection and try again.";
    }

    // Show error toast
    try {
      const errorToast = await toastController.create({
        duration: 3000,
        message: errorMessage,
        color: "danger",
        position: "top",
        showCloseButton: true,
        closeButtonText: "Ok",
        cssClass: "wk-text-center",
      });
      await errorToast.present();
    } catch (toastError) {
      console.error('Failed to show error toast:', toastError);
    }
  } finally {
    // Ensure loading is dismissed whether there was an error or not
    if (loading) {
      try {
        await loading.dismiss();
      } catch (dismissError) {
        console.warn('Failed to dismiss loading overlay:', dismissError);
      }
    }
  }
},

    // Delete Guest //
    async removeGuest(NULL, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/removeGuest", {
          ID: payload.id,
        })
        .then((res) => {
          if (res.data) {
            this.dispatch("getGuestsOfDay", {
              date: payload.ar_date,
              group: payload.group_num,
            });
            let day = "";
            day = payload.ar_date;
            socket.emit("syncUpdateGuestOfWeek", {
              command: "getGuestsOfWeek",
              updateType: "reject",
              guestName: JSON.parse(payload.person)[0].name,
              dayy: day,
              group: payload.group_num,
            });
            socket.emit("syncUpdateGuestOfDay", {
              command: "getGuestsOfDay",
              updateType: "reject",
              guestName: JSON.parse(payload.person)[0].name,
              dayy: day,
              group: payload.group_num,
            });

            modalController.dismiss();
            toastController
              .create({
                duration: 2000,
                message: `تم الرفض بنجاح`,
                color: "success",
                position: "top",
                showCloseButton: true,
                closeButtonText: "Ok",
                cssClass: "wk-text-center",
              })
              .then((toast) => toast.present());
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log("there are an error !");
          }
        });
    },

    // Get Mentioned Guest //
    async getMentionedGuest({ commit }, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/getMentionedGuest", {
          ID: payload,
        })
        .then((res) => {
          commit("setGuestMentioned", res.data[0]);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },
    /* END GUEST */

    /* Files */
    // Upload Files //
    async uploadFiles(context, { Files, Path }) {
      const formData = new FormData();
      formData.append("PATH", Path);
      Files.forEach((file) => {
        formData.append("files", file.data, file.name);
      });

      let uploadLoading;
      try {
        uploadLoading = await loadingController.create({
          message: 'جار تحميل الملفات ...',
          spinner: 'crescent'
        });
        await uploadLoading.present();

        const res = await axios.post("api/uploadFiles", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            uploadLoading.message = `${percentCompleted}% جار تحميل الملفات ...`;
          }
        });

        const successToast = await toastController.create({
          message: 'تم تحميل الملفات بنجاح',
          duration: 2000,
          color: 'success'
        });
        await successToast.present();

        return res.data;
      } catch (error) {
        console.error("Error uploading files:", error);
        const errorToast = await toastController.create({
          message: 'مشكلة في تحميل المفات.',
          duration: 3000,
          color: 'danger'
        });
        await errorToast.present();
        throw error;
      } finally {
        if (uploadLoading) {
          await uploadLoading.dismiss();
        }
      }
    },

    /* START CALLING */
    async establishCall({ commit }, payload) {
      await axios
        .post("api/establishCall", {
          FROM: payload.from,
          TO: payload.to,
          GROUP: payload.group,
          TIME: payload.time,
          DATE: payload.date,
          TYPE: payload.type,
          ELEMENT: payload.element,
          STATUS: payload.status,
        })
        .then((res) => {
          const ppayload = {
            from: payload.from,
            to: payload.to,
            group: payload.group,
          };
          socket.emit("checkUserCalling", ppayload);
          toastController
            .create({
              duration: 2000,
              message: `تم ارسال الطلب بنجاح`,
              color: "success",
              position: "top",
              showCloseButton: true,
              closeButtonText: "Ok",
              cssClass: "wk-text-center",
            })
            .then((toast) => toast.present());
          commit("setSendingChatLoading", false);
          //this.dispatch("getUserCallingByID", ppayload);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },

    // Get Calls //
    async getUserCallingByID({ commit }, payload) {
      /*const loading = await loadingController.create({
        spinner: "crescent",
        message: null,
      });*/
      // await loading.present();
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/getUserCallingByID", {
          TO: payload.to,
          GROUP: payload.group,
        })
        .then((res) => {
          //loading.dismiss();
          if (res.data.length > 0) {
            commit("setHasCalling", res.data);
          } else {
            commit("setHasCalling", null);
          }
        })
        .catch((error) => {
          commit("setChatDataLoading", false);
          if (error.response.status == 401) {
            router.push("/login");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },

    // Get Calls //
    async checkIfCalling({ commit }, payload) {
      /*const loading = await loadingController.create({
        spinner: "crescent",
        message: null,
      });*/
      // await loading.present();
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/checkIfCalling", {
          FROM: payload.from,
          GROUP: payload.group,
        })
        .then((res) => {
          //loading.dismiss();
          if (res.data.length > 0) {
            commit("setIsCalling", res.data);
          } else {
            commit("setIsCalling", null);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/login");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },

    // Response Call //
    async updateCall(NULL, payload) {
      console.log(payload);
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/updateCall", { GROUP: payload })
        .then((res) => {
          const ppayload = {
            from: 7,
            to: localStorage.getItem("awqatiUserId"),
            group: payload,
          };
          socket.emit("checkUserCalling", ppayload);
          toastController
            .create({
              duration: 2000,
              message: `تم ارسال الاستجابة بنجاح`,
              color: "success",
              position: "top",
              showCloseButton: true,
              closeButtonText: "Ok",
              cssClass: "wk-text-center",
            })
            .then((toast) => toast.present());
          //loading.dismiss();
          commit("setIsCalling", null);
          commit("setHasCalling", null);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/login");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },

    /* END CALLING */

    /* START CHAT */
    // New Chat //
    async sendNewChat({ commit }, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      commit("setSendingChatLoading", true);
      await axios
        .post("api/sendNewChat", {
          TEXT: payload.text,
          FROM: payload.from,
          TO: payload.to,
          GROUP_NUM: payload.group,
          LINK: payload.link,
          SEEN: payload.seen,
          LINK_TEXT: payload.link_text,
          LINK_TYPE: payload.link_type,
          REPLY_TO: payload.reply_to,
          REPLY_TEXT: payload.reply_text,
          ATTACHMENTS: payload.attachments,
        })
        .then((res) => {
          commit("setSendingChatLoading", false);
          const ppayload = {
            from: payload.from,
            to: payload.to,
            group: payload.group,
          };
          socket.emit("checkUserForChat", ppayload);
          this.dispatch("getUserChatByID", ppayload);
        })
        .catch((error) => {
          commit("setSendingChatLoading", false);
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },

    // Get Chat OF User By ID //
    async getUserChatByID({ commit }, payload) {
      /*const loading = await loadingController.create({
        spinner: "crescent",
        message: null,
      });*/
      commit("setChatDataLoading", true);
      // await loading.present();
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/getUserChatByID", {
          FROM: payload.from,
          TO: payload.to,
          GROUP: payload.group,
        })
        .then((res) => {
          //loading.dismiss();
          commit("setChatDataLoading", false);
          commit("setChatOfUserById", res.data);
        })
        .catch((error) => {
          commit("setChatDataLoading", false);
          if (error.response.status == 401) {
            router.push("/login");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },

    // Get Total Of Unseen Chat OF User By ID //
    async getTotalOfUnseenChatOfUserByID({ commit }, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/getTotalOfUnseenChatOfUserByID", {
          FROM: payload.from,
          TO: payload.to,
          GROUP: payload.group,
        })
        .then((res) => {
          commit("setTotalOfUnseenChatOfUserById", {
            data: res.data,
            from: payload.from,
          });
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          } else if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },

    // Get Total Of Unseen Chats From Other Users In Same Group //
    async getTotalOfUnseenChatsFromUsers({ commit }, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/getTotalOfUnseenChatsFromUsers", {
          TO: payload.to,
          GROUP: payload.group,
        })
        .then((res) => {
          commit("setTotalOfUnseenChatsFromUsers", {
            data: res.data,
          });
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          } else if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },

    // Get Total Of All Unseen Chat //
    async getTotalOfAllUnseenChat({ commit }, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/getTotalOfAllUnseenChat", {
          TO: payload.to,
          GROUP: payload.group,
        })
        .then((res) => {
          commit("setTotalOfAllUnseenChat", res.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },

    // Update Unseen Chat //
    async updateUnseenChat(NULL, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/updateUnseenChat", {
          FROM: payload.from,
          TO: payload.to,
          GROUP: payload.group,
        })
        .then((res) => {
          this.dispatch("getTotalOfUnseenChatOfUserByID", payload);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },
    /* END CHAT */

    /* START SETTINGS */
    // New Person //
    async saveNewPerson(NULL, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/newPerson", {
          NAME: payload.personName,
          OFFICE: payload.office,
          POSITION: payload.position,
          IDTY: payload.idty,
          PHONE: payload.phone,
          EMAIL: payload.email,
        })
        .then((res) => {
          if (res.data) {
            this.dispatch("getPersons");
            toastController
              .create({
                duration: 2000,
                message: `تم إضافة ${payload.personName}`,
                color: "success",
                position: "top",
                showCloseButton: true,
                closeButtonText: "Ok",
                cssClass: "wk-text-center",
              })
              .then((toast) => toast.present());
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log("there are an error !");
          }
        });
    },

    // Update Person //
    async editPerson(NULL, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/editPerson", {
          ID: payload.id,
          NAME: payload.personName,
          OFFICE: payload.office,
          POSITION: payload.position,
          IDTY: payload.idty,
          PHONE: payload.phone,
          EMAIL: payload.email,
        })
        .then((res) => {
          if (res.data) {
            this.dispatch("getPersons");
            toastController
              .create({
                duration: 2000,
                message: `تم تعديل ${payload.personName}`,
                color: "success",
                position: "top",
                showCloseButton: true,
                closeButtonText: "Ok",
                cssClass: "wk-text-center",
              })
              .then((toast) => toast.present());
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log("there are an error !");
          }
        });
    },

    // Delete Person //
    async removePerson(NULL, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/removePerson", {
          ID: payload.id,
        })
        .then((res) => {
          if (res.data) {
            this.dispatch("getPersons");
            toastController
              .create({
                duration: 2000,
                message: `تم حذف ${payload.name}`,
                color: "success",
                position: "top",
                showCloseButton: true,
                closeButtonText: "Ok",
                cssClass: "wk-text-center",
              })
              .then((toast) => toast.present());
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log("there are an error !");
          }
        });
    },

    // Get Persons List //
    async getPersons({ commit }) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .get("api/getPersons", {})
        .then((res) => {
          commit("setPersonsList", res.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },

    // New Office //
    async saveNewOffice(NULL, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/newOffice", {
          NAME: payload.officeName,
        })
        .then((res) => {
          if (res.data) {
            this.dispatch("getOffices");
            toastController
              .create({
                duration: 2000,
                message: `تم إضافة ${payload.officeName}`,
                color: "success",
                position: "top",
                showCloseButton: true,
                closeButtonText: "Ok",
                cssClass: "wk-text-center",
              })
              .then((toast) => toast.present());
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log("there are an error !");
          }
        });
    },

    // Delete Office //
    async removeOffice(NULL, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/removeOffice", {
          ID: payload.id,
        })
        .then((res) => {
          if (res.data) {
            this.dispatch("getOffices");
            toastController
              .create({
                duration: 2000,
                message: `تم حذف ${payload.office}`,
                color: "success",
                position: "top",
                showCloseButton: true,
                closeButtonText: "Ok",
                cssClass: "wk-text-center",
              })
              .then((toast) => toast.present());
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log("there are an error !");
          }
        });
    },

    // Get Offices List //
    async getOffices({ commit }) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .get("api/getOffices", {})
        .then((res) => {
          commit("setOfficesList", res.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },

    // New Place //
    async saveNewPlace(NULL, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/newPlace", {
          NAME: payload.placeName,
        })
        .then((res) => {
          if (res.data) {
            this.dispatch("getPlaces");
            toastController
              .create({
                duration: 2000,
                message: `تم إضافة ${payload.placeName}`,
                color: "success",
                position: "top",
                showCloseButton: true,
                closeButtonText: "Ok",
                cssClass: "wk-text-center",
              })
              .then((toast) => toast.present());
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log("there are an error !");
          }
        });
    },

    // Delete Place //
    async removePlace(NULL, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/removePlace", {
          ID: payload.id,
        })
        .then((res) => {
          if (res.data) {
            this.dispatch("getPlaces");
            toastController
              .create({
                duration: 2000,
                message: `تم حذف ${payload.place}`,
                color: "success",
                position: "top",
                showCloseButton: true,
                closeButtonText: "Ok",
                cssClass: "wk-text-center",
              })
              .then((toast) => toast.present());
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log("there are an error !");
          }
        });
    },

    // Get Places List //
    async getPlaces({ commit }) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .get("api/getPlaces", {})
        .then((res) => {
          commit("setPlacesList", res.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },

    // New Category //
    async saveNewCategory(NULL, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/newCategory", {
          NAME: payload.categoryName,
          COLOR: payload.color,
        })
        .then((res) => {
          if (res.data) {
            this.dispatch("getCategories");
            toastController
              .create({
                duration: 2000,
                message: `تم إضافة ${payload.categoryName}`,
                color: "success",
                position: "top",
                showCloseButton: true,
                closeButtonText: "Ok",
                cssClass: "wk-text-center",
              })
              .then((toast) => toast.present());
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log("there are an error !");
          }
        });
    },

    // Delete Category //
    async removeCategory(NULL, payload) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .post("api/removeCategory", {
          ID: payload.id,
        })
        .then((res) => {
          if (res.data) {
            this.dispatch("getCategories");
            toastController
              .create({
                duration: 2000,
                message: `تم حذف ${payload.category}`,
                color: "success",
                position: "top",
                showCloseButton: true,
                closeButtonText: "Ok",
                cssClass: "wk-text-center",
              })
              .then((toast) => toast.present());
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error.request.data);
          } else {
            console.log("there are an error !");
          }
        });
    },

    // Get Categories List //
    async getCategories({ commit }) {
      axios.defaults.headers.common["x-access-token"] =
        localStorage.getItem("awqatiAccess_token");
      await axios
        .get("api/getCategories", {})
        .then((res) => {
          commit("setCategoriesList", res.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            router.push("/");
          } else if (error.request) {
            console.log(error);
          } else {
            console.log(error + " there are an error !");
          }
        });
    },
  },
  getters: {
    // Alert //
    getNotificationTitle(state) {
      return state.notificationTitle;
    },
    getNotificationText(state) {
      return state.notificationText;
    },
    getNotificationType(state) {
      return state.notificationType;
    },
    getNotificationStatus(state) {
      return state.notificationStatus;
    },

    getLoggedInStatus(state) {
      return state.loggedIn;
    },
    getTabStatus(state) {
      return state.tabStatus;
    },

    // Loading //
    getSkeletonLoading(state) {
      return state.skeletonLoading;
    },
    getChatDataLoading(state) {
      return state.chatDataLoading;
    },

    // Calling //
    getIsCalling(state) {
      return state.isCalling;
    },
    getHasCalling(state) {
      return state.hasCalling;
    },

    getSendingChatLoading(state) {
      return state.sendingChatLoading;
    },
    /*getSubSkeletonLoading(state) {
      return state.subSkeletonLoading;
    },*/

    // Popovers //
    getDatePopoverStatus(state) {
      return state.datePopver;
    },
    getDatePopoverGuestStatus(state) {
      return state.datePopverGuest;
    },
    getTimePickerStatus(state) {
      return state.timePicker;
    },
    getTimePickerGuestStatus(state) {
      return state.timePickerGuest;
    },

    getNewUserModalStatus(state) {
      return state.newUserModalStatus;
    },
    getEditEventAsideStatus(state) {
      return state.editEventAside;
    },
    getPrivileges(state) {
      return state.privileges;
    },
    getGroups(state) {
      return state.groups;
    },
    getNewGroupModalStatus(state) {
      return state.newGroupModalStatus;
    },
    getUserStatus(state) {
      return state.userStatus;
    },
    getNewUserStatusModalStatus(state) {
      return state.newUserStatusModalStatus;
    },
    getAllUsers(state) {
      return state.setAllUsers;
    },
    newEventAsideStatus(state) {
      return state.newEventAside;
    },
    viewEventAsideStatus(state) {
      return state.viewEventAside;
    },
    currentViewEventAside(state) {
      return state.currentViewEventAside;
    },
    NewGuestAsideStatus(state) {
      return state.newGuestAside;
    },
    getCurrentView(state) {
      return state.currentView;
    },
    getUsersByGroup(state) {
      return state.usersByGroup;
    },
    newEventSelectedDay(state) {
      return state.newEventSelectedDay;
    },
    newEventSelectedTime(state) {
      return state.newEventSelectedTime;
    },
    newGuestSelectedTime(state) {
      return state.newGuestSelectedTime;
    },
    newGuestSelectedDay(state) {
      return state.newGuestSelectedDay;
    },
    getDayEventsList(state) {
      return state.eventsOfDay;
    },
    getEventsOfCommingUp(state) {
      return state.eventsOfCommingUp;
    },
    getEventsOfWeek(state) {
      return state.eventsOfWeek;
    },
    getGuestsOfDay(state) {
      return state.guestsOfDay;
    },
    getGuestsOfWeek(state) {
      return state.guestsOfWeek;
    },
    getSelectedUserForChat(state) {
      return state.selectedUserForChat;
    },
    getChatOfUserById(state) {
      return state.chatOfUserById;
    },
    getTotalOfUnseenChatOfUserById(state) {
      return state.totalOfUnseenChatOfUserById;
    },
    getTotalOfUnseenChatsFromUsers(state) {
      return state.totalOfUnseenChatsFromUsers;
    },
    getTotalOfAllUnseenChat(state) {
      return state.totalOfAllUnseenChat;
    },
    getChatWithEmployeeModalStatus(state) {
      return state.chatWithEmployeeModalStatus;
    },
    getNewPersonModalStatus(state) {
      return state.newPersonModalStatus;
    },
    getEditPerson(state) {
      return state.editPerson;
    },
    getEditPersonModalStatus(state) {
      return state.editPersonModalStatus;
    },
    getEventForChat(state) {
      return state.eventForChat;
    },
    getPersonsList(state) {
      return state.personsList;
    },
    getNewOfficeModalStatus(state) {
      return state.newOfficeModalStatus;
    },
    getOfficesList(state) {
      return state.officesList;
    },
    getNewPlaceModalStatus(state) {
      return state.newPlaceModalStatus;
    },
    getPlacesList(state) {
      return state.placesList;
    },
    getNewCategoryModalStatus(state) {
      return state.newCategoryModalStatus;
    },
    getCategoriesList(state) {
      return state.categoriesList;
    },
    getMetntionedUser(state) {
      return state.mentionedUser;
    },
    getCurrentSettingsPage(state) {
      return state.currentSettingsPage;
    },
    getUserCheckStatus(state) {
      return state.userCheckStatus;
    },
    getMentionedGuest(state) {
      return state.guestMentioned;
    },
  },
});

export default store;
