<template>
  <!-- Large devices -->
  <div class="hidden flex-1 sm:flex justify-between">
    <div
      class="border-t border-gray-200 flex items-center space-x-4 divide-x divide-gray-200 text-sm font-medium sm:mt-0 sm:ml-4 sm:border-none sm:pt-0"
    >
      <div dir="rtl" class="w-20 flex justify-center text-indigo-600">
        <span>{{ this.currentTime }}</span>
      </div>
      <div
        dir="rtl"
        class="flex-1 pl-4 flex flex-col text-indigo-600 text-center"
      >
        <span>{{ this.dayDateAr }} هـ</span>
        <span>{{ this.dayDateEn }} م</span>
      </div>
    </div>
    <div class="flex-1 flex place-content-center">
      <nav v-if="getUserInfo.privilege == 6" class="flex px-2 space-x-8">
        <div
          v-for="item in navigationSuper"
          :key="item.name"
          @click="openPage(item.value)"
          :class="[
            getCurrentPage == item.alias
              ? 'text-indigo-600 border-b-2 border-indigo-400'
              : 'text-gray-600 hover:text-gray-900',
            'group relative flex flex-col items-center px-2 py-2 mt-2 text-md font-bold active:bg-indigo-50',
          ]"
          style="font-weight: bold"
        >
          <span
            v-if="chatNotification != 0 && item.alias == 'Chat'"
            class="absolute left-11 flex items-center place-content-center justify-center rounded-full text-sm px-1 pt-1 h-5 w-5 bg-green-500 text-center text-emerald-50 font-bold"
            >{{ chatNotification }}</span
          >
          <BaseIcon
            :name="item.icon"
            strokeWidth="0.1"
            :class="[
              getCurrentPage == item.alias
                ? 'text-indigo-500'
                : 'text-gray-500roup-hover:text-gray-800',
              'mb-1 flex-shrink-0 h-6 w-6',
            ]"
            aria-hidden="true"
          />
          {{ item.name }}
        </div>
      </nav>
      <nav v-else-if="getUserInfo.privilege == 4" class="flex px-2 space-x-8">
        <div
          v-for="item in navigationEntry"
          :key="item.name"
          @click="openPage(item.value)"
          :class="[
            getCurrentPage == item.alias
              ? 'text-indigo-600 border-b-2 border-indigo-400'
              : 'text-gray-600 hover:text-gray-900',
            'group relative flex flex-col items-center px-2 py-2 mt-2 text-md font-bold active:bg-indigo-50',
          ]"
          style="font-weight: bold"
        >
          <span
            v-if="chatNotification != 0 && item.alias == 'Chat'"
            class="absolute left-11 flex items-center place-content-center justify-center rounded-full text-sm px-1 pt-1 h-5 w-5 bg-green-500 text-center text-emerald-50 font-bold"
            >{{ chatNotification }}</span
          >
          <BaseIcon
            :name="item.icon"
            strokeWidth="0.1"
            :class="[
              getCurrentPage == item.alias
                ? 'text-indigo-500'
                : 'text-gray-500roup-hover:text-gray-800',
              'mb-1 flex-shrink-0 h-6 w-6',
            ]"
            aria-hidden="true"
          />
          {{ item.name }}
        </div>
      </nav>
      <nav v-else-if="getUserInfo.privilege == 1" class="flex px-2 space-x-8">
        <div
          v-for="item in navigationAdmin"
          :key="item.name"
          @click="openPage(item.value)"
          :class="[
            getCurrentPage == item.alias
              ? 'text-indigo-600 border-b-2 border-indigo-400'
              : 'text-gray-600 hover:text-gray-900',
            'group relative flex flex-col items-center px-2 py-2 mt-2 text-md font-bold active:bg-indigo-50',
          ]"
          style="font-weight: bold"
        >
          <span
            v-if="chatNotification != 0 && item.alias == 'Chat'"
            class="absolute left-11 flex items-center place-content-center justify-center rounded-full text-sm px-1 pt-1 h-5 w-5 bg-green-500 text-center text-emerald-50 font-bold"
            >{{ chatNotification }}</span
          >
          <BaseIcon
            :name="item.icon"
            strokeWidth="0.1"
            :class="[
              getCurrentPage == item.alias
                ? 'text-indigo-500'
                : 'text-gray-500roup-hover:text-gray-800',
              'mb-1 flex-shrink-0 h-6 w-6',
            ]"
            aria-hidden="true"
          />
          {{ item.name }}
        </div>
      </nav>
      <nav v-else class="flex px-2 space-x-8">
        <div
          v-for="item in navigation"
          :key="item.name"
          @click="openPage(item.value)"
          :class="[
            getCurrentPage == item.alias
              ? 'text-indigo-600 border-b-2 border-indigo-400'
              : 'text-gray-600 hover:text-gray-900',
            'group relative flex flex-col items-center px-2 py-2 mt-2 text-md font-bold active:bg-indigo-50',
          ]"
          style="font-weight: bold"
        >
          <span
            v-if="chatNotification != 0 && item.alias == 'Chat'"
            class="absolute left-11 flex items-center place-content-center justify-center rounded-full text-sm px-1 pt-1 h-5 w-5 bg-green-500 text-center text-emerald-50 font-bold"
            >{{ chatNotification }}</span
          >
          <BaseIcon
            :name="item.icon"
            strokeWidth="0.1"
            :class="[
              getCurrentPage == item.alias
                ? 'text-indigo-500'
                : 'text-gray-500roup-hover:text-gray-800',
              'mb-1 flex-shrink-0 h-6 w-6',
            ]"
            aria-hidden="true"
          />
          {{ item.name }}
        </div>
      </nav>
    </div>
    <div class="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
      <div class="flex items-center">
        <div class="self-center">
          <h2 class="text-2xl font-bold text-indigo-700">أوقات</h2>
        </div>

        <div class="self-center">
          <svg
            viewBox="0 0 24 24"
            class="w-12 h-12"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
          >
            <rect
              width="16"
              height="16"
              x="4"
              y="4"
              stroke="#334155"
              stroke-width="1.5"
              rx="8"
            />
            <path
              stroke="#334155"
              stroke-linecap="round"
              stroke-width="1.5"
              d="M12.021 12l2.325 2.325"
            />
            <path
              stroke="#4f46e5"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
              d="M12.021 12V6.84"
              class="clock-once"
            />
          </svg>
        </div>
      </div>
    </div>
    <div
      dir="rtl"
      class="sticky flex flex-row justify-between items-center w-80 px-4 top-0 bg-gray-50"
    >
      <div class="group flex items-center">
        <span
          class="h-8 w-8 rounded-full border border-gray-200 overflow-hidden bg-gray-100"
        >
          <svg
            class="h-full w-full text-gray-300"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
            ></path>
          </svg>
        </span>
        <div class="ml-3 rtl:ml-0 rtl:mr-3">
          <p
            class="text-sm font-semibold text-gray-700 group-hover:text-gray-900"
          >
            {{ getUserInfo.name }}
          </p>
          <p
            class="text-sm font-medium text-gray-500 group-hover:text-gray-700"
          >
            {{ getUserInfo.position }}
          </p>
        </div>
      </div>
      <div
        @click="logout()"
        class="bg-gray-100 font-bold text-gray-600 border border-gray-300 text-xs rounded-md pb-1 pt-1.5 px-2 cursor-pointer hover:bg-gray-300 active:bg-rose-500 active:text-white active:border-0"
      >
        <div>تسجيل الخروج</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-hijri";
moment.updateLocale("ar-sa", {
  postformat: (string) => string,
});

import BaseIcon from "../components/BaseIcon.vue";
import { alertController } from "@ionic/vue";

export default {
  data() {
    return {
      navigation: [
        {
          name: "الإعدادات",
          value: "/settings",
          icon: "Settings",
          alias: "Settings",
        },
        { name: "المحادثات", value: "/chat", icon: "Chat", alias: "Chat" },
        { name: "الجدول", value: "/main", icon: "Calendar", alias: "Main" },
      ],
      navigationEntry: [
        { name: "الجدول", value: "/main", icon: "Calendar", alias: "Main" },
      ],
      navigationAdmin: [
        {
          name: "الإعدادات",
          value: "/settings",
          icon: "Settings",
          alias: "Settings",
        },
        { name: "الجدول", value: "/main", icon: "Calendar", alias: "Main" },
      ],
      navigationSuper: [
        { name: "المحادثات", value: "/chat", icon: "Chat", alias: "Chat" },
        { name: "الجدول", value: "/main", icon: "Calendar", alias: "Main" },
      ],
      currentNav: this.$route.path,
      currentTime: moment().format("hh:mm A"),
      dayDateAr: moment().format("iYYYY/iMM/iDD"),
      dayDateEn: moment().format("YYYY/MM/DD"),
      selectedNav: { name: "الجدول", value: "/main", icon: "Calendar" },
    };
  },
  components: {
    BaseIcon,
  },
  methods: {
    async openPage(page) {
      await this.$store.commit("toggleViewEventAside", false);
      await this.$store.commit("toggleNewEventAside", false);
      await this.$store.commit("toggleEditEventModal", false);
      await this.$store.commit("toggleNewGuestAside", false);
      await this.$store.commit("setMentionedUser", "");
      await this.$store.commit("setEventForChat", "");
      this.$store.commit("setSelectedUserForChat", "");
      /*if (page == "/chat") {
        await this.$store.commit("resetChatOfUserById");
        await this.$store.commit("resetSelectedUserForChat");
        const upData = {
          to: parseInt(localStorage.getItem("awqatiUserId")),
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        };
        await this.$store.dispatch("getTotalOfAllUnseenChat", upData);
      }*/
      this.$router.push(page);
      this.setPage(this.$route.path);
    },
    setPage(page) {
      this.currentNav = page;
      return true;
    },
    updateCurrentTime() {
      this.forCommingDate = moment(new Date()).format("HH:mm");
      this.currentTime = moment().format("h:mm:ss A");
      this.dayDateAr = moment().format("iYYYY/iMM/iDD");
      this.dayDateEn = moment().format("YYYY/MM/DD");
    },
    async logout() {
      const alert = await alertController.create({
        header: "تسجيل الخروج",
        message: "هل تريد تسجيل الخروج؟",
        buttons: [
          {
            text: "لا",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {},
          },
          {
            text: "نعم",
            handler: () => {
              this.$store.dispatch("doLogout");
            },
          },
        ],
      });
      return alert.present();
    },
  },
  computed: {
    getCurrentPage() {
      return this.$route.name;
      //return this.selectedNav;
    },
    chatNotification() {
      return this.$store.getters.getTotalOfAllUnseenChat;
    },
    getUserInfo() {
      if (localStorage.getItem("awqatiAccess_token")) {
        let info = {
          name: localStorage.getItem("awqatiUser"),
          position: localStorage.getItem("awqatiUserPosition"),
          privilege: localStorage.getItem("awqatiUserPrivilege"),
        };
        return info;
      } else {
        return this.$router.push("/");
      }
    },
  },
  watch: {
    async chatNotification() {
      /*if (this.$route.name == "Chat") {
        const upData = {
          to: parseInt(localStorage.getItem("awqatiUserId")),
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        };

        this.$store.dispatch("getTotalOfAllUnseenChat", upData);
      }*/
    },
  },
  async created() {
    const upData = {
      to: parseInt(localStorage.getItem("awqatiUserId")),
      group: parseInt(localStorage.getItem("awqatiUserGroup")),
    };

    this.$store.dispatch("getTotalOfAllUnseenChat", upData);

    setInterval(() => this.updateCurrentTime(), 1000);
  },
  async mounted() {
    const upData = {
      to: parseInt(localStorage.getItem("awqatiUserId")),
      group: parseInt(localStorage.getItem("awqatiUserGroup")),
    };

    this.$socket.on("checkUserForChat", (data) => {
      console.log("START CHECK USER");
      if (data.to == localStorage.getItem("awqatiUserId")) {
        console.log("IS (to) USER !");
        if (
          this.$store.getters.getSelectedUserForChat != "" &&
          this.$store.getters.getSelectedUserForChat.id == data.from
        ) {
          console.log("Its current user !!");
        } else {
          console.log("Its Not current user !!");
          this.$store.dispatch("getTotalOfAllUnseenChat", data);
          return;
        }
      } else {
        console.log("NOT (to) USER !");
        return;
      }
    });
  },
};
</script>
