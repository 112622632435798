<template>
  <ion-app>
    <CallingAlert
      v-if="hasCalling !== null && !isSuper()"
      :callData="hasCalling"
    />
    <NewUserModal v-if="getNewUserStatusModalStatus" />
    <NewPersonModal v-if="getNewPersonModalStatus" />
    <NewGroupModal v-if="getNewGroupModalStatus" />
    <EditPersonModal v-if="getEditPersonModalStatus" />
    <NewOfficeModal v-if="getNewOfficeModalStatus" />
    <NewPlaceModal v-if="getNewPlaceModalStatus" />
    <NewCategoryModal v-if="getNewCategoryModalStatus" />
    <DatePopoverEvent v-if="getDatePopoverStatus" />
    <DatePopoverGuest v-if="getDatePopoverGuestStatus" />
    <TimePicker v-if="getTimePickerStatus" />
    <TimePickerGuest v-if="getTimePickerGuestStatus" />
    <Alert class="z-50" />
    <header v-if="loggedIn && !isIntro" class="w-full hidden sm:block">
      <div
        class="relative z-10 flex-shrink-0 h-20 bg-white border-b border-gray-200 shadow-sm flex"
      >
        <Navbar />
      </div>
    </header>
    <ion-content>
      <transition
        enter-active-class="transform transition ease-in-out duration-500 sm:duration-300"
        enter-from-class="translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transform transition ease-in-out duration-500 sm:duration-300"
        leave-from-class="translate-x-0"
        leave-to-class="translate-x-full"
      >
        <ViewEventAside v-if="viewEventAsideStatus" />
      </transition>
      <ion-router-outlet />
    </ion-content>
    <!-- Phone -->
    <!--<Tabs v-if="loggedIn && showing" />-->
  </ion-app>
</template>

<script>
import { Capacitor } from "@capacitor/core";

import Navbar from "./components/Navbar.vue";
import CallingAlert from "./components/CallAlert.vue";
import NewUserModal from "./components/NewUserModal.vue";
import NewPersonModal from "./components/NewPersonModal.vue";
import NewGroupModal from "./components/NewGroupModal.vue";
import EditPersonModal from "./components/EditPersonModal.vue";
import NewOfficeModal from "./components/NewOfficeModal.vue";
import NewPlaceModal from "./components/NewPlaceModal.vue";
import NewCategoryModal from "./components/NewCategoryModal.vue";
import DatePopoverEvent from "./components/DatePopoverEvent.vue";
import DatePopoverGuest from "./components/DatePopoverGuest.vue";
import TimePicker from "./components/TimePicker.vue";
import TimePickerGuest from "./components/TimePickerGuest.vue";
import ViewEventAside from "./components/ViewEventAside.vue";
import Alert from "./components/Alert.vue";
import { IonApp, IonContent, IonRouterOutlet } from "@ionic/vue";
import Tabs from "./components/Tabs.vue";
export default {
  name: "App",
  data() {
    return {
      //fcm: new FCM()
    };
  },
  components: {
    IonApp,
    IonContent,
    IonRouterOutlet,
    Tabs,
    Navbar,
    NewUserModal,
    NewGroupModal,
    NewPersonModal,
    EditPersonModal,
    NewOfficeModal,
    NewPlaceModal,
    NewCategoryModal,
    DatePopoverEvent,
    DatePopoverGuest,
    TimePicker,
    TimePickerGuest,
    ViewEventAside,
    Alert,
    CallingAlert,
  },
  computed: {
    loggedIn() {
      return this.$store.getters.getLoggedInStatus;
    },
    showing() {
      return this.$store.getters.getTabStatus;
    },
    isIntro() {
      if (this.$route.name == "Intro") {
        return true;
      }
      return false;
    },
    hasCalling() {
      return this.$store.getters.getHasCalling;
    },
    getNewUserStatusModalStatus() {
      return this.$store.getters.getNewUserModalStatus;
    },
    getNewGroupModalStatus() {
      return this.$store.getters.getNewGroupModalStatus;
    },
    getNewPersonModalStatus() {
      return this.$store.getters.getNewPersonModalStatus;
    },
    getEditPersonModalStatus() {
      return this.$store.getters.getEditPersonModalStatus;
    },
    getNewOfficeModalStatus() {
      return this.$store.getters.getNewOfficeModalStatus;
    },
    getNewPlaceModalStatus() {
      return this.$store.getters.getNewPlaceModalStatus;
    },
    getNewCategoryModalStatus() {
      return this.$store.getters.getNewCategoryModalStatus;
    },
    getDatePopoverStatus() {
      return this.$store.getters.getDatePopoverStatus;
    },
    getDatePopoverGuestStatus() {
      return this.$store.getters.getDatePopoverGuestStatus;
    },
    getTimePickerStatus() {
      return this.$store.getters.getTimePickerStatus;
    },
    getTimePickerGuestStatus() {
      return this.$store.getters.getTimePickerGuestStatus;
    },
    viewEventAsideStatus() {
      return this.$store.getters.viewEventAsideStatus;
    },
  },
  methods: {
    isSuper() {
      if (localStorage.getItem("awqatiUserPrivilege") == 6) {
        return true;
      }
      return false;
    },
  },
  async mounted() {
    if (localStorage.getItem("awqatiUser")) {
      this.$store.commit("setLoggedIn", true);
      await this.$store.dispatch("getUserCallingByID", {
        to: parseInt(localStorage.getItem("awqatiUserId")),
        group: parseInt(localStorage.getItem("awqatiUserGroup")),
      });
    } else {
      this.$store.commit("setLoggedIn", false);
    }
  },
  async created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpgradeUI = true;
      });
    }
  },
};
</script>
