<template>
  <div
    dir="rtl"
    aria-live="assertive"
    class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
  >
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="getNotificationStatus"
          :class="[
            getNotificationType === 'success'
              ? 'bg-green-600/70'
              : getNotificationType == 'rejectguest'
              ? 'bg-red-600/70'
              : 'bg-green-600/70',
          ]"
          class="max-w-sm w-[30rem] shadow-2xl shadow-slate-300 ring-1 ring-black ring-opacity-10 backdrop-blur backdrop-filter rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
        >
          <div class="px-3 py-2">
            <div class="flex items-center gap-x-1">
              <div class="w-6">
                <BaseIcon
                  name="CheckCircle"
                  v-if="getNotificationType === 'success'"
                  class="h-6 w-6 text-white"
                  aria-hidden="true"
                />
                <BaseIcon
                  name="NewChat"
                  v-if="getNotificationType === 'newchat'"
                  class="h-6 w-6 text-white"
                  aria-hidden="true"
                  strokeWidth="0.2"
                />
                <BaseIcon
                  name="UserPlus"
                  v-if="getNotificationType === 'newguest'"
                  class="h-6 w-6 text-white"
                  aria-hidden="true"
                  strokeWidth="0.2"
                />
                <BaseIcon
                  name="UserCheck"
                  v-if="getNotificationType === 'acceptguest'"
                  class="h-6 w-6 text-white"
                  aria-hidden="true"
                  strokeWidth="0.2"
                />
                <BaseIcon
                  name="UserCross"
                  v-if="getNotificationType === 'rejectguest'"
                  class="h-6 w-6 text-white"
                  aria-hidden="true"
                  strokeWidth="0.2"
                />
              </div>
              <div class="w-0 flex-1 pt-0.5 mr-3">
                <p class="text-md font-bold text-white">
                  {{ getNotificationTitle }}
                </p>
                <p class="mt-1 text-md text-white">
                  {{ getNotificationText }}
                </p>
              </div>
              <div class="ml-4 flex">
                <button
                  type="button"
                  @click="closeNotification()"
                  class="rounded-md inline-flex text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <BaseIcon name="XIcon" class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import BaseIcon from "../components/BaseIcon.vue";

export default {
  name: "Alert",
  data() {
    return {
      show: true,
    };
  },
  components: {
    BaseIcon,
  },
  methods: {
    closeNotification() {
      this.$store.commit("setNotificationStatus", {
        title: "",
        text: "",
        type: "",
        status: false,
      });
    },
  },
  computed: {
    getNotificationTitle() {
      return this.$store.getters.getNotificationTitle;
    },
    getNotificationText() {
      return this.$store.getters.getNotificationText;
    },
    getNotificationType() {
      return this.$store.getters.getNotificationType;
    },
    getNotificationStatus() {
      return this.$store.getters.getNotificationStatus;
    },
  },
};
</script>
