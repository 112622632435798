<template>
  <div
    class="absolute sm:hidden z-20 bottom-0 flex w-full justify-center tracking-tighter"
    style="direction: ltr"
  >
    <ion-modal>
      <ion-content>
        <div dir="rtl" class="my-6 px-2 space-y-1 overflow-hidden">
          <div class="group flex overflow-hidden">
            <span
              class="h-8 w-8 rounded-full border border-gray-200 overflow-hidden bg-gray-100"
            >
              <svg
                class="h-full w-full text-gray-300"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                ></path>
              </svg>
            </span>
            <div class="ml-3 rtl:ml-0 rtl:mr-3">
              <p
                class="text-md font-normal text-gray-700 group-hover:text-gray-900"
              >
                {{ getUserInfo.name }}
              </p>
              <p
                class="text-md font-normal text-gray-500 group-hover:text-gray-700"
              >
                {{ getUserInfo.position }}
              </p>
            </div>
          </div>

          <div
            @click="logout()"
            class="text-center block rounded-md px-3 py-3 text-ms text-white bg-rose-600 font-semibold hover:bg-rose-100 hover:text-rose-800"
          >
            تسجيل الخروج
          </div>
        </div>
      </ion-content>
    </ion-modal>
    <div
      class="bg-class flex justify-between bottom-0 h-24 w-full rounded-t-3xl pt-4 px-4"
    >
      <div id="profile-controls" class="wk-text-center text-gray-400">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-7 w-7"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span class="text-[13px]">حسابي</span>
        </span>
      </div>
      <router-link
        to="/settings"
        exact
        exact-active-class="text-blue-600"
        class="wk-text-center text-gray-400"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            exact-active-class="text-red-500 bg-white rounded-full p-1 shadow-md"
            class="h-7 w-7"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
          <span class="text-[13px]">الإعدادات</span>
        </span>
      </router-link>

      <div
        v-if="getCurrentPage == 'Main'"
        class="absolute left-0 right-0 mx-auto -mt-14 w-12"
      >
        <ion-fab vertical="top" horizontal="center" slot="fixed">
          <ion-fab-button>
            <BaseIcon name="Plus" class="w-6 h-6" />
          </ion-fab-button>
          <ion-fab-list side="top">
            <div
              class="mb-px flex flex-row space-x-14 justify-center w-64 -mx-32"
            >
              <div
                @click="openNewGuestAside"
                class="w-32 text-white bg-indigo-600 rounded-full px-3 py-2 shadow-sm"
              >
                إضافة ضيف
              </div>
              <div
                @click="openNewEventAside"
                class="w-32 text-white bg-indigo-600 rounded-full px-3 py-2 shadow-sm"
              >
                إضافة موعد
              </div>
            </div>
          </ion-fab-list>
        </ion-fab>
      </div>

      <router-link
        to="/chat"
        exact
        exact-active-class="text-blue-600"
        class="wk-text-center text-gray-400"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            exact-active-class="text-red-500 bg-white rounded-full p-1 shadow-md"
            class="h-7 w-7"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1" />
            <line x1="12" y1="12" x2="12" y2="12.01" />
            <line x1="8" y1="12" x2="8" y2="12.01" />
            <line x1="16" y1="12" x2="16" y2="12.01" />
          </svg>
          <span class="text-[13px]">المحادثات</span>
        </span>
      </router-link>
      <router-link
        to="/main"
        exact
        exact-active-class="text-blue-600"
        class="wk-text-center text-gray-400"
      >
        <span>
          <svg
            class="h-7 w-7"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke="none"
              stroke-width="1.5"
              d="M0 0h24v24H0z"
              fill="none"
            />
            <polyline stroke-width="1.5" points="5 12 3 12 12 3 21 12 19 12" />
            <path
              stroke-width="1.5"
              d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"
            />
            <path
              stroke-width="1.5"
              d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"
            />
          </svg>
        </span>
        <span class="text-[13px]">الجدول</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import moment from "moment-hijri";
moment.updateLocale("ar-sa", {
  postformat: (string) => string,
});
import BaseIcon from "../components/BaseIcon.vue";

import {
  IonFab,
  IonFabButton,
  IonFabList,
  IonModal,
  alertController,
  modalController,
} from "@ionic/vue";
export default {
  data() {
    return {
      currentNav: this.$route.path,
      currentTime: moment().format("hh:mm A"),
      dayDateAr: moment().format("iYYYY/iMM/iDD"),
      dayDateEn: moment().format("YYYY/MM/DD"),
    };
  },
  components: {
    IonFab,
    IonFabButton,
    IonFabList,
    IonModal,
    BaseIcon,
  },
  methods: {
    async logout() {
      await modalController.dismiss();
      const alert = await alertController.create({
        header: "تسجيل الخروج",
        message: "هل تريد تسجيل الخروج؟",
        buttons: [
          {
            text: "لا",
            role: "cancel",
            cssClass: "primary",
            handler: (blah) => {},
          },
          {
            text: "نعم",
            handler: () => {
              this.$store.dispatch("doLogout");
            },
          },
        ],
      });
      return alert.present();
    },
    async openNewEventAside() {
      let currentID = parseInt(localStorage.getItem("awqatiUserId"));
      await this.$store.dispatch("checkUserStatus", currentID);
      if (this.$store.getters.getUserCheckStatus) {
        this.$store.commit("toggleNewEventAside", true);
      } else {
        this.$router.push("/intro");
      }
    },
    async openNewGuestAside() {
      let currentID = parseInt(localStorage.getItem("awqatiUserId"));
      await this.$store.dispatch("checkUserStatus", currentID);
      if (this.$store.getters.getUserCheckStatus) {
        this.$store.commit("toggleNewGuestAside", true);
      } else {
        this.$router.push("/intro");
      }
    },
    async openPage(page) {
      let currentID = parseInt(localStorage.getItem("awqatiUserId"));
      await this.$store.commit("toggleViewEventAside", false);
      await this.$store.commit("toggleNewEventAside", false);
      await this.$store.commit("toggleEditEventModal", false);
      await this.$store.commit("toggleNewGuestAside", false);
      await this.$store.dispatch("checkUserStatus", currentID);
      await this.$store.commit("setMentionedUser", "");
      await this.$store.commit("setEventForChat", "");
      if (this.$store.getters.getUserCheckStatus) {
        if (page == "/chat") {
          await this.$store.commit("resetChatOfUserById");
          await this.$store.commit("resetSelectedUserForChat");
          const upData = {
            to: parseInt(localStorage.getItem("awqatiUserId")),
            group: parseInt(localStorage.getItem("awqatiUserGroup")),
          };
          await this.$store.dispatch("getTotalOfAllUnseenChat", upData);
        }
        this.$router.push(page);
        this.setPage(this.$route.path);
      } else {
        this.$router.push("/intro");
      }
    },
    setPage(page) {
      this.currentNav = page;
      return true;
    },
    updateCurrentTime() {
      this.forCommingDate = moment(new Date()).format("HH:mm");
      this.currentTime = moment().format("h:mm:ss A");
      this.dayDateAr = moment().format("iYYYY/iMM/iDD");
      this.dayDateEn = moment().format("YYYY/MM/DD");
    },
  },
  computed: {
    getCurrentPage() {
      //return this.currentNav;
      return this.$route.name;
    },
    chatNotification() {
      return this.$store.getters.getTotalOfAllUnseenChat;
    },
    getUserInfo() {
      if (localStorage.getItem("awqatiAccess_token")) {
        let info = {
          name: localStorage.getItem("awqatiUser"),
          position: localStorage.getItem("awqatiUserPosition"),
        };
        return info;
      } else {
        return this.$router.push("/intro");
      }
    },
  },
  async created() {
    const upData = {
      to: parseInt(localStorage.getItem("awqatiUserId")),
      group: parseInt(localStorage.getItem("awqatiUserGroup")),
    };

    this.$store.dispatch("getTotalOfAllUnseenChat", upData);

    setInterval(() => this.updateCurrentTime(), 1000);
  },
  async mounted() {
    const upData = {
      to: parseInt(localStorage.getItem("awqatiUserId")),
      group: parseInt(localStorage.getItem("awqatiUserGroup")),
    };

    this.$socket.on("checkUserForChat", (data) => {
      console.log("START CHECK USER");
      if (data.to == localStorage.getItem("awqatiUserId")) {
        console.log("IS (to) USER !");
        this.$store.dispatch("getTotalOfAllUnseenChat", upData);
      } else {
        console.log("NOT (to) USER !");
        return;
      }
    });
  },
};
</script>

<style scoped>
.bg-class {
  /* background: rgba(52, 55, 212, 0.795); */
  background: rgba(255, 255, 255, 0.795);
  backdrop-filter: blur(40px);
}

ion-fab-button {
  --background: rgb(79, 70, 229);
  --background-activated: rgb(79, 70, 229);
}
</style>
