<template>
  <ion-page>
    <ion-content>
      <div
        class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-grid-gray-100 bg-gray-50"
      >
        <div class="max-w-md w-full space-y-8">
          <div class="flex w-full text-center justify-center mx-auto">
            <svg
              v-if="!this.loggedIn"
              viewBox="0 0 24 24"
              class="w-24 h-24"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
            >
              <rect
                width="16"
                height="16"
                x="4"
                y="4"
                stroke="#334155"
                stroke-width="1.5"
                rx="8"
              />
              <path
                stroke="#334155"
                stroke-linecap="round"
                stroke-width="1.5"
                d="M12.021 12l2.325 2.325"
              />
              <path
                stroke="#4f46e5"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M12.021 12V6.84"
                class="clock"
              />
            </svg>
            <svg
              v-if="this.loggedIn"
              viewBox="0 0 24 24"
              class="w-24 h-24"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
            >
              <rect
                width="16"
                height="16"
                x="4"
                y="4"
                stroke="#334155"
                stroke-width="1.5"
                rx="8"
              />
              <path
                stroke="#334155"
                stroke-linecap="round"
                stroke-width="1.5"
                d="M12.021 12l2.325 2.325"
              />
              <path
                stroke="#4f46e5"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M12.021 12V6.84"
                class="clock-once"
              />
            </svg>
          </div>
          <div>
            <h2 class="mt-6 text-center text-5xl text-gray-900">
              <b class="text-5xl font-extrabold text-indigo-700">أوقات</b>
            </h2>
          </div>
          <div v-if="!this.loggedIn" class="pt-12 flex w-full justify-center">
            <p>الرجاء الانتظار</p>
          </div>

          <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-50 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <div v-if="this.loggedIn" class="mt-8 space-y-6">
              <div>
                <label
                  for="username"
                  class="block text-sm font-medium text-gray-700 text-right"
                  >اسم المستخدم</label
                >
                <ion-input
                  id="username"
                  v-model="username"
                  name="username"
                  type="text"
                  autocomplete="off"
                  class="appearance-none border border-gray-300 rounded-lg shadow-sm placeholder-gray-400 sm:text-lg font-semibold text-center bg-white/50 focus:border focus:border-indigo-400"
                  style="
                    display: block;
                    width: 100%;
                    --padding-top: 12px;
                    --padding-start: 12px;
                    --padding-end: 12px;
                    --padding-bottom: 12px;
                    --background: white;
                    --color: rgb(99, 102, 241);
                    outline: 2px solid transparent;
                    outline-offset: 2px;
                  "
                ></ion-input>
                <!-- <input id="username" v-model="username" name="username" type="username" autocomplete="off" class="appearance-none block w-full px-3 py-3 border border-gray-300 rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-lg font-semibold text-center" /> -->
              </div>

              <div class="space-y-1">
                <label
                  for="password"
                  class="block text-sm font-medium text-gray-700 text-right"
                  >كلمة المرور</label
                >
                <ion-input
                  @keyup.enter="handleSend($event)"
                  id="password"
                  v-model="password"
                  name="password"
                  type="password"
                  autocomplete="off"
                  class="appearance-none border border-gray-300 rounded-lg shadow-sm bg-white/50 placeholder-gray-400 sm:text-lg font-semibold text-center"
                  style="
                    display: block;
                    width: 100%;
                    --padding-top: 12px;
                    --padding-start: 12px;
                    --padding-end: 12px;
                    --padding-bottom: 12px;
                    --background: white;
                    --tw-ring-shadow: none !important;
                    border-color: none !important;
                    --color: rgb(99, 102, 241);
                    outline: 2px solid transparent;
                    outline-offset: 2px;
                  "
                ></ion-input>
              </div>

              <div>
                <button
                  @click="login()"
                  class="group relative w-full flex justify-center py-3 px-4 border border-transparent text-lg font-bold rounded-lg text-white bg-indigo-700 hover:bg-indigo-800 active:bg-indigo-500 focus:outline-none"
                >
                  تسجيل الدخول
                </button>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonInput, toastController } from "@ionic/vue";
import { mount } from "@vue/test-utils";

export default {
  components: {
    IonPage,
    IonContent,
    IonInput,
  },
  data() {
    return {
      loggedIn: false,
      username: "",
      password: "",
    };
  },
  methods: {
    handleSend(e) {
      if (e.keyCode == 13) {
        this.login();
      }
    },
    async login() {
      if (this.username == "" || this.password == "") {
        toastController
          .create({
            duration: 2000,
            header: "خطأ",
            message: "الرجاء ادخال جميع البيانات",
            color: "danger",
            position: "top",
            showCloseButton: true,
            closeButtonText: "Ok",
            cssClass: "error-toast",
          })
          .then((toast) => toast.present());
      } else {
        let payload = {
          USERNAME: this.username,
          PASSWORD: this.password,
        };

        await this.$store.dispatch("doLogin", payload);
        this.username = "";
        this.password = "";
        //this.$router.push('/main')
      }
    },
  },
  async mounted() {
    const platform = await Capacitor.getPlatform();
    //setTimeout( () => this.$router.push({ path: '/login'}), 3200)
    setTimeout(() => {
      if (localStorage.getItem("awqatiAccess_token")) {
        //if (platform === "ios" || platform === "android") {
        if (
          window.innerWidth < 640 ||
          platform === "ios" ||
          platform === "android"
        ) {
          // Navigate to the desired page for mobile devices
          this.$router.push("/mobile");
        } else {
          //this.$router.push("/mobile");
          this.$router.push("/main");
          // Render the default app experience for non-mobile devices
        }
      } else {
        return (this.loggedIn = true);
      }
    }, 3200);
  },
};
</script>

<style scoped>
.test {
  background: #4f46e5;
  background-color: #334155;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotateonce {
  from {
    transform: rotate(205deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.clock {
  animation: rotate 2s linear infinite both;
  transform-origin: center;
}

.clock-once {
  animation: rotateonce 3s linear both;
  transform-origin: center;
}
</style>
