<template>
  <div
    class="fixed z-50 inset-0 overflow-hidden select-none"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center"
    >
      <div
        @click="closeMe()"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div class="z-50 w-full">
        <div
          dir="rtl"
          class="relative inline-block align-bottom bg-white rounded-lg pt-1 pb-1 px-1 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full"
        >
          <div class="flex justify-between px-2 items-center mb-2">
            <div
              class="flex justify-center w-fit sm:text-lg mt-2 bg-gray-100 p-1 rounded-lg h-8 items-center"
            >
              <button
                v-if="getLocaly"
                @click="changeAPM('م')"
                type="button"
                :class="
                  getSelectedAPM == 'م'
                    ? 'bg-white shadow-sm font-bold text-indigo-700'
                    : 'text-gray-400'
                "
                class="ml-px w-16 p-1.5 rounded-md hover:bg-white hover:shadow-sm focus:outline-none"
              >
                مساءً
              </button>
              <button
                v-if="getLocaly"
                @click="changeAPM('ص')"
                type="button"
                :class="
                  getSelectedAPM == 'ص'
                    ? 'bg-white shadow-sm font-bold text-indigo-700'
                    : 'text-gray-400'
                "
                class="mr-px w-16 p-1.5 rounded-md hover:bg-white hover:shadow-sm focus:outline-none"
              >
                صباحا
              </button>

              <button
                v-if="!getLocaly"
                @click="changeAPM('م')"
                type="button"
                :class="
                  getSelectedTime.apm == 'م'
                    ? 'bg-white shadow-sm font-bold text-indigo-700'
                    : 'text-gray-400'
                "
                class="ml-px w-16 p-1.5 rounded-md hover:bg-white hover:shadow-sm focus:outline-none"
              >
                مساءً
              </button>
              <button
                v-if="!getLocaly"
                @click="changeAPM('ص')"
                type="button"
                :class="
                  getSelectedTime.apm == 'ص'
                    ? 'bg-white shadow-sm font-bold text-indigo-700'
                    : 'text-gray-400'
                "
                class="mr-px w-16 p-1.5 rounded-md hover:bg-white hover:shadow-sm focus:outline-none"
              >
                صباحا
              </button>
            </div>
            <div
              class="flex justify-center mt-2 text-lg sm:text-xl font-semibold text-slate-600"
            >
              <div v-if="getLocaly">{{ getSelectedTime }}</div>
              <div v-if="!getLocaly">
                {{
                  getSelectedTime.hour +
                  ":" +
                  getSelectedTime.minute +
                  " " +
                  getSelectedTime.apm
                }}
              </div>
            </div>
          </div>

          <div
            class="flex flex-row justify-between space-x-0 sm:space-x-0 text-md sm:text-2xl bg-gray-50 pt-2 border-t border-gray-200"
          >
            <div class="flex flex-col">
              <div class="w-full text-center text-lg sm:text-lg text-gray-500">
                الساعات
              </div>
              <div
                v-if="getLocaly"
                class="w-full bg-gray-50 rounded-lg p-2 grid grid-cols-4 gap-4 sm:gap-6"
              >
                <div
                  @click="changeHour(hour)"
                  class="p-1 text-center text-md"
                  :class="
                    getSelectedHour == hour
                      ? 'text-indigo-700 text-lg sm:text-md font-semibold border-b border-indigo-700 bg-indigo-50'
                      : 'text-gray-400'
                  "
                  v-for="hour in hours"
                  :key="hour.id"
                >
                  {{ hour }}
                </div>
              </div>

              <div
                v-if="!getLocaly"
                class="w-full bg-gray-50 rounded-lg p-2 grid grid-cols-4 gap-4 sm:gap-6"
              >
                <div
                  @click="changeHour(hour)"
                  class="p-1 text-center text-md"
                  :class="
                    getSelectedTime.hour == hour
                      ? 'text-indigo-700 text-md sm:text-md font-semibold border-b border-indigo-700 bg-indigo-50'
                      : 'text-gray-400'
                  "
                  v-for="hour in hours"
                  :key="hour.id"
                >
                  {{ hour }}
                </div>
              </div>
            </div>
            <div class="w-px mx-1 bg-gray-400"></div>
            <div class="flex flex-col">
              <div class="w-full text-center text-lg sm:text-lg text-gray-500">
                الدقائق
              </div>
              <div
                v-if="getLocaly"
                class="w-full bg-gray-50 rounded-lg p-2 grid grid-cols-4 gap-4 sm:gap-6 max-h-[12rem] overflow-y-auto"
              >
                <div
                  @click="changeMinute(minute)"
                  class="p-1 text-center text-md"
                  :class="
                    getSelectedMinute == minute
                      ? 'text-indigo-700 text-md sm:text-md font-semibold border-b border-indigo-700 bg-indigo-50'
                      : 'text-gray-400'
                  "
                  v-for="minute in minutes"
                  :key="minute.id"
                >
                  {{ minute }}
                </div>
              </div>

              <div
                v-if="!getLocaly"
                class="w-full bg-gray-50 rounded-lg p-2 grid grid-cols-4 gap-4 sm:gap-6 max-h-[12rem] overflow-y-auto"
              >
                <div
                  @click="changeMinute(minute)"
                  class="p-1 text-center text-md"
                  :class="
                    getSelectedTime.minute == minute
                      ? 'text-indigo-700 text-md font-semibold border-b border-indigo-700 bg-indigo-50'
                      : 'text-gray-400'
                  "
                  v-for="minute in minutes"
                  :key="minute.id"
                >
                  {{ minute }}
                </div>
              </div>
            </div>
          </div>
          <div
            dir="rtl"
            class="flex w-full justify-between text-center px-8 border-t border-gray-200 py-2 text-md sm:text-lg"
          >
            <div
              v-if="getSelectedTime != ''"
              @click="saveSelectedTime()"
              class="p-3 w-full rounded-lg bg-indigo-600 text-white font-semibold"
            >
              حفظ
            </div>
            <div
              @click="closeMe()"
              class="p-3 w-full text-gray-600 font-semibold"
            >
              إلغاء
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-hijri";
moment.updateLocale("ar-sa", {
  postformat: (string) => string,
});

export default {
  data() {
    return {
      selectedAPM: this.$store.getters.newGuestSelectedTime.apm,
      selectedHour: this.$store.getters.newGuestSelectedTime.hour,
      selectedMinute: this.$store.getters.newGuestSelectedTime.minute,
      localy: false,
      hours: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      minutes: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
      ],
      selectedTime: this.$store.getters.newGuestSelectedTime,
    };
  },
  methods: {
    closeMe() {
      this.selectedDay = "";
      this.$store.commit("toggleTimePickerGuest", false);
    },
    preTime(vapm) {
      this.selectedTime = vapm;
    },
    changeAPM(v) {
      this.selectedAPM = v;
      let papm = this.getSelectedHour + ":" + this.getSelectedMinute + " " + v;
      this.preTime(papm);
      this.localy = true;
    },
    changeHour(v) {
      this.selectedHour = v;
      let papm = v + ":" + this.getSelectedMinute + " " + this.getSelectedAPM;
      this.preTime(papm);
      this.localy = true;
    },
    changeMinute(v) {
      this.selectedMinute = v;
      let papm = this.getSelectedHour + ":" + v + " " + this.getSelectedAPM;
      this.preTime(papm);
      this.localy = true;
    },
    changeLocaly(v) {
      this.localy = v;
    },
    async saveSelectedTime() {
      const timeInfo = {
        hour: this.getSelectedHour,
        minute: this.getSelectedMinute,
        apm: this.getSelectedAPM,
      };
      await this.$store.commit("setNewGuestSelectedTime", timeInfo);
      this.$store.commit("toggleTimePickerGuest", false);
    },
  },
  computed: {
    getSelectedAPM() {
      return this.selectedAPM;
    },
    getSelectedHour() {
      return this.selectedHour;
    },
    getSelectedMinute() {
      return this.selectedMinute;
    },
    getSelectedTime() {
      return this.selectedTime;
    },
    getLocaly() {
      return this.localy;
    },
  },
};
</script>
