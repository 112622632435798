<template>
  <EventModal />
  <div class="w-full flex flex-col items-center pr-2" id="topOfPage">
    <div ref="weeky"></div>
    <div class="w-full h-full pb-24 overflow-auto">
      <ul class="flex flex-row h-full pb-12 overflow-auto">
        <li
          v-for="i in 7"
          :key="i"
          class="flex flex-col size pb-1 px-1 text-center h-full overflow-x-hidden overflow-y-auto mb-12"
        >
          <div
            v-if="weekEvents.length != 0"
            class="text-xs sm:text-sm font-semibold text-gray-600 mt-2 pt-2 border-t border-gray-300"
          >
            المواعيد
          </div>
          <div
            v-for="event in weekEvents"
            :key="event.id"
            class="rounded-t-lg active:bg-indigo-50"
            :class="[
              event.status == 'unavailable' ? 'canceldBG' : '',
              checkActivity(event) == 'coming' ? 'bg-white' : '',
              checkActivity(event) == 'now'
                ? 'animate-pulse bg-blue-100/70'
                : 'bg-gray-100',
            ]"
          >
            <div
              @click="openViewEventAside(event)"
              v-if="checkDay(event.ar_date, i)"
              class="relative mb-1 rounded-t-lg"
              :class="`shadow-md ${
                JSON.parse(event.color).shadow_class_color
              }/10`"
            >
              <span
                :class="[
                  event.status == 'unavailable'
                    ? 'text-rose-800/50'
                    : `${
                        JSON.parse(event.color).txt_class_color
                      } rounded-b-full ${
                        JSON.parse(event.color).bg_class_color
                      } bg-opacity-5`,
                  'p-1 text-[0.70rem] text-center rounded truncate font-semibold',
                  checkActivity(event) == 'previous' ? 'opacity-50' : '',
                ]"
              >
                {{ event.category }}
              </span>
              <div>
                <span
                  class="group flex pt-2 justify-center items-center leading-5 text-gray-600 rounded-md"
                >
                  <span>
                    <div class="flex items-center flex-row justify-center">
                      <div
                        class="text-[0.60rem] py-px px-1 rounded-lg bg-gray-50 sm:bg-gray-100 text-gray-600"
                      >
                        {{
                          timeFromTo(JSON.parse(event.time), event.duration)
                            .from
                        }}
                      </div>
                      <BaseIcon
                        name="ChevronLeft"
                        class="hidden text-gray-300 sm:block self-center w-3 h-3"
                      />
                      <div
                        class="text-[0.60rem] py-px px-1 rounded-lg bg-gray-50 sm:bg-gray-100 text-gray-600"
                      >
                        {{
                          timeFromTo(JSON.parse(event.time), event.duration)
                            .durationTo
                        }}
                      </div>
                    </div>
                  </span>
                </span>

                <div
                  class="my-2 flex-1 text-xs sm:text-sm font-semibold"
                  :class="[
                    event.status == 'unavailable'
                      ? 'line-through text-red-900'
                      : '',
                    checkActivity(event) == 'previous' ? 'opacity-50' : '',
                  ]"
                >
                  {{ event.title }}
                </div>
              </div>
              <div
                class="bottom-0 rounded-b-full w-full h-[3px]"
                :class="[
                  event.status == 'unavailable'
                    ? 'bg-rose-800 bg-opacity-30'
                    : `${JSON.parse(event.color).bg_class_color}`,
                  checkActivity(event) == 'previous' ? 'opacity-50' : '',
                ]"
              ></div>
            </div>
          </div>
          <div
            v-if="
              checkDayGuests(i).length != 0 && getUserInfo.privilege !== '4'
            "
            class="text-xs sm:text-sm font-semibold text-gray-600 mt-2 pt-2 border-t border-gray-300"
          >
            الضيوف
          </div>
          <template v-if="getUserInfo.privilege !== '4'">
            <div
              v-for="(guest, index) in checkDayGuests(i)"
              :key="index"
              @click="showGuestInfo(guest)"
              class="shadow-sm rounded-lg p-1 my-1 active:bg-slate-200"
              :class="
                guest.status == 'approved'
                  ? 'bg-indigo-50 bg-opacity-80 text-indigo-700'
                  : 'bg-rose-50'
              "
            >
              <div class="flex flex-col text-center">
                <p class="text-xs font-semibold">
                  {{ JSON.parse(guest.person)[0].name }}
                </p>

                <p class="text-xs text-gray-400 mt-1">{{ guest.title }}</p>
              </div>
            </div>
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import moment from "moment-hijri";
moment.updateLocale("ar-sa", {
  postformat: (string) => string,
});
import BaseIcon from "../components/BaseIcon.vue";
import QuickGuestInfo from "./QuickGuestInfo.vue";
import EventModal from "./EventModal.vue";
import { modalController } from "@ionic/vue";

export default {
  data() {
    return {
      modalStatus: false,
      days: [
        "الأحد",
        "الاثنين",
        "الثلاثاء",
        "الأربعاء",
        "الخميس",
        "الجمعة",
        "السبت",
      ],
    };
  },
  props: ["currentDay"],
  components: {
    EventModal,
    BaseIcon,
    QuickGuestInfo,
  },
  computed: {
    getUserInfo() {
      if (localStorage.getItem("awqatiAccess_token")) {
        let info = {
          name: localStorage.getItem("awqatiUser"),
          position: localStorage.getItem("awqatiUserPosition"),
          privilege: localStorage.getItem("awqatiUserPrivilege"),
        };
        return info;
      } else {
        return this.$router.push("/");
      }
    },
    daysOfWeek() {
      var currentDate = moment(this.currentDay);
      var dayNo = currentDate.startOf("iweek").day();
      var weekStart = "";
      if (dayNo == 0) {
        weekStart = currentDate.startOf("iweek");
      } else if (dayNo > 0) {
        weekStart = currentDate.startOf("iweek").add(-dayNo, "iDate");
      }
      var days = [];
      var dayClass = "text-gray-800 border border-gray-100";
      for (var i = 0; i <= 6; i++) {
        if (
          moment(this.currentDay).format("iYYYY/iM/iD") ==
          moment(weekStart).add(i, "iDate").format("iYYYY/iM/iD")
        ) {
          dayClass =
            "bg-rose-200 bg-opacity-50 text-rose-800 border border-rose-200";
        } else {
          dayClass = "text-gray-800 border border-gray-100";
        }
        days.push({
          day: moment(weekStart).add(i, "iDate").format("dddd"),
          date:
            moment(weekStart).add(i, "iDate").format("iM") +
            "/" +
            moment(weekStart).add(i, "iDate").format("iDD"),
          dayClass: dayClass,
        });
      }

      return days;
    },
    weekEvents() {
      let sortedEvent = this.$store.getters.getEventsOfWeek;
      sortedEvent.sort(function (a, b) {
        let aa = moment(
          a.en_date +
            " " +
            JSON.parse(a.time).hour +
            ":" +
            JSON.parse(a.time).minute +
            " " +
            JSON.parse(a.time).apm,
          ["YYYY/M/D HH:mm A"]
        ).format("YYYY/M/D HH:mm");
        let bb = moment(
          b.en_date +
            " " +
            JSON.parse(b.time).hour +
            ":" +
            JSON.parse(b.time).minute +
            " " +
            JSON.parse(b.time).apm,
          ["YYYY/M/D HH:mm A"]
        ).format("YYYY/M/D HH:mm");
        return moment(aa, "YYYY/M/D HH:mm") - moment(bb, "YYYY/M/D HH:mm");
      });
      return sortedEvent;
    },
    dayGuests() {
      return this.$store.getters.getGuestsOfWeek;
    },
    currentView() {
      return this.$store.getters.getCurrentView;
    },
  },
  updated() {
    this.$refs.weeky.scrollIntoView({
      top: 24,
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
    //var element = document.getElementById('topOfPage');
    //element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center'});
  },
  methods: {
    async showGuestInfo(guest) {
      const modal = await modalController.create({
        component: QuickGuestInfo,
        componentProps: { guest },
        initialBreakpoint: 0.4,
        breakpoints: [0.4, 0.5],
      });
      await modal.present();
    },
    checkDayGuests(i) {
      return this.dayGuests.filter((guest) => {
        const vDay =
          moment(guest.ar_date, "iYYYY/iM/iD").startOf("iweek").day() + 1;
        return vDay == i && guest.status != "waiting";
      });
    },
    openNewEventAside() {
      this.$store.commit("toggleNewEventAside", true);
    },
    async openViewEventAside(dayEvent) {
      this.$store.commit("toggleViewEventAside", {
        status: true,
        event: dayEvent,
      });
    },
    checkActivity(event) {
      const currentDateTime = moment();

      const { en_date, time, duration } = event;

      // Parse the time object
      const timeObj = JSON.parse(time);
      const hour = parseInt(timeObj.hour);
      const minute = parseInt(timeObj.minute);
      const isPM = timeObj.apm === "م";

      // Convert to 24-hour format if PM
      const hour24 = isPM && hour !== 12 ? hour + 12 : hour;

      // Create moment objects for the event start and end times
      const eventStartDateTime = moment(
        `${en_date} ${hour24}:${minute}`,
        "YYYY/M/D HH:mm"
      );
      const eventEndDateTime = moment(eventStartDateTime).add(
        parseInt(duration),
        "minutes"
      );

      if (currentDateTime.isBefore(eventStartDateTime)) {
        return "coming";
      } else if (currentDateTime.isAfter(eventEndDateTime)) {
        return "previous";
      } else {
        return "now";
      }
    },
    checkDay(v, i) {
      const vDay = moment(v, "iYYYY/iM/iD").startOf("iweek").day() + 1;
      //const vDay = moment(v, "iYYYY/iM/iD").format("iD")
      if (vDay == i) {
        return true;
      } else {
        return false;
      }
    },
    timeFromTo(time, duration) {
      let compare;
      if (duration == 0) {
        return (compare = {
          from: time.hour + ":" + time.minute + " " + time.apm,
          durationTo: "غير محدد",
        });
      } else {
        return (compare = {
          from: time.hour + ":" + time.minute + " " + time.apm,
          durationTo: moment(time.hour + ":" + time.minute + " " + time.apm, [
            "hh:mm A",
          ])
            .add(parseInt(duration), "minutes")
            .format("hh:mm A"),
        });
      }
    },
  },
  created() {
    this.$socket.on("syncEventsOfWeek", (data) => {
      if (this.currentView == "week") {
        this.$store.dispatch("getEventsForCommingUp", {
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        });

        var currentDate = moment(this.currentDay);
        var dayNo = currentDate.startOf("iweek").day();
        var weekStart = "";

        if (dayNo == 0) {
          weekStart = currentDate.startOf("iweek");
        } else if (dayNo > 0) {
          weekStart = currentDate.startOf("iweek").add(-dayNo, "iDate");
        }

        for (var i = 0; i <= 6; i++) {
          if (
            data.dayy == moment(weekStart).add(i, "iDate").format("iYYYY/iM/iD")
          ) {
            const newDays = {
              fromDay: moment(weekStart).format("YYYY/M/D"),
              toDay: moment(weekStart).add(6, "iDate").format("YYYY/M/D"),
              group: data.group,
            };
            this.$store.dispatch("getEventsOfWeek", newDays);
            return;
          } else {
            console.log("IT IS NOT IN SAME WEEK!");
            const newDays = {
              fromDay: moment(weekStart).format("YYYY/M/D"),
              toDay: moment(weekStart).add(6, "iDate").format("YYYY/M/D"),
              group: data.group,
            };
            this.$store.dispatch("getEventsOfWeek", newDays);
          }
        }
      }
    });

    this.$socket.on("syncGuestsOfWeek", (data) => {
      if (this.currentView == "week") {
        var currentDate = moment(data.ar_date);
        var dayNo = currentDate.startOf("iweek").day();
        var weekStart = "";
        if (dayNo == 0) {
          weekStart = currentDate.startOf("iweek");
        } else if (dayNo > 0) {
          weekStart = currentDate.startOf("iweek").add(-dayNo, "iDate");
        }

        let lastDay = moment(weekStart).add(6, "iDate").format("YYYY/M/D");
        let days = {
          fromDay: moment(weekStart).format("YYYY/M/D"),
          toDay: lastDay,
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        };
        this.$store.dispatch("getGuestsOfWeek", days);
        let getEventsOfDayPayload = {
          date: moment().format("iYYYY/iM/iD"),
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        };
        this.$store.dispatch("getGuestsOfDay", getEventsOfDayPayload);
        this.$store.dispatch("showNotification", {
          title: "ضيف",
          text: "تم إضافة ضيف جديد",
          type: "newguest",
        });
      }
      /*this.$store.dispatch("getEventsForCommingUp", {
            group: parseInt(localStorage.getItem("awqatiUserGroup")),
          });*/
    });

    this.$socket.on("syncUpdateGuestOfWeek", (data) => {
      if (this.currentView == "week") {
        var currentDate = moment(data.ar_date);
        var dayNo = currentDate.startOf("iweek").day();
        var weekStart = "";
        if (dayNo == 0) {
          weekStart = currentDate.startOf("iweek");
        } else if (dayNo > 0) {
          weekStart = currentDate.startOf("iweek").add(-dayNo, "iDate");
        }

        let lastDay = moment(weekStart).add(6, "iDate").format("YYYY/M/D");
        let days = {
          fromDay: moment(weekStart).format("YYYY/M/D"),
          toDay: lastDay,
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        };
        this.$store.dispatch("getGuestsOfWeek", days);
        let getEventsOfDayPayload = {
          date: moment().format("iYYYY/iM/iD"),
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        };
        this.$store.dispatch("getGuestsOfDay", getEventsOfDayPayload);

        if (data.updateType == "approved") {
          this.$store.dispatch("showNotification", {
            title: "ضيف",
            text: `${data.guestName} تم قبول الضيف`,
            type: "acceptguest",
          });
          /*this.$store.dispatch("getEventsForCommingUp", {
                group: parseInt(localStorage.getItem("awqatiUserGroup")),
              });*/
          this.$store.dispatch(data.command, {
            date: data.dayy,
            group: data.group,
          });
        } else if (data.updateType == "reject") {
          this.$store.dispatch("showNotification", {
            title: "ضيف",
            text: `${data.guestName} تم رفض الضيف`,
            type: "rejectguest",
          });
          /*this.$store.dispatch("getEventsForCommingUp", {
                group: parseInt(localStorage.getItem("awqatiUserGroup")),
              });*/
          this.$store.dispatch(data.command, {
            date: data.dayy,
            group: data.group,
          });
        }
      }
      /*this.$store.dispatch("getEventsForCommingUp", {
            group: parseInt(localStorage.getItem("awqatiUserGroup")),
          });*/
    });
  },
  mounted() {
    //      var element = document.getElementById('topOfPage');
    //  element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center'});
  },
  activated() {},
};
</script>
<style scoped>
ion-modal::part(backdrop) {
  background: rgba(209, 213, 219);
  opacity: 1;
}

.size {
  width: 14.286%;
}
</style>
