<template>
  <div
    dir="rtl"
    class="fixed z-50 inset-0 overflow-y-auto bg-gray-500 bg-opacity-75 transition-opacity"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0"
    >
      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded-lg pt-2 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full"
      >
        <div class="flex items-center justify-center">
          <div class="text-center">
            <h3 class="text-2xl leading-6 font-semibold text-gray-900">
              إضافة تصنيف
            </h3>
          </div>
        </div>
        <div
          class="mt-2 grid px-12 py-6 grid-cols-1 gap-y-4 gap-x-4 bg-gray-50 border-t border-gray-300"
        >
          <div class="sm:col-span-3 text-right">
            <label
              for="category-name"
              class="block text-sm font-medium text-gray-700"
            >
              الاسم
            </label>
            <div class="mt-1">
              <ion-input
                type="text"
                name="category-name"
                id="category-name"
                autocomplete="off"
                v-model="this.categoryName"
                class="shadow-sm block w-full sm:text-sm rounded-md"
                :class="
                  !this.checkData
                    ? 'text-red-700 border border-red-700 focus:ring-red-700 focus:border-red-700'
                    : 'text-indigo-700 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                "
                style="
                  display: block;
                  width: 100%;
                  --padding-top: 6px;
                  --padding-start: 6px;
                  --padding-end: 6px;
                  --padding-bottom: 6px;
                  border-width: 1px;
                  --tw-border-opacity: 1;
                  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
                  --background: white;
                  --color: rgb(99, 102, 241);
                  outline: 2px solid transparent;
                  outline-offset: 2px;
                "
              ></ion-input>
            </div>
          </div>
          <div class="w-full">
            <div
              v-if="!this.checkData"
              class="w-full rounded-md bg-rose-50 px-3"
            >
              <div class="flex items-center">
                <div class="flex-shrink-0">
                  <svg
                    class="h-5 w-5 text-rose-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <div class="mr-3">
                  <h3 class="text-sm font-medium text-red-800">
                    اسم التصنيف مضاف مسبقاً
                  </h3>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <label class="block text-right text-sm font-medium text-gray-700"
                >لون التصنيف</label
              >
              <div class="mt-4 grid grid-cols-6 gap-y-4 items-center">
                <div
                  v-for="(color, index) in getColors"
                  :key="index"
                  @click="selectColor(color)"
                >
                  <div
                    class="relative rounded-full flex items-center justify-center cursor-pointer focus:outline-none"
                  >
                    <div
                      aria-hidden="true"
                      :class="[
                        getSelectedColor == color
                          ? 'border-4 border-gray-800/80'
                          : 'shadow-md',
                        `${color.bg_class_color} h-8 w-8 rounded-full`,
                      ]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          dir="ltr"
          class="py-3 px-4 sm:grid sm:grid-cols-3 sm:gap-1 sm:grid-flow-row-dense border-t border-gray-300"
        >
          <button
            @click="closeModal()"
            class="sm:col-span-1 mr-px py-3 px-4 text-lg font-semibold text-gray-500 focus:outline-none"
          >
            إلغاء
          </button>
          <button
            v-if="requiredData && this.checkData"
            @click="saveCategory()"
            class="sm:col-span-2 ml-px bg-indigo-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-lg font-semibold text-white hover:bg-indigo-700 focus:outline-none"
          >
            حفظ
          </button>
          <button
            v-if="!requiredData || !this.checkData"
            class="sm:col-span-2 ml-px bg-gray-200 border border-transparent rounded-md shadow-sm py-3 px-4 text-lg font-light text-gray-400"
          >
            حفظ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IonInput } from "@ionic/vue";
export default {
  data() {
    return {
      categoryName: "",
      selectedColor: "",
      colors: [
        {
          txt_class_color: "text-green-500",
          bg_class_color: "bg-green-500",
          shadow_class_color: "shadow-green-500/10",
        },
        {
          txt_class_color: "text-indigo-500",
          bg_class_color: "bg-indigo-500",
          shadow_class_color: "shadow-indigo-500/10",
        },
        {
          txt_class_color: "text-pink-500",
          bg_class_color: "bg-pink-500",
          shadow_class_color: "shadow-pink-500/10",
        },
        {
          txt_class_color: "text-yellow-500",
          bg_class_color: "bg-yellow-500",
          shadow_class_color: "shadow-yellow-500/10",
        },
        {
          txt_class_color: "text-blue-500",
          bg_class_color: "bg-blue-500",
          shadow_class_color: "shadow-blue-500/10",
        },
        {
          txt_class_color: "text-orange-500",
          bg_class_color: "bg-orange-500",
          shadow_class_color: "shadow-orange-500/10",
        },
        {
          txt_class_color: "text-purple-500",
          bg_class_color: "bg-purple-500",
          shadow_class_color: "shadow-purple-500/10",
        },
        {
          txt_class_color: "text-rose-500",
          bg_class_color: "bg-rose-500",
          shadow_class_color: "shadow-rose-500/10",
        },
        {
          txt_class_color: "text-lime-500",
          bg_class_color: "bg-lime-500",
          shadow_class_color: "shadow-lime-500/10",
        },
        {
          txt_class_color: "text-emerald-500",
          bg_class_color: "bg-emerald-500",
          shadow_class_color: "shadow-emerald-500/10",
        },
        {
          txt_class_color: "text-cyan-500",
          bg_class_color: "bg-cyan-500",
          shadow_class_color: "shadow-cyan-500/10",
        },
        {
          txt_class_color: "text-fuchsia-500",
          bg_class_color: "bg-fuchsia-500",
          shadow_class_color: "shadow-fuchsia-500/10",
        },
      ],
    };
  },
  components: {
    IonInput,
  },
  methods: {
    selectColor(color) {
      this.selectedColor = color;
    },
    async saveCategory() {
      if (this.checkData) {
        const payload = {
          categoryName: this.categoryName,
          color: JSON.stringify(this.selectedColor),
        };
        await this.$store.dispatch("saveNewCategory", payload);
        this.$store.commit("toggleNewCategoryModal", false);
      } else {
        return;
      }
    },
    closeModal() {
      this.$store.commit("toggleNewCategoryModal", false);
    },
  },
  computed: {
    getCategoriesList() {
      return this.$store.getters.getCategoriesList;
    },
    getSelectedColor() {
      return this.selectedColor;
    },
    getColors() {
      //let availableColors = []
      let unavailableColors = [];
      this.$store.getters.getCategoriesList.forEach((element) => {
        return unavailableColors.push(element.color_class);
      });
      let availableColors = this.colors.filter(
        (color) => !unavailableColors.includes(color)
      );
      return availableColors;
    },
    requiredData() {
      if (this.categoryName !== "") {
        return true;
      }
      return false;
    },
    checkData() {
      let category = this.$store.getters.getCategoriesList;
      if (
        category.some((category) => category.category === this.categoryName)
      ) {
        return false;
      }
      return true;
    },
  },
  async created() {
    await this.$store.dispatch("getCategories");
  },
};
</script>
