<template>
  <div
    dir="rtl"
    id="allBody"
    as="div"
    class="relative z-50"
    v-if="this.callData.length > 0"
  >
    <div
      class="animate-pulse fixed inset-0 bg-rose-700/50 transition-opacity"
    />

    <div class="fixed inset-0 z-20 w-screen overflow-y-auto">
      <div
        class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
      >
        <div
          class="mx-auto w-[40rem] h-[40rem] transform divide-y divide-gray-500 divide-opacity-10 overflow-hidden rounded-xl bg-white bg-opacity-50 shadow-2xl ring-1 ring-black ring-opacity-5 backdrop-blur backdrop-filter transition-all"
        >
          <div class="p-12" id="allBody">
            <div class="mx-auto flex h-12 w-12 items-center justify-center">
              <BaseIcon
                name="MegaPhone-D"
                class="h-24 w-24 text-red-600"
                strokeWidth="0.1"
                aria-hidden="true"
              />
            </div>
            <div class="mt-3 text-center sm:mt-5">
              <div as="h3" class="text-2xl font-bold leading-6 text-gray-600">
                طلب سمو الوزير
              </div>
              <div class="mt-2">
                <ul
                  role="list"
                  class="flex flex-col items-center gap-y-3 w-full h-[25rem] overflow-y-auto"
                >
                  <li
                    v-for="(call, idx) in callData"
                    :key="idx"
                    class="flex justify-between w-full gap-x-6 bg-white shadow bg-opacity-30 py-5 px-4 rounded-lg border border-slate-400"
                  >
                    <div class="flex items-center min-w-0 gap-x-4">
                      <div
                        class="flex items-center justify-center bg-rose-200 rounded-full w-10 h-10"
                      >
                        <BaseIcon
                          name="MegaPhone"
                          strokeWidth="0.1"
                          class="h-6 w-6 text-rose-700"
                        />
                      </div>
                      <div class="min-w-0 flex-auto">
                        <span
                          class="before:block before:absolute before:-inset-1 before:-skew-y-3 before:bg-rose-700 relative inline-block"
                        >
                          <span class="relative text-white text-xl fonr-bold">{{
                            call.element !== "null" ? call.element : "طلب حضور"
                          }}</span>
                        </span>
                      </div>
                    </div>
                    <div
                      class="hidden shrink-0 sm:flex sm:flex-col sm:items-end"
                    >
                      <p class="text-sm leading-6 text-gray-900">
                        {{ moment(call.created_at).format("YYYY-MM-DD") }}
                      </p>
                      <p class="text-sm">
                        {{ JSON.parse(call.call_time).minute }}
                        :{{ JSON.parse(call.call_time).hour }}
                        {{ JSON.parse(call.call_time).apm }}
                      </p>
                      <div class="mt-1 text-xs leading-5 text-gray-500">
                        <div>
                          {{
                            moment(call.created_at).from(this.getCurrentTime)
                          }}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="absolute bottom-0 left-0 w-full flex items-center justify-center py-8"
            >
              <div
                @click="resonseCall()"
                class="flex items-center gap-x-3 px-4 py-2 shadow-md rounded-lg bg-white/50 font-bold text-2xl text-green-800 active:bg-green-50"
              >
                <BaseIcon
                  name="CheckCircle-D"
                  className="w-7 h-7"
                  strokeWidth="0.2"
                />
                <span class="mt-2">إستجابة</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-hijri";
moment.updateLocale("ar-sa", {
  postformat: (string) => string,
});
import BaseIcon from "../components/BaseIcon.vue";
export default {
  data() {
    return {
      moment: moment,
      interval: null,
      currentTime: moment(new Date()),
    };
  },
  props: ["callData"],
  components: {
    BaseIcon,
  },
  methods: {
    updateCurrentTime() {
      this.currentTime = moment(new Date());
    },
    resonseCall() {
      this.$store.dispatch("updateCall", this.callData[0].group_num);
    },
  },
  computed: {
    getCurrentTime() {
      return this.currentTime;
    },
  },
  created() {
    this.interval = setInterval(() => this.updateCurrentTime(), 30000);
  },
  mounted() {
    /*var audioFrame = document.getElementById("audioFrame");
    const click_event = new CustomEvent("click");

    setTimeout(() => {
      audioFrame.setAttribute(
        "src",
        "http://64.23.186.36/assets/sounds/notification.mp3"
      );
    }, 5000);*/
    /* var ifr = document.createElement("iframe");
    ifr.setAttribute(
      "src",
      "http://64.23.186.36/assets/sounds/notification.mp3"
    );
    ifr.setAttribute("allow", "autoplay");
    ifr.setAttribute("width", "1px");
    ifr.setAttribute("height", "1px");
    ifr.setAttribute("scrolling", "no");
    ifr.style.border = "0px";
    document.body.appendChild(ifr);
    ifr.click();*/
    this.interval = setInterval(() => this.updateCurrentTime(), 30000);
  },
  beforeUnmount() {},
  async beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
