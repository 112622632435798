<template>
  <ion-content>
    <ion-list>
      <ion-item lines="full">
        <div
          class="w-full text-right float-right my-2 border-r-4 border-green-900 bg-green-50 px-1"
        >
          <p class="py-3">{{ chatText }}</p>
        </div>
      </ion-item>
      <ion-item
        @click="replyText()"
        :button="true"
        detail="false"
        :lines="canCopy ? 'full' : 'none'"
      >
        <ion-label>
          <BaseIcon name="Reply" class="w-5 h-5" strokeWidth="0.5" />
        </ion-label>
        <ion-note slot="end">
          <span class="!text-gray-900 font-bold">الرد</span>
        </ion-note>
      </ion-item>
      <ion-item
        v-if="canCopy"
        @click="copyText()"
        :button="true"
        detail="false"
        lines="none"
      >
        <ion-label>
          <BaseIcon name="Copy" class="w-5 h-5" strokeWidth="0.5" />
        </ion-label>
        <ion-note slot="end">
          <span class="!text-gray-900 font-bold">نسخ</span>
        </ion-note>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import BaseIcon from "../components/BaseIcon.vue";
import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonNote,
  popoverController,
} from "@ionic/vue";

export default {
  name: "Popover",
  props: ["chatText"],
  components: { BaseIcon, IonContent, IonList, IonItem, IonLabel, IonNote },
  methods: {
    async replyText() {
      await popoverController.dismiss("startReply");
    },
    copyText() {
      if (!navigator.clipboard) {
        alert("Error");
        return;
      }
      navigator.clipboard.writeText(this.chatText).then(
        async function () {
          await popoverController.dismiss("textCopied");
        },
        function (err) {
          alert("Async: Could not copy text: ", err);
        }
      );
    },
  },
  computed: {
    canCopy() {
      if (!navigator.clipboard) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
<style scoped>
ion-label strong {
  display: block;

  max-width: calc(100% - 60px);

  overflow: hidden;

  text-overflow: ellipsis;
}
</style>
