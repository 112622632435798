<template>
  <ion-header>
    <ion-toolbar style="padding-top: 20px">
      <button
        slot="start"
        @click="closeModal()"
        class="
          sm:col-span-1
          mr-px
          py-3
          px-4
          text-lg
          font-semibold
          text-rose-700
          focus:outline-none
        "
      >
        إلغاء
      </button>
      <ion-title>
        <h3 class="text-2xl leading-6 font-semibold text-gray-900">
          إضافة جهة
        </h3>
      </ion-title>
      <button
        slot="end"
        v-if="requiredData && this.checkData"
        @click="saveOffice()"
        class="
          sm:col-span-2
          ml-px
          bg-indigo-600
          border border-transparent
          rounded-md
          shadow-sm
          py-3
          px-4
          text-lg
          font-semibold
          text-white
          hover:bg-indigo-700
          focus:outline-none
        "
      >
        حفظ
      </button>
      <button
        slot="end"
        v-if="!requiredData || !this.checkData"
        class="sm:col-span-2 ml-px py-3 px-4 text-lg font-light text-gray-400"
      >
        حفظ
      </button>
    </ion-toolbar>
  </ion-header>
  <ion-content fullscreen>
    <div class="mt-2 grid px-8 py-6 grid-cols-1 gap-y-4 gap-x-4 bg-gray-50">
      <div class="sm:col-span-3 text-right">
        <label
          for="office-name"
          class="block text-sm font-medium text-gray-700"
        >
          الاسم
        </label>
        <div class="mt-1">
          <ion-input
            type="text"
            name="office-name"
            id="office-name"
            autocomplete="off"
            v-model="this.officeName"
            class="shadow-sm block w-full sm:text-sm rounded-md"
            :class="
              !this.checkData
                ? 'text-red-700 border border-red-700 focus:ring-red-700 focus:border-red-700'
                : 'text-indigo-700 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
            "
            style="
              display: block;
              width: 100%;
              --padding-top: 6px;
              --padding-start: 6px;
              --padding-end: 6px;
              --padding-bottom: 6px;
              border-width: 1px;
              --tw-border-opacity: 1;
              border-color: rgba(209, 213, 219, var(--tw-border-opacity));
              --background: white;
              --color: rgb(99, 102, 241);
              outline: 2px solid transparent;
              outline-offset: 2px;
            "
          ></ion-input>
        </div>
      </div>
      <span v-if="!this.checkData" class="text-center text-red-700"
        >اسم الجهة مضاف مسبقاً</span
      >
    </div>
  </ion-content>
</template>

<script>
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonInput,
  modalController,
} from "@ionic/vue";
export default {
  data() {
    return {
      officeName: "",
    };
  },
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonInput,
  },
  methods: {
    async saveOffice() {
      if (this.checkData) {
        const payload = {
          officeName: this.officeName,
        };
        await this.$store.dispatch("saveNewOffice", payload);
        await modalController.dismiss();
      } else {
        return;
      }
    },
    async closeModal() {
      await modalController.dismiss();
    },
  },
  computed: {
    modalStatus() {
      return this.$store.getters.getNewOfficeModalStatus;
    },
    requiredData() {
      if (this.officeName !== "") {
        return true;
      }
      return false;
    },
    getOffice() {
      return this.$store.getters.getOfficeList;
    },
    checkData() {
      let office = this.$store.getters.getOfficesList;
      if (office.some((office) => office.office === this.officeName)) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.$store.dispatch("getOffices");
  },
};
</script>

<style scoped>
ion-content {
  --background: rgb(249, 250, 251);
}
</style>