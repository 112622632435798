<template>
  <div
    dir="rtl"
    class="fixed z-50 inset-0 overflow-hidden bg-gray-500 bg-opacity-75 transition-opacity"
  >
    <div
      class="flex items-end justify-center min-h-screen pb-20 text-center sm:block sm:p-0"
    >
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-x-hidden overflow-y-auto shadow-xl transform transition-all sm:my-8 sm:align-middle max-h-[85vh] w-[40rem]"
      >
        <div
          class="flex justify-start items-center sticky text-slate-800 top-0 z-40 py-4 px-4 max-h-16 bg-white border-b border-gray-200"
        >
          <div class="flex items-center gap-x-2 justify-start py-3 px-3">
            <BaseIcon name="Users-D" className="w-7 h-7" strokeWidth="0.1" />
            <p class="text-2xl leading-6 font-semibold">إضافة مجموعة</p>
          </div>
        </div>
        <div
          class="grid px-12 py-6 grid-cols-1 gap-y-4 gap-x-4 bg-gray-50 h-full overflow-x-hidden overflow-y-auto"
        >
          <div class="sm:col-span-3 text-right">
            <label
              for="group-name"
              class="block text-sm font-medium text-gray-700"
            >
              اسم المجموعة
            </label>
            <div class="mt-1">
              <ion-input
                type="text"
                name="group-name"
                id="group-name"
                autocomplete="off"
                v-model="this.groupName"
                :class="
                  !this.checkData
                    ? 'text-red-700 border border-red-700 focus:ring-red-700 focus:border-red-700'
                    : 'text-indigo-700 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                "
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md text-indigo-700"
                style="
                  display: block;
                  width: 100%;
                  --padding-top: 6px;
                  --padding-start: 6px;
                  --padding-end: 6px;
                  --padding-bottom: 6px;
                  border-width: 1px;
                  --tw-border-opacity: 1;
                  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
                  --background: white;
                  --color: rgb(99, 102, 241);
                  outline: 2px solid transparent;
                  outline-offset: 2px;
                "
              ></ion-input>
            </div>
          </div>
          <div
            v-if="!this.checkData"
            class="w-full rounded-md bg-rose-50 px-3 pb-2"
          >
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <svg
                  class="h-5 w-5 text-rose-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <div class="mr-3">
                <h3 class="text-sm font-medium text-red-800">
                  اسم المجموعة مضاف مسبقاً
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div
          dir="ltr"
          class="flex sticky bottom-0 z-40 py-2 justify-between bg-gray-50 px-4 sm:px-6 border-t border-gray-200"
        >
          <button
            @click="closeModal()"
            class="sm:col-span-1 mr-px py-3 px-4 text-lg font-semibold text-gray-500 focus:outline-none"
          >
            إلغاء
          </button>
          <button
            v-if="requiredData && checkData"
            @click="saveGroup()"
            class="sm:col-span-2 ml-px bg-indigo-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-lg font-semibold text-white hover:bg-indigo-700 focus:outline-none"
          >
            حفظ
          </button>
          <button
            v-if="!requiredData || !checkData"
            class="sm:col-span-2 ml-px bg-gray-200 border border-transparent rounded-md shadow-sm py-3 px-4 text-lg font-light text-gray-400"
          >
            حفظ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from "../components/BaseIcon.vue";
import { IonInput } from "@ionic/vue";
export default {
  data() {
    return {
      groupName: "",
    };
  },
  components: {
    BaseIcon,
    IonInput,
  },
  methods: {
    async saveGroup() {
      if (this.checkData) {
        const payload = {
          groupName: this.groupName,
        };
        await this.$store.dispatch("saveNewGroup", payload);
        this.closeModal();
      } else {
        return;
      }
    },
    async closeModal() {
      await this.$store.commit("toggleNewGroupModal", false);
    },
  },
  computed: {
    requiredData() {
      if (this.groupName !== "") {
        return true;
      }
      return false;
    },
    getGroups() {
      return this.$store.getters.getGroups;
    },
    checkData() {
      let groups = this.$store.getters.getGroups;
      if (groups.some((group) => group.group === this.groupName)) {
        return false;
      }
      return true;
    },
  },
  created() {
    this.$store.dispatch("getGroups");
  },
};
</script>
