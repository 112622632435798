<template>
  <div class="h-full">
    <header class="bg-slate-100">
      <nav
        class="mx-auto flex w-full items-center justify-between p-2.5 lg:px-8"
        aria-label="Global"
      >
        <div class="flex-1">
          <BaseIcon @click="closeViewer()" name="XIcon" class="w-5 h-5" />
        </div>
        <div
          class="w-full flex items-center justify-center text-lg mt-1.5 font-bold"
        >
          {{ props.fileName }}
        </div>
      </nav>
    </header>
    <div class="h-full overflow-y-auto">
      <div v-if="loading" class="loading-overlay">
        <div class="loader"></div>
        <div class="progress-text">{{ loadingProgress }}%</div>
      </div>
      <div v-else>
        <div v-for="page in pages" :key="page">
          <VuePDF @load="onLoad" fit-parent :pdf="pdf" :page="page" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseIcon from "../components/BaseIcon.vue";
import { ref, onMounted } from "vue";
import { VuePDF, usePDF } from "@tato30/vue-pdf";
import { modalController, loadingController } from "@ionic/vue";

const props = defineProps(["fileName", "filePath", "folder"]);
const loading = ref(true);
const loadingProgress = ref(0);

const { pdf, pages, info } = usePDF(
  `http://46.101.194.80:3000/${props.folder}/${props.filePath}`,
  {
    onProgress: (progress) => {
      loadingProgress.value = Math.round(
        (progress.loaded / progress.total) * 100
      );
    },
  }
);

const showLoading = async () => {
  const loading = await loadingController.create({
    message: "جار فتح الملف",
    spinner: "crescent",
  });
  await loading.present();
  return loading;
};

const onLoad = () => {
  loading.value = false;
};

const closeViewer = async () => {
  await modalController.dismiss();
};

onMounted(async () => {
  const loadingController = await showLoading();

  // Start a timer to update progress even if the actual loading is stuck
  const progressInterval = setInterval(() => {
    if (loadingProgress.value < 90) {
      loadingProgress.value += 1;
    }
  }, 200);

  // Wait for the PDF to load
  await pdf.value;

  clearInterval(progressInterval);
  loadingProgress.value = 100;

  await new Promise((resolve) => setTimeout(resolve, 500)); // Give a moment to show 100%
  loadingController.dismiss();
  loading.value = false;
});
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.progress-text {
  color: white;
  font-size: 18px;
  margin-top: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>