<template>
  <ion-content>
    <div
      class="w-full h-full overflow-auto relative"
      :class="
        this.getCurrentTimeForHeight == 0
          ? 'mb-24 sm:mb-12'
          : 'mt-44 mb-24 sm:mb-12'
      "
      id="content"
      ref="content"
    >
      <div
        v-if="sameDate()"
        class="w-screen z-30 absolute flex flex-row items-center p-0 m-0"
        id="tic_toc"
        ref="tic_toc"
      >
        <div
          class="pt-px shadow shadow-blue-500/80 w-10 text-[13px] bg-blue-600 text-white text-center"
        >
          <span>{{ getRealTime }}</span>
        </div>
        <div class="triangle"></div>
        <div class="w-full h-[1px] shadow shadow-blue-500/80 bg-blue-600"></div>
      </div>
      <div
        class="flex relative flex-row mb-[64.4px] w-screen"
        v-for="i in dayTimes"
        :key="i"
      >
        <div
          class="right-1 w-screen overflow-x-auto overflow-y-hidden h-8 pt-2"
        >
          <span
            class="w-full h-[12px] text-[13] p-0 m-0 flex flex-row items-center text-gray-600"
          >
            <div
              style="width: 3.3rem"
              class="text-[13px] text-center text-gray-500"
            >
              {{ i.name }}:00 {{ i.apm }}
            </div>
            <div class="w-full h-[1px] bg-gray-300/60"></div>
          </span>

          <div
            v-for="(event, index) in checkDayEvents(i)"
            :key="event.id"
            @click="showEventInfo(event)"
            :class="[
              event.status == 'unavailable' ? 'canceldBG' : '',
              endEvent(JSON.parse(event.time), event.duration, event.en_date)
                ? 'bg-gray-100'
                : 'bg-white',
            ]"
            class="absolute mr-12 z-20 shadow-md rounded-lg p-2 w-40"
            :style="`top: ${getStartTime(
              JSON.parse(event.time).minute
            )}; height: ${getDuration(event.duration)}; right: ${getRight(
              index
            )};`"
          >
            <div
              class="flex flex-col"
              v-if="
                JSON.parse(event.time).hour == i.name &&
                JSON.parse(event.time).apm == i.apm
              "
            >
              <div
                class="h-[2px] rounded-full -mt-2"
                :class="[
                  event.status == 'unavailable'
                    ? 'bg-rose-800 bg-opacity-30'
                    : '',
                  endEvent(
                    JSON.parse(event.time),
                    event.duration,
                    event.en_date
                  )
                    ? 'bg-gray-300'
                    : `${JSON.parse(event.color).bg_class_color}`,
                ]"
              ></div>
              <div
                class="absolute flex flex-row items-center font-light text-xs px-px -top-4 left-3 bg-gray-300 bg-opacity-20 backdrop-filter backdrop-blur rounded-lg"
                :class="
                  endEvent(
                    JSON.parse(event.time),
                    event.duration,
                    event.en_date
                  )
                    ? 'text-gray-500'
                    : `${JSON.parse(event.color).txt_class_color}`
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-[1.1rem] w-[1.1rem]"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="1.2"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <circle cx="12" cy="13" r="7" />
                  <polyline points="12 10 12 13 14 13" />
                  <line x1="7" y1="4" x2="4.25" y2="6" />
                  <line x1="17" y1="4" x2="19.75" y2="6" />
                </svg>
                <div class="flex mt-1">
                  <span class="font-light">{{
                    `${JSON.parse(event.time).hour}:${
                      JSON.parse(event.time).minute
                    } ${JSON.parse(event.time).apm}`
                  }}</span>
                  <span class="mx-1">-</span>
                  <span class="font-light">{{
                    `${getToTime(JSON.parse(event.time), event.duration)}`
                  }}</span>
                </div>
              </div>
              <div
                class="text-sm mt-1 font-semibold"
                :class="[
                  endEvent(
                    JSON.parse(event.time),
                    event.duration,
                    event.en_date
                  )
                    ? 'text-gray-500'
                    : `${JSON.parse(event.color).txt_class_color}`,
                  event.status == 'unavailable'
                    ? 'line-through text-red-900'
                    : '',
                ]"
              >
                {{ event.title }}
              </div>
            </div>
          </div>
          <template v-if="getUserInfo.privilege !== '4'">
            <div
              v-for="(guest, index) in checkDayGuests(i)"
              :key="index"
              @click="showGuestInfo(guest)"
              class="absolute mr-12 mt-1 z-10 shadow-md rounded-lg p-2 w-40"
              :class="
                guest.status == 'approved'
                  ? 'bg-indigo-50 bg-opacity-80'
                  : 'bg-rose-50'
              "
              :style="`top: ${getStartTime(
                JSON.parse(guest.time).minute
              )}; right: ${getRight(index + 1)};`"
            >
              <div
                class="flex flex-col"
                v-if="
                  JSON.parse(guest.time).hour == i.name &&
                  JSON.parse(guest.time).apm == i.apm
                "
              >
                <div
                  class="flex flex-row"
                  :class="
                    guest.status == 'approved'
                      ? 'text-indigo-700'
                      : 'text-rose-700'
                  "
                >
                  <div v-if="guest.status == 'approved'">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-[1.1rem] w-[1.1rem]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="1.2"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="9" cy="7" r="4" />
                      <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                      <path d="M16 11l2 2l4 -4" />
                    </svg>
                  </div>
                  <div v-if="guest.status == 'denied'">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-[1.1rem] w-[1.1rem]"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="1.2"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="9" cy="7" r="4" />
                      <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                      <path d="M17 9l4 4m0 -4l-4 4" />
                    </svg>
                  </div>
                  <div>
                    <p class="text-xs mr-1">
                      {{ JSON.parse(guest.person)[0].name }}
                    </p>
                  </div>
                </div>
                <p class="text-sm text-gray-400 mr-4 mt-1">{{ guest.title }}</p>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </ion-content>
</template>

<script>
import moment from "moment-hijri";
import QuickEventInfo from "./QuickEventInfo.vue";
import QuickGuestInfo from "./QuickGuestInfo.vue";

import { IonContent, modalController } from "@ionic/vue";

export default {
  data() {
    return {
      currentTime: moment().format("hh:mm A"),
      currentTimeForHeight: moment().format("HH"),
      interval: null,
      currentIdOfElement: "",
      dayTimes: [
        { name: 12, apm: "ص" },
        { name: 1, apm: "ص" },
        { name: 2, apm: "ص" },
        { name: 3, apm: "ص" },
        { name: 4, apm: "ص" },
        { name: 5, apm: "ص" },
        { name: 6, apm: "ص" },
        { name: 7, apm: "ص" },
        { name: 8, apm: "ص" },
        { name: 9, apm: "ص" },
        { name: 10, apm: "ص" },
        { name: 11, apm: "ص" },
        { name: 12, apm: "م" },
        { name: 1, apm: "م" },
        { name: 2, apm: "م" },
        { name: 3, apm: "م" },
        { name: 4, apm: "م" },
        { name: 5, apm: "م" },
        { name: 6, apm: "م" },
        { name: 7, apm: "م" },
        { name: 8, apm: "م" },
        { name: 9, apm: "م" },
        { name: 10, apm: "م" },
        { name: 11, apm: "م" },
      ],
      scrollUpTime: false,
    };
  },
  components: {
    IonContent,
    QuickEventInfo,
    QuickGuestInfo,
  },
  props: ["currentDay"],
  methods: {
    async showEventInfo(event) {
      const modal = await modalController.create({
        component: QuickEventInfo,
        componentProps: { event },
        initialBreakpoint: 0.7,
        breakpoints: [0.7, 0.8],
      });
      await modal.present();
    },
    async showGuestInfo(guest) {
      const modal = await modalController.create({
        component: QuickGuestInfo,
        componentProps: { guest },
        initialBreakpoint: 0.4,
        breakpoints: [0.4, 0.5],
      });
      await modal.present();
    },
    updateCurrentTime() {
      const currentDate = moment().format("YYYY/M/D");
      const clickedDate = moment(this.currentDay).format("YYYY/M/D");
      //console.log(currentDate)
      //console.log(clickedDate)
      if (currentDate == clickedDate) {
        this.currentTime = moment().format("h:mm A");

        var minute = moment().format("mm");
        var hour = parseInt(moment().format("H")) * 60;
        //hour =
        var total = (hour + parseInt(minute)) / 10;

        var clock = document.getElementById("tic_toc");
        //console.log('UPDATE POSITION --> ' + hour + minute + 'rem')
        if (typeof clock !== "undefined" && clock !== null) {
          clock.style.top = total + "rem";
        }
      } else {
        if (this.scrollUpTime == false) {
          var element = document.getElementById("topOfPage");
          var element2 = document.getElementById("topOfTopPage");
          if (
            typeof element !== "undefined" &&
            element2 !== "undefined" &&
            element2 !== null &&
            element !== null
          ) {
            element2.setAttribute("style", "margin-top: 0px;");
            element.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center",
            });
            this.scrollUpTime = true;
          }
        }
      }
    },
    sameDate() {
      const currentDate = moment().format("YYYY/M/D");
      const clickedDate = moment(this.currentDay).format("YYYY/M/D");
      if (currentDate == clickedDate) {
        return true;
      } else {
        return false;
      }
    },
    checkEventTime(t) {
      var tt = moment(t, "hh:mm").format("h");
      return tt;
    },
    checkEventAPM(t) {
      var tapm = moment(t, "hh:mm").format("A");
      return tapm;
    },
    checkDayEvents(v) {
      return this.dayEvents.filter((event) => {
        return (
          JSON.parse(event.time).hour == v.name &&
          JSON.parse(event.time).apm == v.apm
        );
      });
    },
    checkDayGuests(v) {
      return this.dayGuests.filter((guest) => {
        return (
          JSON.parse(guest.time).hour == v.name &&
          JSON.parse(guest.time).apm == v.apm &&
          guest.status != "waiting"
        );
      });
    },
    endEvent(time, duration, date) {
      const currentDate = moment().format("YYYY/M/D");
      const currentDateTime = moment().format("YYYY/M/D HH:mm");
      const eventDateTime = moment(
        date + " " + time.hour + ":" + time.minute + " " + time.apm,
        ["YYYY/MM/D hh:mm A"]
      )
        .add(duration, "minutes")
        .format("YYYY/M/D HH:mm");
      if (date == currentDate) {
        if (eventDateTime >= currentDateTime) {
          return false;
        } else {
          return true;
        }
      } else if (date < currentDate) {
        return true;
      } else if (date > currentDate) {
        return false;
      }
    },
    getDuration(v) {
      let vv = v / 8; // was 9
      return vv + "rem";
    },
    getRight(v) {
      let vv = v * 200;
      return vv + "px";
    },
    getStartTime(v) {
      let vv = parseInt(v) / 10;
      return vv + "rem";
    },
    getToTime(time, duration) {
      const eventDateTime = moment(
        time.hour + ":" + time.minute + " " + time.apm,
        ["hh:mm A"]
      )
        .add(duration, "minutes")
        .format("hh:mm A");
      return eventDateTime;
    },
    getTicToc(time) {
      if (this.sameDate) {
        let clock =
          moment(time.hour + ":" + time.minute + " " + time.apm, [
            "hh:mm A",
          ]).format("H") +
          "." +
          moment(time.hour + ":" + time.minute + " " + time.apm, [
            "hh:mm A",
          ]).format("MM");
        return clock + "rem";
      }
    },
  },
  computed: {
    getUserInfo() {
      if (localStorage.getItem("awqatiAccess_token")) {
        let info = {
          name: localStorage.getItem("awqatiUser"),
          position: localStorage.getItem("awqatiUserPosition"),
          privilege: localStorage.getItem("awqatiUserPrivilege"),
        };
        return info;
      } else {
        return this.$router.push("/");
      }
    },
    getCurrentTimeForHeight() {
      return this.currentTimeForHeight;
    },
    getRealTime() {
      return this.currentTime;
    },
    getTime() {
      let minute = moment(this.currentTime, "h:mm A").format("mm");
      let hour = moment(this.currentTime, "h:mm A").format("hh");
      let apm = moment(this.currentTime, "h:mm A").format("A");
      return { m: minute, h: hour, apm: apm };
    },
    selectedDay() {
      var currentDate = moment(this.currentDay);
      return currentDate.format("dddd [-] iYYYY/iM/iD [هـ]");
    },
    compareDate() {
      var currentDate = moment(this.currentDay);
      return currentDate.format("iYYYY/iM/iD");
    },
    dayEvents() {
      return this.$store.getters.getDayEventsList;
    },
    dayGuests() {
      return this.$store.getters.getGuestsOfDay;
    },
    currentView() {
      return this.$store.getters.getCurrentView;
    },
  },
  created() {
    this.interval = setInterval(() => this.updateCurrentTime(), 1000);

    this.$socket.on("syncEventsOfDay", (data) => {
      this.$store.dispatch("getEventsForCommingUp", {
        group: parseInt(localStorage.getItem("awqatiUserGroup")),
      });
      if (this.currentView == "day") {
        if (
          moment(this.currentDay).format("iYYYY/iM/iD") == data.dayy &&
          parseInt(localStorage.getItem("awqatiUserGroup")) == data.group
        ) {
          this.$store.dispatch(data.command, {
            date: data.dayy,
            group: data.group,
          });
        } else {
          console.log("IT IS NOT IN SAME DAY!");
        }
      }
    });

    this.$socket.on("syncGuestsOfDay", (data) => {
      if (this.currentView == "day") {
        if (
          moment(this.currentDay).format("iYYYY/iM/iD") == data.dayy &&
          parseInt(localStorage.getItem("awqatiUserGroup")) == data.group
        ) {
          /*this.$store.dispatch("getEventsForCommingUp", {
            group: parseInt(localStorage.getItem("awqatiUserGroup")),
          });*/
          this.$store.dispatch(data.command, {
            date: data.dayy,
            group: data.group,
          });
        } else {
          console.log("IT IS NOT IN SAME DAY!");
        }
      }
    });
  },
  updated() {
    if (this.$refs.tic_toc) {
      this.$refs.tic_toc.scrollIntoView({
        top: -200,
        behavior: "smooth",
        block: "center",
      });
    }
  },
  mounted() {
    //var element = document.querySelector("#containerDiv");
    var element = document.getElementById(`${this.getRealTime}`);
    if (typeof element !== "undefined" && element !== null) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
    //myDiv.innerHTML = variableLongText;
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
ion-content {
  --offset-bottom: auto !important;
  --overflow: hidden;
  height: 100vh;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='%23f1f5f9'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e");
}

ion-modal::part(backdrop) {
  background: rgba(209, 213, 219);
  opacity: 1;
}

.size {
  width: 14.286%;
}

.triangle {
  width: 0;
  height: 5px;
  border-width: 10px 11px 10px 2px;
  border-color: transparent rgb(37, 99, 235) transparent transparent;
  border-style: solid;
}
</style>
