<template>
  <ion-header dir="rtl">
    <Transition name="slide-left" mode="out-in">
      <ion-toolbar v-if="!getNewPerson" style="padding-top: 20px !important">
        <ion-searchbar
          :debounce="1000"
          dir="rtl"
          placeholder="ابحث ..."
          autocomplete="off"
          v-model="this.personSearch"
        ></ion-searchbar>
        <button
          slot="end"
          @click="openNewPerson()"
          class="text-center pl-2 mr-4 mt-2"
        >
          <BaseIcon
            name="PlusCircle"
            className="h-6 w-6 text-indigo-700"
            strokeWidth="0.1"
            aria-hidden="true"
          />
        </button>
      </ion-toolbar>
      <ion-toolbar
        v-else-if="getNewPerson"
        style="padding-top: 20px !important"
      >
        <button
          v-if="!requiredData && !getIsSaving"
          slot="start"
          class="text-center font-bold text-slate-300 pl-2 mt-2"
        >
          حفظ
        </button>
        <button
          v-if="requiredData && !getIsSaving"
          slot="start"
          @click="savePerson()"
          class="text-center font-bold text-slate-800 pl-2 mt-2"
        >
          حفظ
        </button>
        <svg
          v-if="getIsSaving"
          class="animate-spin -ml-1 mr-3 h-4 w-4 text-blue-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <ion-title>إضافة شخص جديد</ion-title>
        <button
          slot="end"
          v-if="!getIsSaving"
          @click="cancelNewPerson()"
          class="text-center font-bold text-rose-700 pl-2 mt-2"
        >
          إلغاء
        </button>
        <button
          v-if="getIsSaving"
          slot="end"
          class="text-center font-bold text-slate-300 pl-2 mt-2"
        >
          إلغاء
        </button>
      </ion-toolbar>
    </Transition>
  </ion-header>
  <ion-content>
    <Transition name="slide-left" mode="out-in">
      <div
        v-if="!getNewPerson"
        dir="rtl"
        class="relative bg-white h-80 overflow-auto -my-px"
      >
        <div class="relative" v-for="office in offices" :key="office">
          <div
            class="sticky top-0 px-4 py-2 flex items-center font-light text-sm text-gray-900 bg-gray-50/90 backdrop-blur-sm ring-1 ring-gray-900/10"
          >
            {{ office }}
          </div>
          <div class="divide-y">
            <div
              @click="selectPerson(person)"
              v-for="person in getPersonList"
              :key="person"
              class="active:bg-indigo-300"
            >
              <div
                class="flex items-center gap-3 p-3"
                v-if="JSON.parse(person.office).office == office"
              >
                <BaseIcon
                  name="UserSquare"
                  className="w-7 h-7 text-gray-500"
                  strokeWidth="0.1"
                />
                <div class="flex flex-col space-x-2">
                  <strong class="text-gray-900 text-sm font-semibold">{{
                    person.name
                  }}</strong>
                  <span class="text-gray-600 text-sm">
                    {{ person.position }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="getNewPerson"
        dir="rtl"
        class="relative bg-white h-80 overflow-auto -my-px"
      >
        <ion-list :inset="true">
          <ion-item>
            <ion-label>الاسم</ion-label>
            <input
              type="text"
              name="person-name"
              id="person-name"
              v-model="this.personName"
              autocomplete="off"
              class="block w-[70%] bg-slate-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none px-1 focus:border focus:border-indigo-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
          </ion-item>
          <ion-item>
            <ion-select
              dir="rtl"
              justify="center"
              label="الجهة"
              interface="action-sheet"
              placeholder="اختر الجهة"
              @ionChange="selectOffice($event)"
            >
              <ion-select-option
                v-for="office in getOfficesList"
                :key="office.id"
                :value="office"
                v-model="this.office"
                >{{ office.office }}</ion-select-option
              >
            </ion-select>
            <div
              @click="openNewOffice()"
              class="flex items-center gap-x-1 text-sm font-bold col-span-1 px-3 py-2 rounded-lg bg-indigo-50 text-indigo-600"
            >
              <BaseIcon
                name="PlusCircle"
                class="h-5 w-5 text-indigo-700"
                strokeWidth="0.1"
                aria-hidden="true"
              />
              <div class="pt-1">إضافة جهة</div>
            </div>
          </ion-item>
          <ion-item>
            <ion-label>المنصب</ion-label>
            <input
              type="text"
              name="person-position"
              id="person-position"
              v-model="this.position"
              autocomplete="off"
              class="block w-[70%] bg-slate-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none px-1 focus:border focus:border-indigo-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
          </ion-item>
          <ion-item>
            <ion-label>رقم الهوية</ion-label>
            <input
              type="number"
              name="person-idty"
              id="person-idty"
              v-model="this.idty"
              autocomplete="off"
              class="block w-[70%] bg-slate-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none px-1 focus:border focus:border-indigo-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
          </ion-item>
          <ion-item>
            <ion-label>رقم الجوال</ion-label>
            <input
              type="number"
              name="person-number"
              id="person-number"
              v-model="this.phone"
              placeholder="05XXXXXXXX"
              autocomplete="off"
              class="block w-[70%] bg-slate-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none px-1 focus:border focus:border-indigo-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
          </ion-item>
          <ion-item>
            <ion-label>البريد الإلكتروني</ion-label>
            <input
              type="text"
              name="person-email"
              id="person-email"
              v-model="this.email"
              autocomplete="off"
              class="block w-[70%] bg-slate-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none px-1 focus:border focus:border-indigo-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            />
          </ion-item>
        </ion-list>
      </div>
    </Transition>
  </ion-content>
</template>

<script>
import BaseIcon from "../components/BaseIcon.vue";
import NewOfficeMobileModal from "./NewOfficeMobileModal.vue";
import {
  IonContent,
  IonItem,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonList,
  IonSearchbar,
  modalController,
} from "@ionic/vue";

export default {
  data() {
    return {
      isSaving: "",
      personSearch: "",

      // New Person
      newPerson: false,
      personName: "",
      office: "",
      position: "",
      idty: "",
      phone: "",
      email: "",
    };
  },
  components: {
    BaseIcon,
    IonContent,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonList,
    IonSearchbar,
    IonInput,
    NewOfficeMobileModal,
  },
  methods: {
    openNewPerson() {
      this.newPerson = true;
    },
    async openNewOffice() {
      const modal = await modalController.create({
        component: NewOfficeMobileModal,
        initialBreakpoint: 0.4,
        cssClass: "pick-person-modal-block",
      });

      modal.present();
    },
    selectOffice(event) {
      this.office = event.target.value;
    },
    async savePerson() {
      this.isSaving = true;
      const payload = {
        personName: this.personName,
        office: JSON.stringify(this.office),
        position: this.position,
        idty: this.idty,
        phone: this.phone,
        email: this.email,
      };
      await this.$store.dispatch("saveNewPerson", payload);
      this.isSaving = false;
      this.newPerson = false;
    },
    cancelNewPerson() {
      this.personName = "";
      this.office = "";
      this.position = "";
      this.idty = "";
      this.phone = "";
      this.email = "";
      this.newPerson = false;
    },
    groupBy(xs) {
      let officesNames = [];
      xs.forEach((element) => {
        if (
          officesNames.find(
            (office) => office == JSON.parse(element.office).office
          )
        ) {
          return;
        }
        officesNames.push(JSON.parse(element.office).office);
      });
      return officesNames;
    },
    async selectPerson(val) {
      await modalController.dismiss(val);
    },
  },
  computed: {
    getIsSaving() {
      return this.isSaving;
    },
    getPersonSearch() {
      return this.personSearch;
    },
    getPersonList() {
      let filterList = "";
      let list = this.$store.getters.getPersonsList;

      if (this.personSearch == "") {
        filterList = list;
        return filterList;
      } else if (this.personSearch != "") {
        filterList = list.filter((person) => {
          return person.name.includes(this.personSearch);
        });
        return filterList;
      }
    },
    offices() {
      let list = this.$store.getters.getPersonsList;
      return this.groupBy(list);
    },
    getSelectedPersons() {
      return this.selectedPersons;
    },
    getNewPerson() {
      return this.newPerson;
    },
    selectedOffice() {
      return this.office;
    },
    getOfficesList() {
      return this.$store.getters.getOfficesList;
    },
    requiredData() {
      if (this.personName !== "" && this.office !== "") {
        return true;
      }
      return false;
    },
  },
  async created() {
    await this.$store.dispatch("getOffices");
    await this.$store.dispatch("getPersons");
  },
};
</script>

<style scoped>
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.25s ease-out;
}

.slide-left-enter-from {
  opacity: 0;
  transform: translateX(100%);
}

.slide-left-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}
</style>
