<template>
  <ion-page>
    <div class="h-full flex">
      <!-- Content area -->
      <div class="flex-1 flex flex-col overflow-hidden">
        <!-- Main content -->
        <div
          class="flex-1 flex items-stretch overflow-hidden bg-grid-gray-100 bg-gray-50"
        >
          <!-- Secondary column (hidden on smaller screens) -->
          <!-- <aside class="hidden w-96 bg-white border-l border-gray-200 overflow-y-auto xl:block"> -->

          <main dir="rtl" class="flex-1 overflow-hidden">
            <div
              class="z-30 flex flex-col sticky top-0 px-1 sm:px-2 bg-gray-50 pt-8 sm:pt-0"
            >
              <div class="flex flex-row justify-between items-center">
                <div class="flex flex-row items-center">
                  <BaseIcon
                    name="Calendar-SVG"
                    className="h-16 w-16 text-indigo-100"
                  />
                  <div class="flex flex-col py-4">
                    <div
                      class="flex-1 text-lg font-semibold leading-6 text-gray-900"
                    >
                      {{ this.calendarTitleAr }}
                    </div>
                    <div
                      class="flex-1 text-sm sm:text-md font-light text-gray-500"
                    >
                      الموافق - {{ this.calendarTitleEn }}
                    </div>
                  </div>
                  <!-- Include the AudioPlayer component -->
                  <AudioPlayer
                    class="mr-4"
                    v-if="getUserInfo.privilege == '2'"
                  />
                </div>

                <div
                  class="hidden md:flex z-50 text-sm bg-gray-100 p-1 rounded-lg h-8 items-center"
                >
                  <button
                    @click="changeView('week')"
                    type="button"
                    :class="
                      currentView == 'week'
                        ? 'bg-white shadow-sm font-semibold text-indigo-700'
                        : 'text-gray-400'
                    "
                    class="ml-px w-16 p-1.5 rounded-md hover:bg-white text-xs sm:text-xs hover:shadow-sm focus:outline-none"
                  >
                    اسبوع
                  </button>
                  <button
                    @click="changeView('day')"
                    type="button"
                    :class="
                      currentView == 'day'
                        ? 'bg-white shadow-sm font-semibold text-indigo-700'
                        : 'text-gray-400'
                    "
                    class="mr-px w-16 p-1.5 rounded-md hover:bg-white text-xs sm:text-xs hover:shadow-sm focus:outline-none"
                  >
                    يوم
                  </button>
                </div>
              </div>

              <!-- Calendar For Mobile -->
              <div
                class="mb-2 flex justify-end sm:justify-center sm:hidden rounded-md ml-4 w-auto"
              >
                <button
                  id="trigger-mobile-calendar"
                  type="button"
                  class="relative inline-flex items-center space-x-2 px-3 py-[6px] shadow-sm border border-gray-300 text-xs font-semibold rounded-r-md text-gray-700 bg-white focus:outline-none"
                >
                  <BaseIcon
                    name="Calendar"
                    strokeWidth="0.2"
                    class="h-5 w-5 text-gray-500"
                    aria-hidden="true"
                  />
                </button>
                <ion-modal
                  trigger="trigger-mobile-calendar"
                  :swipe-to-close="true"
                  :initialBreakpoint="0.6"
                  :breakpoints="[0, 0.6]"
                >
                  <ion-header translucent>
                    <ion-toolbar>
                      <ion-title>التقويم</ion-title>
                      <ion-buttons slot="start">
                        <div
                          @click="closeMobileCalendar()"
                          class="px-2 py-1 rounded-xl text-red-700"
                        >
                          إغلاق
                        </div>
                      </ion-buttons>
                    </ion-toolbar>
                  </ion-header>
                  <ion-content>
                    <div dir="rtl" class="w-full bg-white">
                      <div
                        class="flex px-8 mb-4 justify-between items-center w-full pt-2"
                      >
                        <BaseIcon
                          name="ChevronRight"
                          class="h-7 w-7 heroicon-stroke-w-1 text-gray-700"
                          @click="nextMonth"
                        />
                        <div class="flex flex-col justify-center text-center">
                          <div
                            class="text-lg font-semibold leading-6 text-gray-900"
                          >
                            {{ month.arMonth }} {{ year.arYear }}
                          </div>
                          <div class="text-sm font-light text-gray-800">
                            {{ month.enMonth }} {{ year.enYear }}
                          </div>
                        </div>
                        <BaseIcon
                          name="ChevronLeft"
                          @click="lastMonth"
                          class="h-7 w-7 heroicon-stroke-w-1 text-gray-700"
                        />
                      </div>
                      <ul class="flex flex-wrap rounded-lg shadow-sm">
                        <li
                          class="text-[0.90rem] text-center size p-px text-gray-400 font-semibold mb-4"
                          v-for="dow in days"
                          :key="dow"
                        >
                          {{ dow }}
                        </li>
                        <li
                          v-for="blank in firstDayOfMonth"
                          class="size p-1 text-center cursor-pointer"
                          :key="blank"
                        ></li>
                        <li
                          v-for="date in daysInMonth"
                          :key="date.day"
                          @click="openDay(date.englishDate)"
                          class="flex flex-col size p-1 text-center cursor-pointer"
                          :class="date.elClass"
                        >
                          <span>{{ date.fullDate }}</span>
                          <span class="text-xs opacity-50">{{
                            date.enDate
                          }}</span>
                        </li>
                      </ul>
                    </div>
                  </ion-content>
                </ion-modal>
                <div class="bg-gray-200 w-[0.81px]"></div>
                <div class="relative flex focus-within:z-10">
                  <button
                    @click="openToday()"
                    type="button"
                    class="relative inline-flex items-center space-x-2 pt-2.5 px-3 py-[6px] shadow-sm border border-gray-300 text-xs font-semibold rounded-l-md text-indigo-700 bg-white focus:outline-none"
                  >
                    الذهاب لليوم
                  </button>
                </div>
              </div>

              <div>
                <!-- Start Top Bar For Mobile -->
                <div
                  class="sticky top-0 z-50 grid flex-none grid-cols-7 bg-gray-50 text-xs text-gray-500 md:hidden mb-2 pb-1 border-b-2 border-gray-100"
                >
                  <button
                    v-for="dow in daysOfWeek(this.getCurrentDay)"
                    :key="dow"
                    :class="dow.dayClass"
                    type="button"
                    @click="openDay(dow.englishDate)"
                    class="flex flex-col items-center pt-3 pb-1.5"
                  >
                    <span>{{ dow.day }}</span>
                    <span
                      class="mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold"
                      >{{ dow.date }}</span
                    >
                  </button>
                </div>
                <!-- End Top Bar For Mobile -->

                <div
                  v-if="currentView == 'day'"
                  class="w-auto hidden md:flex justify-center items-center sm:h-16 h-10 bg-rose-100 bg-opacity-50 text-rose-800 border border-rose-200 text-sm text-center p-px font-semibold z-50"
                >
                  {{ formatDay(this.getCurrentDay) }}
                </div>
                <div
                  class="hidden md:block sticky w-full z-40 bg-gray-50"
                  v-if="currentView == 'week'"
                >
                  <ul class="flex">
                    <li
                      class="flex justify-center items-center sm:h-16 h-10 bg-gray-50 bg-opacity-50 text-xs text-center size"
                      v-for="dow in daysOfWeek(this.getCurrentDay)"
                      :key="dow"
                      :class="dow.dayClass"
                    >
                      <div class="flex flex-col">
                        <p class="font-semibold">{{ dow.day }}</p>
                        <p class="font-light text-gray-500">{{ dow.date }}</p>
                      </div>
                    </li>
                    <!-- <div v-for="ev in 168" :key="ev"> -->
                    <!-- </div> -->
                  </ul>
                </div>
              </div>
            </div>
            <!-- Primary column -->
            <ion-content
              aria-labelledby="primary-heading"
              class="min-w-0 flex-1 h-full overflow-hidden flex flex-col lg:order-last px-4"
            >
              <!-- Your content -->
              <WeekView
                v-if="currentView == 'week'"
                :currentDay="this.getCurrentDay"
              />
              <DayView
                class=""
                v-if="currentView == 'day'"
                :currentDay="this.getCurrentDay"
              />
            </ion-content>
          </main>
        </div>
      </div>

      <!-- Narrow sidebar -->
      <transition
        enter-active-class="transform transition ease-in-out duration-500 sm:duration-300"
        enter-from-class="translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transform transition ease-in-out duration-500 sm:duration-300"
        leave-from-class="translate-x-0"
        leave-to-class="translate-x-full"
      >
        <NewGuestAside v-if="NewGuestAsideStatus" />
      </transition>

      <transition
        enter-active-class="transform transition ease-in-out duration-500 sm:duration-300"
        enter-from-class="translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transform transition ease-in-out duration-500 sm:duration-300"
        leave-from-class="translate-x-0"
        leave-to-class="translate-x-full"
      >
        <EditEventAside v-if="getEditEventAsideStatus" />
      </transition>

      <transition
        enter-active-class="transform transition ease-in-out duration-500 sm:duration-300"
        enter-from-class="translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transform transition ease-in-out duration-500 sm:duration-300"
        leave-from-class="translate-x-0"
        leave-to-class="translate-x-full"
      >
        <NewEventAside v-if="NewEventAsideStatus" />
      </transition>

      <transition
        enter-active-class="transform transition ease-in-out duration-500 sm:duration-300"
        enter-from-class="translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transform transition ease-in-out duration-500 sm:duration-300"
        leave-from-class="translate-x-0"
        leave-to-class="translate-x-full"
      >
        <ViewEventAside v-if="viewEventAsideStatus" />
      </transition>

      <aside
        dir="rtl"
        style="width: 20rem"
        class="hidden sm:block w-72 h-full overflow-y-auto overflow-x-hidden bg-gray-100"
      >
        <!-- Cards list -->
        <nav class="h-full flex-1 overflow-y-auto mb-32" aria-label="Cards">
          <!-- Calendar -->
          <div class="w-full py-6 flex flex-col items-center select-none">
            <div class="mb-4">
              <button
                @click="openToday()"
                type="button"
                class="bg-white shadow-sm font-semibold text-indigo-700 text-sm py-2 px-6 rounded-md hover:bg-white hover:shadow-sm focus:outline-none"
              >
                الذهاب لليوم
              </button>
            </div>

            <div v-if="!isSuper()" class="w-full">
              <div class="flex px-8 mb-4 justify-between items-center w-full">
                <BaseIcon
                  name="ChevronRight"
                  class="h-7 w-7 heroicon-stroke-w-1 text-gray-700 active:bg-slate-100 rounded-full active:scale-90"
                  @click="nextMonth"
                />
                <div class="flex flex-col justify-center text-center">
                  <div class="font-semibold text-gray-800">
                    {{ month.arMonth }} {{ year.arYear }}
                  </div>
                  <div class="text-sm font-light text-gray-800">
                    {{ month.enMonth }} {{ year.enYear }}
                  </div>
                </div>
                <BaseIcon
                  name="ChevronLeft"
                  class="h-7 w-7 heroicon-stroke-w-1 text-gray-700 active:bg-slate-100 rounded-full active:scale-90"
                  @click="lastMonth"
                />
              </div>
              <ul
                class="flex flex-wrap rounded-lg shadow-sm bg-gray-100 bg-opacity-60"
              >
                <li
                  class="text-[0.65rem] text-center size p-px text-gray-400 font-semibold mb-4"
                  v-for="dow in days"
                  :key="dow"
                >
                  {{ dow }}
                </li>
                <li
                  v-for="blank in firstDayOfMonth"
                  class="size p-1 text-center cursor-pointer"
                  :key="blank"
                ></li>
                <li
                  v-for="date in daysInMonth"
                  :key="date.day"
                  @click="openDay(date.englishDate)"
                  class="flex flex-col size p-1 text-center text-sm cursor-pointer"
                  :class="[
                    date.elClass,
                    getSelectedDay == date.englishDate
                      ? 'bg-indigo-100 text-indigo-600 rounded-full font-bold border border-indigo-500'
                      : isDateGrater(date.englishDate)
                      ? 'text-indigo-600'
                      : 'text-gray-400',
                  ]"
                >
                  <span>{{ date.fullDate }}</span>
                  <span class="text-xs opacity-50">{{ date.enDate }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="getUserInfo.privilege !== '4'" class="px-3 flex flex-col">
            <h3
              class="px-3 text-sm font-semibold text-gray-800"
              id="mobile-teams-headline"
            >
              الموعد القادم
            </h3>
            <div
              v-if="safeUpcomingEvent.title !== 'لا توجد مواعيد قادمة'"
              @click="showEventInfo(upcomingEvent)"
              class="relative w-11/12 self-center bg-white shadow-lg rounded-lg p-2"
            >
              <div
                class="absolute flex flex-row items-center text-white font-light text-xs px-1 py-px -top-2 left-4 bg-rose-500 shadow-md shadow-rose-500/50 rounded-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="1.2"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <circle cx="12" cy="13" r="7" />
                  <polyline points="12 10 12 13 14 13" />
                  <line x1="7" y1="4" x2="4.25" y2="6" />
                  <line x1="17" y1="4" x2="19.75" y2="6" />
                </svg>
                <span class="font-light"
                  >بعد {{ toGo(upcomingEvent.from) }}</span
                >
              </div>
              <div class="flex flex-col">
                <div class="flex justify-start text-xs">
                  <div
                    class="pb-[1px] pt-1 px-1 ml-1 rounded-md bg-gray-100 text-gray-600"
                  >
                    {{ upcomingEvent.from }}
                  </div>
                  <BaseIcon
                    name="ChevronLeft"
                    className="w-5 h-5 text-gray-500"
                    strokeWidth="0.2"
                  />
                  <div
                    class="pb-[1px] pt-1 px-1 mr-1 rounded-md bg-gray-100 text-gray-600"
                  >
                    {{ upcomingEvent.durationTo }}
                  </div>
                </div>
                <div class="mr-4 mt-3 text-md text-gray-800 font-semibold">
                  {{ upcomingEvent.title }}
                </div>
                <div class="flex items-center mt-2 gap-x-1">
                  <BaseIcon
                    name="Pin"
                    className="w-4 h-4 text-gray-400"
                    strokeWidth="0.5"
                  />
                  <div class="text-xs text-gray-600">
                    {{ upcomingEvent.place }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="relative w-full self-center place-self-center items-center bg-slate-200/80 border border-dashed border-slate-300 rounded-lg px-2 py-8 text-center mt-2"
            >
              <span class="text-sm text-slate-400 font-light">
                {{ safeUpcomingEvent.title }}
              </span>
            </div>
          </div>
          <div v-if="getUserInfo.privilege !== '4'" class="mt-4 flex flex-col">
            <h3
              class="px-3 mx-3 text-sm font-semibold text-gray-800"
              id="mobile-teams-headline"
            >
              قائمة انتظار الضيوف
            </h3>
            <div
              v-if="getUnapprovedGuests.length != 0"
              class="relative w-full flex items-center gap-1 py-4 snap-x snap-mandatory overflow-x-auto touch-pan-x bg-gray-200 bg-opacity-80 border-y border-gray-300"
            >
              <div
                v-for="guest in getUnapprovedGuests"
                :key="guest.id"
                @click="showGuestInfo(guest)"
                class="flex flex-col gap-x-4 snap-start shrink-0 first:mr-6 last:mr-6 mx-2 scroll-mx-6 shrink-0 bg-white shadow-lg rounded-lg p-2 w-60"
              >
                <div class="flex items-center gap-x-1 text-blue-800">
                  <BaseIcon
                    name="UserSquare"
                    className="w-5 h-5"
                    strokeWidth="0.1"
                  />
                  <span class="text-sm">{{
                    JSON.parse(guest.person)[0].name
                  }}</span>
                </div>
                <div class="flex items-center gap-x-3 mt-2">
                  <BaseIcon
                    name="Timer"
                    className="w-5 h-5 text-blue-800"
                    strokeWidth="0.1"
                  />
                  <span
                    class="w-full text-blue-800 pr-2 border-r-2 border-blue-800/60 bg-blue-50 text-sm font-light"
                  >
                    {{ guest.title }}
                  </span>
                </div>
                <div class="z-20 mt-6 flex flex-row justify-between text-md">
                  <div
                    @click.stop="acceptGuest(guest)"
                    class="self-center text-white font-light text-xs px-3 py-2 bg-green-600 shadow-md shadow-green-500/50 rounded-lg"
                  >
                    <p>قبول</p>
                  </div>
                  <div
                    @click.stop="denyGuest(guest)"
                    class="self-center text-white font-light text-xs px-3 py-2 bg-rose-500 shadow-md shadow-rose-500/50 rounded-lg"
                  >
                    <p>رفض</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="getUnapprovedGuests.length == 0"
              class="relative w-full self-center place-self-center items-center bg-slate-200/80 border border-dashed border-slate-300 rounded-lg px-2 py-8 text-center mt-2"
            >
              <span class="text-sm text-slate-400 font-light">
                لا ضيوف في قائمة الانتظار لهذا اليوم
              </span>
            </div>
          </div>
          <div
            v-if="
              getCategoriesList &&
              getCategoriesList.length > 0 &&
              getUserInfo.privilege !== '4'
            "
            class="px-3 py-3 flex flex-col mt-4"
          >
            <h3
              class="px-3 text-sm font-semibold text-gray-800"
              id="mobile-teams-headline"
            >
              التصنيف
            </h3>
            <div
              class="grid grid-cols-2 items-center gap-1"
              role="group"
              aria-labelledby="mobile-teams-headline"
            >
              <div
                v-for="category in getCategoriesList"
                :key="category.id"
                class="group flex items-center px-3 py-1 text-base leading-5 font-medium text-gray-600 rounded-md"
              >
                <span
                  :class="JSON.parse(category.color_class).bg_class_color"
                  class="w-2.5 h-2.5 mr-4 rounded-full"
                  aria-hidden="true"
                />
                <span class="mr-1 truncate text-xs">
                  {{ category.category }}
                </span>
              </div>
            </div>
          </div>
        </nav>
        <div></div>
        <div
          v-if="!isSuper()"
          class="fixed w-[20rem] bottom-0 z-20 bg-gray-100 flex gap-x-1 border-t border-gray-200 py-6 px-4"
        >
          <button
            @click="openNewEventAside()"
            class="flex-1 bg-indigo-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-semibold text-white hover:bg-indigo-700 focus:outline-none active:bg-indigo-500"
          >
            إضافة موعد
          </button>
          <button
            v-if="getUserInfo.privilege !== '4'"
            @click="openNewGuestAside()"
            class="flex-1 bg-indigo-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-semibold text-white hover:bg-indigo-700 focus:outline-none active:bg-indigo-500"
          >
            إضافة ضيف
          </button>
        </div>
        <div
          v-if="isSuper()"
          class="fixed w-[20rem] bottom-0 z-20 bg-gray-100 grid grid-cols-2 gap-x-1 border-t border-gray-200 py-6 px-4"
        >
          <button
            v-if="isCalling !== null"
            class="sm:col-span-1 flex flex-col items-center justify-center gap-y-1 bg-green-600 border border-transparent rounded-md shadow-sm py-3 px-2 text-base font-semibold text-white"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              class="motion-reduce:hidden animate-bounce h-8 w-8 text-white"
            >
              <g clip-path="url(#clip0_895_4455)">
                <circle
                  cx="12"
                  cy="13"
                  r="9"
                  fill="currentColor"
                  fill-opacity="0.16"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.25 1C9.25 0.585789 9.58578 0.250001 10 0.250002L14 0.250002C14.4142 0.250002 14.75 0.585789 14.75 1C14.75 1.41422 14.4142 1.75 14 1.75L10 1.75C9.58578 1.75 9.25 1.41422 9.25 1ZM3.75 13C3.75 8.44365 7.44365 4.75 12 4.75C16.5563 4.75 20.25 8.44365 20.25 13C20.25 17.5564 16.5563 21.25 12 21.25L7 21.25C6.58578 21.25 6.25 21.5858 6.25 22C6.25 22.4142 6.58578 22.75 7 22.75L12 22.75C17.3848 22.75 21.75 18.3848 21.75 13C21.75 7.61523 17.3848 3.25 12 3.25C6.61522 3.25 2.25 7.61523 2.25 13C2.25 13.4142 2.58578 13.75 3 13.75C3.41421 13.75 3.75 13.4142 3.75 13ZM1.25 16C1.25 15.5858 1.58578 15.25 2 15.25L7 15.25C7.41421 15.25 7.75 15.5858 7.75 16C7.75 16.4142 7.41421 16.75 7 16.75L2 16.75C1.58578 16.75 1.25 16.4142 1.25 16ZM3.25 19C3.25 18.5858 3.58578 18.25 4 18.25L10 18.25C10.4142 18.25 10.75 18.5858 10.75 19C10.75 19.4142 10.4142 19.75 10 19.75L4 19.75C3.58578 19.75 3.25 19.4142 3.25 19ZM12.75 10C12.75 9.58579 12.4142 9.25 12 9.25C11.5858 9.25 11.25 9.58579 11.25 10L11.25 14C11.25 14.4142 11.5858 14.75 12 14.75C12.4142 14.75 12.75 14.4142 12.75 14L12.75 10Z"
                  fill="currentColor"
                />
              </g>
              <defs>
                <clipPath id="clip0_895_4455">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <span v-if="isCalling[0].element == 'null'">تم ارسال الطلب</span>
          </button>
          <button
            v-else-if="isCalling == null"
            @click="callAlert('Manager')"
            class="sm:col-span-1 flex flex-col items-center justify-center gap-y-2 bg-white border border-transparent rounded-md shadow-sm py-3 px-2 text-base font-semibold text-rose-600 active:bg-rose-100 focus:outline-none active:bg-indigo-500"
          >
            <BaseIcon
              name="MegaPhone-D"
              className="w-7 h-7"
              strokeWidth="0.2"
            />
            <span>طلب مدير المكتب</span>
          </button>
          <button
            @click="callAlert('Other')"
            class="sm:col-span-1 flex flex-col items-center justify-center gap-y-2 bg-white border border-transparent rounded-md shadow-sm py-3 px-2 text-base font-semibold text-yellow-700 active:bg-yellow-100 focus:outline-none active:bg-indigo-500"
          >
            <BaseIcon name="Bell-D" className="w-7 h-7" strokeWidth="0.2" />
            <span class="text-md">أخرى</span>
          </button>
        </div>
      </aside>
    </div>

    <!-- Phone -->
    <!-- <Tabs /> -->
  </ion-page>
</template>

<script>
import WeekView from "../components/WeekView.vue";
import DayView from "../components/DayView.vue";
import NewEventAside from "../components/NewEventAside.vue";
import ViewEventAside from "../components/ViewEventAside.vue";
import EditEventAside from "../components/EditEventAside.vue";
import NewGuestAside from "../components/NewGuestAside.vue";
import BaseIcon from "../components/BaseIcon.vue";
import QuickEventInfo from "../components/QuickEventInfo.vue";
import QuickGuestInfo from "../components/QuickGuestInfo.vue";
import CallOtherOptions from "../components/CallOtherOptions";
import GuestTimeOptions from "../components/GuestTimeOptions.vue";
import AudioPlayer from "../components/AudioPlayer.vue";
//import { PushNotifications } from "@capacitor/push-notifications";
//import Tabs from "../components/Tabs.vue";

import {
  IonPage,
  IonContent,
  IonDatetime,
  IonInput,
  IonPopover,
  IonItem,
  IonButton,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  alertController,
  actionSheetController,
  modalController,
} from "@ionic/vue";
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
  RadioGroup,
  RadioGroupLabel,
  RadioGroupDescription,
  RadioGroupOption,
} from "@headlessui/vue";
//var moment = require('../moment-hijri');
var symbolMap$1 = {
    1: "١",
    2: "٢",
    3: "٣",
    4: "٤",
    5: "٥",
    6: "٦",
    7: "٧",
    8: "٨",
    9: "٩",
    0: "٠",
  },
  numberMap = {
    "١": "1",
    "٢": "2",
    "٣": "3",
    "٤": "4",
    "٥": "5",
    "٦": "6",
    "٧": "7",
    "٨": "8",
    "٩": "9",
    "٠": "0",
  };
import moment from "moment-hijri";
moment.defineLocale("ar-sa", {
  months:
    "يناير_فبراير_مارس_أبريل_مايو_يونيو_يوليو_أغسطس_سبتمبر_أكتوبر_نوفمبر_ديسمبر".split(
      "_"
    ),
  monthsShort:
    "يناير_فبراير_مارس_أبريل_مايو_يونيو_يوليو_أغسطس_سبتمبر_أكتوبر_نوفمبر_ديسمبر".split(
      "_"
    ),
  weekdays: "الأحد_الإثنين_الثلاثاء_الأربعاء_الخميس_الجمعة_السبت".split("_"),
  weekdaysShort: "أحد_إثنين_ثلاثاء_أربعاء_خميس_جمعة_سبت".split("_"),
  weekdaysMin: "ح_ن_ث_ر_خ_ج_س".split("_"),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY HH:mm",
    LLLL: "dddd D MMMM YYYY HH:mm",
  },
  meridiemParse: /ص|م/,
  isPM: function (input) {
    return "م" === input;
  },
  meridiem: function (hour, minute, isLower) {
    if (hour < 12) {
      return "ص";
    } else {
      return "م";
    }
  },
  calendar: {
    sameDay: "[اليوم على الساعة] LT",
    nextDay: "[غدا على الساعة] LT",
    nextWeek: "dddd [على الساعة] LT",
    lastDay: "[أمس على الساعة] LT",
    lastWeek: "dddd [على الساعة] LT",
    sameElse: "L",
  },
  relativeTime: {
    future: "في %s",
    past: "منذ %s",
    s: "ثوان",
    ss: "%d ثانية",
    m: "دقيقة",
    mm: "%d دقائق",
    h: "ساعة",
    hh: "%d ساعات",
    d: "يوم",
    dd: "%d أيام",
    M: "شهر",
    MM: "%d أشهر",
    y: "سنة",
    yy: "%d سنوات",
  },
  preparse: function (string) {
    return string
      .replace(/[١٢٣٤٥٦٧٨٩٠]/g, function (match) {
        return numberMap$1[match];
      })
      .replace(/،/g, ",");
  },
  postformat: function (string) {
    return string
      .replace(/\d/g, function (match) {
        return symbolMap$2[match];
      })
      .replace(/,/g, "،");
  },
  week: {
    dow: 0, // Sunday is the first day of the week.
    doy: 6, // The week that contains Jan 6th is the first week of the year.
  },
});
export default {
  components: {
    IonPage,
    IonContent,
    IonDatetime,
    IonInput,
    IonPopover,
    IonItem,
    IonButton,
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    WeekView,
    DayView,
    NewEventAside,
    ViewEventAside,
    NewGuestAside,
    //Tabs,
    BaseIcon,
    RadioGroup,
    RadioGroupLabel,
    RadioGroupDescription,
    RadioGroupOption,
    EditEventAside,
    QuickEventInfo,
    QuickGuestInfo,
    CallOtherOptions,
    GuestTimeOptions,

    // Audio
    AudioPlayer,
  },
  computed: {
    isCalling() {
      return this.$store.getters.getIsCalling;
    },
    calendarTitleAr() {
      return moment(this.propCurrentDay).format(
        "[شهر] [(]iM[)] iMMMM / iYYYY [هـ]"
      );
    },
    calendarTitleEn() {
      return moment(this.propCurrentDay).format(
        "[شهر] [(]M[)] MMMM / YYYY [م]"
      );
    },
    getCurrentDay() {
      return this.propCurrentDay;
    },
    getSide() {
      return this.sideStatus;
    },
    year() {
      let year = {
        arYear: this.dateContext.format("iYYYY"),
        enYear: this.dateContext.format("YYYY"),
      };
      return year;
    },
    month() {
      let month = {
        arMonth: this.dateContext.format("iMMMM"),
        enMonth: this.dateContext.format("MMMM"),
      };
      return month;
    },
    daysInMonth() {
      var day = this.dateContext.endOf("iMonth").format("iD");
      var month = this.dateContext.format("iM");
      var year = this.dateContext.format("iYYYY");
      let days = [];

      for (let i = 1; i <= day; i++) {
        var elClass = "";

        // Construct the current date to compare against initial date
        const currentDate = moment(this.getCurrentDay);
        const initialDate = moment(`${year}-${month}-${i}`, "YYYY-M-D");

        if (initialDate.isBefore(currentDate, "day")) {
          // Previous days
          elClass = "text-gray-300"; // Gray for past days
        } else if (initialDate.isSame(currentDate, "day")) {
          // Current day
          elClass = "bg-rose-100 text-rose-700 font-bold rounded-xl"; // Rose for today
        } else {
          // Future days
          elClass = "text-indigo-600"; // Indigo for future days
        }

        var hijFull = this.year.arYear + "/" + month + "/" + i;

        days.push({
          day: i,
          fullDate: month + "/" + i,
          englishDate: moment(hijFull, "iYYYY/iM/iD").format("YYYY/M/D"),
          enDate: moment(hijFull, "iYYYY/iM/iD").format("M/D"),
          month: month,
          year: year,
          elClass: elClass,
        });
      }
      return days;
    },
    daysInMonth() {
      var day = this.dateContext.endOf("iMonth").format("iD");
      var month = this.dateContext.format("iM");
      var year = this.dateContext.format("iYYYY");
      let days = [];

      const currentDate = moment(this.getCurrentDay);
      const initialDate = moment(
        `${this.initialYear}/${this.initialMonth}/${this.initialDate}`,
        "iYYYY/iM/iD"
      );

      for (let i = 1; i <= day; i++) {
        const currentDay = moment(`${year}/${month}/${i}`, "iYYYY/iM/iD");
        let elClass = "";

        if (currentDay.isBefore(initialDate, "day")) {
          elClass = "text-gray-300"; // Past days
        } else if (currentDay.isSame(initialDate, "day")) {
          elClass = "bg-gray-200 text-gray-700 font-bold rounded-xl"; // Initial date
        } else if (currentDay.isSame(currentDate, "day")) {
          elClass = "bg-rose-100 text-rose-700 font-bold rounded-xl"; // Current day
        } else if (currentDay.isAfter(currentDate, "day")) {
          elClass = "text-indigo-600"; // Future days
        }

        var hijFull = this.year.arYear + "/" + month + "/" + i;
        days.push({
          day: i,
          fullDate: month + "/" + i,
          englishDate: currentDay.format("YYYY/M/D"),
          enDate: currentDay.format("M/D"),
          month: month,
          year: year,
          elClass: elClass,
        });
      }

      return days;
    },
    currentDate() {
      return this.dateContext.get("iDate");
    },
    firstDayOfMonth() {
      var firstDay = moment(this.dateContext).startOf("iMonth").day(); //moment(t.dateContext).subtract((t.currentDate - 1), 'iDays');
      return firstDay; //.weekday();
    },
    //Previous Code Above
    initialDate() {
      return this.today.format("iD");
    },
    initialMonth() {
      return this.today.format("iM");
    },
    initialYear() {
      return this.today.format("iYYYY");
    },
    viewEventAsideStatus() {
      return this.$store.getters.viewEventAsideStatus;
    },
    NewEventAsideStatus() {
      return this.$store.getters.newEventAsideStatus;
    },
    currentView() {
      return this.$store.getters.getCurrentView;
    },
    chatNotification() {
      return this.$store.getters.getTotalOfAllUnseenChat;
    },
    getUserInfo() {
      if (localStorage.getItem("awqatiAccess_token")) {
        let info = {
          name: localStorage.getItem("awqatiUser"),
          position: localStorage.getItem("awqatiUserPosition"),
          privilege: localStorage.getItem("awqatiUserPrivilege"),
        };
        return info;
      } else {
        return this.$router.push("/");
      }
    },
    upcomingEvent() {
      const eventsObject = this.$store.getters.getEventsOfCommingUp;
      const currentTime = moment();
      return this.getMostUpcomingEvent(eventsObject, currentTime);
    },
    safeUpcomingEvent() {
      return (
        this.upcomingEvent || {
          title: "لا توجد مواعيد قادمة",
          en_date: "",
          from: "",
          durationTo: "",
          place: "",
          category: "",
          person: "",
          note: "",
        }
      );
    },
    getUnapprovedGuests() {
      // I HAVE TO CHANG IT TO MOMENT AND ADD MINUTES //
      let guestsList = this.$store.getters.getGuestsOfDay;

      var compare = [];

      if (guestsList.length != 0) {
        for (var i = guestsList.length - 1; i >= 0; i--) {
          if (guestsList[i].status == "waiting") {
            compare.push(guestsList[i]);
          }
        }
      }
      return compare;
    },
    newPersonModalStatus() {
      return this.$store.getters.getNewPersonModalStatus;
    },
    NewGuestAsideStatus() {
      return this.$store.getters.NewGuestAsideStatus;
    },
    chatWithEmployeeModalStatus() {
      return this.$store.getters.getChatWithEmployeeModalStatus;
    },
    getCategoriesList() {
      return this.$store.getters.getCategoriesList;
    },
    getEditEventAsideStatus() {
      return this.$store.getters.getEditEventAsideStatus;
    },
    getSelectedDay() {
      return this.getCurrentDay;
    },
  },
  methods: {
    getMostUpcomingEvent(eventsObject, currentTime) {
      // If input is not an object or is null, return null
      if (typeof eventsObject !== "object" || eventsObject === null) {
        console.error("eventsObject is not a valid object:", eventsObject);
        return null;
      }

      // Convert object to array of events if it's not already an array
      const eventsList = Array.isArray(eventsObject)
        ? eventsObject
        : Object.values(eventsObject);

      // Convert current time to moment object if it's not already
      const now = moment.isMoment(currentTime)
        ? currentTime
        : moment(currentTime);

      let closestUpcomingEvent = null;
      let closestTimeDiff = Infinity;

      for (let i = 0; i < eventsList.length; i++) {
        const event = eventsList[i];
        if (
          !event ||
          typeof event !== "object" ||
          !event.time ||
          !event.en_date
        ) {
          continue;
        }

        let eventDateTime;
        try {
          if (moment.isMoment(event.time)) {
            eventDateTime = moment(
              event.en_date + " " + event.time.format("HH:mm"),
              "YYYY/M/D HH:mm"
            );
          } else if (typeof event.time === "string") {
            const timeObj = JSON.parse(event.time);
            eventDateTime = moment(
              event.en_date +
                " " +
                timeObj.hour +
                ":" +
                timeObj.minute +
                " " +
                timeObj.apm,
              "YYYY/M/D h:mm A"
            );
          } else if (typeof event.time === "object") {
            eventDateTime = moment(
              event.en_date +
                " " +
                event.time.hour +
                ":" +
                event.time.minute +
                " " +
                (event.time.apm || ""),
              "YYYY/M/D h:mm A"
            );
          }
        } catch (e) {
          console.error("Error parsing event time:", e, event);
          continue;
        }

        if (!eventDateTime.isValid()) {
          continue;
        }

        const timeDiff = eventDateTime.diff(now);
        if (timeDiff > 0 && timeDiff < closestTimeDiff) {
          closestTimeDiff = timeDiff;
          closestUpcomingEvent = event;
        }
      }

      if (!closestUpcomingEvent) {
        return null;
      }

      // Safely parse the time
      let fromTime, toTime;
      try {
        const timeObj =
          typeof closestUpcomingEvent.time === "string"
            ? JSON.parse(closestUpcomingEvent.time)
            : closestUpcomingEvent.time;

        fromTime = moment(
          closestUpcomingEvent.en_date +
            " " +
            timeObj.hour +
            ":" +
            timeObj.minute +
            " " +
            timeObj.apm,
          "YYYY/M/D h:mm A"
        );
        toTime = fromTime
          .clone()
          .add(parseInt(closestUpcomingEvent.duration) || 0, "minutes");
      } catch (e) {
        console.error("Error parsing event time:", e, closestUpcomingEvent);
        fromTime = null;
        toTime = null;
      }

      // Format the event data
      return {
        title: closestUpcomingEvent.title || "Untitled Event",
        color: closestUpcomingEvent.color || "",
        category: closestUpcomingEvent.category || "",
        time: closestUpcomingEvent.time,
        duration: closestUpcomingEvent.duration || "",
        ar_date: closestUpcomingEvent.ar_date || "",
        en_date: closestUpcomingEvent.en_date || "",
        person: closestUpcomingEvent.person || "",
        note: closestUpcomingEvent.note || "",
        place: closestUpcomingEvent.place || "",
        attachment: closestUpcomingEvent.attachment || "",
        from: fromTime ? fromTime.format("hh:mm A") : "Unknown",
        durationTo: toTime ? toTime.format("hh:mm A") : "Unknown",
      };
    },
    isSuper() {
      if (localStorage.getItem("awqatiUserPrivilege") == 6) {
        return true;
      }
      return false;
    },
    async callAlert(val) {
      if (val == "Manager") {
        this.$store.dispatch("establishCall", {
          from: parseInt(localStorage.getItem("awqatiUserId")),
          to: 6,
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
          date: moment().format("YYYY-MM-DD"),
          time: JSON.stringify({
            hour: `${moment().format("hh")}`,
            minute: `${moment().format("mm")}`,
            apm: `${moment().format("A")}`,
          }),
          type: "calling",
          element: null,
          status: true,
        });
      } else if ("Other") {
        const modal = await modalController.create({
          component: CallOtherOptions,
          //componentProps: { guest },
          initialBreakpoint: 0.4,
          breakpoints: [0.4, 0.5],
        });
        await modal.present();
      }
    },
    async showEventInfo(event) {
      const modal = await modalController.create({
        component: QuickEventInfo,
        componentProps: { event },
        initialBreakpoint: 0.7,
        breakpoints: [0.7, 0.8],
      });
      await modal.present();
    },
    async showGuestInfo(guest) {
      const modal = await modalController.create({
        component: QuickGuestInfo,
        componentProps: { guest },
        initialBreakpoint: 0.4,
        breakpoints: [0.4, 0.5],
      });
      await modal.present();
    },
    openToday() {
      this.dateContext = moment();
      this.openDay(moment(new Date()).format("YYYY/MM/D"));
    },
    toGuests() {
      this.$router.push("/main/guests");
    },
    async openPage(page) {
      await this.$store.commit("toggleViewEventAside", false);
      await this.$store.commit("toggleNewEventAside", false);
      await this.$store.commit("toggleEditEventModal", false);
      this.$router.push(page);
    },
    async acceptGuest(guest) {
      const modal = await modalController.create({
        component: GuestTimeOptions,
        componentProps: { guest },
        initialBreakpoint: 0.5,
        breakpoints: [0.5, 0.6],
      });
      await modal.present();
      /*const actionSheet = await actionSheetController.create({
        header: `قبول الضيف`,
        cssClass: "my-custom-class",
        buttons: [
          {
            text: "تأكيد قبول الضيف",
            role: "destructive",
            handler: () => {
              //this.getCurrentEventData.type = 'cancel'
              guest.status = "approved";
              guest.time = JSON.stringify({
                hour: `${moment().format("hh")}`,
                minute: `${moment().format("mm")}`,
                apm: `${moment().format("A")}`,
              });
              this.$store.dispatch("updateGuest", guest);
              this.$store.dispatch("getGuestForCommingUp", {
                group: parseInt(guest.group_num),
              });
            },
          },
          {
            text: "إغلاق",
            role: "cancel",
            handler: () => {},
          },
        ],
      });
      await actionSheet.present();*/
    },
    async denyGuest(guest) {
      const actionSheet = await actionSheetController.create({
        header: `رفض الضيف`,
        cssClass: "my-custom-class",
        buttons: [
          {
            text: "تأكيد رفض الضيف",
            role: "destructive",
            handler: () => {
              this.$store.dispatch("removeGuest", guest);
            },
          },
          {
            text: "إغلاق",
            role: "cancel",
            handler: () => {},
          },
        ],
      });
      await actionSheet.present();
    },
    toGo(v) {
      //let currentTime = moment(new Date)
      let currentTime = moment().format("YYYY/MM/D");
      let vv = currentTime + " " + v;
      let time = moment(vv, ["YYYY/MM/D h:mm A"]).toNow(true); //moment(created_at).fromNow()
      return time;
    },
    formatDay(v) {
      return moment(v).format("dddd [-] iYYYY/iM/iD [هـ]");
    },
    updateCurrentTime() {
      this.forCommingDate = moment(new Date()).format("HH:mm");
    },
    async changeView(view) {
      await this.$store.commit("setCurrentView", view);
      await this.openDay(this.getCurrentDay);
    },
    async closeMobileCalendar() {
      await modalController.dismiss();
    },
    async openDay(D) {
      //this.closeMobileCalendar();
      this.propCurrentDay = D;

      if (this.currentView == "day") {
        let getEventsOfDayPayload = {
          date: moment(D).format("iYYYY/iM/iD"),
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        };
        await this.$store.dispatch("getEventsOfDay", getEventsOfDayPayload);
        this.$store.dispatch("getGuestsOfDay", getEventsOfDayPayload);
      } else if (this.currentView == "week") {
        var currentDate = moment(D);
        var dayNo = currentDate.startOf("iweek").day();
        var weekStart = "";
        if (dayNo == 0) {
          weekStart = currentDate.startOf("iweek");
        } else if (dayNo > 0) {
          weekStart = currentDate.startOf("iweek").add(-dayNo, "iDate");
        }

        let lastDay = moment(weekStart).add(6, "iDate").format("YYYY/M/D");
        let days = {
          fromDay: moment(weekStart).format("YYYY/M/D"),
          toDay: lastDay,
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        };
        await this.$store.dispatch("getEventsOfWeek", days);
        await this.$store.dispatch("getGuestsOfWeek", days);
        let getEventsOfDayPayload = {
          date: moment(D).format("iYYYY/iM/iD"),
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        };
        this.$store.dispatch("getGuestsOfDay", getEventsOfDayPayload);
      }
    },
    toggleEvent() {
      this.sideStatus = !this.sideStatus;
    },
    async openNewEventAside() {
      this.$store.commit("toggleNewEventAside", true);
    },
    async openNewGuestAside() {
      this.$store.commit("toggleNewGuestAside", true);
    },
    nextMonth() {
      this.dateContext = moment(this.dateContext).add(1, "iMonth");
    },
    lastMonth() {
      this.dateContext = moment(this.dateContext).add(-1, "iMonth");
    },
    soundNotification() {
      const sound = new Audio("@/static/sounds/notification.mp3");

      const promise = sound.play();

      if (promise !== undefined) {
        promise.then(() => {}).catch((error) => console.error);
      }
    },
    daysOfWeek(v) {
      var currentDate = moment(v);
      var dayNo = currentDate.startOf("iweek").day();
      var weekStart = "";
      if (dayNo == 0) {
        weekStart = currentDate.startOf("iweek");
      } else if (dayNo > 0) {
        weekStart = currentDate.startOf("iweek").add(-dayNo, "iDate");
      }
      var days = [];
      var dayClass = "text-gray-700 border border-gray-100";
      var hijFull = "";
      for (var i = 0; i <= 6; i++) {
        hijFull = moment(weekStart).add(i, "iDate").format("iYYYY/iM/iD");
        if (
          moment(v).format("iYYYY/iM/iD") ==
          moment(weekStart).add(i, "iDate").format("iYYYY/iM/iD")
        ) {
          dayClass =
            "bg-rose-200 bg-opacity-50 text-rose-800 rounded-md shadow-border-b";
        } else {
          dayClass = "text-gray-600 border border-gray-100";
        }
        days.push({
          day: moment(weekStart).add(i, "iDate").format("dddd"),
          date:
            moment(weekStart).add(i, "iDate").format("iM") +
            "/" +
            moment(weekStart).add(i, "iDate").format("iDD"),
          dayClass: dayClass,
          englishDate: moment(hijFull, "iYYYY/iM/iD").format("YYYY/M/D"),
        });
      }

      return days;
    },
    isDateGrater(cueerntDate) {
      const val = moment(cueerntDate).isAfter(this.today.format("YYYY/M/D"));
      return val;
    },
  },
  async mounted() {
    //await PushNotifications.removeAllDeliveredNotifications();
    await this.$store.dispatch("getCategories");
    let getEventsOfDayPayload = {
      date: moment(new Date()).format("iYYYY/iM/iD"),
      group: parseInt(localStorage.getItem("awqatiUserGroup")),
    };
    this.$socket.on("syncEventsOfWeek", (data) => {
      if (this.currentView == "week") {
        this.$store.dispatch("getEventsForCommingUp", {
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        });

        var currentDate = moment(this.currentDay);
        var dayNo = currentDate.startOf("iweek").day();
        var weekStart = "";

        if (dayNo == 0) {
          weekStart = currentDate.startOf("iweek");
        } else if (dayNo > 0) {
          weekStart = currentDate.startOf("iweek").add(-dayNo, "iDate");
        }

        for (var i = 0; i <= 6; i++) {
          if (
            data.dayy == moment(weekStart).add(i, "iDate").format("iYYYY/iM/iD")
          ) {
            const newDays = {
              fromDay: moment(weekStart).format("YYYY/M/D"),
              toDay: moment(weekStart).add(6, "iDate").format("YYYY/M/D"),
              group: data.group,
            };
            this.$store.dispatch("getEventsOfWeek", newDays);
            return;
          } else {
            console.log("IT IS NOT IN SAME WEEK!");
            const newDays = {
              fromDay: moment(weekStart).format("YYYY/M/D"),
              toDay: moment(weekStart).add(6, "iDate").format("YYYY/M/D"),
              group: data.group,
            };
            this.$store.dispatch("getEventsOfWeek", newDays);
          }
        }
      }
    });

    this.$socket.on("syncGuestsOfWeek", (data) => {
      if (this.currentView == "week") {
        var currentDate = moment(data.ar_date);
        var dayNo = currentDate.startOf("iweek").day();
        var weekStart = "";
        if (dayNo == 0) {
          weekStart = currentDate.startOf("iweek");
        } else if (dayNo > 0) {
          weekStart = currentDate.startOf("iweek").add(-dayNo, "iDate");
        }

        let lastDay = moment(weekStart).add(6, "iDate").format("YYYY/M/D");
        let days = {
          fromDay: moment(weekStart).format("YYYY/M/D"),
          toDay: lastDay,
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        };
        this.$store.dispatch("getGuestsOfWeek", days);
        let getEventsOfDayPayload = {
          date: moment().format("iYYYY/iM/iD"),
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        };
        this.$store.dispatch("getGuestsOfDay", getEventsOfDayPayload);
        this.$store.dispatch("showNotification", {
          title: "ضيف",
          text: "تم إضافة ضيف جديد",
          type: "newguest",
        });
      }
      /*this.$store.dispatch("getEventsForCommingUp", {
            group: parseInt(localStorage.getItem("awqatiUserGroup")),
          });*/
    });

    this.$socket.on("syncUpdateGuestOfWeek", (data) => {
      if (this.currentView == "week") {
        var currentDate = moment(data.ar_date);
        var dayNo = currentDate.startOf("iweek").day();
        var weekStart = "";
        if (dayNo == 0) {
          weekStart = currentDate.startOf("iweek");
        } else if (dayNo > 0) {
          weekStart = currentDate.startOf("iweek").add(-dayNo, "iDate");
        }

        let lastDay = moment(weekStart).add(6, "iDate").format("YYYY/M/D");
        let days = {
          fromDay: moment(weekStart).format("YYYY/M/D"),
          toDay: lastDay,
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        };
        this.$store.dispatch("getGuestsOfWeek", days);
        let getEventsOfDayPayload = {
          date: moment().format("iYYYY/iM/iD"),
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        };
        this.$store.dispatch("getGuestsOfDay", getEventsOfDayPayload);

        if (data.updateType == "approved") {
          this.$store.dispatch("showNotification", {
            title: "ضيف",
            text: `${data.guestName} تم قبول الضيف`,
            type: "acceptguest",
          });
          /*this.$store.dispatch("getEventsForCommingUp", {
                group: parseInt(localStorage.getItem("awqatiUserGroup")),
              });*/
          this.$store.dispatch(data.command, {
            date: data.dayy,
            group: data.group,
          });
        } else if (data.updateType == "reject") {
          this.$store.dispatch("showNotification", {
            title: "ضيف",
            text: `${data.guestName} تم رفض الضيف`,
            type: "rejectguest",
          });
          /*this.$store.dispatch("getEventsForCommingUp", {
                group: parseInt(localStorage.getItem("awqatiUserGroup")),
              });*/
          this.$store.dispatch(data.command, {
            date: data.dayy,
            group: data.group,
          });
        }
      }
      /*this.$store.dispatch("getEventsForCommingUp", {
            group: parseInt(localStorage.getItem("awqatiUserGroup")),
          });*/
    });
    let D = moment(new Date()).format("YYYY/MM/D");
    let lastDay = moment(weekStart).add(6, "iDate").format("YYYY/M/D");
    let days = {
      fromDay: moment(weekStart).format("YYYY/M/D"),
      toDay: lastDay,
      group: parseInt(localStorage.getItem("awqatiUserGroup")),
    };
    var currentDate = moment(D);
    var dayNo = currentDate.startOf("iweek").day();
    var weekStart = "";
    if (dayNo == 0) {
      weekStart = currentDate.startOf("iweek");
    } else if (dayNo > 0) {
      weekStart = currentDate.startOf("iweek").add(-dayNo, "iDate");
    }

    await this.$store.dispatch("getEventsOfWeek", days);
    await this.$store.dispatch("getGuestsOfWeek", days);
    //await this.$store.dispatch("getEventsOfDay", getEventsOfDayPayload);
    //await this.$store.dispatch("getGuestsOfDay", getEventsOfDayPayload);
    await this.$store.dispatch("getEventsForCommingUp", {
      group: parseInt(localStorage.getItem("awqatiUserGroup")),
    });

    await this.$store.dispatch("checkIfCalling", {
      from: parseInt(localStorage.getItem("awqatiUserId")),
      group: parseInt(localStorage.getItem("awqatiUserGroup")),
    });

    const upData = {
      to: parseInt(localStorage.getItem("awqatiUserId")),
      group: parseInt(localStorage.getItem("awqatiUserGroup")),
    };
    this.$store.dispatch("getTotalOfAllUnseenChat", upData);
    this.$socket.on("checkUserForChat", (data) => {
      console.log("START CHECK USER");
      if (data.to == localStorage.getItem("awqatiUserId")) {
        console.log("IS (to) USER !");
        if (this.$route.name !== "Chat") {
          this.$store.dispatch("showNotification", {
            title: "رسالة",
            text: "لديك رسالة جديدة",
            type: "newchat",
          });
        }
        this.$store.dispatch("getTotalOfAllUnseenChat", upData);
      } else {
        console.log("NOT (to) USER !");
        return;
      }
    });

    await this.$store.dispatch("getUserCallingByID", {
      to: parseInt(localStorage.getItem("awqatiUserId")),
      group: parseInt(localStorage.getItem("awqatiUserGroup")),
    });

    this.$socket.on("syncGuestsOfDay", (data) => {
      if (
        moment(this.currentDay).format("iYYYY/iM/iD") == data.dayy &&
        parseInt(localStorage.getItem("awqatiUserGroup")) == data.group
      ) {
        this.$store.dispatch("showNotification", {
          title: "ضيف",
          text: "تم إضافة ضيف جديد",
          type: "newguest",
        });
        /*this.$store.dispatch("getEventsForCommingUp", {
            group: parseInt(localStorage.getItem("awqatiUserGroup")),
          });*/
        this.$store.dispatch(data.command, {
          date: data.dayy,
          group: data.group,
        });
      } else {
        console.log("IT IS NOT IN SAME DAY!");
      }
    });

    this.$socket.on("syncUpdateGuestOfDay", (data) => {
      if (
        moment(this.currentDay).format("iYYYY/iM/iD") == data.dayy &&
        parseInt(localStorage.getItem("awqatiUserGroup")) == data.group
      ) {
        if (data.updateType == "approved") {
          this.$store.dispatch("showNotification", {
            title: "ضيف",
            text: `${data.guestName} تم قبول الضيف`,
            type: "acceptguest",
          });
          /*this.$store.dispatch("getEventsForCommingUp", {
                group: parseInt(localStorage.getItem("awqatiUserGroup")),
              });*/
          this.$store.dispatch(data.command, {
            date: data.dayy,
            group: data.group,
          });
        } else if (data.updateType == "reject") {
          this.$store.dispatch("showNotification", {
            title: "ضيف",
            text: `${data.guestName} تم رفض الضيف`,
            type: "rejectguest",
          });
          /*this.$store.dispatch("getEventsForCommingUp", {
                group: parseInt(localStorage.getItem("awqatiUserGroup")),
              });*/
          this.$store.dispatch(data.command, {
            date: data.dayy,
            group: data.group,
          });
        }
      } else {
        console.log("IT IS NOT IN SAME DAY!");
      }
    });
  },
  created() {
    this.$socket.on("checkUserCalling", (data) => {
      console.log("Arrive");
      if (data.to == localStorage.getItem("awqatiUserId")) {
        this.$store.dispatch("getUserCallingByID", {
          to: parseInt(localStorage.getItem("awqatiUserId")),
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        });
      } else {
        this.$store.dispatch("checkIfCalling", {
          from: parseInt(localStorage.getItem("awqatiUserId")),
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
        });
      }
    });
    setInterval(() => this.updateCurrentTime(), 1000);
  },
  data() {
    return {
      forCommingDate: moment(new Date()).format("HH:mm"),
      currentTime: moment().format("hh:mm A"),
      propCurrentDay: moment(),
      today: moment(),
      dateContext: moment(),
      days: [
        "الأحد",
        "الإثنين",
        "الثلاثاء",
        "الأربعاء",
        "الخميس",
        "الجمعة",
        "السبت",
      ],
      navigation: [
        {
          name: "الإعدادات",
          href: "/Settings2",
          icon: "Settings",
          current: false,
        },
        {
          name: "المحادثات",
          href: "/chat",
          icon: "Chat",
          current: false,
        },
        {
          name: "قائمة الضيوف",
          href: "/guests",
          icon: "Guests",
          current: false,
        },
        { name: "الجدول", href: "/main", icon: "Calendar", current: true },
      ],
      getToday: moment(),
      currentLastDay: null,
      mobileMenuOpen: false,
      sideStatus: false,
    };
  },
};
</script>

<style scoped>
ion-content {
  --padding-bottom: 120px;
  --offset-bottom: auto !important;
  --offset-top: auto !important;
  --overflow: hidden;
  overflow-y: auto;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='%23f1f5f9'%3e%3cpath d='M0 .5H31.5V32'/%3e%3c/svg%3e");
}

ion-modal::part(backdrop) {
  background: rgba(209, 213, 219);
  opacity: 1;
}

.size {
  width: 14.286%;
}

.shadow-border-b {
  box-shadow: 0 4px 6px -1px rgb(136 18 52 / 0.081),
    0 2px 4px -2px rgb(136 18 52 / 0.081);
}
</style>
