<template>
  <ion-page>
    <ion-content>
      <div class="h-full flex">
        <!-- Content area -->
        <transition
          enter-active-class="transform transition ease-in-out duration-500 sm:duration-300"
          enter-from-class="translate-x-full"
          enter-to-class="translate-x-0"
          leave-active-class="transform transition ease-in-out duration-500 sm:duration-300"
          leave-from-class="translate-x-0"
          leave-to-class="translate-x-full"
        >
          <EditEventAside v-if="getEditEventAsideStatus" />
        </transition>
        <transition
          enter-active-class="transform transition ease-in-out duration-500 sm:duration-300"
          enter-from-class="translate-x-full"
          enter-to-class="translate-x-0"
          leave-active-class="transform transition ease-in-out duration-500 sm:duration-300"
          leave-from-class="translate-x-0"
          leave-to-class="translate-x-full"
        >
          <ViewEventAside v-if="viewEventAsideStatus" />
        </transition>
        <div class="flex-1 flex flex-col overflow-hidden">
          <!-- Main content -->
          <div
            class="flex-1 flex items-stretch overflow-hidden bg-grid-gray-100 bg-gray-50"
          >
            <!-- Secondary column (hidden on smaller screens) -->
            <!-- <aside class="hidden w-96 bg-white border-l border-gray-200 overflow-y-auto xl:block"> -->

            <main
              dir="rtl"
              class="flex-1"
              :class="
                getSelectedUser == '' ? 'overflow-hidden' : 'overflow-y-auto'
              "
            >
              <!-- Primary column -->
              <section
                v-if="getSelectedUser == ''"
                class="bg-gray-200 mx-auto flex w-full h-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8 overflow-hidden"
              >
                <div class="flex flex-shrink-0 justify-center">
                  <BaseIcon
                    name="Chat-D"
                    className="w-24 h-24 text-slate-400"
                    strokeWidth="0.1"
                  />
                </div>
                <div class="py-12">
                  <div class="text-center">
                    <h1
                      class="mt-2 text-4xl font-bold tracking-tight text-slate-400"
                    >
                      المحادثات
                    </h1>
                  </div>
                </div>
              </section>
              <section
                aria-labelledby="primary-heading"
                class="min-w-0 relative flex-1 h-full overflow-hidden flex flex-col lg:order-last"
              >
                <div
                  v-show="getChatDataLoading"
                  class="absolute top-1 w-full left-0 right-0 mx-auto flex items-center justify-center"
                >
                  <div class="flex items-center justify-center">
                    <div
                      class="inline-flex items-center px-2.5 py-px font-semibold leading-6 text-xs shadow rounded-md text-white bg-slate-700 transition ease-in-out duration-150"
                      disabled=""
                    >
                      <span class="mt-1">جلب البيانات</span>
                      <svg
                        class="animate-spin -ml-1 mr-3 h-4 w-4 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  id="messages"
                  class="flex flex-col space-y-4 h-full overflow-y-auto overflow-x-hidden pt-1 pb-12 px-6 border-b border-gray-200"
                >
                  <div
                    class="w-full message"
                    v-for="chat in getChatOfUserById"
                    :key="chat.id"
                  >
                    <div
                      v-if="chat.link == 0"
                      style="width: fit-content !important"
                      class="flex flex-col py-2 rounded-xl shadow"
                      :class="
                        chat.from_user == getMyID
                          ? 'bg-blue-50 float_right'
                          : 'bg-white float-left'
                      "
                    >
                      <span
                        class="mx-4"
                        :class="
                          chat.from_user == getMyID
                            ? 'text-blue-600'
                            : 'text-gray-800'
                        "
                        >{{ chat.text }}</span
                      >
                      <span class="mx-4 text-xs text-gray-400">{{
                        moment(chat.created_at).from(getCurrentTime)
                      }}</span>
                    </div>
                    <div
                      @click="showMentioned(chat.link, chat.link_type)"
                      v-else-if="chat.link != 0"
                      class="flex flex-col w-fit pb-2 rounded-xl shadow"
                      :class="
                        chat.from_user == getMyID
                          ? 'bg-blue-50 float_right'
                          : 'bg-white float-left'
                      "
                    >
                      <div>
                        <div
                          class="w-full flex items-center text-center text-lg border-b border-yellow-800 rounded-t-xl py-3 px-2 bg-yellow-50 text-yellow-700"
                        >
                          <BaseIcon
                            name="Blub-D"
                            class="h-6 w-6 text-yellow-800 ml-2"
                            strokeWidth="0.5"
                            aria-hidden="true"
                          />
                          <b>الموعد</b>
                        </div>
                      </div>
                      <div class="flex flex-col">
                        <span
                          class="mx-4"
                          :class="
                            chat.from_user == getMyID ? 'text-blue-600' : ''
                          "
                          >{{ chat.text }}</span
                        >
                        <span class="mx-4 text-xs text-gray-400">{{
                          moment(chat.created_at).from(getCurrentTime)
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Your content -->
                <div
                  v-if="selectedEventForChat != ''"
                  class="p-2 mx-1 rounded-lg bg-yellow-100 border border-yellow-500 shadow-lg"
                >
                  <div class="flex items-center justify-between flex-wrap">
                    <div class="w-0 flex-1 flex items-center">
                      <span class="flex p-2 rounded-lg bg-yellow-800">
                        <BaseIcon
                          name="Blub-D"
                          class="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </span>
                      <p
                        class="mr-3 pt-px font-medium text-yellow-700 truncate"
                      >
                        <span class="inline font-semibold">
                          {{ selectedEventForChat.title }}
                        </span>
                        -
                        <span>{{ selectedEventForChat.ar_date }} هـ</span>
                      </p>
                    </div>
                    <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                      <button
                        @click="removeMention()"
                        type="button"
                        class="-mr-1 flex p-2 rounded-md hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-white"
                      >
                        <BaseIcon
                          name="XIcon"
                          class="h-6 w-6 text-yellow-500"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <!-- Short Text -->
                <!--<div class="bg-white grid grid-cols-1 gap-4 lg:col-span-3">
                  <div class="p-6">
                    <div class="relative">
                      <div class="relative sm:overflow-hidden">
                        <div class="pb-3">
                          <div class="hidden sm:block">
                            <div class="border-b border-gray-200">
                              <nav
                                class="-mb-px flex gap-x-8 overflow-x-auto"
                                aria-label="Tabs"
                              >
                                <template
                                  v-for="(parent, idx) in textList"
                                  :key="idx"
                                >
                                  <span
                                    @click="openChild(parent, $event)"
                                    class="rounded-md bg-gray-100 text-gray-900 whitespace-nowrap flex pb-2 pt-2.5 px-3 border-b-2 font-medium text-sm border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200"
                                  >
                                    {{ parent.parentText }}
                                  </span>
                                </template>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div
                  v-if="!getSelectedUser == ''"
                  class="flex justify-center"
                  style="
                    background-color: rgba(255, 255, 255, 0.5);
                    filter: blur(10px) !important;
                    -webkit-backdrop-filter: blur(10px);
                  "
                >
                  <div
                    class="bg-white sticky flex flex-row justify-between space-x-2 items-center top-[calc(100vh-80px)] self-center z-40 rounded-xl shadow my-8 px-2 py-2 h-12 w-2/3"
                  >
                    <div class="w-1/1">
                      <button
                        v-if="!getSendingChatLoading"
                        @click.stop="sendChat()"
                        class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                      >
                        ارسال
                      </button>
                      <button
                        v-else-if="getSendingChatLoading"
                        class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-800 focus:outline-none transition ease-in-out duration-150 cursor-not-allowed"
                        :disabled="true"
                      >
                        ارسال
                        <svg
                          class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                          ></circle>
                          <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </button>
                    </div>
                    <div class="w-full">
                      <input
                        @keyup.enter="handleSend($event)"
                        v-model="this.chatText"
                        type="text"
                        name="chat-text"
                        id="chat-text"
                        autocomplete="off"
                        class="ml-3 pr-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm sm:text-sm sm:leading-6 outline-none"
                      />
                      <div
                        v-if="getChatText !== ''"
                        @click="clearChatText"
                        class="absolute ml-4 inset-y-0 left-0 flex items-center pr-3"
                      >
                        <BaseIcon
                          name="XIcon"
                          class="h-5 w-5 text-gray-500"
                          aria-hidden="true"
                        />
                      </div>
                      <!--<ion-input
                        @keyup.enter="handleSend($event)"
                        v-model="this.chatText"
                        clear-input
                        type="text"
                        name="chat-text"
                        id="chat-text"
                        autocomplete="off"
                        class="inline-flex w-full sm:text-sm bg-gray-50 border-gray-200 rounded-xl mr-2 focus:bg-indigo-100"
                        style="
                          direction: rtl;
                          display: inline-flex;
                          width: 100%;
                          --padding-top: 6px;
                          --padding-start: 6px;
                          --padding-end: 6px;
                          --padding-bottom: 6px;
                          border-width: none;
                          --background: white;
                          --color: rgb(99, 102, 241);
                          outline: none !important;
                          outline-offset: none !important;
                        "
                      ></ion-input> -->
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </div>

        <!-- Narrow sidebar -->
        <transition
          enter-active-class="transform transition ease-in-out duration-500 sm:duration-300"
          enter-from-class="translate-x-full"
          enter-to-class="translate-x-0"
          leave-active-class="transform transition ease-in-out duration-500 sm:duration-300"
          leave-from-class="translate-x-0"
          leave-to-class="translate-x-full"
        >
          <div dir="rtl" class="overflow-hidden w-full sm:w-80 bg-gray-100">
            <div class="flex flex-col h-full">
              <div
                class="sticky z-40 py-4 px-3 top-0 bg-gray-100 border-b border-gray-200"
              >
                <div class="flex items-center gap-x-3 justify-start">
                  <BaseIcon
                    name="Chat-D"
                    className="w-8 h-8 text-gray-900"
                    strokeWidth="0.1"
                  />
                  <div>
                    <p class="text-2xl mt-2.5 font-extrabold text-gray-900">
                      قائمة المحادثات
                    </p>
                  </div>
                </div>
              </div>

              <div class="bg-gray-100 shadow overflow-y-auto overflow-x-hidden">
                <ul
                  v-if="getSkeletonLoading"
                  role="list"
                  class="divide-y divide-gray-200"
                >
                  <li class="animate-pulse">
                    <!-- Start Users List For Large screen -->
                    <div class="block">
                      <div class="w-full flex items-center px-4 py-4 sm:px-6">
                        <div class="rounded-full bg-slate-400 h-6 w-6"></div>
                        <div class="flex-1 flex-col gap-y-4 pl-12 mr-2">
                          <div class="h-2 bg-slate-300 rounded"></div>
                          <div class="w-32 mt-2 h-1 bg-slate-300 rounded"></div>
                        </div>
                      </div>
                    </div>
                    <!-- End Users List For Large screen -->

                    <!-- Start Users List For Mobile -->
                    <!-- End Users List For Mobile -->
                  </li>
                  <li class="animate-pulse">
                    <!-- Start Users List For Large screen -->
                    <div class="block">
                      <div class="w-full flex items-center px-4 py-4 sm:px-6">
                        <div class="rounded-full bg-slate-400 h-6 w-6"></div>
                        <div class="flex-1 flex-col gap-y-4 pl-12 mr-2">
                          <div class="h-2 bg-slate-300 rounded"></div>
                          <div class="w-32 mt-2 h-1 bg-slate-300 rounded"></div>
                        </div>
                      </div>
                    </div>
                    <!-- End Users List For Large screen -->

                    <!-- Start Users List For Mobile -->
                    <!-- End Users List For Mobile -->
                  </li>
                </ul>

                <ul v-else role="list" class="divide-y divide-gray-200">
                  <li v-for="user in usersGroup" :key="user.id">
                    <!-- Start Users List For Large screen -->
                    <div
                      v-if="user.user_status != 4"
                      @click="selectUser(user, 'in')"
                      class="hidden sm:block hover:bg-white"
                      :class="getSelectedUser.id == user.id ? 'bg-white' : ''"
                    >
                      <div class="flex items-center px-4 py-4 sm:px-6">
                        <div class="min-w-0 flex-1 flex items-center">
                          <div class="flex-shrink-0">
                            <div
                              class="h-7 w-7 rounded-full border border-gray-200 overflow-hidden bg-gray-100"
                            >
                              <svg
                                :class="
                                  getSelectedUser.id == user.id
                                    ? 'text-indigo-500'
                                    : 'text-gray-400'
                                "
                                class="h-full w-full"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                          <div class="min-w-0 flex flex-col px-4">
                            <div>
                              <p
                                class="text-lg truncate text-sm"
                                :class="
                                  getSelectedUser.id == user.id
                                    ? 'text-indigo-600 font-semibold'
                                    : 'text-gray-700'
                                "
                              >
                                {{ user.name }}
                              </p>
                            </div>
                            <div>
                              <p class="text-sm text-gray-500">
                                {{ user.user_position }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="flex flex-row items-center">
                          <span
                            v-for="userChat in getTotalOfUnseenChatsFromUsers.data"
                            :key="userChat"
                          >
                            <span
                              v-if="
                                userChat.from_user == user.id &&
                                userChat.chatCount > 0
                              "
                              class="flex items-center justify-center rounded-full px-3 pb-2.5 pt-3.5 h-5 w-5 bg-green-500 text-center text-sm font-bold text-emerald-50"
                              >{{ userChat.chatCount }}</span
                            >
                          </span>
                          <BaseIcon
                            name="ChevronLeft"
                            class="h-5 w-5"
                            aria-hidden="true"
                            :class="
                              getSelectedUser == user
                                ? 'text-indigo-500'
                                : 'text-gray-400'
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <!-- End Users List For Large screen -->

                    <!-- Start Users List For Mobile -->
                    <div
                      v-if="user.user_status != 4"
                      @click="selectUserForMobile(user, 'in')"
                      class="sm:hidden block hover:bg-white"
                      :class="getSelectedUser.id == user.id ? 'bg-white' : ''"
                    >
                      <div class="flex items-center px-4 py-4 sm:px-6">
                        <div class="min-w-0 flex-1 flex items-center">
                          <div class="flex-shrink-0">
                            <div
                              class="h-7 w-7 rounded-full border border-gray-200 overflow-hidden bg-gray-100"
                            >
                              <svg
                                :class="
                                  getSelectedUser.id == user.id
                                    ? 'text-indigo-500'
                                    : 'text-gray-400'
                                "
                                class="h-full w-full"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                                ></path>
                              </svg>
                            </div>
                          </div>
                          <div class="min-w-0 flex flex-col px-4">
                            <div>
                              <p
                                class="text-lg truncate text-sm"
                                :class="
                                  getSelectedUser.id == user.id
                                    ? 'text-indigo-600 font-semibold'
                                    : 'text-gray-700'
                                "
                              >
                                {{ user.name }}
                              </p>
                            </div>
                            <div>
                              <p class="text-sm text-gray-500">
                                {{ user.user_position }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="flex flex-row items-center">
                          <span
                            v-for="userChat in getTotalOfUnseenChatsFromUsers.data"
                            :key="userChat"
                          >
                            <span
                              v-if="
                                userChat.from_user == user.id &&
                                userChat.chatCount > 0
                              "
                              class="flex items-center justify-center rounded-full px-3 pb-2.5 pt-3.5 h-5 w-5 bg-green-500 text-center text-sm font-bold text-emerald-50"
                              >{{ userChat.chatCount }}</span
                            >
                          </span>
                          <BaseIcon
                            name="ChevronLeft"
                            class="h-5 w-5"
                            aria-hidden="true"
                            :class="
                              getSelectedUser == user
                                ? 'text-indigo-500'
                                : 'text-gray-400'
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <!-- End Users List For Mobile -->

                    <div v-if="user.user_status == 4" class="block bg-red-50">
                      <div class="flex items-center px-4 py-4 sm:px-6">
                        <div class="min-w-0 flex-1 flex items-center">
                          <div class="flex-shrink-0">
                            <BaseIcon
                              name="UserCircle"
                              class="w-8 h-8 heroicon-stroke-w-1.2 text-red-700"
                            />
                          </div>
                          <div class="min-w-0 flex flex-col px-4">
                            <div>
                              <p class="text-lg text-red-700 truncate">
                                {{ user.name }} - <b>المستخدم محظور</b>
                              </p>
                            </div>
                            <div>
                              <p class="text-sm text-red-700">
                                {{ user.position }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </ion-content>
    <!-- Phone -->
    <!-- <Tabs /> -->
  </ion-page>
</template>

<script>
import BaseIcon from "../components/BaseIcon.vue";
import ViewEventAside from "../components/ViewEventAside.vue";
import EditEventAside from "../components/EditEventAside.vue";
//import Tabs from "../components/Tabs.vue";
import UserChatMobile from "../components/UserChatMobile.vue";
import textPopover from "../components/TextPopover.vue";
import QuickGuestInfo from "../components/QuickGuestInfo.vue";

import moment from "moment-hijri";
moment.updateLocale("ar-sa", {
  postformat: (string) => string,
});

import {
  IonPage,
  IonContent,
  IonInput,
  modalController,
  popoverController,
} from "@ionic/vue";
export default {
  components: {
    ViewEventAside,
    EditEventAside,
    //Tabs,
    IonPage,
    IonContent,
    IonInput,
    BaseIcon,
    textPopover,
    QuickGuestInfo,
  },
  data() {
    return {
      moment: moment,
      selectedUser: "",
      chatText: "",
      newChatfromUsersList: [],
      interval: null,
      currentTime: moment(new Date()),
      messages: null,
      textList: [
        {
          parentText: "عبارات",
          childTexts: ["إلغاء", "تعديل", "مناسب"],
        },
        {
          parentText: "ضيافة",
          childTexts: ["شاي", "قهوة", "ماء"],
        },
      ],
      selectedTextParent: null,
      replyText: null,
      replyTo: null,
    };
  },
  methods: {
    /*hideSoftKeyboard() {
      document.addEventListener("focusout", function (e) {
        window.scrollTo(0, 0);
      });
    },*/
    async openChild(text, event) {
      this.selectTextParent(text.parentText);
      const popover = await popoverController.create({
        component: textPopover,
        cssClass: "date-popover",
        showBackdrop: true,
        event: event,
        componentProps: {
          texts: text.childTexts,
          update: (product) => this.selectTextParent(product),
        },
      });
      await popover.present();

      const { role } = await popover.onDidDismiss();
    },
    async selectTextParent(val) {
      if (val == null) {
        await popoverController.dismiss();
      }
      this.selectedTextParent = val;
    },
    async showMentioned(mentioned, linkType) {
      if (linkType == "event") {
        await this.$store.dispatch("getMentionedEvent", mentioned);
      } else if (linkType == "guest") {
        await this.$store.dispatch("getMentionedGuest", mentioned);
        const eventData = await this.$store.getters.getMentionedGuest;
        const modal = await modalController.create({
          component: QuickGuestInfo,
          componentProps: { guest: eventData },
          initialBreakpoint: 0.7,
          breakpoints: [0.7, 0.8],
        });
        await modal.present();
      }
    },
    async selectUser(user, where) {
      this.messages = null;
      const upData = {
        to: parseInt(localStorage.getItem("awqatiUserId")),
        group: parseInt(localStorage.getItem("awqatiUserGroup")),
      };
      if (where == "in") {
        await this.$store.commit("setEventForChat", "");
        //this.selectedEventForChat = ''
      }
      this.$store.commit("setSelectedUserForChat", user);
      let payload = {
        from: user.id,
        to: parseInt(localStorage.getItem("awqatiUserId")),
        group: parseInt(localStorage.getItem("awqatiUserGroup")),
      };
      await this.$store.dispatch("updateUnseenChat", payload);
      await this.$store.dispatch("getUserChatByID", payload);
      await this.$store.dispatch("getTotalOfUnseenChatsFromUsers", upData);
      await this.$store.dispatch("getTotalOfAllUnseenChat", upData);

      this.messages = document.getElementById("messages");
      this.scrollToBottom();
    },
    async selectUserForMobile(user, where) {
      if (where == "in") {
        await this.$store.commit("setEventForChat", "");
        //this.selectedEventForChat = ''
      }
      await this.$store.commit("setSelectedUserForChat", user);

      this.openMobileChat(user);
    },
    async openMobileChat(user) {
      const modal = await modalController.create({
        component: UserChatMobile,
        cssClass: "my-custom-class",
        componentProps: {
          fromUser: user,
        },
      });
      return modal.present();
    },
    updateCurrentTime() {
      this.currentTime = moment(new Date());
    },
    handleSend(e) {
      if (e.keyCode == 13) {
        this.sendChat();
      }
    },
    async sendChat() {
      let linkValue = 0;
      let linkTitle = null;
      let linkType = null;
      if (this.selectedEventForChat != "") {
        linkValue = this.selectedEventForChat.id;
        linkType = this.selectedEventForChat.linkType;
        linkTitle =
          this.selectedEventForChat.linkType == "event"
            ? this.selectedEventForChat.title
            : this.selectedEventForChat.title +
              " - " +
              JSON.parse(this.selectedEventForChat.person)[0].name;
      }

      if (this.chatText != "") {
        let payload = {
          text: this.chatText,
          from: parseInt(localStorage.getItem("awqatiUserId")),
          to: this.$store.getters.getSelectedUserForChat.id, //this.selectedUser.id,
          group: parseInt(localStorage.getItem("awqatiUserGroup")),
          link: linkValue,
          seen: 0,
          link_text: linkTitle,
          link_type: linkType,
          reply_to: this.replyTo,
          reply_text: this.replyText,
          attachments: null,
        };
        await this.$store.dispatch("sendNewChat", payload);
        await this.$store.commit("setEventForChat", "");
        await this.$store.commit("setMentionedUser", "");
        this.clearChat();
        document.getElementById("chat-text").focus();
        this.messages = document.getElementById("messages");
        setTimeout(() => {
          this.scrollToBottom();
        }, 800);
      }
    },
    clearChat() {
      this.chatText = "";
      //this.selectedEventForChat = ''
      this.$store.commit("setEventForChat", "");
    },
    addUserToNotification(user) {
      const found = this.newChatfromUsers.some((el) => el == user);
      if (!found) this.newChatfromUsersList.push(user);
      //return arr;
    },
    async removeMention() {
      await this.$store.commit("setMentionedUser", "");
      this.$store.commit("setEventForChat", "");
      //this.selectedEventForChat = ''
    },
    appendMessage() {
      const message = document.getElementsByClassName("message")[0];
      const newMessage = message.cloneNode(true);
      this.messages.appendChild(newMessage);
    },
    getMessages() {
      // Prior to getting your messages.

      /*
       * Get your messages, we'll just simulate it by appending a new one syncronously.
       */
      this.appendMessage();
      // After getting your messages.
      if (
        this.messages.scrollTop + this.messages.clientHeight ===
          this.messages.scrollHeight &&
        this.messages !== null
      ) {
        this.scrollToBottom();
      }
    },
    scrollToBottom() {
      if (this.messages !== null) {
        this.messages.scrollTop = this.messages.scrollHeight;
      }
    },
    async getNewMsgAndUpdate(data) {
      await this.$store.dispatch("getUserChatByID", data);
      let payload = {
        from: this.$store.getters.getSelectedUserForChat.id, //this.selectedUser.id,
        to: parseInt(localStorage.getItem("awqatiUserId")),
        group: parseInt(localStorage.getItem("awqatiUserGroup")),
      };
      await this.$store.dispatch("getTotalOfAllUnseenChat", data);
      await this.$store.dispatch("getTotalOfUnseenChatsFromUsers", data);
      await this.$store.dispatch("updateUnseenChat", payload);
      this.scrollToBottom();
    },
    clearChatText() {
      this.chatText = "";
    },
    async socketFun() {
      this.$socket.on("checkUserForChat", (data) => {
        const upData = data;
        console.log("START CHECK USER");
        if (data.to == localStorage.getItem("awqatiUserId")) {
          console.log("IS (to) USER !");
          if (
            this.$store.getters.getSelectedUserForChat != "" &&
            this.$store.getters.getSelectedUserForChat.id == data.from
          ) {
            console.log("Its current user !!");
            this.getNewMsgAndUpdate(data);
          } else {
            console.log("Its Not current user !!");
            //this.addUserToNotification(data.from)
            //console.log(JSON.stringify(upData) + "This is the data");
            //this.$store.dispatch("getTotalOfUnseenChatOfUserByID", upData);
            //this.inUserNotification.push()
            return;
          }
          this.$store.dispatch("getTotalOfUnseenChatsFromUsers", upData);
        } else {
          console.log("NOT (to) USER !");
          return;
        }
      });
    },
  },
  computed: {
    getSkeletonLoading() {
      return this.$store.getters.getSkeletonLoading;
    },
    getChatDataLoading() {
      return this.$store.getters.getChatDataLoading;
    },
    getSendingChatLoading() {
      return this.$store.getters.getSendingChatLoading;
    },
    getChatText() {
      return this.chatText;
    },
    getScrollHeight() {
      return this.scrollHight;
    },
    thereismentioned() {
      return this.$store.getters.getMetntionedUser;
    },
    selectedEventForChat() {
      return this.$store.getters.getEventForChat;
    },
    getCurrentTime() {
      return this.currentTime;
    },
    usersGroup() {
      return this.$store.getters.getUsersByGroup;
    },
    getSelectedUser() {
      return this.$store.getters.getSelectedUserForChat; //this.selectedUser
    },
    getChatOfUserById() {
      return this.$store.getters.getChatOfUserById;
    },
    getMyID() {
      return parseInt(localStorage.getItem("awqatiUserId"));
    },
    newChatfromUsers() {
      return this.newChatfromUsersList;
    },
    getTotalOfAllUnseenChat() {
      return this.$store.getters.getTotalOfAllUnseenChat;
    },
    /*getTotalOfUnseenChatOfUserById() {
      return this.$store.getters.getTotalOfUnseenChatOfUserById;
    },*/
    getTotalOfUnseenChatsFromUsers() {
      return this.$store.getters.getTotalOfUnseenChatsFromUsers;
    },
    viewEventAsideStatus() {
      return this.$store.getters.viewEventAsideStatus;
    },
    getEditEventAsideStatus() {
      return this.$store.getters.getEditEventAsideStatus;
    },
  },
  watch: {
    getTotalOfAllUnseenChat() {
      console.log("changgggggggg!");
      this.socketFun();
    },
    /*async $route(to, from, next) {
      if (to.name == "Chat") {
        await this.setUpAgain();
        this.callSocket();
        next;
      }
      next;
    },*/
    thereismentioned(newCount, oldCount) {
      if (newCount != "") {
        if (window.innerWidth <= 400) {
          return this.selectUserForMobile(
            this.$store.getters.getMetntionedUser,
            "out"
          );
        }
      }
    },
  },
  async created() {
    this.$socket.on("checkUserForChat", (data) => {
      const upData = data;
      console.log("START CHECK USER");
      if (data.to == localStorage.getItem("awqatiUserId")) {
        console.log("IS (to) USER !");
        if (
          this.$store.getters.getSelectedUserForChat != "" &&
          this.$store.getters.getSelectedUserForChat.id == data.from
        ) {
          console.log("Its current user !!");
          this.getNewMsgAndUpdate(data);
        } else {
          console.log("Its Not current user !!");
          //this.addUserToNotification(data.from)
          //console.log(JSON.stringify(upData) + "This is the data");
          this.$store.dispatch("getTotalOfUnseenChatsFromUsers", upData);
          //this.$store.dispatch("getTotalOfUnseenChatOfUserByID", upData);
          //this.inUserNotification.push()
          return;
        }
      } else {
        console.log("NOT (to) USER !");
        return;
      }
    });
    this.interval = setInterval(() => this.updateCurrentTime(), 30000);
  },
  async mounted() {
    this.$store.commit("setSelectedUserForChat", "");
    let payload = {
      group: parseInt(localStorage.getItem("awqatiUserGroup")),
      me: parseInt(localStorage.getItem("awqatiUserId")),
    };
    await this.$store.dispatch("getUsersByGroup", payload);
    await this.$store.dispatch("getTotalOfUnseenChatsFromUsers", {
      to: parseInt(localStorage.getItem("awqatiUserId")),
      group: parseInt(localStorage.getItem("awqatiUserGroup")),
    });
    /*const users = await this.$store.getters.getUsersByGroup;
      if (users && users.length > 0) {
        users.forEach((user) => {
          this.$store.dispatch("getTotalOfUnseenChatOfUserByID", {
            from: user.id,
            to: parseInt(localStorage.getItem("awqatiUserId")),
            group: parseInt(localStorage.getItem("awqatiUserGroup")),
          });
        });
      }*/
  },
  unmounted() {
    /*
      if(this.selectedUserValue != '') {
        this.selectUser(this.selectedUserValue)
      }
    */
  },
  beforeUnmount() {},
  async beforeDestroy() {
    clearInterval(this.interval);
  },
  beforeUpdate() {
    this.scrollToBottom();
  },
};
</script>

<style scoped>
input {
  border: none !important;
  outline: none !important;
}

input:focus {
  border: none;
}
</style>
