<template>
  <ion-header>
    <ion-toolbar>
      <ion-button
        slot="end"
        :disabled="!requiredData"
        color="primary"
        @click="addGuest()"
        >إضافة</ion-button
      >
      <ion-title>إضافة ضيف</ion-title>
      <ion-buttons slot="start">
        <ion-button @click="dismiss()" color="danger">إلغاء</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content dir="rtl" color="light">
    <ion-list :inset="true">
      <ion-item>
        <ion-label>الموضوع</ion-label>
        <input
          type="text"
          name="person-name"
          id="person-name"
          v-model="this.guestTitle"
          autocomplete="off"
          class="block w-[70%] bg-slate-50 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none px-1 focus:border focus:border-indigo-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
        />
      </ion-item>
      <ion-item>
        <ion-input label="الضيف/الضيوف"></ion-input>
        <span
          @click="openPickPerson"
          class="inline-flex w-full rounded-lg items-center justify-center gap-x-2 ml-20 mr-6 py-1 pl-2.5 pr-1 text-md font-medium bg-blue-50 text-blue-700"
        >
          <button
            class="flex-shrink-0 h-4 w-4 rounded-lg inline-flex items-center justify-center text-blue-400"
          >
            <BaseIcon
              name="Plus"
              class="w-5 h-5 text-blue-600"
              strokeWidth="0.5"
            />
          </button>
          <span class="mt-1">إضافة</span>
        </span>
      </ion-item>
      <ion-item v-if="getSelectedPersons.length > 0">
        <ion-label>
          <span
            v-for="(person, index) in getSelectedPersons"
            :key="index"
            class="inline-flex rounded-lg items-center py-0.5 pl-2.5 pr-1 m-1 text-sm font-medium bg-indigo-100 text-indigo-700"
          >
            {{ person.name }}
            <button
              @click="removePerson(index)"
              type="button"
              class="flex-shrink-0 ml-0.5 mr-2 h-4 w-4 rounded-lg inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white"
            >
              <svg
                class="h-2 w-2"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 8 8"
              >
                <path
                  stroke-linecap="round"
                  stroke-width="1.5"
                  d="M1 1l6 6m0-6L1 7"
                />
              </svg>
            </button>
          </span>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label> التاريخ والوقت </ion-label>
        <ion-datetime-button
          :value="this.currentDate"
          datetime="datetime"
        ></ion-datetime-button>
        <ion-modal :keep-contents-mounted="true">
          <ion-datetime
            v-model="this.values"
            :value="this.currentDate"
            dir="rtl"
            id="datetime"
            locale="ar-SA"
          ></ion-datetime>
        </ion-modal>
      </ion-item>
      <ion-item>
        <ion-label>الحالة</ion-label>

        <RadioGroup>
          <div class="w-full gap-x-6 my-1 flex items-center justify-between">
            <RadioGroupOption
              as="template"
              v-for="status in this.getStatusList"
              :key="status.id"
              @click="selectStatus(status)"
            >
              <div
                class="w-18 relative rounded-lg shadow-sm py-1 px-2 flex cursor-pointer focus:outline-none"
                :class="
                  getSelectedStatus.value == status.value
                    ? `${status.bg_color_class} bg-opacity-10`
                    : 'bg-gray-50'
                "
              >
                <div class="flex-1 flex items-center gap-x-1">
                  <span
                    :class="
                      getSelectedStatus.value == status.value
                        ? `${status.bg_color_class}`
                        : 'bg-gray-200'
                    "
                    class="w-2.5 h-2.5 ml-px rounded-full"
                    aria-hidden="true"
                  />
                  <div class="flex flex-col">
                    <RadioGroupLabel
                      as="span"
                      class="mt-1 block text-sm font-medium"
                      :class="
                        getSelectedStatus.value == status.value
                          ? `${status.txt_color_class}`
                          : 'text-gray-400'
                      "
                    >
                      {{ status.name }}
                    </RadioGroupLabel>
                  </div>
                </div>
                <BaseIcon
                  v-if="status.value == 'approved'"
                  name="UserCheck"
                  class="h-4 w-4 mr-2 mt-1"
                  strokeWidth="0.1"
                  :class="
                    getSelectedStatus.value == 'approved'
                      ? `${status.txt_color_class}`
                      : 'text-gray-400'
                  "
                  aria-hidden="true"
                />
                <BaseIcon
                  v-else
                  name="UserAlert"
                  class="h-4 w-4 mr-2 mt-1"
                  strokeWidth="0.1"
                  :class="
                    getSelectedStatus.value == 'waiting'
                      ? `${status.txt_color_class}`
                      : 'text-gray-400'
                  "
                  aria-hidden="true"
                />
                <div
                  class="absolute -inset-px rounded-lg pointer-events-none"
                  aria-hidden="true"
                />
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>
      </ion-item>
    </ion-list>
    <ion-note color="medium" class="pb-8 text-xs ion-margin-horizontal">
      <span><strong>مؤكد: </strong>تمت الموافقة على حضور الضيف</span> <br />
      <span class="mr-4"
        ><strong>غير مؤكد: </strong>لم تتم الموافقة على حضور الضيف</span
      >
    </ion-note>

    <ion-list :inset="true">
      <ion-item>
        <ion-textarea
          v-model="this.guestNote"
          label="الملاحظات"
          label-placement="floating"
          rows="5"
        ></ion-textarea>
      </ion-item>
    </ion-list>

    <ion-list :inset="true">
      <ion-item>
        <ion-label>ملفات الضيف</ion-label>
        <div
          class="w-[70%] flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6"
        >
          <div class="flex w-0 flex-1 items-center">
            <label
              for="meeting-files"
              class="flex justify-center items-center gap-x-3 w-full cursor-pointer rounded-md text-sm text-white font-semibold px-3 py-2 bg-slate-600 focus-within:outline-none shadow-sm hover:bg-slate-500"
            >
              <BaseIcon
                name="Browse"
                className="text-white w-4 h-4 rtl:ml-1 ltr:mr-1"
              />
              <span class="mt-1">أضف ملف</span>
              <input
                id="meeting-files"
                name="meeting-files"
                type="file"
                class="sr-only"
                @change="onFileChange($event.target.name, $event.target.files)"
                ref="file"
                multiple
                accept="image/*,.pdf"
              />
            </label>
          </div>
        </div>
      </ion-item>
      <template v-if="getGuestFiles.length > 0">
        <ion-item v-for="(file, idx) in getGuestFiles" :key="file">
          <ion-label>
            <div class="w-[100%] flex items-center gap-x-3">
              <div
                class="w-24 h-24 group block overflow-hidden rounded-lg bg-gray-100 shadow"
              >
                <a
                  v-if="file.ext !== 'pdf'"
                  :href="file.URL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    :src="file.URL"
                    alt="image"
                    class="group-hover:opacity-75 cursor-pointer object-cover object-center"
                  />
                </a>
                <a
                  v-else
                  :href="file.URL"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="flex-initial ml-2 px-2 py-1 flex items-center justify-center text-xs rounded-md font-semibold text-purple-500"
                >
                  <BaseIcon name="PDF" className="w-24 h-24 mr-2 -mt-1" />
                </a>
              </div>
              <div class="w-[100%] flex justify-between items-center">
                <div
                  class="mr-2 flex flex-col items-start self-end min-w-0 flex-1 gap-2"
                >
                  <input
                    v-model="this.guestFiles[idx].description"
                    type="member-files"
                    name="member-files"
                    id="member-files"
                    autocomplete="off"
                    class="block w-full text-xs rounded-md border-0 px-2 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                    placeholder="اسم الملف"
                  />
                  <span class="text-xs flex-shrink-0 text-gray-400">{{
                    file.name
                  }}</span>
                  <span class="text-xs flex-shrink-0 text-gray-400"
                    >{{ file.size }} MB</span
                  >
                </div>
                <div class="mr-4 flex-shrink-0">
                  <BaseIcon
                    @click="removeFile(idx)"
                    :name="'Trash'"
                    :className="'w-9 h-9 p-2 rounded-full text-rose-600 border-full hover:bg-rose-50 cursor-pointer'"
                    :strokeWidth="'0.2'"
                  />
                </div>
              </div>
            </div>
          </ion-label>
        </ion-item>
        <div
          v-if="getGuestFiles.length > 1"
          class="w-full flex items-center justify-end mb-3 -mr-3"
        >
          <div
            @click="removeAllFiles()"
            class="w-auto flex items-center gap-x-3 px-3 py-1.5 rounded-md bg-rose-600 text-white"
          >
            <BaseIcon name="Trash" className="w-4 h-4" :strokeWidth="'0.2'" />
            <span class="text-sm mt-1">حذف جميع الملفات</span>
          </div>
        </div>
      </template>
    </ion-list>

    <ion-note color="medium" class="py-8 text-xs ion-margin-horizontal">
      صيغ الملفات PDF, jpeg, png, gif, jpg
    </ion-note>
  </ion-content>
</template>

<script>
import moment from "moment-hijri";
moment.updateLocale("ar-sa", {
  postformat: (string) => string,
});
import BaseIcon from "./BaseIcon.vue";
import NewPersonModal from "../components/NewPersonModalMobile.vue";
import PickPersonModal from "../components/PickPerson.vue";
import {
  IonPopover,
  IonInput,
  IonTextarea,
  IonSearchbar,
  popoverController,
  IonButtons,
  IonButton,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonList,
  IonItem,
  IonCheckbox,
  IonNote,
  IonDatetime,
  IonDatetimeButton,
  modalController,
  alertController,
} from "@ionic/vue";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
export default {
  data() {
    return {
      moment: moment,
      currentDate: moment(new Date()),
      values: null,
      date: "",
      selectedPersons: [],
      guestTitle: "",
      guestNote: "",
      getStatusList: [
        {
          name: "غير مؤكد",
          value: "waiting",
          bg_color_class: "bg-yellow-700",
          txt_color_class: "text-yellow-700",
        },
        {
          name: "مؤكد",
          value: "approved",
          bg_color_class: "bg-green-700",
          txt_color_class: "text-green-700",
        },
      ],
      guestFiles: [],
      selectedStatus: {
        name: "غير مؤكد",
        value: "waiting",
        bg_color_class: "bg-yellow-700",
        txt_color_class: "text-yellow-700",
      },
    };
  },
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    IonButtons,
    IonButton,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonList,
    IonItem,
    IonCheckbox,
    IonNote,
    IonSearchbar,
    BaseIcon,
    IonPopover,
    IonInput,
    IonTextarea,
    IonDatetime,
    IonDatetimeButton,
    PickPersonModal,
  },
  methods: {
    // Guest Files //
    onFileChange(fieldName, file) {
      //const loading = await bla bla;
      this.alerts = [];
      const formData = new FormData();
      if (file.length > 0) {
        for (let index = 0; index < file.length; index++) {
          let upFile = file[index];
          let lastDot = file[index].name.lastIndexOf(".");
          let ext = file[index].name.substring(lastDot + 1);
          let fileName = upFile.name;

          let size = upFile.size / 1000;
          if (
            !ext == "jpg" ||
            !ext == "jpeg" ||
            !ext == "png" ||
            !ext == "gif" ||
            !ext == "pdf"
          ) {
            this.setAlert("خطأ", "صيغة الملف غير مدعومة", ["حسناً"]);
          } else {
            let fileURL = URL.createObjectURL(upFile);
            formData.append(fieldName, file[index]);
            this.$emit("input", { formData, fileURL });
            this.fileURL = { formData, fileURL };

            let fileNameExt = "";
            fileNameExt = `${moment().format("x")}${index}.${ext}`;

            if (this.guestFiles) {
              this.guestFiles.push({
                data: upFile,
                URL: fileURL,
                name: fileNameExt,
                description: "",
                ext,
                size,
              });
            } else {
              this.guestFiles = [
                {
                  data: upFile,
                  URL: fileURL,
                  name: fileNameExt,
                  description: "",
                  ext,
                  size,
                },
              ];
            }
          }
        }
        this.$refs.file.value = "";
      }
    },
    removeFile(index) {
      this.idxHolder = index;
      this.setAlert("تنبيه", "هل تريد حذف الملف", [
        {
          text: "إلغاء",
          role: "cancel",
          handler: () => {
            console.log("Alert canceled");
          },
        },
        {
          text: "تأكيد",
          role: "confirm",
          handler: () => {
            this.guestFiles.splice(this.idxHolder, 1);
          },
        },
      ]);
    },
    removeAllFiles() {
      this.setAlert("تنبيه", "هل تريد حذف جميع الملفات", [
        {
          text: "إلغاء",
          role: "cancel",
          handler: () => {
            console.log("Alert canceled");
          },
        },
        {
          text: "تأكيد",
          role: "confirm",
          handler: () => {
            this.guestFiles = [];
          },
        },
      ]);
    },
    async setAlert(header, message, button) {
      const alert = await alertController.create({
        header,
        message,
        buttons: button,
      });

      await alert.present();
    },

    async openPickPerson() {
      const modal = await modalController.create({
        component: PickPersonModal,
        initialBreakpoint: 0.6,
        backdropBreakpoint: 1,
        cssClass: "pick-person-modal-block",
      });

      await modal.present();

      return modal.onDidDismiss().then((data) => {
        if (data) {
          if (data.data) {
            if (
              this.selectedPersons.find(
                (person) => person.name == data.data.name
              )
            ) {
              return;
            }
            this.selectedPersons.push(data.data);
          } else {
            return;
          }
        }
      });
    },
    dismiss() {
      modalController.dismiss();
    },
    selectStatus(status) {
      this.selectedStatus = status;
    },
    async openPicker() {
      this.$store.commit("toggleTimePickerGuest", true);
    },
    async openDatePopover() {
      /*const popover = await popoverController.create({
        component: Popover,
        cssClass: "date-popover",
        showBackdrop: true,
      });
      await popover.present();

      const { role } = await popover.onDidDismiss();*/
      //this.$store.commit("toggleDatePopover", true);
      this.$store.commit("toggleDatePopoverGuest", true);
    },
    confirmTime() {
      popoverController.dismiss();
    },
    async closeSide() {
      await this.clearData();
      await this.$store.commit("toggleNewGuestAside", false);
    },
    selectPerson(val) {
      if (this.selectedPersons.find((person) => person.name == val.name)) {
        return;
      }
      this.selectedPersons.push(val);
    },
    removePerson(val) {
      this.selectedPersons.splice(val, 1);
    },
    async addGuest() {
      this.$store.commit("toggleNewGuestAside", false);
      const persons = [];
      if (this.selectedPersons.length != 0) {
        this.selectedPersons.map(function (value) {
          persons.push({
            name: value.name,
            position: value.position,
          });
        });
      }
      const guestData = {
        title: this.guestTitle,
        arDate: this.selectedFromTime.arDate,
        enDate: this.selectedFromTime.enDate,
        time: JSON.stringify(this.selectedFromTime.time),
        person: JSON.stringify(persons),
        note: this.guestNote,
        user: localStorage.getItem("awqatiUser"),
        attachments: this.guestFiles,
        group: parseInt(localStorage.getItem("awqatiUserGroup")),
        status: this.selectedStatus.value,
      };
      //await this.$store.commit('setDayEventsList', guestData)
      await this.$store.dispatch("saveNewGuest", guestData);
      this.clearData();
    },
    clearData() {
      //this.$store.commit("setNewGuestSelectedDay", "");
      //this.$store.commit("resetNewGuestSelectedTime");
      //this.$store.commit("restNewGuestSelectedDay");
      this.selectedPersons = [];
      this.guestTitle = "";
      this.guestNote = "";
    },
    async openNewPersonModal() {
      await popoverController.dismiss();
      this.$store.commit("toggleNewPersonModal", true);
    },
    async openNewPersonModalMobile() {
      //await modalController.dismiss();
      const modal = await modalController.create({
        component: NewPersonModal,
        cssClass: "my-custom-class",
        componentProps: {
          title: "New Title",
        },
      });
      return modal.present();
    },
    async openNewPlaceModal() {
      await popoverController.dismiss();
      this.$store.commit("toggleNewPlaceModal", true);
    },
    groupBy(xs) {
      let officesNames = [];
      xs.forEach((element) => {
        if (
          officesNames.find(
            (office) => office == JSON.parse(element.office).office
          )
        ) {
          return;
        }
        officesNames.push(JSON.parse(element.office).office);
      });
      return officesNames;
    },
  },
  computed: {
    getGuestFiles() {
      return this.guestFiles;
    },
    requiredData() {
      if (this.guestTitle !== "" && this.selectedPersons.length != 0) {
        return true;
      }
      return false;
    },
    modalStatus() {
      return this.$store.getters.NewGuestAsideStatus;
    },
    seletedDay() {
      return this.$store.getters.newGuestSelectedDay;
    },
    selectedFromTime() {
      let currentDateTime =
        this.values !== null ? this.values : this.currentDate;
      let dateTimeInfo = {
        arDate: moment(currentDateTime).format("iYYYY/iM/iD"),
        enDate: moment(currentDateTime).format("YYYY/M/D"),
        time: {
          hour: moment(currentDateTime).format("hh"),
          minute: moment(currentDateTime).format("mm"),
          apm: moment(currentDateTime).format("a"),
        },
      };
      return dateTimeInfo;
    },
    offices() {
      let list = this.$store.getters.getPersonsList;
      return this.groupBy(list);
    },
    getSelectedPersons() {
      return this.selectedPersons;
    },
    getSelectedStatus() {
      return this.selectedStatus;
    },
    getDatePopoverStatus() {
      return this.$store.getters.getDatePopoverStatus;
    },
    requiredData() {
      if (this.guestTitle !== "" && this.selectedPersons.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  async created() {
    await this.$store.dispatch("getOffices");
    await this.$store.dispatch("getPersons");
  },
};
</script>
