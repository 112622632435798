<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>قائمة الموظفين</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content v-if="getIsLoading.chat" dir="rtl" color="light">
    <div
      class="w-full h-full mx-auto my-[60%] flex itemc-center justify-center"
    >
      <div class="fixed inset-0 bg-gray-500 bg-opacity-20 transition-opacity" />
      <ion-spinner name="dots" color="dark"></ion-spinner>
    </div>
  </ion-content>
  <ion-content v-else dir="rtl" color="light">
    <ion-list v-if="getIsLoading.users" :inset="true">
      <ion-item class="animate-pulse">
        <ion-avatar aria-hidden="true" slot="start">
          <div class="rounded-full bg-slate-400 h-7 w-7"></div>
        </ion-avatar>
        <ion-label>
          <div class="h-2 bg-slate-300 rounded"></div>
        </ion-label>
      </ion-item>
      <ion-item class="animate-pulse">
        <ion-avatar aria-hidden="true" slot="start">
          <div class="rounded-full bg-slate-400 h-7 w-7"></div>
        </ion-avatar>
        <ion-label>
          <div class="h-2 bg-slate-300 rounded"></div>
        </ion-label>
      </ion-item>
    </ion-list>
    <ion-list v-else-if="!getIsLoading.users" :inset="true">
      <ion-item
        @click="goToChat(user)"
        :button="true"
        v-for="(user, idx) in usersList"
        :key="idx"
      >
        <ion-avatar aria-hidden="true" slot="start">
          <div
            class="h-7 w-7 rounded-full border border-gray-200 overflow-hidden bg-gray-100"
          >
            <svg
              class="h-full w-full text-gray-400"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
              ></path>
            </svg>
          </div>
        </ion-avatar>
        <ion-label>{{ user.name }}</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import {
  IonBackdrop,
  IonSpinner,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonContent,
  IonList,
  IonItem,
  IonAvatar,
  IonLabel,
  modalController,
} from "@ionic/vue";

export default {
  data() {
    return {
      isUsersLoading: false,
      isChatLoading: false,
    };
  },
  components: {
    IonBackdrop,
    IonSpinner,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonContent,
    IonList,
    IonItem,
    IonAvatar,
    IonLabel,
  },
  props: {
    payload: Object,
    eventData: Object,
    linkType: String,
  },
  computed: {
    getIsLoading() {
      return {
        users: this.isUsersLoading,
        chat: this.isChatLoading,
      };
    },
    usersList() {
      let valideUsers = this.$store.getters.getUsersByGroup.filter((user) => {
        return user.user_status != 4;
      });
      return valideUsers;
    },
  },
  methods: {
    async goToChat(user) {
      let payload = {
        from: user.id,
        to: parseInt(localStorage.getItem("awqatiUserId")),
        group: parseInt(localStorage.getItem("awqatiUserGroup")),
      };
      this.isChatLoading = true;
      this.eventData.linkType = this.linkType;
      await this.$store.commit("setEventForChat", "");
      await this.$store.commit("setMentionedUser", "");
      await this.$store.commit("resetChatOfUserById");
      await this.$store.commit("resetSelectedUserForChat");
      await this.$store.dispatch("updateUnseenChat", payload);
      await this.$store.dispatch("getUserChatByID", payload);
      await this.$store.commit("setSelectedUserForChat", user);
      await this.$store.commit("setEventForChat", this.eventData);
      await this.$store.commit("setMentionedUser", user);
      this.isChatLoading = false;
      await modalController.dismiss({ command: "openChat", userData: user });
    },
  },
  async mounted() {
    this.isUsersLoading = true;
    await this.$store.dispatch("getUsersByGroup", this.payload);
    this.isUsersLoading = false;
  },
};
</script>

<style scoped>
.spin {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
ion-spinner {
  width: 28px;
  height: 28px;
  stroke: #444;
  fill: #222;
}
</style>
