import { createRouter, createWebHistory } from "@ionic/vue-router";
import Intro from "../views/Intro.vue";
import Main from "../views/Main.vue";
import Chat from "../views/Chat.vue";
import Mobile from "../views/Mobile.vue";
import Settings from "../views/Settings.vue";
import guardRoute from "./guardRoute";

const routes = [
  {
    path: "/",
    redirect: "/intro",
  },
  {
    path: "/intro",
    name: "Intro",
    component: Intro,
  },
  {
    path: "/main",
    name: "Main",
    component: Main,
    beforeEnter: guardRoute,
  },
  {
    path: "/chat",
    name: "Chat",
    component: Chat,
    beforeEnter: guardRoute,
    props: true,
  },
  {
    path: "/mobile",
    name: "Mobile",
    component: Mobile,
    beforeEnter: guardRoute,
    props: true,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    beforeEnter: guardRoute,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(), //createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;
