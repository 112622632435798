<template>
  <div
    dir="rtl"
    class="fixed z-50 inset-0 overflow-hidden bg-gray-500 bg-opacity-75 transition-opacity"
  >
    <div
      class="flex items-end justify-center min-h-screen pb-20 text-center sm:block sm:p-0"
    >
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-x-hidden overflow-y-auto shadow-xl transform transition-all sm:my-8 sm:align-middle max-h-[85vh] w-[40rem]"
      >
        <div
          class="flex justify-start items-center sticky text-slate-800 top-0 z-40 py-4 px-4 max-h-16 bg-white border-b border-gray-200"
        >
          <div class="flex items-center gap-x-2 justify-start py-3 px-3">
            <BaseIcon name="UserPlus-D" className="w-7 h-7" strokeWidth="0.1" />
            <p class="text-2xl leading-6 font-semibold">إضافة مستخدم</p>
          </div>
        </div>
        <div
          class="grid px-12 py-6 grid-cols-1 gap-y-4 gap-x-4 bg-gray-50 h-full overflow-x-hidden overflow-y-auto"
        >
          <div class="sm:col-span-3 text-right">
            <label for="name" class="block text-sm font-medium text-gray-700">
              الاسم
            </label>
            <div class="mt-1">
              <ion-input
                type="text"
                name="name"
                id="name"
                autocomplete="off"
                v-model="this.name"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md text-indigo-700"
                style="
                  display: block;
                  width: 100%;
                  --padding-top: 6px;
                  --padding-start: 6px;
                  --padding-end: 6px;
                  --padding-bottom: 6px;
                  border-width: 1px;
                  --tw-border-opacity: 1;
                  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
                  --background: white;
                  --color: rgb(99, 102, 241);
                  outline: 2px solid transparent;
                  outline-offset: 2px;
                "
              ></ion-input>
            </div>
          </div>

          <div class="sm:col-span-3 text-right">
            <label
              for="user-name"
              class="block text-sm font-medium text-gray-700"
            >
              اسم المستخدم
            </label>
            <div class="mt-1">
              <ion-input
                type="text"
                name="user-name"
                id="user-name"
                autocomplete="off"
                v-model="this.userName"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md text-indigo-700"
                style="
                  display: block;
                  width: 100%;
                  --padding-top: 6px;
                  --padding-start: 6px;
                  --padding-end: 6px;
                  --padding-bottom: 6px;
                  border-width: 1px;
                  --tw-border-opacity: 1;
                  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
                  --background: white;
                  --color: rgb(99, 102, 241);
                  outline: 2px solid transparent;
                  outline-offset: 2px;
                "
              ></ion-input>
            </div>
          </div>

          <div class="sm:col-span-3 text-right">
            <label
              for="password"
              class="block text-sm font-medium text-gray-700"
            >
              كلمة المرور
            </label>
            <div class="mt-1">
              <ion-input
                type="password"
                name="password"
                id="password"
                autocomplete="off"
                v-model="this.password"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md text-indigo-700"
                style="
                  display: block;
                  width: 100%;
                  --padding-top: 6px;
                  --padding-start: 6px;
                  --padding-end: 6px;
                  --padding-bottom: 6px;
                  border-width: 1px;
                  --tw-border-opacity: 1;
                  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
                  --background: white;
                  --color: rgb(99, 102, 241);
                  outline: 2px solid transparent;
                  outline-offset: 2px;
                "
              ></ion-input>
            </div>
          </div>

          <div class="sm:col-span-3 text-right">
            <div class="col-span-2">
              <Listbox as="div">
                <ListboxLabel class="block text-sm font-medium text-gray-700">
                  المجموعة
                </ListboxLabel>
                <div
                  class="mt-1 relative border border-gray-300 rounded-lg shadow-sm"
                >
                  <ListboxButton
                    class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-right cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <span
                      class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                    >
                      <BaseIcon
                        name="ChevronDown"
                        class="h-5 w-5 text-gray-400 mt-px"
                        aria-hidden="true"
                      />
                    </span>
                    <span class="block truncate text-indigo-700"
                      >{{ selectedGroup.group || "اختر المجموعة" }}
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    >
                      <ListboxOption
                        as="template"
                        v-for="group in groups"
                        :key="group.id"
                      >
                        <li
                          @click="selectGroup(group)"
                          :class="[
                            selectedGroup.id == group.id
                              ? 'text-white bg-indigo-700'
                              : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-3 pr-9',
                          ]"
                        >
                          <span
                            :class="[
                              selectedGroup.id == group.id
                                ? 'font-semibold'
                                : 'font-normal',
                              'block truncate',
                            ]"
                          >
                            {{ group.group }}
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
          </div>

          <div class="sm:col-span-3 text-right">
            <div class="col-span-2">
              <Listbox as="div">
                <ListboxLabel class="block text-sm font-medium text-gray-700">
                  الصلاحية
                </ListboxLabel>
                <div
                  class="mt-1 relative border border-gray-300 rounded-lg shadow-sm"
                >
                  <ListboxButton
                    class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-right cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <span
                      class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                    >
                      <BaseIcon
                        name="ChevronDown"
                        class="h-5 w-5 text-gray-400 mt-px"
                        aria-hidden="true"
                      />
                    </span>
                    <span class="block truncate text-indigo-700"
                      >{{ selectedPrivilege.privilege || "اختر الجهة" }}
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    >
                      <ListboxOption
                        as="template"
                        v-for="privilege in privileges"
                        :key="privilege.id"
                      >
                        <li
                          @click="selectPrivilege(privilege)"
                          :class="[
                            selectedPrivilege.id == privilege.id
                              ? 'text-white bg-indigo-700'
                              : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-3 pr-9',
                          ]"
                        >
                          <span
                            :class="[
                              selectedPrivilege.id == privilege.id
                                ? 'font-semibold'
                                : 'font-normal',
                              'block truncate',
                            ]"
                          >
                            {{ privilege.privilege }}
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
          </div>

          <div class="sm:col-span-3 text-right">
            <div class="col-span-2">
              <Listbox as="div">
                <ListboxLabel class="block text-sm font-medium text-gray-700">
                  الحالة
                </ListboxLabel>
                <div
                  class="mt-1 relative border border-gray-300 rounded-lg shadow-sm"
                >
                  <ListboxButton
                    class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-right cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <span
                      class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                    >
                      <BaseIcon
                        name="ChevronDown"
                        class="h-5 w-5 text-gray-400 mt-px"
                        aria-hidden="true"
                      />
                    </span>
                    <span class="block truncate text-indigo-700"
                      >{{ selectedUserStatus.status || "اختر الحالة" }}
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    >
                      <ListboxOption
                        as="template"
                        v-for="status in getUserStatus"
                        :key="status.id"
                      >
                        <li
                          @click="selectUserStatus(status)"
                          :class="[
                            selectedUserStatus.id == status.id
                              ? 'text-white bg-indigo-700'
                              : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-3 pr-9',
                          ]"
                        >
                          <span
                            :class="[
                              selectedUserStatus.id == status.id
                                ? 'font-semibold'
                                : 'font-normal',
                              'block truncate',
                            ]"
                          >
                            {{ status.status }}
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
          </div>

          <div class="sm:col-span-3 text-right">
            <div class="col-span-2">
              <Listbox as="div">
                <ListboxLabel class="block text-sm font-medium text-gray-700">
                  الجهة
                </ListboxLabel>
                <div
                  class="mt-1 relative border border-gray-300 rounded-lg shadow-sm"
                >
                  <ListboxButton
                    class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-right cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <span
                      class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                    >
                      <BaseIcon
                        name="ChevronDown"
                        class="h-5 w-5 text-gray-400 mt-px"
                        aria-hidden="true"
                      />
                    </span>
                    <span class="block truncate text-indigo-700"
                      >{{ selectedOffice.office || "اختر الجهة" }}
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    >
                      <ListboxOption
                        as="template"
                        v-for="office in getOfficesList"
                        :key="office.id"
                      >
                        <li
                          @click="selectOffice(office)"
                          :class="[
                            selectedOffice.id == office.id
                              ? 'text-white bg-indigo-700'
                              : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-3 pr-9',
                          ]"
                        >
                          <span
                            :class="[
                              selectedOffice.id == office.id
                                ? 'font-semibold'
                                : 'font-normal',
                              'block truncate',
                            ]"
                          >
                            {{ office.office }}
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
          </div>

          <div class="sm:col-span-3 text-right">
            <label
              for="position-name"
              class="block text-sm font-medium text-gray-700"
            >
              المنصب
            </label>
            <div class="mt-1">
              <ion-input
                type="text"
                name="position-name"
                id="position-name"
                autocomplete="off"
                v-model="this.position"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md text-indigo-700"
                style="
                  display: block;
                  width: 100%;
                  --padding-top: 6px;
                  --padding-start: 6px;
                  --padding-end: 6px;
                  --padding-bottom: 6px;
                  border-width: 1px;
                  --tw-border-opacity: 1;
                  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
                  --background: white;
                  --color: rgb(99, 102, 241);
                  outline: 2px solid transparent;
                  outline-offset: 2px;
                "
              ></ion-input>
            </div>
          </div>

          <div class="sm:col-span-3 text-right">
            <label for="phone" class="block text-sm font-medium text-gray-700">
              رقم الجوال
            </label>
            <div class="mt-1">
              <ion-input
                type="tel"
                name="phone"
                id="phone"
                autocomplete="off"
                v-model="this.phone"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md text-indigo-700"
                style="
                  display: block;
                  width: 100%;
                  --padding-top: 6px;
                  --padding-start: 6px;
                  --padding-end: 6px;
                  --padding-bottom: 6px;
                  border-width: 1px;
                  --tw-border-opacity: 1;
                  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
                  --background: white;
                  --color: rgb(99, 102, 241);
                  outline: 2px solid transparent;
                  outline-offset: 2px;
                "
              ></ion-input>
            </div>
          </div>

          <div class="sm:col-span-3 text-right">
            <label for="email" class="block text-sm font-medium text-gray-700">
              البريد الالكتروني
            </label>
            <div class="mt-1">
              <ion-input
                type="text"
                name="email"
                id="email"
                autocomplete="off"
                v-model="this.email"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md text-indigo-700"
                style="
                  display: block;
                  width: 100%;
                  --padding-top: 6px;
                  --padding-start: 6px;
                  --padding-end: 6px;
                  --padding-bottom: 6px;
                  border-width: 1px;
                  --tw-border-opacity: 1;
                  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
                  --background: white;
                  --color: rgb(99, 102, 241);
                  outline: 2px solid transparent;
                  outline-offset: 2px;
                "
              ></ion-input>
            </div>
          </div>
        </div>
        <div
          dir="ltr"
          class="flex sticky bottom-0 z-40 py-2 justify-between bg-gray-50 px-4 sm:px-6 border-t border-gray-200"
        >
          <button
            @click="closeModal()"
            class="sm:col-span-1 mr-px py-3 px-4 text-lg font-semibold text-gray-500 focus:outline-none"
          >
            إلغاء
          </button>
          <button
            v-if="requiredData"
            @click="saveUser()"
            class="sm:col-span-2 ml-px bg-indigo-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-lg font-semibold text-white hover:bg-indigo-700 focus:outline-none"
          >
            حفظ
          </button>
          <button
            v-if="!requiredData"
            class="sm:col-span-2 ml-px bg-gray-200 border border-transparent rounded-md shadow-sm py-3 px-4 text-lg font-light text-gray-400"
          >
            حفظ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseIcon from "../components/BaseIcon.vue";
import { IonInput } from "@ionic/vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    BaseIcon,
    IonInput,
  },
  data() {
    return {
      name: "",
      userName: "",
      password: "",
      group: "",
      privilege: "",
      userStatus: "",
      office: "",
      position: "",
      phone: "",
      email: "",
    };
  },
  methods: {
    selectOffice(office) {
      this.office = office;
    },
    selectPrivilege(privilege) {
      this.privilege = privilege;
    },
    selectGroup(group) {
      this.group = group;
    },
    selectUserStatus(userStatus) {
      this.userStatus = userStatus;
    },
    async saveUser() {
      const payload = {
        name: this.name,
        userName: this.userName,
        password: this.password,
        group: this.group.id,
        privilege: this.privilege.id,
        userStatus: this.userStatus.id,
        office: JSON.stringify(this.office),
        position: this.position,
        phone: this.phone,
        email: this.email,
      };
      await this.$store.dispatch("saveNewUser", payload);
      this.$store.commit("toggleNewUserModal", false);
    },
    closeModal() {
      this.$store.commit("toggleNewUserModal", false);
    },
  },
  computed: {
    requiredData() {
      if (
        this.name !== "" &&
        this.userName != "" &&
        this.password != "" &&
        this.group != "" &&
        this.privilege != "" &&
        this.userStatus != "" &&
        this.position != "" &&
        this.office !== ""
      ) {
        return true;
      }
      return false;
    },
    selectedOffice() {
      return this.office;
    },
    selectedPrivilege() {
      return this.privilege;
    },
    selectedGroup() {
      return this.group;
    },
    selectedUserStatus() {
      return this.userStatus;
    },
    getOfficesList() {
      return this.$store.getters.getOfficesList;
    },
    allUsers() {
      return this.$store.getters.getAllUsers;
    },
    privileges() {
      return this.$store.getters.getPrivileges;
    },
    groups() {
      return this.$store.getters.getGroups;
    },
    getUserStatus() {
      return this.$store.getters.getUserStatus;
    },
  },
  async created() {
    await this.$store.dispatch("getOffices");
    await this.$store.dispatch("getAllUsers");
    await this.$store.dispatch("getPrivileges");
    await this.$store.dispatch("getGroups");
    this.$store.dispatch("getUserStatus");
  },
};
</script>
